import React, {lazy, Suspense, useEffect, useState} from 'react'
import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
// import Translation from 'src/sections/BrochureOutputs/translationClientOutput'
import { useDispatch } from 'react-redux';
import { addDetails, addLanguageAndId } from '../redux/proposalSlice';
// import RafikyClient from 'src/sections/BrochureOutputs/RafikyOutput';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks

  return (
    <Suspense fallback={<div></div>}>
      <Component {...props} />
    </Suspense>
  );
};

export const BROCHURE_DETAILS = gql`
  query brochure($brochureId: ID!) {
    brochure(brochureId: $brochureId) {
      id
      brochureNumber
      companyLogo
      clients{
          name
          logo
        }
      industry {
        code
        name
      }
      companyNameAndId {
        id
        name
        paymentTerms
        address1
        address2
        city
        postcode
        VAT
      }
      accountManager {
        name
        email
        phoneNumber
        jobTitle
        avatarUrl
        language {
      code
      name
      nativename
    }
      }
      address1
  
      bookedBy{
        firstName
        lastName
        email
      }
      quote
      createdAtDate
      
    }
  }
`;

export default function BrochureClientOutput() {

  const {id, lang} = useParams()
  console.log(lang)
  const { loading, error, data } = useQuery(BROCHURE_DETAILS, {
    variables: { brochureId: id },
  });
  const dispatch = useDispatch()
  //creating function to load ip address from the API
  useEffect(() => {
    //passing getData method to the lifecycle method
    dispatch(addLanguageAndId({language:String(lang).toLowerCase() === "italian" ? "Italian" : "English", id:id}))    
    if(data){
      //  .then((res)=>console.log(res?.data))
      if(data?.brochure?.quote === "Rafiky"){
        dispatch(addDetails({details:{...data?.brochure, brand:{rafiky:true}}, type:""}))
      }else if(data?.brochure?.quote === "Interpretation"){
        dispatch(addDetails({details:{...data?.brochure, brand:{rafiky:false}}, type:""}))

      }else{
        dispatch(addDetails({details: data?.brochure, type:""}))
      }
     // dispatch(addDetails(data?.brochure))
      }
   }, [data])


  return (
    <>
     {data?.brochure?.quote === "Interpretation" && <InterpretClient />}
      {data?.brochure?.quote === "Translation" && <TranslationClientView/>}
      {data?.brochure?.quote === "Rafiky" &&  <RafikyBrochure language={lang} />}

    </>
  )
}
const TranslationClientView = Loadable(lazy(() => import('../translation/Brochure')));
const RafikyBrochure = Loadable(lazy(() => import('../interpretation/RafikyBrochure')));
const InterpretClient = Loadable(lazy(() => import('../interpretation/Brochure')));
