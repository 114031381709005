import { Box, Typography, Stack } from "@mui/material";
import Iconify from "./iconify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PiCaretCircleDoubleDownFill } from "react-icons/pi";

// ----------------------------------------------------------------------

export default function FixedButton({type="CI"}) {
  const { lang } = useParams();
  const [isAtQuoteSection, setIsAtQuoteSection] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);

  // Function to detect if the user has scrolled past or reached the #quote_info section
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const viewportHeight = window.innerHeight;
    const pageHeight = document.documentElement.scrollHeight;

    const quoteSection = document.getElementById("quote_info");
    const quoteSection1 = document.getElementById("quote_info1");
    if (quoteSection) {
      const quoteSectionTop = quoteSection.getBoundingClientRect().top + window.scrollY;
      const quoteSectionBottom = quoteSectionTop + quoteSection.offsetHeight;

      // Check if user has passed or reached the #quote_info section
      if (scrollPosition + viewportHeight > quoteSectionTop) {
        setIsAtQuoteSection(true);
      } else {
        setIsAtQuoteSection(false);
      }
    }else if(quoteSection1) {
      const quoteSectionTop1 = quoteSection1.getBoundingClientRect().top + window.scrollY;
      const quoteSectionBottom = quoteSectionTop1 + quoteSection1.offsetHeight;

      // Check if user has passed or reached the #quote_info section
      if (scrollPosition + viewportHeight > quoteSectionTop1) {
        setIsAtQuoteSection(true);
      } else {
        setIsAtQuoteSection(false);
      }
    }

    // Detect if we are within 50px of the bottom of the page
    if (scrollPosition + viewportHeight >= pageHeight - 50) {
      setIsAtBottom(true);
    } else {
      setIsAtBottom(false);
    }
  };

  // Attach the scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        p: 0.5,
        right: 10,
        bottom: 10,
        zIndex: 9999999999,
        position: "fixed",
        display: {
          xs: 'none',
          lg: "inherit",
        },
      }}
    >
      {!isAtQuoteSection && !isAtBottom && (
        <Box
          component="a"
          href={type === "LY" ? "#quote_info1" : "#quote_info"}
          style={{
            textDecoration: "none",
          }}
        >
          <Stack direction="column" alignItems="center">
            {/* <Iconify
              variant="contained"
              icon="solar:round-double-alt-arrow-down-bold"
              width={30}
              height={30}
              color="white"
              style={{objectFit:"contain"}}
            /> */}
            <PiCaretCircleDoubleDownFill  style={{color:"white", width:"30px", height:"30px"}}/>
            <Typography
              style={{
                textAlign: "center",
                color: "white",
                textDecoration: "none",
                fontWeight: 700,
              }}
            >
              {lang === 'English' ? "GO TO QUOTE DETAILS" : "DETTAGLI OFFERTA"}
            </Typography>
          </Stack>
        </Box>
      )}
      {isAtBottom && (
        <Box
          component="a"
          href={type === "LY" ? "#translation-proposal" : "#interpret-proposal"}
          style={{
            textDecoration: "none",
          }}
        >
          <Stack direction="column" alignItems="center">
            <Iconify
              variant="contained"
              icon="solar:round-double-alt-arrow-up-bold"
              width={30}
              height={30}
              color="white"
              style={{objectFit:"contain"}}

            />
            <Typography
              style={{
                textAlign: "center",
                color: "white",
                textDecoration: "none",
                fontWeight: 700,
              }}
            >
        {lang === 'English' ? "BACK TO TOP" : "TORNA ALL'INIZIO"}

            </Typography>
          </Stack>
        </Box>
      )}
    </Box>
  );
}
