import React, { lazy, Suspense, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {  addIp, addDetails, addLanguageAndId } from '../redux/proposalSlice';
import LingoAcademyProposal from '../translation/lingoAcademy';


export const TRANSLATION_QUOTE_DETAILS = gql`
  query TranslationOrder($translationOrderId: ID!) {
    translationOrder(translationOrderId: $translationOrderId) {
    id
    orderNumber
    notesOptions
    optionSelected
    utm
        campaignGclid
        source
        marketingChannel{
         type
         details{
          source
          sourceDetails{
            utm
            campaignGclid
            ppcSource
          cpn
          gad_source
          kwid
          adgroup
            keywords
          }
         }
        }
    language
    companyLogo
    taxes
    paymentLink{
        receipt_url
        url
        isPaid
        amount
      }
    quoteSignature
    totalDiscount
    grandTotal
    netTotal
    taxesOptions
    totalDiscountOptions
    netTotalOptions
    grandTotalOptions
    subtotalOptions
    workingDaysToDeadline
    reasonForClosure
    industry {
      code
      name
    }
    companyNameAndId {
      id
      name
      address1
    address2
    city
    postcode
    paymentTerms
    VAT
    }
    accountManager {
      name
      email
      avatarUrl
      jobTitle
      phoneNumber
      language {
      code
      name
      nativename
    }
    }
    translationItem {
      fileName
      title
      sourceLanguage {
      code
      name
      nativename
    }
    targetLanguage {
      code
      name
      nativename
    }
      service {
        Code
        InvCode
        Item{
          italian
        english
        }
        Description{
          italian
        english
        }
      }
      newWords
      repetitionWords
      totalWords
      pricePerWord
      total
      discount
    }
    translationItem2 {
      fileName
      title
      sourceLanguage {
      code
      name
      nativename
    }
    targetLanguage {
      code
      name
      nativename
    }
      service {
        Code
        InvCode
        Item{
          italian
        english
        }
        Description{
          italian
        english
        }
      }
      newWords
      repetitionWords
      totalWords
      pricePerWord
      total
      discount
    }
    deliveryDateTime
    quoteDeadline
    followUpDate
    subtotal
    linguistSubtotal
    bookedBy {
      firstName
      lastName
      email
    }
    status
    originalLink
    createdAtDate
    notes
    pmNotes
    additionalService {
      price
      title
      discount
      swornDeliveryDateTime
      quantity
      service{
        Item{
          italian
        english
        }
      Code
      InvCode
      Description{
        italian
        english
      }
      }
      serviceTotal
    }
    additionalService2 {
      price
      title
      discount
      swornDeliveryDateTime
      quantity
      service{
        Item{
          italian
        english
        }
      Code
      InvCode
      Description{
        italian
        english
      }
      }
      serviceTotal
    }
    addOns {
      seo
      cover
      website
      quoteInfo
      companyInfo
      packages
      urgency
      friendly
      sector
    }
    brand{
      lingoyou
      academy
    }
    clients{
          name
          logo
        }
  }
  }
`;

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};
function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
export default function TranslationClientOutput() {

  const {id, lang} = useParams()
  // const [sendNotification] = useMutation(PROPOSAL_NOTIFICATION)
  
   const dispatch = useDispatch()
 
  const { loading, error, data, refetch }= useQuery(TRANSLATION_QUOTE_DETAILS, {
    variables: { translationOrderId: id },
  });
  console.log({id,data})


  useEffect(()=>{
   async function storeData(){
    
    dispatch(addDetails({details: data?.translationOrder, type:"LY"}))
    dispatch(addLanguageAndId({language: lang ? capitalizeFirstLetter(lang) : data?.translationOrder?.language, id:id}))
   }
   storeData()
  },[data])
  return (
    <div>
      {data && data?.translationOrder?.brand?.academy  ? <LingoAcademyProposal refetch={refetch} /> : <Translation refetch={refetch}/> }
      </div>
  )
}
export const LoadingScreen = () => {
  return (
    <div>
      <div className="file-loader">
        <div className="file-circle">
          <div className="file-wave"></div>
          <div className="file-wave file-wave3"></div>
          <img
            src="/logo.png"
            alt="Loader Icon"
          />
        </div>
      </div>
    </div>

  )
}

const Translation = Loadable(lazy(() => import('../translation/Proposal')));
