import React from "react";
import { styles } from "../globalStyles";
import { Image, Link, Page, Text, View } from "@react-pdf/renderer";

const content = {
  items: [
      {
        city: {
          english: "Ribera",
          italian: "Ribera",
        },
        address: {
          english: "Viale Garibaldi 79, 92016, Ribera (AG)",
          italian: "Viale Garibaldi 79, 92016, Ribera (AG)",
        },
        email:"academy@lingoyou.com",
        width: "391px",
      },
      
    ],
  };
const ContactUs = ({  language, brand }) => {
  console.log({language})
  return (
    <Page wrap={false} size="A4" style={{ ...styles.page }}>
      <View
        style={{
          marginTop: "20px",
          marginLeft: "30px",
          width: "500px",
          paddingBottom: "5px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Image
          src={"/lingoAcademy/logos/academy-wh.png"}
          style={{ objectFit: "contain", width: "100px" }}
        />
        <View style={{ display: "flex", width: "160px", flexDirection: "row" }}>
          <Link href={"https://m.facebook.com/lingoyougroup/"}>  
          <Image
            style={{ objectFit: "contain", marginRight: "3px" }}
            src="/assets/icons/facebook.png"
          />
          </Link>
          <Link href={'https://x.com/lingoyou3'}> 

          <Image
            style={{ objectFit: "contain", marginRight: "3px" }}
            src="/assets/icons/twitter.png"
          />
          </Link>
          <Link href='https://linkedin.com/company/lingoyou'> 

          <Image
            style={{ objectFit: "contain", marginRight: "3px" }}
            src="/assets/icons/linkedIn.png"
          />
          </Link>
          <Link href={'https://www.instagram.com/lingo_you/'}> 

          <Image
            style={{ objectFit: "contain", marginRight: "3px" }}
            src="/assets/icons/insta.png"
          />
          </Link>
        </View>
      </View>
      <View
        style={{
          width: "500px",
          marginLeft: "30px",
          marginTop: "20px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {content.items.map((item) => (
          <View style={{ width: "160px", marginLeft: "10px" }}>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Image
                src={"/assets/icons/location.png"}
                style={{ objectFit: "contain", width: "10px", height: "10px" }}
              />
              <Text
                style={{ fontSize: "7px", color: "white", fontWeight: "bold" }}
              >
                {item.city[String(language).toLowerCase()]}
              </Text>
            </View>
            <Text
              style={{ fontSize: "7px", color: "white", fontWeight: "light" }}
            >
              {item.address[String(language).toLowerCase()]}
            </Text>
           
          </View>
        ))}
      </View>
      <View
        style={{
          width: "500px",
          marginLeft: "30px",
          marginTop: "20px",
          display: "flex",
          flexDirection: "row",
        }}
      >
         <View style={{ width: "200px", marginLeft: "10px" }}>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Image
                src={"/lingoAcademy/icons/globe.png"}
                style={{ objectFit: "contain", width: "10px", height: "10px" }}
              />
              <Text
                style={{ fontSize: "7px", color: "white", fontWeight: "bold" }}
              >
               {' '} {content.items[0]?.email}
              </Text>
            </View>
           
          </View>

      </View>
      <View
        style={{
          width: "500px",
          marginLeft: "30px",
          marginTop: "5px",
          display: "flex",
          flexDirection: "row",
        }}
      >
         <View style={{ width: "200px", marginLeft: "10px" }}>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Image
                src={"/lingoAcademy/icons/phone.png"}
                style={{ objectFit: "contain", width: "10px", height: "10px" }}
              />
              <Text
                style={{ fontSize: "7px", color: "white", fontWeight: "bold" }}
              >
               {" "}  +39 375 580 6530
              </Text>
            </View>
           
          </View>
          
      </View>
      <Image
        src={"/translation/aboutUs-footer-bg-pdf.png"}
        style={{
          objectFit: "cover",
          zIndex: "-1111",
          width: "101%",
          marginTop: "-280px",
          height: "290px",
        }}
      />
    </Page>
  );
};

export default ContactUs;
