import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';



const Innovation = ({ accountManager, language="English"}) => {
  return (
    <Page wrap={false} size="A4" style={{ ...styles.page }}>
    <View style={{ ...styles.header, marginBottom: 0, width: "100%", display:"flex", flexDirection:"row", marginHorizontal: "auto" }}>
      <Text style={{fontSize:"8px",marginTop:"2px", marginLeft:"5px", color:"#A158D6"}}>
      {language === 'English' ? 
      `TECHNOLOGIES AND INNOVATION` :
      `TECNOLOGIE E INNOVAZIONE`}      
        </Text>
         <View style={{marginLeft:"400px"}}>
         <Image style={styles.headerRightImg} src="/lingoAcademy/logos/academy.png" />
         </View>
        <View style={styles.headerItem} />
      </View>
      <View style={{display:"flex", marginRight:"3px", marginTop:"30px", justifyContent:"space-between", flexDirection:"row"}}>
        <View>
           <Image src={"/lingoAcademy/icons/point1.png"} style={{width:"90px", marginLeft:"10px",
             height:"auto", objectFit:"contain"}}/>
           <Text style={{fontSize:"7px",marginTop:"2px", marginLeft:"5px", padding:"5px", 
            border:"1px solid #2DA0F0", textAlign:"center", borderRadius:"5px", width:"130px", color:"#2DA0F0"}}>
      {language === "English" ? "Receive convenient and immediate offers":
               "Ricevere offerte convenienti e immediate"}
        </Text>
        </View>
        <View>
           <Image src={"/lingoAcademy/icons/point4.png"}style={{width:"90px", marginLeft:"20px", height:"auto", objectFit:"contain"}}/>
           <Text style={{fontSize:"7px",marginTop:"2px", marginBottom:"5px", marginLeft:"5px", padding:"5px", 
            border:"1px solid #2DA0F0", textAlign:"center", borderRadius:"5px", width:"130px", color:"#2DA0F0"}}>
      {language === "English" ? "Check complete course status reports with activity lists and calendars":
               "Controllare i report completi sullo status del corso con gli elenchi di attività e calendari"}
             
        </Text>
        </View>
      </View>
      <View style={{display:"flex", marginRight:"3px", justifyContent:"space-between", flexDirection:"row"}}>
        <View>
           <Image src={"/lingoAcademy/icons/point2.png"} style={{width:"90px", marginLeft:"10px", height:"auto", objectFit:"contain"}}/>
           <Text style={{fontSize:"7px",marginTop:"2px", marginLeft:"5px", padding:"5px", 
            border:"1px solid #2DA0F0", borderRadius:"5px", textAlign:"center", width:"130px", color:"#2DA0F0"}}>
      {language === "English" ? "Easily access all your teaching and learning materials in one place and across multiple devices" : 
                "Accedere facilmente a tutti i materiali didattici e di apprendimento in un unica piattaforma e su più dispositivi"}
         
        </Text>
        </View>
        <View>
           <Image src={"/lingoAcademy/icons/point5.png"}style={{width:"90px", marginLeft:"20px", 
            height:"auto", objectFit:"contain"}}/>
           <Text style={{fontSize:"7px",marginTop:"2px", marginLeft:"5px", padding:"5px", 
            border:"1px solid #2DA0F0", textAlign:"center", borderRadius:"5px", width:"130px", color:"#2DA0F0"}}>
     {language === "English" ? "Receive support and materials, anytime, anywhere" : 
                "Ricevere supporto e materiali, in qualsiasi momento e ovunque"}  </Text>
        </View>
      </View>
      <View style={{display:"flex", marginRight:"3px", marginTop:"14px", justifyContent:"space-between", flexDirection:"row"}}>
        <View>
           <Image src={"/lingoAcademy/icons/point3.png"} style={{width:"90px", marginLeft:"10px", height:"auto", objectFit:"contain"}}/>
           <Text style={{fontSize:"7px",marginTop:"2px", marginLeft:"5px", padding:"5px", 
            border:"1px solid #2DA0F0", textAlign:"center", borderRadius:"5px", width:"130px", color:"#2DA0F0"}}>
       {language === "English" ? "Upload and download files of any type" :
                 "Caricare e scaricare file di ogni tipo"}
        </Text>
        </View>
        <View>
           <Image src={"/lingoAcademy/icons/point6.png"}style={{width:"90px", marginLeft:"20px", height:"auto", objectFit:"contain"}}/>
           <Text style={{fontSize:"7px",marginTop:"2px", marginLeft:"5px", padding:"5px", 
            border:"1px solid #2DA0F0", textAlign:"center", borderRadius:"5px", width:"130px", color:"#2DA0F0"}}>
{language === "English" ? "Receive automatic email information and updates" : 
                "Ricevere informazioni e aggiornamenti e-mail automatici"} 
        </Text>
        </View>
      </View>
      <Image src={"/translation/privacy/bg.png"} style={{objectFit:"contain",
      transform:"scale(0.6)", marginTop:"-470px",
        zIndex:"-1111"}}/>
        <View style={{justifyContent:"center", marginLeft:"220px", marginTop:"-235px", marginInline:"auto", textAlign:"center"}}>
        <Text style={{display:"flex", backgroundColor:"#2DA0F0", flexDirection:"row",
            borderRadius: '7px', fontSize:"10px", width:"143px", padding:"9px", marginBottom:"30px"}}>
             <Image
                style={{ width: '15px', height: '12px', marginBottom:"-15px", paddingRight:"10px" }}
                src="/lingoAcademy/icons/learning-app.png"
              />
           <Text 
            style={{
            textAlign: 'center',
            color: '#FFFFFF',
            fontFamily: "Poppins 'sans-serif'",
            fontStyle: 'normal',
            fontWeight: 'normal',fontSize:"7px", marginTop:"-15px"}}>
      
      {language === 'English' ? 
      `TECHNOLOGIES AND INNOVATION` :
      `TECNOLOGIE E INNOVAZIONE`}               
       </Text>
            </Text> 
        <View style={{marginTop:"35px", marginBottom:"30px"}}>
           <Image src={"/lingoAcademy/icons/point7.png"}style={{width:"90px", marginLeft:"25px", height:"auto", objectFit:"contain"}}/>
           <Text style={{fontSize:"7px",marginTop:"2px", marginLeft:"8px", padding:"5px", 
            border:"1px solid #2DA0F0", textAlign:"center", borderRadius:"5px", textAlign:"center", width:"130px", color:"#2DA0F0"}}>
      {language === "English" ? "Collaborate with selected virtual team members":
               "Collaborare con i membri del team virtuale selezionato"}    </Text>
        </View>
        </View>
        <View style={{backgroundImage:"linear-gradient(to right, #A158D6, #CCF0FF)",
        flexDirection:"row", display:"flex", width:"100%",
        padding:"20px", marginLeft:"35px"}}>
        <View style={{ width:"150px", textAlign:"center", justifyContent:"center", alignItems:"center"}}>
        <Image src={"/lingoAcademy/innovation1.jpg"} style={{objectFit:"contain", 
            marginRight:"15px", width:"140px", height:"auto"}}/>
        <Text style={{fontSize:"8px", width:"130px", color:"white", fontWeight:"normal", marginTop:"5px", textAlign:"center"}}>
        {language === "English" ? "Flexible and customizable courses based on students' objectives" : 
               "Corsi flessibili e personalizzabili in base agli obiettivi degli studenti"}
        </Text>
        </View>
        <View style={{ width:"150px", textAlign:"center", marginLeft:"20px", justifyContent:"center", alignItems:"center"}}>
        <Image src={"/lingoAcademy/innovation2.jpg"} style={{objectFit:"contain", 
            marginRight:"15px", width:"140px", height:"auto"}}/>
        <Text style={{fontSize:"8px", width:"130px", color:"white", fontWeight:"normal", marginTop:"5px", textAlign:"center"}}>
        {language === "English" ? "365/365 Subscriptions and Customer Care available 24/7" : 
               "Iscrizioni 365/365 e Customer Care disponibile 24/7"}
        </Text>
        </View>         
        <View style={{ width:"150px", textAlign:'center', marginLeft:"20px", justifyContent:"center", alignItems:"center"}}>
        <Image src={"/lingoAcademy/innovation3.jpg"} style={{objectFit:"contain", 
            marginRight:"15px", width:"140px", height:"auto"}}/>
        <Text style={{fontSize:"8px",  width:"130px", color:"white", fontWeight:"normal", marginTop:"5px", textAlign:"center"}}>
        {language === "English" ? "Using the best learning platforms" : 
               "Impiego delle migliori piattaforme di apprendimento"}
        </Text>
        </View>
        </View>
        <Image src={"/translation/intro-cover-pdf.png"} style={{width:"130%",zIndex:"-11111", 
            marginLeft:"-100px", objectFit:"cover", marginTop:"-170px", height:"180px"}}/>
    </Page>
  );
};

export default Innovation;



