import React from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { SectorsContent } from "../../../mock/sectorsText";
import styles from "../styles/Home.module.css"

export default function Home() {
  const { details, language } = useSelector((state) => state.proposal);

  const {
    companyNameAndId,
    bookedBy,
    accountManager,
    subject,
    orderNumber,
    addOns,
    industry,
  } = details;
   const companyLogo = "/lingoAcademy/logos/lingoyou.png"
  return (
    <div
      id="home"
      className={styles.home}
      style={{
        boxShadow:"rgba(0, 0, 0, 1) 0px 0px 0.9px 0.5px",
        backgroundImage:`url('/clients/E-Learning/coverelearning.webp')`,
        width: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        textAlign: "center",
      }}
    >
      <div
            className={styles.home}

        style={{
          
          backgroundImage:
            "linear-gradient(to right top, rgb(39, 161, 214, 0.8), rgb(101, 33, 151, 0.9) 60%)",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        //   paddingBottom: "20px",
          backgroundSize: "cover",
          textAlign: "center",
          marginTop:"20px"

        }}
      >
        <Grid
          container
          spacing={3}
          style={{
            marginInline: "auto",
            paddingTop: "20px",
            textAlign: "center",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{ textAlign: "-webkit-center", marginInline: "auto" }}
          >
            <img
              alt="logo"
              src={companyLogo}
              style={{
                height: "200px",
                marginTop: "10px",
                marginBottom:"100px",
                objectFit: "contain",
              }}
            />
            {/*            
               <p  style={{color:"white",fontSize:"16px", fontWeight:"500", 
                textTransform:"uppercase", fontFamily:"'Poppins'", marginTop:'-10px', letterSpacing:"2.24px"}}>
                Client company name
               </p> */}
            <div
              style={{
                marginTop: companyLogo ? "20px" : "180px",
                marginBottom: `${
                  companyLogo
                    ? companyNameAndId?.name?.length > 56
                      ? -65 // 60px (logo) + 200px (name > 56 chars)
                      : companyNameAndId?.name?.length > 30
                      ? 20 // 60px (logo) + 120px (name > 36 chars)
                      : 100 // 60px (logo) + 100px (name <= 36 chars)
                    : // When the logo does not exist
                    companyNameAndId?.name?.length > 56
                    ? 130 // 200px for name > 56 chars
                    : companyNameAndId?.name?.length > 30
                    ? 130 // 120px for name > 36 chars
                    : 200 // 100px for name <= 36 chars
                }px`,
              }}
            >
              <p
                style={{
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "500",
                  textTransform: "uppercase",
                  letterSpacing: "2.24px",
                  fontFamily: "'Poppins'",
                }}
              >
                {language === "English"
                  ? "A new Era of Global Communication"
                  : "Una nuova era di comunicazione globale"}
              </p>
              <p
                style={{
                  maxWidth: "740px",
                  textAlign: "center",
                  fontWeight: "600",
                  color: "white",
                  marginTop: "20px",
                  letterSpacing: "2.24px",
                  fontSize: "44px",
                  fontFamily: "'Poppins'",
                }}
              >
                {language === "English"
                  ? "Proposal Dedicated To"
                  : "Proposal per"}{" "}
                {companyNameAndId?.name?.length > 56
                  ? companyNameAndId?.name.slice(0, 56) + "..."
                  : companyNameAndId?.name}
              </p>
              <p
                style={{
                  width: "auto",
                  maxWidth: "300px",
                  border: "2px solid white",
                  borderRadius: "10px",
                  height: "50px",
                  textAlign: "center",
                  fontWeight: "500",
                  fontSize: "20px",
                  color: "white",
                }}
              >
                <span style={{ position: "relative", top: "7px" }}>
                  {language === "English"
                    ? `Quote: LY/${orderNumber} Project`
                    : `Preventivo: LY/${orderNumber}`}
                </span>
              </p>
              <p style={{ marginTop: "40px" }}>
                <span
                  style={{
                    width: "236px",
                    fontSize: "18px",
                    textAlign: "center",
                    fontWeight: "600",
                    color: "white",
                  }}
                >
                </span>
                <br />
                <span
                  style={{
                    textAlign: "center",
                    color: "white",
                    fontWeight: "400",
                    fontSize: "24px",
                    width: "426px",
                  }}
                >
                  {language === "English"
                    ? "Kindly Addressed To"
                    : "All'attenzione di"}{" "}
                  {bookedBy
                    ? `${bookedBy?.firstName} ${bookedBy?.lastName}`
                    : "___"}
                  .
                </span>
              </p>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ textAlign: "-webkit-center", marginTop: "60px" }}
          >
            <hr
              style={{
                width: "80%",
                textAlign: "center",
                opacity: 0.1,
                color: "grey",
              }}
            />
            <p
              style={{
                marginTop: "10px",
                width: "80%",
                fontStyle: "italic",
                fontFamily: "'Poppins'",
                fontSize: "18px",
                color: "white",
                textAlign: "end",
                fontWeight: "300",
              }}
            >
              {language === "English"
                ? "Document Created By"
                : "Documento creato da"}{" "}
              {accountManager?.name}
            </p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
