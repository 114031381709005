import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';
import AboutUs2 from './AboutUs2.js';

const content = {
    "Italian":{
        title:'Il nostro team',
        team:[
            {
                name:'Manuel Costa M’Skani:',
                jobTitle:'CEO & Founder dell’azienda',
                avatar:'/lingoAcademy/Manuel.png',
                skills:[
                    'Laurea magistrale in Economia, Business Management, Marketing & Management',
                    'Anni di esperienza: 17',
                    'Inglese: C2',
                    'Francese: C1',
                    'Arabo: C1'
                ]
            },
            {
                name:'Christine Dowse:',
                jobTitle:'Insegnante d’inglese',
                avatar:'/lingoAcademy/Christine.png',
                skills:[
                    'Madrelingua inglese',
                    'Traduttrice e scrittrice',
                    'Insegnante d’inglese per pubblica amministrazione, privati e aziende',
                    'Francese: C1',
                    'Anni di esperienza: +40'
                ]
            },
            {
                name:'Miriam Assenzo:',
                jobTitle:'Insegnante d’inglese',
                avatar:'/lingoAcademy/Miriam.png',
                skills:[
                    'Madrelingua inglese',
                    'Insegnante d’inglese per pubblica amministrazione, privati e aziende',
                    'Anni di esperienza: +20',
                ]
            },
            {
                name:'Giovanna Quartararo:',
                jobTitle:'Insegnante di lettere',
                avatar:'/lingoAcademy/Giovanna.png',
                skills:[
                    'Laurea in Lettere',
                    ' Insegnante di italiano e latino per pubblica amministrazione, privati e aziende',
                    'Anni di esperienza: +40',
                ]
            },
            {
                name:'Chiara Moschitta:',
                jobTitle:'Senior Account Manager',
                avatar:'/lingoAcademy/Chiara.png',
                skills:[
                    'Laurea in lingue per la comunicazione interculturale e la cooperazione internazionale',
                    'Anni di esperienza: 2',
                    'Inglese: C1',                   
                     'Spagnolo: C1',
                ]
            }
        ]
    },
    "English":{
      title: "Our Team",
      team: [
          {
              name: "Manuel Costa M’Skani:",
              jobTitle: "CEO & Founder of the company",
              avatar: "/lingoAcademy/Manuel.png",
              skills: [
                  "Master's degree in Economics, Business Management, Marketing & Management",
                  "Years of experience: 17",
                  "English: C2",
                  "French: C1",
                  "Arabic: C1"
              ]
          },
          {
              name: "Christine Dowse:",
              jobTitle: "English Teacher",
              avatar: "/lingoAcademy/Christine.png",
              skills: [
                  "Native English speaker",
                  "Translator and writer",
                  "English teacher for public administration, individuals, and companies",
                  "French: C1",
                  "Years of experience: +40"
              ]
          },
          {
              name: "Miriam Assenzo:",
              jobTitle: "English Teacher",
              avatar: "/lingoAcademy/Miriam.png",
              skills: [
                  "Native English speaker",
                  "English teacher for public administration, individuals, and companies",
                  "Years of experience: +20"
              ]
          },
          {
              name: "Giovanna Quartararo:",
              jobTitle: "Literature Teacher",
              avatar: "/lingoAcademy/Giovanna.png",
              skills: [
                  "Degree in Literature",
                  "Italian and Latin teacher for public administration, individuals, and companies",
                  "Years of experience: +40"
              ]
          },
          {
              name: "Chiara Moschitta:",
              jobTitle: "Senior Account Manager",
              avatar: "/lingoAcademy/Chiara.png",
              skills: [
                  "Degree in languages for intercultural communication and international cooperation",
                  "Years of experience: 2",
                  "English: C1",
                  "Spanish: C"
              ]
          }
      ]
  }
  
  }

const OurTeam = ({ accountManager, language="English"}) => {
  return (
    <Page wrap={false} size="A4" style={{ ...styles.page }}>
    <View
        style={{
          ...styles.header,
          marginBottom: 0,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          marginHorizontal: "auto",
        }}
      >
        <Text
          style={{
            fontSize: "8px",
            marginTop: "2px",
            marginLeft: "5px",
            color: "white",
          }}
        >
          {content[language]?.title}
        </Text>
        <View style={{ marginLeft: "430px" }}>
          <Image
            style={styles.headerRightImg}
            src="/lingoAcademy/logos/academy-wh.png"
          />
        </View>
        <View style={styles.headerItem} />
      </View>
      
      <View style={{width:"210px", textAlign:"center", marginTop:"40px", 
        borderRadius:"10px", marginLeft:"200px", backgroundColor:"white",  color:"white", alignItems:"center", justifyContent:"center", padding:"5px"}}>
          <Image src={content[language]?.team[0]?.avatar} style={{marginTop:"-15px", objectFit:"contain", borderRadius:"50%", width:"40px", height:"40px"}}/>
       <View style={{marginTop:"5px"}}>
            <Text style={{fontSize:"8px",marginLeft:"50px", color:"black", fontWeight:"bold", textAlign:"center"}}>
            {content[language]?.team[0]?.name}
            </Text>
            <Text style={{fontSize:"7px",marginLeft:"50px", marginBottom:"10px", color:"black", fontWeight:"light", textAlign:"center"}}>
            {content[language]?.team[0]?.jobTitle}
            </Text>
            {content[language]?.team[0]?.skills?.map((skill)=>
                <Text style={{fontSize:"7px", color:"black", fontWeight:"light", textAlign:"left"}}>
                    •{' '} {skill}
                </Text>
            )}
       </View>
      </View>
    
      <View style={{display:"flex", justifyContent:"space-between", marginLeft:"10px", 
        marginRight:"10px", marginBottom:"15px", flexDirection:"row"}}>
      <View style={{width:"210px", textAlign:"center", marginTop:"40px", 
        borderRadius:"10px", backgroundColor:"white",  color:"white", alignItems:"center", justifyContent:"center", padding:"5px"}}>
          <Image src={content[language]?.team[1]?.avatar} style={{marginTop:"-15px", objectFit:"contain", borderRadius:"50%", width:"40px", height:"40px"}}/>
       <View style={{marginTop:"5px"}}>
            <Text style={{fontSize:"8px",marginLeft:"60px", color:"black", fontWeight:"bold", textAlign:"center"}}>
            {content[language]?.team[1]?.name}
            </Text>
            <Text style={{fontSize:"7px",marginLeft:"60px", marginBottom:"10px", color:"black", fontWeight:"light", textAlign:"center"}}>
            {content[language]?.team[1]?.jobTitle}
            </Text>
            {content[language]?.team[1]?.skills?.map((skill)=>
                <Text style={{fontSize:"7px", color:"black", fontWeight:"light", textAlign:"left"}}>
                    •{' '} {skill}
                </Text>
            )}
       </View>

      </View>
      <View style={{width:"210px", textAlign:"center", marginTop:"40px", 
        borderRadius:"10px",  backgroundColor:"white",  color:"white", alignItems:"center", justifyContent:"center", padding:"5px"}}>
          <Image src={content[language]?.team[2]?.avatar} style={{marginTop:"-25px", objectFit:"contain", borderRadius:"50%", width:"40px", height:"40px"}}/>
       <View style={{marginTop:"5px"}}>
            <Text style={{fontSize:"8px",marginLeft:"60px", color:"black", fontWeight:"bold", textAlign:"center"}}>
            {content[language]?.team[2]?.name}
            </Text>
            <Text style={{fontSize:"7px",marginLeft:"60px", marginBottom:"20px", color:"black", fontWeight:"light", textAlign:"center"}}>
            {content[language]?.team[2]?.jobTitle}
            </Text>
            {content[language]?.team[2]?.skills?.map((skill)=>
                <Text style={{fontSize:"7px", color:"black", fontWeight:"light", textAlign:"left"}}>
                    •{' '} {skill}
                </Text>
            )}
       </View>
      </View>
      </View> 
      <View style={{display:"flex", justifyContent:"space-between",
         marginLeft:"10px", marginRight:"10px",  marginBottom:"15px", flexDirection:"row"}}>
      <View style={{width:"210px", textAlign:"center", marginTop:"40px", 
        borderRadius:"10px", backgroundColor:"white",  color:"white", alignItems:"center", justifyContent:"center", padding:"5px"}}>
          <Image src={content[language]?.team[3]?.avatar} style={{marginTop:"-15px", objectFit:"contain", borderRadius:"50%", width:"40px", height:"40px"}}/>
       <View style={{marginTop:"5px"}}>
            <Text style={{fontSize:"8px",marginLeft:"60px", color:"black", fontWeight:"bold", textAlign:"center"}}>
            {content[language]?.team[3]?.name}
            </Text>
            <Text style={{fontSize:"7px",marginLeft:"60px", marginBottom:"10px", color:"black", fontWeight:"light", textAlign:"center"}}>
            {content[language]?.team[3]?.jobTitle}
            </Text>
            {content[language]?.team[3]?.skills?.map((skill)=>
                <Text style={{fontSize:"7px", color:"black", fontWeight:"light", textAlign:"left"}}>
                    •{' '} {skill}
                </Text>
            )}
       </View>

      </View>
      <View style={{width:"210px", textAlign:"center", marginTop:"40px", 
        borderRadius:"10px",  backgroundColor:"white",  color:"white", alignItems:"center", justifyContent:"center", padding:"5px"}}>
          <Image src={content[language]?.team[4]?.avatar} style={{marginTop:"-15px", objectFit:"contain", borderRadius:"50%", width:"40px", height:"40px"}}/>
       <View style={{marginTop:"5px"}}>
            <Text style={{fontSize:"8px",marginLeft:"60px", color:"black", fontWeight:"bold", textAlign:"center"}}>
            {content[language]?.team[4]?.name}
            </Text>
            <Text style={{fontSize:"7px",marginLeft:"60px", marginBottom:"10px", color:"black", fontWeight:"light", textAlign:"center"}}>
            {content[language]?.team[4]?.jobTitle}
            </Text>
            {content[language]?.team[4]?.skills?.map((skill)=>
                <Text style={{fontSize:"7px", color:"black", fontWeight:"light", textAlign:"left"}}>
                    •{' '} {skill}
                </Text>
            )}
       </View>
      </View>
      </View>      
      <Image src={"/translation/aboutUs-footer-bg-pdf.png"} style={{objectFit:"cover", zIndex:"-1111",
         width:"100%", marginTop:"-600px", height:"620px"}}/>
    </Page>
  );
};

export default OurTeam;



