import { Image, Link, Page, Text, View } from "@react-pdf/renderer";
import React from "react";
import { styles } from "../globalStyles";

const content = {
  Italian: [
    "Ci conformeremo e risponderemo alle esigenze e ai tempi del cliente attraverso una comunicazione chiara e trasparente.",
    "Garantiremo massima professionalità e alti standard di qualità.",
    "Risponderemo ad ogni richiesta studiando percorsi adatti alle esigenze e agli obiettivi dello studente.",
    "Faremo in modo di fornire corsi flessibili e personalizzabili in base agli obiettivi degli studenti.",
    "Garantiremo coerenza e accuratezza in ogni fase del corso.",
    "Valuteremo costantemente rischi e opportunità al fine di migliorare le nostre procedure e di conseguenza la soddisfazione dei clienti.",
    "Terremo in considerazione feedback e suggerimenti per monitorare il livello di soddisfazione del cliente e migliorarci ogni giorno.",
    "Proporremo ai nostri studenti metodi di studio estremamente innovativi e alternativi.",
  ],
  English: [
    "We will comply with and respond to the Customer's needs and timelines through clear and transparent communication.",
    "We will ensure the highest professionalism and high-quality standards.",
    "We will respond to every request by creating paths tailored to the needs and goals of the student.",
    "We will strive to provide flexible and customizable courses based on the students' goals.",
    "We will guarantee consistency and accuracy at every stage of the course.",
    "We will constantly assess risks and opportunities to improve our procedures and, consequently, customer satisfaction.",
    "We will consider feedback and suggestions to monitor Customer satisfaction and improve every day.",
    "We will offer our students extremely innovative and alternative study methods.",
  ],
};
const clients = [
  "iso.png",
  "cambridge.png",
  "ielts.png",
  "oxford1.png",
  "toefl.png",
  "defl.png",
  "CELI.png",
  "telc.png",
  "deli.png",
];
const Quality = ({ language, quoteId }) => {
  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
      <View
        style={{
          ...styles.header,
          marginBottom: 0,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          marginHorizontal: "auto",
        }}
      >
        <Text
          style={{
            fontSize: "8px",
            marginTop: "2px",
            marginLeft: "5px",
            color: "#A158D6",
          }}
        >
          {language === "English" ? "Quality" : "Qualità"}
        </Text>
        <View style={{ marginLeft: "430px" }}>
          <Image
            style={styles.headerRightImg}
            src="/lingoAcademy/logos/academy.png"
          />
        </View>
        <View style={styles.headerItem} />
      </View>
      <View
        style={{
          display: "flex",
          marginTop: "20px",
          marginLeft: "60px",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            textAlign: "center",
            justifyContent: "center",
            width: "130px",
            flexDirection: "column",
            padding: "10px",
            borderRadius: "5px",
            margin: "5px",
            border: "1px solid #AA32E1",
          }}
        >
          <Text style={{ marginLeft: "40px", width: "40px" }}>50+</Text>
          <Text
            style={{
              fontSize: "8px",
              textAlign: "center",
              fontWeight: "normal",
              margin: "3px",
            }}
          >
            {language === "English" ? "languages" : "lingue"}
          </Text>
        </View>
        <View
          style={{
            textAlign: "center",
            justifyContent: "center",
            width: "130px",
            flexDirection: "column",
            padding: "10px",
            borderRadius: "5px",
            margin: "5px",
            border: "1px solid #AA32E1",
          }}
        >
          <Text
            style={{
              marginLeft: "40px",
              width: "40px",
            }}
          >
            10
          </Text>
          <Text
            style={{
              fontSize: "8px",
              textAlign: "center",
              fontWeight: "normal",
              margin: "3px",
            }}
          >
            {language === "English"
              ? "years of experience in the sector"
              : "anni di esperienza nel settore"}
          </Text>
        </View>
        <View
          style={{
            textAlign: "center",
            justifyContent: "center",
            width: "130px",
            flexDirection: "column",
            padding: "10px",
            borderRadius: "5px",
            margin: "5px",
            border: "1px solid #AA32E1",
          }}
        >
          <Text
            style={{
              marginLeft: "40px",
              width: "40px",
            }}
          >
            98%
          </Text>
          <Text
            style={{
              fontSize: "8px",
              textAlign: "center",
              fontWeight: "normal",
              margin: "3px",
            }}
          >
            {language === "English"
              ? "satisfied customers"
              : "clienti soddisfatti"}
          </Text>
        </View>
      </View>
      <View style={{ marginLeft: "30px", marginRight: "30px" }}>
        
        <Text
          style={{ fontSize: "9px", marginTop: "15px", fontWeight: "light" }}
        >
          {language === "English"
                ? `With the goal of achieving a high level of customer satisfaction, at LingoYou Academy we are committed to ensuring the highest standards of quality.`
                : `Ponendoci come obiettivo il raggiungimento di un elevato grado di soddisfazione del cliente, in LingoYou Academy
ci impegniamo a garantire i più alti standard di qualità.`} 
</Text>
<Text
          style={{ fontSize: "9px", marginTop: "15px", fontWeight: "light" }}
        >
          {language === "English"
                ? `Our quality policy is reviewed periodically to ensure its adaptation to the context and indirection of the continuous improvement of our actions, procedures and operations through the implementation of management systems consistent with the requirements of ISO 9001: 2015 and ISO 17100: 2017.`
                : `La nostra politica di qualità viene rivista periodicamente per garantirne l’adeguamento con il contesto e in
direzione del miglioramento continuo delle nostre azioni, procedure ed operazioni attraverso l’implementazione di sistemi di amministrazione coerenti con i requisiti ISO 9001: 2015 e ISO 17100: 2017.`}
       
</Text>

{content[language]?.map((item)=>(
        <Text
          style={{ fontSize: "9px", marginTop: "10px", fontWeight: "light" }}
        >
      •{' '}{item}
      </Text>
     ))}
      </View>

      <View style={{ placeContent: 'center', textAlignLast: 'center', alignItems: 'center', justifyContent: 'center', width:"100%", textAlign:"center", marginBottom:"10px"}}>

<View style={{marginTop:"10px", placeContent: 'center', textAlignLast: 'center', 
    alignItems: 'center', justifyContent: 'center', display:"flex", 
    flexDirection:"row", width:"80%", textAlign:"center"}}>
 {clients?.length > 0  && clients.slice(0, 5)?.map((client)=>(
   <Image
   style={{
     marginLeft: '5px',
     height:'60px',
     width:'100px',
     objectFit:'contain',
     marginTop: '10px',
   }}
   src={`/lingoAcademy/${client}`}

 />

 ))}
 
 </View>
 <View style={{ placeContent: 'center', textAlignLast: 'center', alignItems: 'center', justifyContent: 'center', display:"flex", flexDirection:"row", width:"60%", textAlign:"center"}}>
 {clients?.length > 0 && clients.slice(5, 9)?.map((client)=>(
   <Image
   style={{
     ...styles.greenImages,
     marginRight: '5px',
     height:'60px',
     width:"70px",
     objectFit:'contain',
     marginTop: '12px',
   }}
   src={`/lingoAcademy/${client}`}
 />

 ))}
 
 </View>
 </View>
      <Image
        src={"/translation/pdf-bg.png"}
        style={{
          objectFit: "cover",
          width: "100%",
          zIndex: "-1111",
          marginTop: "-1000px",
          height: "1020px",
        }}
      />
    </Page>
  );
};

export default Quality;
