import { Grid, Typography } from "@mui/material";
import React from "react";
import { FaLock } from "react-icons/fa";
import { useSelector } from "react-redux";
import styles from '../styles/Quality.module.css';
import Slider from "react-slick";


const content = {
    "Italian" :[
        "Ci conformeremo e risponderemo alle esigenze e ai tempi del cliente attraverso una comunicazione chiara e trasparente.",
        "Garantiremo massima professionalità e alti standard di qualità.",
        "Risponderemo ad ogni richiesta studiando percorsi adatti alle esigenze e agli obiettivi dello studente.",
        "Faremo in modo di fornire corsi flessibili e personalizzabili in base agli obiettivi degli studenti.",
        "Garantiremo coerenza e accuratezza in ogni fase del corso.",
        "Valuteremo costantemente rischi e opportunità al fine di migliorare le nostre procedure e di conseguenza la soddisfazione dei clienti.",
        "Terremo in considerazione feedback e suggerimenti per monitorare il livello di soddisfazione del cliente e migliorarci ogni giorno.",
        "Proporremo ai nostri studenti metodi di studio estremamente innovativi e alternativi."
     ],
     "English":[
        "We will comply with and respond to the Customer's needs and timelines through clear and transparent communication.",
        "We will ensure the highest professionalism and high-quality standards.",
        "We will respond to every request by creating paths tailored to the needs and goals of the student.",
        "We will strive to provide flexible and customizable courses based on the students' goals.",
        "We will guarantee consistency and accuracy at every stage of the course.",
        "We will constantly assess risks and opportunities to improve our procedures and, consequently, customer satisfaction.",
        "We will consider feedback and suggestions to monitor Customer satisfaction and improve every day.",
        "We will offer our students extremely innovative and alternative study methods."
    ]
}
const clients = [
  'iso.png',
  'cambridge.png',
  'ielts.png',
  'oxford1.png',
  'toefl.png',
  'defl.png',
  'CELI.png',
  'telc.png',
   'deli.png'
]
export default function Quality() {
  const { language } = useSelector((state) => state.proposal);

  const settings = {
    dots: false,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 4,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
  };
  return (
    <div style={{borderTop:"1px solid #2DA0F0"}}>
      <div
        style={{
          textAlign: "right",
          width: "97%",
          padding: "2px",
          marginInline: "auto",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "2px solid #AA32E1",
        }}
      >
        <p>
          <span
            style={{ fontWeight: "500", textTransform:"uppercase", color: "#AA32E1", fontSize: "20px" }}
          >
      {language === "English" ? "Quality" : "Qualità"}
      </span>
        </p>
        <img
          src={"/lingoAcademy/logos/academy.png"}
          style={{ marginRight: "20px", objectFit: "contain" }}
          alt="logo"
        />
      </div>
      <div
        style={{
            backgroundImage: `linear-gradient(to right top, rgba(45, 160, 240, 0.15), white 40%, rgba(45, 160, 240, 0.15))`,

          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "auto",
          paddingBottom: "40px",
          backgroundSize: "cover",
          textAlign: "center",
        }}
      >
         <Grid
          container
          spacing={3}
          sx={{ marginInline:"auto", paddingTop: "20px", width: "95%", my:0.2, placeContent: "center" }}
          className={styles.icons}
        >
          <Grid item xs={4} sm={6} md={4}>
          <div className={styles.icon}>
              {/* <p style={{color:"#aa32e1", fontSize:"20px", fontWeight:"bold", marginBottom:"10px",
                 placeContent:"center", marginInline:"auto", alignItems:'center',
                  justifyContent:"center", backgroundColor:"white", marginTop:"-20px", 
                  width:"50px", height:"50px", padding:"10px", fontWeight:"bold"}}>
                   10
              </p> */}
              <p style={{fontSize:"30px"}}>
              <span style={{fontWeight:"bold"}}>
              50+
              </span> <br/>
              {language === "English" ? "languages":"lingue"}
              </p>
             
            </div>
          </Grid>
          <Grid item xs={4} sm={6} md={4}>
          <div className={styles.icon}>
              {/* <p style={{color:"#aa32e1", fontSize:"20px", fontWeight:"bold", marginBottom:"10px",
                 placeContent:"center", marginInline:"auto", alignItems:'center',
                  justifyContent:"center", backgroundColor:"white", marginTop:"-20px", 
                  width:"50px", height:"50px", padding:"10px", fontWeight:"bold"}}>
                   10
              </p> */}
              <p style={{fontSize:"30px"}}>
              <span style={{fontWeight:"bold"}}>
              10
              </span> <br/>
                {language === "English" ? "years of experience in the sector":"anni di esperienza nel settore"}
              </p>
            </div>
          </Grid>
          <Grid item xs={4} sm={6} md={4}>
          <div className={styles.icon}>
              {/* <p style={{color:"#aa32e1", fontSize:"20px", fontWeight:"bold", marginBottom:"10px",
                 placeContent:"center", marginInline:"auto", alignItems:'center',
                  justifyContent:"center", backgroundColor:"white", marginTop:"-20px", 
                  width:"50px", height:"50px", padding:"10px", fontWeight:"bold"}}>
                   10
              </p> */}
              <p style={{fontSize:"30px"}}>
              <span style={{fontWeight:"bold"}}>
              98%
              </span> <br/>
                {language === "English" ? "satisfied customers":
                "clienti soddisfatti"}
              </p>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          style={{
            marginInline: "auto",
            marginTop: 0,
            paddingTop: "30px",
            maxWidth:"1400px",
          }}
        >
          <Grid item xs={12} md={11} sx={{ textAlign: "left", marginBottom:"10px" }}>
            
            <Typography
              variant="body1"
              sx={{
                fontFamily: "'Poppins', sans-serif",
                marginTop:"20px",
                marginLeft:"20px"
              }}
            >
              {language === "English"
                ? `With the goal of achieving a high level of customer satisfaction, at LingoYou Academy we are committed to ensuring the highest standards of quality.`
                : `Ponendoci come obiettivo il raggiungimento di un elevato grado di soddisfazione del cliente, in LingoYou Academy
ci impegniamo a garantire i più alti standard di qualità.`}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "'Poppins', sans-serif",
                marginTop:"20px",
                marginLeft:"20px"
              }}
            >
              {language === "English"
                ? `Our quality policy is reviewed periodically to ensure its adaptation to the context and indirection of the continuous improvement of our actions, procedures and operations through the implementation of management systems consistent with the requirements of ISO 9001: 2015 and ISO 17100: 2017.`
                : `La nostra politica di qualità viene rivista periodicamente per garantirne l’adeguamento con il contesto e in
direzione del miglioramento continuo delle nostre azioni, procedure ed operazioni attraverso l’implementazione di sistemi di amministrazione coerenti con i requisiti ISO 9001: 2015 e ISO 17100: 2017.`}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginTop:"10px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
            <ul>
               {content[language]?.map((item)=>(
                <li style={{marginTop:"10px"}}>
                    {item}
                </li>
               ))}               
            </ul>
            </Typography>
           
          </Grid>
          <Grid item xs={12} md={1}></Grid>
        </Grid>
        <div className={styles.text} style={{maxWidth:"800px", marginInline:"auto"}}>

       <Slider {...settings}>
         
         {clients?.map((client)=> <div style={{width:"150px", objectFit:"contain"}} className={styles.slider_images}>
            <img alt='office' style={{objectFit:"contain", width:"150px"}} src={`/lingoAcademy/${client}`}/>
          </div> 
          )}
        </Slider>
        </div>
     
      </div>
    </div>
  );
}
