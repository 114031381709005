import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';
import AboutUs2 from './AboutUs2.js';

const content = {
    English: {
      subTitle: "At LingoYou Academy, we believe that the best way to learn a language is by practicing it. For this reason, in addition to using top learning platforms, the LingoYou Academy team has decided to integrate extra language activities, creating a motivating environment and a welcoming atmosphere for every student. Our packages include dedicated events where you will speak only in the language you are learning through various activities, from aperitifs to sports.",
      title: "EXTRA LANGUAGE ACTIVITIES",
      sections: [
          {
              title: "Summer Activities",
              para1: "We take advantage of the summer season to learn a language through nature, new sports, and group activities:",
              items: [
                  "Free group SUP sessions",
                  "Free SUP aperitif",
                  "Free boat tour"
              ],
              img: "activity1.png"
          },
          {
            title:"Recreational Activities",
            para1:"At LingoYou Academy you can enjoy an aperitif or eat a pizza with your teacher and your classmates, using the foreign language you are learning to order and converse.",
              img: "activity2.png"
          }
      ]
  },
    Italian: {
      subTitle: "Da LingoYou Academy crediamo che il miglior modo per imparare una lingua sia metterla in pratica. Per questo motivo, oltre all’impiego delle migliori piattaforme di apprendimento, il team di LingoYou Academy ha deciso di integrare delle attività linguistiche extra, creando così un ambiente motivante e un clima accogliente per ogni studente. All’interno dei pacchetti, infatti, prevediamo eventi dedicati in cui parlerai soltanto nella lingua che stai imparando attraverso attività di ogni tipo, dall’aperitivo allo sport.",
      title: "ATTIVITÀ LINGUISTICHE EXTRA",
      sections:[
          {
              title:"Attività estive",
              para1:"Approfittiamo del periodo estivo per imparare una lingua, attraverso il contatto con la natura, nuovi sport e attività di gruppo:",
              items:[
                  "Sessioni di gruppo gratuite di SUP",
                  "Aperitivo in SUP gratuito",
                  "Tour in barca gratuito"
              ],
              img:"activity1.png"
          },
          {
              title:"Attività ricreative",
              para1:"Da LingoYou Academy potrai gustare un aperitivo o mangiare una pizza con il tuo insegnante e i tuoi compagni di corso, utilizzando la lingua straniera che stai imparando per ordinare e conversare.",
              
              img:"activity2.png"
          },
      ]
    },
  };

const LangsActivities = ({ accountManager, language="English"}) => {
  return (
    <Page wrap={false} size="A4" style={{ ...styles.page }}>
    <View style={{marginTop:"15px", textAlign:"center", justifyContent:"center"}}>
          
          <Text style={{color:"white", fontSize:"17px", marginLeft:"-10px", 
           fontWeight:"bold", textAlign:"center" }}>
           {content[language]?.title}
          </Text>
          <Text style={{color:"white", fontSize:"8px", marginTop:"3px", width:"460px", 
           fontWeight:"normal", textAlign:"center", marginLeft:"60px" }}>
           {content[language]?.subTitle}
          </Text>
     </View>
    
      <View style={{display:"flex", marginLeft:"20px", marginTop:"20px", marginBottom:"15px", flexDirection:"row"}}>
      <View style={{width:"300px",}}>
      <Text style={{ backgroundColor:"#2DA0F0",
            borderRadius: '7px', width:"140px", padding:"9px", marginTop:"30px"}}>
           <Text 
            style={{
            textAlign: 'center',
            color: '#FFFFFF',
            fontFamily: "Poppins 'sans-serif'",
            fontStyle: 'normal',
            fontWeight: 'normal',fontSize:"9px", marginTop:"-5px"}}>
      
      {content[language]?.sections[0]?.title}
           
       </Text>
            </Text> 
        <Text style={{fontSize:"9px", marginTop:"15px", color:"white", fontWeight:"normal"}}>
        {content[language]?.sections[0]?.para1}
        </Text>
        {content[language]?.sections[0]?.items?.map((item)=><Text style={{fontSize:"9px", 
            marginTop:"2px", color:"white", fontWeight:"normal"}}>
                •{item}
             </Text>)}
        
      </View>
      <View style={{width:"250px", marginLeft:"10px"}}>
      <Image
                    style={{ objectFit: "contain", width:"100%" }}
                    src={`/lingoAcademy/${content[language]?.sections[0]?.img}`}
                  />
      </View>
      </View> 
      <View style={{display:"flex", marginLeft:"20px", marginTop:"20px", marginBottom:"15px", flexDirection:"row"}}>
     
      <View style={{width:"250px", marginLeft:"10px"}}>
      <Image
                    style={{ objectFit: "contain", transform:"scaleX(-1)", width:"100%" }}
                    src={`/lingoAcademy/${content[language]?.sections[1]?.img}`}
                  />
      </View>
      <View style={{width:"260px", marginLeft:"20px", paddingTop:"30px"}}>
      <Text style={{ backgroundColor:"#2DA0F0",
            borderRadius: '7px', width:"120px", padding:"9px", marginTop:"30px"}}>
           <Text 
            style={{
            textAlign: 'center',
            color: '#FFFFFF',
            fontFamily: "Poppins 'sans-serif'",
            fontStyle: 'normal',
            fontWeight: 'normal',fontSize:"9px", marginTop:"-5px"}}>
      
      {content[language]?.sections[1]?.title}
           
       </Text>
            </Text> 
        <Text style={{fontSize:"9px", marginTop:"15px", color:"white", fontWeight:"normal"}}>
        {content[language]?.sections[1]?.para1}
        </Text>
        
      </View>
      </View>      
      <Image src={"/translation/aboutUs-footer-bg-pdf.png"} style={{objectFit:"cover", zIndex:"-1111",
         width:"100%", marginTop:"-570px", height:"580px"}}/>
    </Page>
  );
};

export default LangsActivities;



