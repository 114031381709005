import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import styles from '../styles/Introduction.module.css'
import { useSelector } from "react-redux";



export default function Introduction() {

  
  const {details, language} = useSelector(state => state.proposal)

  const { bookedBy, accountManager, companyNameAndId, brand,  addOns, clients } = details;
  return (
    <div id='introduction'
      style={{
        backgroundImage: "linear-gradient(to right top,#A158D6 10%, #CCF0FF 90%)",
        width: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "auto",
        paddingTop: "10px",
        backgroundSize: "cover",
        textAlign: "center",
        paddingBottom:"30px"
      }}
    ><div
    style={{
      textAlign: "right",
      width: "97%",
      marginInline:"auto",
      padding:"2px",
      display:"flex",
      marginBottom: "50px",
      justifyContent: "space-between",
      borderBottom: "2px solid white",
    }}
  >
     <p>
           <span  style={{fontWeight:"500", color: "white", fontSize: "20px"}}
           >
          {/* {language === 'English' ? `Introductory Letter` : `Lettera Introduttiva`} */}
          </span>
    </p>
    <img
      src={brand && brand?.academy ? "/lingoAcademy/logos/academy.png":"/translation/logos/lingoyou.png"}
      style={{ marginRight: "20px", objectFit:"contain" }}
      alt="logo"
    />
  </div>
      <div
        style={{
        //   backgroundImage: brand?.rafiky ? "" : "url('/assets/intro-cover.webp')",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "auto",
          backgroundSize: "cover",
          textAlign: "left",
          minBlockSize: "100%",
          marginTop:"60px",
          paddingBottom:"50px",
        }}
      >
        <Box sx={{ py:1, width: "100%", marginInline: "auto" }}>
         
          <Grid container spacing={1} sx={{marginTop:"-20px"}}>
     <Grid item xs={12} md={1} lg={1.52} sx={{padding:"0px"}}>
              <img alt="logo"  style={{marginLeft:"-30px"}}
               src="/translation/logos/lingo-icon-white.svg"/>
            </Grid> 
            <Grid
              item
              xs={12}
              md={10}
              lg={9.8}
            >
                 <p style={{marginTop:"-2px", marginBottom:"30px"}}>
           
           <span style={{
              color: "white",
              fontFamily: "Poppins",
              fontWeight: "600",
              fontSize:"36px"
            }}>
 {language === 'English' ? `Introductory Letter` : `Lettera introduttiva`}           
 </span>
          </p>
          <div style={{display:"flex", marginTop:"15px"}}>
  {addOns?.friendly ? <Box sx={{
                backgroundColor: "#2DA0F0",
                 paddingTop:"40px",
                 px:5,
                marginTop: "5px",
                borderRadius: "50px",
                zIndex:11111,
                position:"relative"
              }}>

              <Typography
                variant="body1"
                className={styles.content}
              >
                {language === "English" ? `Dear ${bookedBy ? bookedBy?.firstName : '__'},` : 
       `Gentile ${bookedBy ? bookedBy?.firstName : '__'},`}
              </Typography>
              <Typography
                variant="body1"
                className={styles.content}
              >
                {language === "English" ? `Thank you for choosing to collaborate with LingoYou. For more information regarding our reality, please browse the pages of this presentation that we have dedicated to ${companyNameAndId?.name}.` : 
       `Ti ringraziamo per aver scelto di collaborare con LingoYou. Per avere maggiori informazioni riguardanti la nostra realtà, ti preghiamo di sfogliare le pagine di questa presentazione che abbiamo dedicato a ${companyNameAndId?.name}.`
       }    <br />
              </Typography>
              <Typography
                variant="body1"
                className={styles.content}
              >
{language === "English" ? `As requested, we will send you our quote below.` : 
       `Come richiesto, ti inviamo di seguito il nostro preventivo.`}
        <br />
              </Typography>
              <Typography
                variant="body1"
                className={styles.content}

              >
{language === "English" ? `Our considerable experience in the sector, together with our flexibility and precision, make LingoYou the best choice for major companies such as ` : 
       `La nostra notevole esperienza nel settore, insieme alla nostra flessibilità e precisione, rende LingoYou la migliore scelta per importanti aziende come `
       }               <b>
                  {" "}
                  {addOns?.sector ? clients?.map((client)=> client?.name).join(", "): 
             `Sky, Google, Ministero dell'Interno, Telepass, Ferrari, Mercedes-Benz, Honda, Alfasigma, Takeda, Clarins, MAC Cosmetics, Unicredit, Banca d'Italia, Gucci, Dior, Gruppo Angelini
             `
             }
                </b>{" "} {language === "English" ? `and many others, for which we have provided translation and interpreting services and organized on-site and online conferences.` : 
       `e molte altre, per le quali abbiamo fornito servizi di traduzione e di interpretariato e organizzato conferenze on-site e online.`}
        
                <br />
              </Typography>
             
              <Typography
                variant="body1"
                className={styles.content}

              >
             {language === "English" ? `Here at LingoYou every customer is unique and special, and we would particularly like to start a long and fruitful collaboration with ${companyNameAndId?.name}.` : 
       `Qui a LingoYou ogni cliente è unico e speciale, e ci terremmo particolarmente ad iniziare una lunga e proficua collaborazione con ${companyNameAndId?.name}.`}
       <br />
              </Typography>
              <Typography
                variant="body1"
                className={styles.content}

              >
                {language === "English" ? `Quality has always been our strong point. For this, we select translators registered with one of the major trade associations (AITI, AssoInterpreti, AIIC, etc.), with at least five years of work experience in your sector.` : 
       `La qualità è da sempre il nostro punto di forza. Per questo, selezioniamo traduttori iscritti ad una delle maggiori associazioni di categoria (AITI, AssoInterpreti, AIIC, etc.), con almeno cinque anni di esperienza lavorativa nel vostro settore.`}
          <br />
          </Typography>
          <Typography
                variant="body1"
                className={styles.content}

              >
              {language === "English" ? `We will contact you by phone to agree on the next steps together.` : 
       `Ti contatteremo telefonicamente per concordare insieme i prossimi step.`}    <br />
              </Typography>
              <Typography
                variant="body1"
                className={styles.content}

              >
                {language === "English" ? `See you soon,` : 
       `A presto,`}
              </Typography>
              <div style={{
                marginTop:'20px',
                display:"flex",
                marginBottom:"-14px",
                marginLeft:"-15px"
              }}>
              {accountManager && accountManager.name === 'Giuseppe Gagliano' && (
        <img alt="avatar" style={{objectFit:"contain"}} src="/assets/managers/GiuseppeGagliano.png" />
      )}
{accountManager && accountManager.name === 'Alessia Tortorici' && (

<img alt="avatar" style={{objectFit:"contain"}} src="/assets/managers/AlessiaTortorici.png" />

)}
{accountManager && accountManager.name === "Leonardo Moscato" && (
  <img alt="avatar" style={{objectFit:"contain"}} src="/assets/managers/LeonardoMoscato.png" />

)}
      {accountManager && accountManager.name === 'Chiara Moschitta' && (
        <img alt="avatar" style={{objectFit:"contain"}} src="/assets/managers/ChiaraMoschitta.png" />
      )}
      {accountManager && accountManager.name === "Giulia Caravasso" &&        
        <img alt="avatar" style={{objectFit:"contain"}} src="/assets/managers/GiuliaCaravasso.png"/>

     }
              <p style={{marginTop:"30px", marginLeft:"10px"}}>
              <img alt="sign"  src="/assets/icons/sign.svg" />
              <p style={{ color:"white", fontFamily:"Poppins"}}>
               <span style={{fontWeight:"600", color:"white", fontSize:"18px"}}>
               {accountManager ? accountManager.name : '__'}
                </span><br/>
                <span style={{fontSize:"14px"}}>
                {accountManager?.jobTitle} - Lingoyou
                </span> <br/>
                <span style={{ color:"white", fontSize:"14px", display:"flex"}}>
                {language === "English" ? "Languages" :"Lingue"}:
                {accountManager &&
                    accountManager?.language &&
                    accountManager?.language?.map((language) => {
                      if (language.name === 'Chinese') {
                        return (<img alt="sign" 
                          style={{width:"20px",marginLeft:"10px", height:"auto", objectFit:"contain"}} 
                          src="/assets/langs/artwork1.png" />
                        )}else if(language.name === 'Italian'){
                          return (
                            <img alt="sign" 
                            style={{width:"20px",marginLeft:"10px", height:"auto", objectFit:"contain"}} 
                            src="/assets/langs/artwork2.png" />
                          )
                        }else if (language.name === 'English') {
                          return (
                            <img alt="sign" 
                            style={{width:"20px",marginLeft:"10px", height:"auto", objectFit:"contain"}} 
                            src="/assets/langs/artwork3.png" />
                          );
                        }
                    })} 
               
              </span>
              </p>
              
              </p>
              </div>
              </Box> : <Box sx={{
                backgroundColor: "#2DA0F0",
                 paddingTop:"40px",
                 px:5,
                marginTop: "5px",
                borderRadius: "50px",
                zIndex:11111,
                position:"relative"
              }}>

              <Typography
                variant="body1"
                className={styles.content}
              >
                {language === "English" ? `Dear ${bookedBy ? bookedBy?.firstName : '__'},` : 
       `Gentile ${bookedBy ? bookedBy?.firstName : '__'},`}
              </Typography>
              <Typography
                variant="body1"
                className={styles.content}
              >
                      {language === "English" ? `Thank you for choosing to partner with LingoYou. For more information regarding our reality, please browse the pages of this presentation that we have dedicated to ${companyNameAndId?.name}.` : `La ringraziamo per aver scelto di collaborare con LingoYou. Per avere maggiori informazioni riguardanti la nostra realtà, la preghiamo di sfogliare le pagine di questa presentazione che abbiamo dedicato a ${companyNameAndId?.name}.`}
                      <br />
              </Typography>
              <Typography
                variant="body1"
                className={styles.content}
              >
       {language === "English" ? `As requested, we send you our estimate below.` : `Come richiesto, le inviamo di seguito il nostro preventivo.`}

        <br />
              </Typography>
              <Typography
                variant="body1"
                className={styles.content}

              >
       {language === "English" ? `Our considerable experience in the sector, together with our flexibility and precision, make LingoYou the best choice for important companies such as  ` : `La nostra notevole esperienza nel settore, insieme alla nostra flessibilità e precisione, rende LingoYou la migliore scelta per importanti aziende come `}
       <b>
                  {" "}
                  {addOns?.sector ? clients?.map((client)=> client?.name).join(", "): 
             `Sky, Google, Ministero dell'Interno, Telepass, Ferrari, Mercedes-Benz, Honda, Alfasigma, Takeda, Clarins, MAC Cosmetics, Unicredit, Banca d'Italia, Gucci, Dior, Gruppo Angelini
             `
             }
                </b>{" "}
                {language === "English" ? ` and many others, for which we have provided translation and interpreting services and organized on-site and online conferences.` : 
       ` e molte altre, per le quali abbiamo fornito servizi di traduzione e di interpretariato e organizzato conferenze on-site e online.`}
     
                <br />
              </Typography>
              
              <Typography
                variant="body1"
                className={styles.content}

              >
            {language === "English" ? `Here at LingoYou each client is unique and special, and we would be particularly keen to begin a long and fruitful relationship with ${companyNameAndId?.name}.` : 
       ` Qui a LingoYou ogni cliente è unico e speciale, e ci terremmo particolarmente ad iniziare una lunga e proficua collaborazione con ${companyNameAndId?.name}.`}
             <br />
              </Typography>
              <Typography
                variant="body1"
                className={styles.content}

              >
               {language === "English" ? `Quality has always been our strong point. For this reason, we select translators registered with one of the major trade associations (AITI, AssoInterpreti, AIIC, etc.), with at least five years of work experience in your sector.` : 
       `La qualità è da sempre il nostro punto di forza. Per questo, selezioniamo traduttori iscritti ad una delle maggiori associazioni di categoria (AITI, AssoInterpreti, AIIC, etc.), con almeno cinque anni di esperienza lavorativa nel vostro settore.`}
         <br />
          </Typography>
          <Typography
                variant="body1"
                className={styles.content}

              >
              {language === "English" ? `We will contact you by phone to agree on the next steps together.` : 
       `La contatteremo telefonicamente per concordare insieme i prossimi step.`}
       <br />
              </Typography>
              <Typography
                variant="body1"
                className={styles.content}

              >
                {language === "English" ? `Best regards,` : 
         `Cordiali saluti,`}
              </Typography>
              <div style={{
                marginTop:'20px',
                display:"flex",
                marginBottom:"-14px",
                marginLeft:"-15px"
              }}>
              {accountManager && accountManager.name === 'Giuseppe Gagliano' && (
        <img alt="avatar" style={{objectFit:"contain"}} src="/assets/managers/GiuseppeGagliano.png" />
      )}
{accountManager && accountManager.name === 'Alessia Tortorici' && (

<img alt="avatar" style={{objectFit:"contain"}} src="/assets/managers/AlessiaTortorici.png" />

)}
{accountManager && accountManager.name === "Leonardo Moscato" && (
  <img alt="avatar" style={{objectFit:"contain"}} src="/assets/managers/LeonardoMoscato.png" />

)}
      {accountManager && accountManager.name === 'Chiara Moschitta' && (
        <img alt="avatar" style={{objectFit:"contain"}} src="/assets/managers/ChiaraMoschitta.png" />
      )}
      {accountManager && accountManager.name === "Giulia Caravasso" &&        
        <img alt="avatar" style={{objectFit:"contain"}} src="/assets/managers/GiuliaCaravasso.png"/>

     }
              <p style={{marginTop:"30px", marginLeft:"10px"}}>
              <img alt="sign"  src="/assets/icons/sign.svg" />
              <p style={{ color:"white", fontFamily:"Poppins"}}>
               <span style={{fontWeight:"600", color:"white", fontSize:"18px"}}>
               {accountManager ? accountManager.name : '__'}
                </span><br/>
                <span style={{fontSize:"14px"}}>
                {accountManager?.jobTitle} - Lingoyou
                </span> <br/>
                <span style={{ color:"white", fontSize:"14px", display:"flex"}}>
                {language === "English" ? "Languages" :"Lingue"}:
                {accountManager &&
                    accountManager?.language &&
                    accountManager?.language?.map((language) => {
                      if (language.name === 'Chinese') {
                        return (<img alt="sign" 
                          style={{width:"20px",marginLeft:"10px", height:"auto", objectFit:"contain"}} 
                          src="/assets/langs/artwork1.png" />
                        )}else if(language.name === 'Italian'){
                          return (
                            <img alt="sign" 
                            style={{width:"20px",marginLeft:"10px", height:"auto", objectFit:"contain"}} 
                            src="/assets/langs/artwork2.png" />
                          )
                        }else if (language.name === 'English') {
                          return (
                            <img alt="sign" 
                            style={{width:"20px",marginLeft:"10px", height:"auto", objectFit:"contain"}} 
                            src="/assets/langs/artwork3.png" />
                          );
                        }
                    })} 
               
              </span>
              </p>
              
              </p>
              </div>
              </Box> }
              <img alt="icon"style={{marginTop:"-380px", marginLeft:"-20px"}} 
              src="/assets/icons/intro-commas-icon.svg"/>

          </div>
          
            </Grid>
            <Grid item xs={12} md={1} lg={0.68}>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}
