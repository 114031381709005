import React from 'react'
import {  Grid, Card, TableContainer, Table, TableHead, TableRow, TableCell } from '@mui/material';
import { useSelector } from 'react-redux';
import Scrollbar from '../Scrollbar';


const content = {
 "English":{ title:'Quote Includes',
  icons: [
    { width: '160px', img: '/assets/icons/headset.png', en: 'Customer service available 24/7;' },
    { width: '203px', img: '/assets/icons/quality.png', en: 'Final quality control performed by the Project Manager;' },
    { width: '160px', img: '/assets/icons/tower.png', en: 'Formatting & Graphic Design;' },
    { width: '364px', img: '/assets/icons/translation.png', en: 'Professional translation service performed by a native linguist with at least 5 years of experience in the required field;' },
    { width: '160px', img: '/assets/icons/vector.png', en: 'Creation of translation memories;' },
    { width: '203px', img: '/assets/icons/rectangle.png', en: 'Development of specific glossaries for translated texts;' },
    { width: '160px', img: '/assets/icons/messanger.png', en: 'Final feedback request via Call & Email.' },
    { width: '354px', img: '/assets/icons/quality.png', en: 'Quality control carried out by a second professional native translator (reserved for Premium Service);' },
]
 },
 "Italian":{
    title: 'Il preventivo include:',
    icons: [
        { width: '160px', img: '/assets/icons/headset.png', en: 'Servizio clienti disponibile 24/7.' },
        { width: '203px', img: '/assets/icons/quality.png', en: 'Controllo qualità finale effettuato da parte del Project Manager.' },
        { width: '160px', img: '/assets/icons/tower.png', en: 'Formatting & impostazione grafica.' },
        { width: '364px', img: '/assets/icons/translation.png', en: 'Servizio di traduzione professionale effettuato da linguista madrelingua con almeno 5 anni di esperienza nel settore richiesto.' },
        { width: '160px', img: '/assets/icons/vector.png', en: 'Creazione memorie di traduzione.' },
        { width: '203px', img: '/assets/icons/rectangle.png', en: 'Sviluppo di glossari specifici sui testi tradotti.' },
        { width: '160px', img: '/assets/icons/messanger.png', en: 'Richiesta feedback finale tramite call & email.' }, 
        { width: '354px', img: '/assets/icons/quality.png', en: 'Controllo qualità effettuato da parte di un secondo traduttore madrelingua professionista (riservato al servizio Premium).' },

    ],
  }
}

const contentAcademy = {
  "English":{ title:'Quote Includes',
  icons: [
    { "width": "160px", "img": "/assets/icons/headset.png", "en": "24/7 Customer Care available" },
    { "width": "203px", "img": "/assets/icons/quality.png", "en": "Learning app and interactive lessons" },
    { "width": "203px", "img": "/lingoAcademy/icons/teach.png", "en": "Use of the best learning platforms" },
    { "width": "354px", "img": "/lingoAcademy/icons/discount.png", "en": "10% discount for new students" },
    { "width": "160px", "img": "/assets/icons/vector.png", "en": "Recreational activities and dedicated events" },
    { "width": "203px", "img": "/assets/icons/rectangle.png", "en": "Use of cutting-edge software and apps" },
    { "width": "203px", "img": "/lingoAcademy/icons/classroom.png", "en": "Innovative teaching methodologies" },
    { "width": "354px", "img": "/assets/icons/quality.png", "en": "Completely free initial test to verify the knowledge level of the chosen language" }
  ]
 },
 "Italian":{
    title: 'Il preventivo include:',
    icons: [
        { width: '160px', img: '/assets/icons/headset.png', en: 'Customer Care disponibile 24/7' },
        { width: '203px', img: '/assets/icons/quality.png', en: 'Learning app e lezioni interattive' },
        { width: '203px', img: '/lingoAcademy/icons/teach.png', en: 'Impiego delle migliori piattaforme di apprendimento' },
        { width: '354px', img: '/lingoAcademy/icons/discount.png', en: '10% di sconto per i nuovi studenti' },
        { width: '160px', img: '/assets/icons/vector.png', en: 'Attività ricreative ed eventi dedicati' },
        { width: '203px', img: '/assets/icons/rectangle.png', en: 'Utilizzo di Software e App all’avanguardia' },
        { width: '203px', img: '/lingoAcademy/icons/classroom.png', en: 'Metodologie didattiche innovative' }, 
        { width: '354px', img: '/assets/icons/quality.png', en: 'Test iniziale completamente gratuito per verificare il livello di conoscenza della lingua scelta' },

    ],
  }
}
function Table3() {

    const {details, language} = useSelector((state)=>state.proposal);
    const {addOns, brand} = details;
  return (
  <>
  {addOns?.urgency ?   <Scrollbar>
        <TableContainer sx={{ width: '95%', marginTop: '20px', marginInline: 'auto' }}>
          <Table sx={{ width: '100%', borderCollapse: 'inherit' }}>
            <TableHead
              sx={{
                borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                '& th': { backgroundColor: '#ce4545' },
                '& .css-1k91p2d-MuiTableCell-root:last-of-type, & .css-1k91p2d-MuiTableCell-root:first-of-type':
                  {
                    boxShadow: 'none',
                  },
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    width: '50%',
                    color: 'white',
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: 'small',
                    fontWeight: '400',
                  }}
                  align="left"
                >
                  {language === 'English'
                    ? `We confirm your request for an urgent translation service and are pleased to provide the following quote. However, we would like to emphasize that the urgent nature of this service may impact the quality of the translation provided. The client is requested to carefully read the following clause and confirm their consent before proceeding with the acceptance of the quote:
                    The Client understands and agrees that the request for an urgent translation service involves significant time constraints, which may limit the time available for translator selection, translation completion, text revision, and translation quality control. Consequently, the quality of the translation may be lower compared to services performed within standard timeframes.
                    The Client acknowledges that, despite our best efforts, the required speed may affect the accuracy and completeness of the provided translation. This limitation is unavoidable given the time constraints imposed by the urgent nature of the service.
                    The Client waives any claims or liabilities against the service provider for any spelling, grammatical, lexical, or syntactical errors (there is a high probability of style and form discrepancies, with textual inconsistencies due to the division of texts among different translators), omissions, and/or reduction in the quality of the translation caused by the urgent nature of the request.
                    The Client is aware that any requests for revision or correction may be limited due to time constraints, and the service provider will not guarantee in any way the complete elimination of any defects.
                    The Client confirms having read and understood this clause, expressly acknowledging the nature of the urgent service and accepting the possible effects on the quality of the provided translation.
                    By accepting this quote, the Client confirms their consent to the specified terms and conditions, including the above-mentioned clause.
                    If it is possible to extend the delivery date, please inform us so that we can review the quote. LingoYou will make every effort to avoid the aforementioned issues, but cannot guarantee the same quality as a translation completed within standard timeframes and, therefore, assumes no responsibility for the final result.`    :
                     `Confermiamo la vostra richiesta di servizio di traduzione urgente e siamo lieti di fornire il seguente preventivo. Tuttavia, desideriamo sottolineare che la natura urgente di questo servizio potrebbe influire sulla qualità della traduzione fornita. Il cliente è pregato di leggere attentamente la seguente clausola e confermare il proprio consenso prima di procedere con l'accettazione del preventivo:
Il Cliente comprende e accetta che la richiesta di un servizio di traduzione urgente comporta restrizioni temporali significative, il  che potrebbero limitare il tempo disponibile per la selezione del traduttore, la realizzazione della traduzione, la revisione dei testi e il controllo di qualità della traduzione. Di conseguenza, la qualità della traduzione potrebbe essere inferiore rispetto ai servizi effettuati in tempi standard.
Il Cliente riconosce che, nonostante i nostri migliori sforzi, la rapidità della richiesta potrebbe influire sulla precisione e sulla completezza della traduzione fornita. Tale limitazione è inevitabile date le restrizioni temporali imposte dalla natura urgente del servizio.
Il Cliente rinuncia a qualsiasi pretesa o responsabilità nei confronti del fornitore di servizi per eventuali errori ortografici, grammaticali, lessicali o sintattici (vi è un’alta probabilità che si verifichino discrepanze a livello di stile e di forma, con incongruenze testuali dovute alla divisione dei testi su diversi traduttori), omissioni e/o riduzione della qualità della traduzione causati dalla natura urgente della richiesta.
Il Cliente è consapevole che eventuali richieste di revisione o correzione potrebbero essere limitate a causa delle restrizioni temporali e che il fornitore di servizi non garantirà, in alcun modo, la completa eliminazione di eventuali difetti.
Il Cliente conferma di aver letto e compreso la presente clausola, riconoscendo espressamente la natura del servizio urgente e accettando i possibili effetti sulla qualità della traduzione fornita.
Con l'accettazione del presente preventivo, il Cliente conferma il suo consenso ai termini e alle condizioni specificati, inclusa la clausola sopraesposta.
Qualora fosse possibile estendere la data di consegna, vi preghiamo di comunicarcelo così da poter rivedere il preventivo.  LingoYou cercherà di avvalersi di ogni strumento per evitare quanto sopra citato, ma non potrà garantire la stessa qualità di una traduzione svolta entro le tempistiche standard e, pertanto, non si assume la responsabilità del risultato finale. `}
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Scrollbar>:  <div>
<Grid container spacing={3} sx={{ marginInline:"auto",paddingTop:"0px", marginTop:"-20px", textAlign:"center" }}>
           <Grid item xs={12} sx={{textAlign:"-webkit-center",paddingTop:"0px !important", marginInline:"auto"}}>
               
               <p  style={{fontWeight:"600", fontSize:"36px",
                fontFamily:"'Poppins'"}}>
                {content[language]?.title}
               </p>
             
          </Grid>
       </Grid>
       <div style={{marginInline:"auto", justifyContent:"center", textAlign:"-webkit-center"}}>
       <Grid container spacing={3} sx={{mt:2, my:3, maxWidth:"1270px"}}>
        {brand?.academy ? contentAcademy[language]?.icons.map((icon, index)=><Grid item xs={3} sm={4} lg={3} sx={{marginLeft:"0px", display:'flex', textAlign:"center"}}>
            <div style={{paddingTop:'5%',width:"100%", maxWidth:`${icon.width}`,mt:3, 
                 flex:`1, 1, ${icon.width}`, textAlign:'center'}}>
                <div style={{background:"#2DA0F0",width:'50px',height:'50px', textAlign:'center', 
                    marginInline:'auto', borderRadius:'5px', padding:'5px'}}>
                    <img src={icon.img} style={{width:'40px',height:'40px',paddingTop:'10%', objectFit:'contain'}} alt='logo'/>
                </div>
                <p style={{paddingTop:'5px', fontSize:"16px", width:'95%', fontWeight:'500'}}>
                {icon.en}
                </p>
            </div>
        </Grid>) : content[language]?.icons.map((icon, index)=><Grid item xs={3} sm={4} lg={3} sx={{marginLeft:"0px", display:'flex', textAlign:"center"}}>
            <div style={{paddingTop:'5%',width:"100%", maxWidth:`${icon.width}`,mt:3, 
                 flex:`1, 1, ${icon.width}`, textAlign:'center'}}>
                <div style={{background:"#2DA0F0",width:'50px',height:'50px', textAlign:'center', 
                    marginInline:'auto', borderRadius:'5px', padding:'5px'}}>
                    <img src={icon.img} style={{width:'40px',height:'40px',paddingTop:'10%', objectFit:'contain'}} alt='logo'/>
                </div>
                <p style={{paddingTop:'5px', fontSize:"16px", width:'95%', fontWeight:'500'}}>
                {icon.en}
                </p>
            </div>
        </Grid>)}
        </Grid>
        </div>
    </div>}
  </>
  
  )
}

export default Table3