import React, { lazy, Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { addDetails, addLanguageAndId, addOption } from "../redux/proposalSlice";
// import InterpretClientProposal from "../interpretation/Proposal";
// import RafikyClientProposal from "../interpretation/Rafiky";



export const INTERPRETATION_QUOTE_DETAILS = gql`
  query InterpretationOrder($interpretationOrderId: ID!) {
    interpretationOrder(interpretationOrderId: $interpretationOrderId) {
      id
      orderNumber
      language
      upSellingServices
      marketingChannel {
        type
        details {
          source
          sourceDetails {
            utm
            campaignGclid
            ppcSource
            cpn
            gad_source
            adgroup
            kwid
            keywords
          }
        }
      }
      companyLogo
      totalDiscount
      quoteSignature
      reasonForClosure
      grandTotal
      netTotal
      optionSelected
      taxesOptions
      totalDiscountOptions
      netTotalOptions
      grandTotalOptions
      subtotalOptions
      industry {
        code
        name
      }
      companyNameAndId {
        id
        name
        paymentTerms
        address1
        address2
        city
        postcode
        VAT
      }
      accountManager {
        name
        email
        phoneNumber
        jobTitle
        avatarUrl
        language {
          code
          name
          nativename
        }
      }
      address1
      taxes
      interpretationItem {
        sourceLanguage {
          code
          name
          nativename
        }
        targetLanguage {
          code
          name
          nativename
        }
        service {
          Code
          InvCode
          Description {
            italian
            english
          }
          Service {
            italian
            english
          }
        }
        discount
        title
        quantity
        price
        total
        timezone
        type
        startDateTime
        endDateTime
      }
      interpretationItem2 {
        sourceLanguage {
          code
          name
          nativename
        }
        targetLanguage {
          code
          name
          nativename
        }
        service {
          Code
          InvCode
          Description {
            italian
            english
          }
          Service {
            italian
            english
          }
        }
        discount
        title
        quantity
        price
        total
        timezone
        type
        startDateTime
        endDateTime
      }
      subtotal
      subject
      address2
      postCode
      city
      country {
        code
        label
        phone
      }
      bookedBy {
        firstName
        lastName
        email
      }
      status
      supportMaterialLink
      createdAtDate
      startDate
      notes
      notesOptions
      pmNotes
      quoteDeadline
      followUpDate
      serviceSubtotal
      additionalService {
        title
        price
        quantity
        startDateTime
        endDateTime
        discount
        service {
          Code
          InvCode
          Description {
            italian
            english
          }
          Service {
            italian
            english
          }
        }
        serviceTotal
      }
      additionalService2 {
        title
        price
        quantity
        startDateTime
        endDateTime
        discount
        service {
          Code
          InvCode
          Description {
            italian
            english
          }
          Service {
            italian
            english
          }
        }
        serviceTotal
      }
      addOns {
        cover
        quoteInfo
        packages
        companyInfo
        noOfEvents
        handleEvent
        hostessService
        location
        friendly
        sector
        remoteEventAddress
        upSellingServices
      }
      brand {
        rafiky
        interpretation
      }
      defaultPkg
      clients {
        name
        logo
      }
    }
  }
`;

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function InterpretClientOutput() {
  const { id, lang } = useParams();
  // const [sendNotification] = useMutation(PROPOSAL_NOTIFICATION_INTERPRETATION)
  const dispatch = useDispatch();
  const { data } = useQuery(INTERPRETATION_QUOTE_DETAILS, {
    variables: { interpretationOrderId: id },
  });

  useEffect(() => {
    //passing getData method to the lifecycle method
    async function fetchData() {
      console.log("func called", data?.interpretationOrder);
      dispatch(addDetails({ details: data?.interpretationOrder, type: "CI" }));

      //  dispatch(addDetails(data?.interpretationOrder))
      dispatch(
        addLanguageAndId({
          language: lang ? lang : data?.interpretationOrder?.language,
          id: id,
        })
      );
      if (
        data?.interpretationOrder?.interpretationItem2?.length > 0 &&
        (data?.interpretationOrder?.status === "Quote-Won" ||
          data?.interpretationOrder?.status === "Sent To PM")
      ) {
        dispatch(addOption(data?.interpretationOrder?.optionSelected + 1));
      }
    }
    const scrollToSection = () => {
      // Get the hash from the URL
      const hash = window.location.hash;
      // If hash exists and it's not empty
      if (hash !== "" && hash.length > 1) {
        // Scroll to the section with the specified ID
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        } else {
          console.error(`Element with ID '${hash}' not found`);
        }
      }
    };

    // Call the function when the component mounts
    scrollToSection();
    if (data) {
      fetchData();
    }
  }, [data]);

  return (
    <>
      {data && data?.interpretationOrder && (
        <>

        {data?.interpretationOrder?.brand?.interpretation ? <InterpretClientProposal />:
        <RafikyClientProposal/>
        }
        
        </>
      )}
    </>
  );
}
export const LoadingScreen = () => {
  return (
    <div>
      <div className="file-loader">
        <div className="file-circle">
          <div className="file-wave"></div>
          <div className="file-wave file-wave2"></div>
          <img
            src="/CI_logo.png"
            alt="Loader Icon"
          />
        </div>
      </div>
    </div>
  )
}
const InterpretClientProposal = Loadable(lazy(() => import('../interpretation/Proposal')));
const RafikyClientProposal = Loadable(lazy(() => import("../interpretation/Rafiky")));
