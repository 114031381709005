import { TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';

function TableHeader() {

  const {details, language} = useSelector((state)=>state.proposal);

  const {brand} = details
  return (
    <TableHead
    sx={{
      
      borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
      '& th': { backgroundColor: '#2DA0F0' },
      height: '80px',
    }}
  >
    <TableRow>
      <TableCell
        sx={{
          backgroundColor: '#2DA0F0',
          width: '65%',
          color: 'white',
          fontFamily: "'Poppins'",
          fontSize: 'large',
        }}
        align="left"
      >
        {language === "English" ? 'Description' : "Descrizione"}{' '}
      </TableCell>
      <TableCell
        align="center"
        sx={{
          backgroundColor: '#2DA0F0',
          color: 'white',
          fontFamily: "'Poppins', sans-serif",
          fontSize: 'large',
          width: '10%',
        }}
      >
        {language === "English" ? "PRICE" : "TARIFFA"}{' '}
      </TableCell>
      <TableCell
        align="center"
        sx={{
          backgroundColor: '#2DA0F0',
          color: 'white',
          fontFamily: "'Poppins', sans-serif",
          fontSize: 'large',
          width: '10%',
        }}
      >
        {language === "English"? "QUANTITY" : "QUANTITÀ"}
      </TableCell>
      <TableCell
        align="center"
        sx={{
          backgroundColor: '#2DA0F0',
          color: 'white',
          fontFamily: "'Poppins', sans-serif",
          fontSize: 'large',
        }}
      >
        {language === "English" ? "DISCOUNT" : "SCONTO"}
      </TableCell>
      <TableCell
        colSpan={2}
        align="center"
        sx={{
          backgroundColor: '#2DA0F0',
          color: 'white',
          fontFamily: "'Poppins', sans-serif",
          fontSize: 'large',
          width: '100%',
        }}
      >
        {language === "English"? "TOTAL":"TOTALE"}
      </TableCell>
    </TableRow>
  </TableHead>
  )
}

export default TableHeader