import { Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import styles from '../styles/Values.module.css';

const content = {
    "Italian":{
        items:[
            { desc: "Dedizione al cliente: ci concentriamo sul cliente e tutto il resto arriverà." },
{ desc: "Innovazione e curiosità sono la nostra benzina. Abbracciamo e guidiamo il cambiamento." },
{ desc: "Integrità, onestà e trasparenza. Costruiamo relazioni aperte e oneste con la comunicazione." },
{ desc: "Assumiamo e formiamo solo i migliori talenti." },
{ desc: 'Esigiamo i più alti standard. "Bene" non è abbastanza.' },
{ desc: "Diamo più valore alle idee che alla gerarchia. Crediamo che la democrazia in azienda esista." },
{ desc: "Celebriamo le differenze. Un team responsabile, diversificato e inclusivo." },
{ desc: "Agiamo con responsabilità e facciamo la cosa giusta." },
{ desc: "Creare un'azienda forte e costruire un mondo migliore non sono obiettivi in conflitto." },
{ desc: "Siamo più motivati dall'impatto che dal denaro." }

        ]
    },
    "English":{
        items:[
            { desc:'Customer Obsession:Focus on the client and all else will follow.'},
            {desc:'Innovation & Curiosity are our Gasoline. We Embrace and Drive Change'},
            { desc:'Integrity, Honesty and Transparency. We Build Open and Honest relationships With Communication'},
            { desc:'We Hire and Develop the Best.'},
            { desc:'We Insist on the Highest Standards. Great just isn’t good enough.'},
            { desc:'We value ideas over hierarchy. We believe that democracy in the company works.'},
            { desc:'We celebrate differences. A team that’s empowered, diverse and inclusive.'},
            { desc:'We act like owners & we do the right thing'},
            { desc:'Creating a strong business and building a better world are not conflicting Goals.'},
            { desc:'We are more motivated by impact than money.'}

        ]
    }
}

export default function Values() {
  const { details, language } = useSelector((state) => state.proposal);

  return (
    <div style={{borderTop:"1px solid #2DA0F0", paddingBottom:"20px"}}>
     <div
    style={{
      textAlign: "right",
      width: "97%",
      padding:"2px",
      marginInline:"auto",
      display:"flex",
      marginBottom: "50px",
      justifyContent: "space-between",
      borderBottom: "2px solid #AA32E1",

    }}
  >
     <p>
           <span  style={{fontWeight:"500", color: "#AA32E1", fontSize: "20px"}}
           >
          {language === 'English' ? `COMPANY VALUES` : `VALORI DELL'AZIENDA`}

    </span>
    </p>
    <img
      src={"/lingoAcademy/logos/academy.png"}
      style={{ marginRight: "20px", objectFit:"contain" }}
      alt="logo"
    />
  </div>
        
        <Grid container spacing={1} sx={{ marginTop: "5px" }}>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{ marginInline: "auto", textAlign: "center" }}
            lg={12}
          >
            <Grid
          container
          spacing={3}
          sx={{ marginTop: "20px", width: "95%", margin: "5px", marginInline:"auto", placeContent: "center" }}
          className={styles.icons}
        >
         
         {content[language]?.items?.map((item, index)=>{
            return (
<Grid item xs={3} sm={4} lg={3}>
            <div className={styles.icon}>
              <p style={{color:"#aa32e1", fontSize:"20px", fontWeight:"bold", marginBottom:"25px", placeContent:"center", marginInline:"auto", alignItems:'center', justifyContent:"center", backgroundColor:"white", marginTop:"-50px", width:"50px", height:"50px", padding:"10px", borderRadius:"50%", border:"2px solid #aa32e1", fontWeight:"bold"}}>
                   {index+1}
              </p>
              <p style={{fontSize:"17px", margin:'5px'}}>
                {item?.desc}
              </p>
            </div>
          </Grid>
            )
         }) }
         
        </Grid>
          </Grid>
          <Grid item xs={12} md={0.5} lg={0.5}></Grid>

        </Grid>
      
    </div>
  );
}
