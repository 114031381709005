import { Grid } from "@mui/material";
import React from "react";
import styles from "../styles/Courses.module.css";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import { useSelector } from "react-redux";

export default function Courses() {

  const {language} = useSelector((state)=>state.proposal);

  if (language === 'English') {
    return (<div style={{borderTop:"1px solid #2DA0F0"}} className={styles.desktop24}>
      <div className={styles.desktop24Child} />
      {/* <div className={styles.div}>11</div> */}
      <div className={styles.interpreting}>
        LingoYou Academy, language courses for companies and professionals
      </div>
      <div className={styles.text3543Parent}>
        <img alt="" src="/lingoAcademy/logos/academy.png" />{" "}
      </div>
      <div className={styles.sections}>
        <div className={styles.section1}>
          <p>Professional training on various levels.</p>
          <p>
          Qualified teachers, innovative teaching methods and cutting-edge technologies for a service of high-quality standards.
          </p>
          <div className={styles.arrows}>
            <div className={styles.arrow1}>
              
            </div>
            <div className={styles.arrow1}>
              
            </div>
            <div className={styles.arrow1}>
              
            </div>
          </div>
          <div className={styles.arrows2}>
            <div className={styles.arrow21}>
            </div>
            <div className={styles.arrow22}>
            </div>{" "}
            <div className={styles.arrow23}>
            </div>
          </div>
          <div className={styles.cards}>
            <div className={styles.card1}>
              <h4>Tailored courses
              </h4>
              <p>
              Our corporate language courses are adaptable and customized according to company requirements and goals.
              </p>
            </div>
            <div className={styles.card2}>
              <h4>
                Mother tongue teachers

              </h4>
              <p>
                Qualified native speaking professionals specialized in teaching.

              </p>
            </div>
            <div className={styles.card3}>
              <h4>Quality</h4>
              <p>
              Many years of experience in the field and cutting-edge technologies for a high-quality service
              </p>
            </div>
          </div>
        </div>
        <div className={styles.section2}>
        <div style={{marginTop:"100px"}}>
          <LiteYouTubeEmbed 
        
        id="VRIr22lCZ80"
        title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
    />
          </div>
        </div>
      </div>
      <div className={styles.divider}>OUR COURSES</div>
      <Grid container spacing={3} className={styles.footer}>
        <Grid item md={4}>
          <div className={styles.footerCard1}>
            <div>
              <img style={{objectFit:"contain", width:"30px", height:"40px"}}  src="/lingoAcademy/icons/single-user-black.png" />
              <p>Individual courses</p>
              <h4>INDIVIDUAL STUDY</h4>
            </div>
            <p>
            By choosing our one-to-one courses, you access a completely personalized path, defined according to specific needs and objectives in order to develop Business Skills and Professional English topics most relevant to your field of reference.
            </p>
          </div>
        </Grid>
        <Grid item md={4}>
          <div className={styles.footerCard1}>
            <div>
              <img  style={{objectFit:"contain", width:"30px", height:"40px"}} src="/lingoAcademy/icons/contacts-black.png" />
              <p>
                <b>Semi-individual courses</b>
              </p>
              <h4>SMALL GROUPS OF 2-3 PEOPLE</h4>
            </div>
            <p>
            Our semi-individual courses combine the advantages of a personalized path with those of group lessons, also representing a real team building experience with the aim of developing the ability to work in synergy.
            </p>
          </div>
        </Grid>
        <Grid item md={4}>
          <div className={styles.footerCard1}>
            <div>
              <img style={{objectFit:"contain", width:"30px", height:"40px"}}  src="/lingoAcademy/icons/black-contacts.png" />
              <img style={{objectFit:"contain", width:"30px", height:"40px"}}  src="/lingoAcademy/icons/black-contacts.png" />
              <img style={{objectFit:"contain", width:"30px", height:"40px"}}  src="/lingoAcademy/icons/black-contacts.png" />

              <p>
                <b>Group courses

                </b>
              </p>
              <h4>GROUP LESSONS
              </h4>
            </div>
            <p>
            Group courses are the cheapest, but no less efficient, alternative to a lot of courses offered by LingoYou Academy. Group lessons are designed to develop the linguistic, working, and relational skills of your company's employees.
               </p>
          </div>
        </Grid>
      </Grid>
    </div>)
  }
  return (
    <div style={{borderTop:"1px solid #2DA0F0"}} className={styles.desktop24}>
      <div className={styles.desktop24Child} />
      {/* <div className={styles.div}>11</div> */}
      <div className={styles.interpreting}>
        LingoYou Academy, corsi di lingue per aziende e professionisti
      </div>
      <div className={styles.text3543Parent}>
        <img   alt="" src="/lingoAcademy/logos/academy.png" />{" "}
      </div>
      <div className={styles.sections}>
        <div className={styles.section1}>
          <p>Formazione professionale e a vari livelli.</p>
          <p>
            Docenti qualificati, metodologie didattiche innovative e tecnologie
            all’avanguardia per un servizio ad alti standard qualitativi.
          </p>
          <div className={styles.arrows}>
            <div className={styles.arrow1}>
              
            </div>
            <div className={styles.arrow1}>
              
            </div>
            <div className={styles.arrow1}>
             
            </div>
          </div>
          <div className={styles.arrows2}>
            <div className={styles.arrow21}>
            </div>
            <div className={styles.arrow22}>
            </div>{" "}
            <div className={styles.arrow23}>
            </div>
          </div>
          <div className={styles.cards}>
            <div className={styles.card1}>
              <h4>Corsi su misura</h4>
              <p>
                I nostri corsi di lingua per aziende sono adattabili e vengono
                personalizzati in funzione delle esigenze e degli obiettivi
                aziendali.
              </p>
            </div>
            <div className={styles.card2}>
              <h4>
                Docenti Madrelingua
              </h4>
              <p>
                Professionisti madrelingua qualificati e specializzati nell’insegnamento.
              </p>
            </div>
            <div className={styles.card3}>
              <h4>Qualità</h4>
              <p>
                Esperienza pluriennale sul campo e tecnologie all’avanguardia per un servizio di alta qualità.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.section2}>
          <div style={{marginTop:"100px"}}>
          <LiteYouTubeEmbed 
        
        id="VRIr22lCZ80"
        title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
    />
          </div>
        </div>
      </div>
      <div className={styles.divider}>I nostri corsi</div>
      <Grid container spacing={3} className={styles.footer}>
        <Grid item md={4}>
          <div className={styles.footerCard1}>
            <div>
              <img style={{objectFit:"contain", width:"30px", height:"40px"}}  src="/lingoAcademy/icons/single-user-black.png" />
              <p>Corsi individuali</p>
              <h4>STUDIA INDIVIDUALMENTE</h4>
            </div>
            <p>
              Scegliendo i nostri corsi individuali, accedi a un percorso
              completamente personalizzato, adatto alle esigenze e a obiettivi
              specifici al fine di sviluppare le Business Skills e le tematiche
              di Professional English più rilevanti per il tuo settore di
              riferimento.
            </p>
          </div>
        </Grid>
        <Grid item md={4}>
          <div className={styles.footerCard1}>
            <div>
              <img style={{objectFit:"contain", width:"30px", height:"40px"}}  src="/lingoAcademy/icons/contacts-black.png" />
              <p>
                <b>Corsi semi-individuali</b>
              </p>
              <h4>MINI-GRUPPI DI 2-3 PERSONE</h4>
            </div>
            <p>
              I nostri <b>corsi semi-individuali</b> uniscono i vantaggi di un
              percorso personalizzato a quelli delle lezioni di gruppo,
              costituendo nell’insieme anche una vera e propria esperienza di{" "}
              <b>team building</b>, con l’obiettivo di sviluppare la capacità di
              lavorare in sinergia.
            </p>
          </div>
        </Grid>
        <Grid item md={4}>
          <div className={styles.footerCard1}>
            <div>
              <img  style={{objectFit:"contain", width:"30px", height:"40px"}} src="/lingoAcademy/icons/black-contacts.png" />
              <img style={{objectFit:"contain", width:"30px", height:"40px"}}  src="/lingoAcademy/icons/black-contacts.png" />
              <img style={{objectFit:"contain", width:"30px", height:"40px"}}  src="/lingoAcademy/icons/black-contacts.png" />

              <p>
                <b>Corsi collettivi</b>
              </p>
              <h4>LEZIONI DI GRUPPO</h4>
            </div>
            <p>
              I <b>corsi collettivi</b> sono <b>l’alternativa più economica,</b>{" "}
              ma non per questo meno efficiente, della rosa di corsi proposti da
              LingoYou Academy. Le lezioni di gruppo sono pensate per sviluppare
              le skill linguistiche, lavorative e relazionali dei dipendenti
              della tua azienda.
            </p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
