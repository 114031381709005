import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';
import AboutUs2 from './AboutUs2.js';



const AboutUs1 = ({ accountManager, language="English"}) => {
  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, paddingBottom:"20px" }}>
   
      <View style={{display:"flex", marginLeft:"20px", marginBottom:"15px", flexDirection:"row"}}>
      <View style={{width:"300px", marginTop:"20px", marginLeft:"10px"}}>
        <Text style={{fontSize:"13px", fontWeight:"bold", color:"black"}}>
        {language === "English" ? "Professional training at various levels.":
            "Formazione professionale e a vari livelli."}
        </Text>
        <Text style={{fontSize:"9px", marginTop:"15px", fontWeight:"light"}}>
        {language === "English" ? `For more than ten years we have dedicated ourselves to teaching and training with passion and professionalism.
Our school is recognized for its high quality standards, the result of the experience and
skills of our team of experts. Our teachers are professionals from all over the
world, specialized in teaching and highly qualified.` : 
       `Da più di dieci anni ci dedichiamo all’insegnamento e alla formazione con passione e professionalità.
La nostra scuola è riconosciuta per gli elevati standard di qualità, risultato dell’esperienza e delle
competenze del nostro team di esperti. I nostri insegnanti sono professionisti provenienti da ogni
parte del mondo, specializzati nell’insegnamento e altamente qualificati.
`}  
        </Text>
        <Text style={{fontSize:"9px", marginTop:"5px", fontWeight:"light"}}>
        {language === "English" ? `In addition to standard language courses, LingoYou Academy offers the opportunity to choose specific courses aimed at objectives and/or needs (such as business, travel, sports), with programs created ad hoc and tailor-made.` : 
       `Oltre ai corsi di lingua standard, LingoYou Academy offre l’opportunità di scegliere corsi specifici mirati
su obiettivi e/o esigenze (come business, viaggi, sport), con programmi creati ad hoc e su misura.
`}     </Text>
        
      </View>
      <View style={{width:"260px", marginLeft:"10px"}}>
      <Image
                    style={{ objectFit: "contain", width:"100%" }}
                    src="/lingoAcademy/academy-aboutUs.png"
                  />
      </View>
      </View> 
    <AboutUs2 language={language}/>
     
    </Page>
  );
};

export default AboutUs1;



