import React from 'react';
import { Page, Text, View, Image, Document } from '@react-pdf/renderer';
import { styles } from './globalStyles.js';
import Preventivo from '../../pdf/pages/TableComponents/Preventivo.js';
import TableItems from '../../pdf/pages/TableComponents/TableItems.js';
import Home from './pages/Home.js';
 import AboutUs1 from './pages/AboutUs1.js';
 import AboutUs2 from './pages/AboutUs2.js';
import ContactUs from './pages/ContactUs.js';
import OurClients from '../../pdf/pages/OurClients.js';
import Header from '../../pdf/pages/TableComponents/Header.js';
import TableHeader from '../../pdf/pages/TableComponents/TableHeader.js';
import TableTotals from '../../pdf/pages/TableComponents/TableTotals.js';
import TermsAndCondition from './pages/Terms&Conditions.js';
import Packages from '../../pdf/pages/Packages.js';
import Values from './pages/Values.js';
import Quality from './pages/Quality.js';
import LangsActivities from './pages/LangsActivities.js';
import Courses from './pages/Courses.js';
import Certification from './pages/Certification.js';
import OurTeam from './pages/OurTeam.js';
import LearningApps from './pages/LearningApps.js';
import Innovation from './pages/Innovation.js';

const PDFDoc = ({grandTotalValue, language = 'Italian', details = {},name, packagesPrices, items1Translation,
items2Translation,selectedPackageOption,
service1Translation,
service2Translation, packagesNetTotals, packagesItem, packagesItemsTranslation, quoteId, orgPkgsOption }) => {
  
  console.log(packagesItem);
  const {
    companyNameAndId,
    accountManager,
    notes,
    taxes,
    grandTotal,
    netTotal,
    totalDiscount,
    subtotal,
    bookedBy,
    netTotalOptions,
    additionalService,
    additionalService2,
    notesOptions,
    industry,
    translationItem,
    translationItem2,
    orderNumber,
    subject,
    clients,
    deliveryDateTime,
    addOns,
    brand,
    quoteSignature, totalDiscountOptions, subtotalOptions,
    taxesOptions, grandTotalOptions, workingDaysToDeadline
  } = details;

  let combinedItems = [];
  let itemsPerPage;
  let totalPages;
  if(addOns?.packages){
    const item = packagesItem?.item || [];
    const service = packagesItem?.service || []
  combinedItems = [
   ...item, ...service
  ];
   itemsPerPage = combinedItems?.length <= 5 ? 4 : 5;
   totalPages = combinedItems.length > 3 ?  Math.ceil(combinedItems.length / itemsPerPage) : 1;
  
  }else{
  combinedItems = [
    ...translationItem,
    ...additionalService,
  ];
   itemsPerPage = combinedItems?.length <= 5 ? 4 : 5;
   totalPages = combinedItems.length > 3 ?  Math.ceil(combinedItems.length / itemsPerPage) : 1;
  
  }
  const totalLengthOption2 = translationItem2?.length + additionalService2?.length;

  const renderItemsForPage = (pageNumber) => {
    let combinedItemsTranslation = []
    if(addOns?.packages){
    combinedItemsTranslation = packagesItemsTranslation ? [
      ...packagesItemsTranslation,
      ...service1Translation 
    ] : []
    
    }else{
    combinedItemsTranslation = items1Translation ? [
      ...items1Translation,
      ...service1Translation 
    ] : []
    }
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = Math.min(pageNumber * itemsPerPage, combinedItems.length);

    const itemsToRender = combinedItems.slice(startIndex, endIndex);
    const itemsTransToRender = combinedItemsTranslation?.slice(startIndex, endIndex)
    return (
      <TableItems 
      language={language} 
      details={{translationItem:itemsToRender}}
      items1Translation={itemsTransToRender ? itemsTransToRender : null}
    />
    );
  };
  const combinedItemOptions =  [...translationItem2]?.map((item, index)=>[...item, ...additionalService2[index]])
  let itemsPerPageOption = combinedItemOptions?.length > 0 ?  combinedItemOptions.map((item)=> item?.length <= 5 ? 4 : 5) : [];
  let totalPagesPerOption = [];
 if(itemsPerPageOption?.length > 0){
  for(var i = 0; i < itemsPerPageOption?.length; i++){
   totalPagesPerOption.push(combinedItemOptions[i].length > 3 ?  Math.ceil(combinedItemOptions[i].length / itemsPerPageOption[i]) : 1);
  }
 }else{
  itemsPerPageOption = []
 }
  const renderItemOptionsForPage = (pageNumber, index, optionNo) => {
    const optionItemsTranslation = items2Translation?.length > 0 ? [...items2Translation[optionNo]] : []
    const optionServicesTranslation = service2Translation?.length > 0 ? [...service2Translation[optionNo]] : []
    const combinedItemsTranslation = (optionItemsTranslation?.length > 0 || optionServicesTranslation?.length > 0) ? [
      ...optionItemsTranslation,
      ...optionServicesTranslation 
    ] : null
    const startIndex = (pageNumber - 1) * itemsPerPageOption[optionNo];
    const endIndex = Math.min(pageNumber * itemsPerPageOption[optionNo], combinedItemOptions[optionNo].length);

    const itemsToRender = combinedItemOptions[optionNo].slice(startIndex, endIndex);
    console.log("Options", index, combinedItemOptions[optionNo], totalPagesPerOption);
    console.log("Options1", itemsToRender, itemsPerPageOption)
    const itemsTransToRender = combinedItemsTranslation && combinedItemsTranslation?.slice(startIndex, endIndex)
    return (
      <TableItems 
      language={language} 
      details={{translationItem:itemsToRender}}
      items1Translation={itemsTransToRender ? itemsTransToRender : null}
    />
    );
  };
  return (
    <>
    {details?.addOns &&  <Document>
      {addOns.cover && (
        <Home
          language={language}
          data={{ companyNameAndId, bookedBy, accountManager, orderNumber, addOns, industry }}
        />
      )}
      {addOns.companyInfo && (
        <>
        <AboutUs1 language={language} accountManager={accountManager} />
        <OurTeam language={language}/>
        <Values language={language}/>
         <Quality language={language}/>
         <Innovation language={language}/>
         <LearningApps language={language}/>
         <Courses language={language}/>
          <LangsActivities language={language}/>
          <Certification language={language}/> 
          <OurClients language={language} />
        </>
      )}
      {addOns.packages && <Packages orgPkgsOption={orgPkgsOption} status={details?.status} selectedPackageOption={selectedPackageOption} packagesNetTotals={packagesNetTotals} language={language} deliveryDateTime={deliveryDateTime} packagesPrices={packagesPrices}/>}
     {(addOns && addOns?.quoteInfo && !addOns?.packages) ? (
        <>
     {Array.from({ length: totalPages }, (_, index) => (
      <Page wrap={false} size={[595.28, 640.89]} style={styles.page}>
      {index === 0 ? (
        <>
        <Header brand={brand} language={language} grandTotal={grandTotalValue} bookedBy={bookedBy} 
        accountManager={accountManager} companyNameAndId={companyNameAndId}/>
         <TableHeader language={language} option={translationItem2?.length > 0 ? 1 : 0}/>
         {renderItemsForPage(index + 1)}
        </>
        
      ): (
        <>
               {renderItemsForPage(index + 1)}
        </>
      )}
       {((totalPages - index) === 1) && <>
        <View style={styles.tableItems}> </View>
        <TableTotals details={{ notes,
            totalDiscount,  
            subtotal, netTotal, taxes, grandTotal}}
           language={language}/>
            {totalLengthOption2 === 0 && <Preventivo brand={brand} language={language} addOns={addOns} />}
        
       </>
          } 
      </Page>
     ))}
      {totalLengthOption2 > 0 && translationItem2?.map((item, index)=>(
       <>
       {Array.from({ length: totalPagesPerOption[index] }, (_, ind) => (
        <Page wrap={ false } size={[595.28, 640.89]} style={styles.page}>
        {ind === 0 ? (
          <>
           <TableHeader language={language} option={index + 2}/>
           {renderItemOptionsForPage(ind + 1, ind, index)}
          </>
          
        ): (
          <>
                 {renderItemOptionsForPage(ind + 1, ind, index)}
          </>
        )}
        {((totalPagesPerOption[index] - ind) === 1) && <>
        <View style={styles.tableItems}> </View>
        <TableTotals details={{ notes: notesOptions[index],
          totalDiscount:totalDiscountOptions[index],  
          subtotal: subtotalOptions[index], netTotal:netTotalOptions[index], taxes:taxesOptions[index], grandTotal:grandTotalOptions[index]}} language={language}/>
        {(translationItem2?.length - index === 1) && <Preventivo brand={brand} language={language} addOns={addOns} />
}
        </>
        }
        </Page>
        
      ))}
      </>
     ))}
       <TermsAndCondition
            language={language}
            data={details}
            // translationItem={translationItem}
            // address1={address1}
            companyNameAndId={companyNameAndId}
            workingDaysToDeadline={workingDaysToDeadline}
            // orderNumber={orderNumber}
            // additionalService={additionalService}
            name={name}
            // isBtn={isBtn}
            quoteSignature={quoteSignature}
            // additionalService2={additionalService2}
            selectedPackageOption={selectedPackageOption}
            packagesNetTotals={packagesNetTotals}
          />

      </>
      
      ) : (
       <>
     {Array.from({ length: totalPages }, (_, index) => (
      <Page wrap={false} size={[595.28, 640.89]} style={styles.page}>
      {index === 0 ? (
        <>
        <Header language={language} grandTotal={grandTotalValue} bookedBy={bookedBy} accountManager={accountManager} companyNameAndId={companyNameAndId}/>
         <TableHeader language={language} option={translationItem2?.length > 0 ? 1 : 0}/>
         {renderItemsForPage(index + 1)}
        </>
        
      ): (
        <>
               {renderItemsForPage(index + 1)}
        </>
      )}
       {((totalPages - index) === 1) && <>
        <View style={styles.tableItems}> </View>
       { <TableTotals details={{ notes,
           totalDiscount: packagesItem?.totalDiscount,  
            subtotal: packagesItem?.subtotal, netTotal: packagesItem?.netTotal, taxes, grandTotal: packagesItem?.grandTotal}}
           language={language}
           />}
            {totalLengthOption2 === 0 && <Preventivo brand={brand} language={language} addOns={addOns} selectedPackageOption={selectedPackageOption}/>}
        
       </>
          } 
      </Page>
     ))}
      {totalLengthOption2 > 0 && translationItem2?.map((item, index)=>(
       <>
       {Array.from({ length: totalPagesPerOption[index] }, (_, ind) => (
        <Page wrap={ false } size={[595.28, 640.89]} style={styles.page}>
        {ind === 0 ? (
          <>
           <TableHeader language={language} option={index + 2}/>
           {renderItemOptionsForPage(ind + 1, ind, index)}
          </>
          
        ): (
          <>
                 {renderItemOptionsForPage(ind + 1, ind, index)}
          </>
        )}
        {((totalPagesPerOption[index] - ind) === 1) && <>
        <View style={styles.tableItems}> </View>
        <TableTotals details={{ notes,
          totalDiscount:totalDiscountOptions[index],  
          subtotal: subtotalOptions[index], netTotal:netTotalOptions[index], taxes:taxesOptions[index], grandTotal:grandTotalOptions[index]}} language={language}/>
        {(translationItem2?.length - index === 1) && <Preventivo brand={brand} language={language} addOns={addOns}/>
}
        </>
        }
        </Page>
        
      ))}
      </>
     ))}
       <TermsAndCondition
            language={language}
            data={details}
            // translationItem={translationItem}
            // address1={address1}
            companyNameAndId={companyNameAndId}
            workingDaysToDeadline={workingDaysToDeadline}
            // orderNumber={orderNumber}
            // additionalService={additionalService}
            name={name}
            // isBtn={isBtn}
            quoteSignature={quoteSignature}
            // additionalService2={additionalService2}
            selectedPackageOption={selectedPackageOption}
            packagesNetTotals={packagesNetTotals}
          />

      </>
      )}
      {(addOns.companyInfo || addOns.quoteInfo || addOns.cover) && (
      <ContactUs language={language} />
      )}
    </Document>
    
    }
    </>
  );
};

export default PDFDoc;
