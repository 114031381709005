import React from 'react';
import { styles } from '../../globalStyles';
import { Text, View } from '@react-pdf/renderer';
import { fCurrency } from '../../../../utils/formatNumber';
import { languageMappings } from '../../../../utils/languages';
import _ from 'lodash';


function formatWithTrailingZero(value, precision = 3) {
  // Convert the string value to a number
  // const numericValue = Number(value);

  // Floor the value to the desired precision
  const flooredValue = _.floor(Number(value), 3);
  const flooredString = flooredValue.toFixed(2); // Ensures consistent decimal places
  const lastValue = String(value)?.split('.')[1]
  // Check if the last digit after decimal is 0
  if (value[value.length - 1] === '0' ) {
    return flooredString; // Add a trailing 0
}else if(lastValue?.length === 1){
  return flooredString
}


  return String(flooredValue); // Return as string without unnecessary formatting
}
const TableItems = ({
  language,
   details,  items1Translation,
   service1Translation,
}) => {

  const {
    translationItem,
    additionalService,
  } = details

  return (
    <>
       
        {translationItem && (
          <View style={styles.tableItemsBody}>
            {translationItem.map((row, index) => (
              <View style={{...styles.tableItemsBodyItem, backgroundColor: index % 2 === 0 ? '#e6f5ff' : 'transparent',}} key={index}>
                <View style={styles.tableItemsItemLeft}>
                {row?.sourceLanguage?.name && <Text style={{display:'flex', flexDirection:'row'}}>
                  <Text style={{...styles.bold, fontSize:'10px'}}>
                  {language === "English" ?  'File Name: ' : 'Nome File: ' }
                  </Text>
                  <Text style={{ fontSize:'10px'}}>
                  {row?.title}
                  </Text>
                  </Text>
                 }
                  <Text style={styles.bold}>
                  {row.service.Item[language.toLowerCase()]}
                  </Text>
                 {row?.sourceLanguage?.name && <Text style={{display:'flex', flexDirection:'row'}}>
                  <Text style={{...styles.bold, fontSize:'9.5px'}}>
                  {language === "English" ?  'from: ' : 'da: ' }
                  </Text>
                  <Text style={{ fontSize:'9.5px'}}>
                  {language === "English" ? row.sourceLanguage?.name : languageMappings[`${row.sourceLanguage?.name}`]}
                  </Text>
                  <Text style={{...styles.bold, fontSize:'9px'}}>
                  {language === "English" ?  ' to: ' : ' a: ' }
                  </Text>
                  <Text style={{ fontSize:'9.5px'}}>
                  {row?.targetLanguage?.map((lang) => {
                    if(language === "English"){
                     return lang.name
                    }else{
                     return languageMappings[`${lang?.name}`]
                    }
                   
                    }).join(', ')}
                  </Text>
                    
                  </Text>}
                  <Text style={styles.description}>
                  {language === "English" ? `${items1Translation?.length > 0 ? items1Translation[index] : ''}`:
 row.service.Description[language.toLowerCase()]}
                  </Text>
                </View>
                <View style={styles.tableItemsItemRight}>
                  <View style={{ ...styles.tableItemsItemCol, ...styles.celCenter }}>
                    <Text> &euro;&nbsp;{formatWithTrailingZero(row.pricePerWord || row.price)}</Text>
                  </View>
                  <View style={{ ...styles.tableItemsItemCol, ...styles.celCenter }}>
                    <Text>{row.totalWords || row.quantity}</Text>
                  </View>
                  <View style={{ ...styles.tableItemsItemCol, ...styles.celCenter }}>
                    <Text>{row.discount ? row.discount + '%' : ''}</Text>
                  </View>
                  <View style={{ ...styles.tableItemsItemCol, ...styles.celCenter }}>
                     &nbsp;
                    <>
                      <Text>
                        {row?.total
                          ? fCurrency(row.total)
                          : fCurrency(
                              Number(row?.totalWords || row?.quantity) * Number(row?.pricePerWord || row?.price) -
                              (Number(row?.pricePerWord || row?.price) * Number(row?.totalWords || row?.quantity) * Number(row.discount)) / 100
                            )}
                      </Text>
                      {row.discount > 0 && row.discount && ( <Text style={styles.del}>
                        {' '}
                         &nbsp;{fCurrency(Number(row?.pricePerWord || row?.price) * Number(row?.totalWords || row?.quantity))}
                      </Text>
                      )}
                    </>{' '}
                  </View>
                </View>
              </View>
            ))}
          </View>
        )}
        {additionalService && (
          <View style={styles.tableItemsBody}>
            {additionalService.map((row, index) => (
              <View style={{...styles.tableItemsBodyItem, backgroundColor: index % 2 === 0 ? '#e6f5ff' : 'transparent',}} key={index}>
                <View style={styles.tableItemsItemLeft}>
                  <Text style={styles.bold}>
                  {row.service.Item[language.toLowerCase()]}
                  </Text>
                  <Text style={styles.description}>
                  {language === "English" ? `${service1Translation?.length > 0 ? service1Translation[index] : ''}` :
                  row.service.Description[language.toLowerCase()]}
                  </Text>
                </View>
                <View style={styles.tableItemsItemRight}>
                  <View style={{ ...styles.tableItemsItemCol, ...styles.celCenter }}>
                    <Text> &nbsp;{fCurrency(row.price)}</Text>
                  </View>
                  <View style={{ ...styles.tableItemsItemCol, ...styles.celCenter }}>
                    <Text>{row.quantity}</Text>
                  </View>
                  <View style={{ ...styles.tableItemsItemCol, ...styles.celCenter }}>
                    <Text>{row.discount ? row.discount + '%' : ''}</Text>
                  </View>
                  <View style={{ ...styles.tableItemsItemCol, ...styles.celCenter }}>
                    <Text> &nbsp;{fCurrency(Number(row.price) * Number(row.quantity))}</Text>
                  </View>
                </View>
              </View>
            ))}
          </View>
        )}
    </>
  );
};

export default TableItems;
