import React from "react";
import {  Grid} from "@mui/material";
import { useSelector } from "react-redux";
import { fCurrency } from "../../../utils/formatNumber";

export default function Table1() {
  const { language, details, packagesItem, option } = useSelector((state) => state.proposal);

  const { accountManager, companyNameAndId, bookedBy, grandTotal, addOns, grandTotalOptions } = details;
  return (
    <div style={{ marginInline: "auto" }}>
      <div
        style={{
          backgroundImage: `url('/translation/quote-header-bg.png')`,
          width: "80%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "auto",
          paddingBottom: "20px",
          textAlign: "center",
          marginInline: "auto",
          backgroundSize: "cover",
          // paddingLeft:"50px",
          // paddingRight:"16px",
          marginTop:"80px"
        }}
      >
        <div style={{ padding: "30px" }}>
          <Grid
            container
            spacing={3}
            style={{
              marginInline: "auto",
              paddingTop: "30px",
              textAlign: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                textAlign: "-webkit-center",
                marginInline: "auto",
                color: "white",
              }}
            >
              <span
                style={{
                  letterSpacing: "1.5px",
                  fontSize: "35px",
                  fontWeight: "600",
                  color: "white",
                  lineHeight: "61px",
                }}
              >
                {language === "English"
                  ? `Customer Billing Data`
                  : `Dati fatturazione cliente`}
              </span>
              <br />
              <span
                style={{
                  fontWeight: "400",
                  fontSize: "23px",
                  fontFamily: "'Poppins'",
                }}
              >
                {language === "English"
                  ? "Reciept of Customer Billing Data"
                  : "Ricezione dei dati di fatturazione del cliente"}
              </span>
            </Grid>
          </Grid>
          <div
            style={{
              width: "80%",
              marginTop: "16px",
              marginInline: "auto",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <p
              style={{
                fontSize: "24px",
                fontWeight: "300",
                color: "white",
                marginTop: "4px",
                marginBottom: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {language === "English" ? "Total Payment" : "Pagamento totale"}
            </p>
            <p
              style={{
                fontSize: "36px",
                fontWeight: "600",
                color: "white",
                marginTop: "4px",
                marginBottom: "4px",
              }}
            >
{addOns?.packages ? fCurrency(packagesItem?.grandTotal)  :
                (grandTotalOptions?.length > 0 && option > 1) ? fCurrency(grandTotalOptions[option-2]):
                fCurrency(grandTotal)
                }            </p>
          </div>
          <div
            style={{
              
              width: "98%",
              marginBottom: "30px",
              marginTop: "-10px",
              marginInline: "auto",
              display: "flex",
              justifyContent: "space-around",
              paddingTop: "10px",
              paddingRight:"10px"
            }}
          >
            <div style={{ margin: "20px" }}>
              <p
                style={{
                  color: "white",
                  padding: "15px",
                  border: "1px solid white",
                  borderRadius: "15px",
                  textAlign: "left",
                  width: "100%",
                  maxWidth: "400px",
                }}
              >
                <span
                  style={{
                    fontSize: "23px",
                    fontWeight: "300",
                    color: "white",
                  }}
                >
                  {language === "English" ? "Billed To" : "Fatturato a"}
                </span>{" "}
                <br />
                <span
                  style={{
                    fontSize: "24px",
                    width: "100%",
                    maxWidth: "400px",
                    fontWeight: "500",
                    color: "white",
                  }}
                >
                  <span style={{ textTransform: "uppercase" }}>
                    {companyNameAndId?.name
                      ? companyNameAndId?.name?.length > 50
                        ? companyNameAndId?.name.slice(0, 50) + "..."
                        : companyNameAndId?.name
                      : ""}
                    
                  </span>{" "}<br/>
                  {companyNameAndId?.address1 && (
                    <span>
                      {companyNameAndId?.address1}
                      <br />
                    </span>
                  )}
                  {companyNameAndId?.address2 && (
                    <span>
                      {companyNameAndId?.address2}
                      <br />
                    </span>
                  )}
                  <span>
                    {companyNameAndId?.postcode} {companyNameAndId?.city}
                  </span>
                </span>
              </p>
              <p
                style={{
                  color: "white",
                  padding: "15px",
                  border: "1px solid white",
                  borderRadius: "15px",
                  textAlign: "left",
                  maxWidth: "400px",
                  width: "100%",
                }}
              >
                <span
                  style={{
                    fontSize: "24px",
                    fontWeight: "300",
                    color: "white",
                  }}
                >
                  {language === "English" ? "Contact:" : "Contatto:"}
                </span>{" "}
                <br />
                <span
                  style={{
                    fontSize: "24px",
                    fontWeight: "300",
                    color: "white",
                  }}
                >
                  {bookedBy?.firstName} {bookedBy?.lastName}
                 
                </span>
              </p>
            </div>
            <div style={{ margin: "20px" }}>
              <p
                style={{
                  color: "white",
                  padding: "15px",
                  border: "1px solid white",
                  borderRadius: "15px",
                  textAlign: "right",
                  width: "100%",
                  maxWidth: "400px",
                }}
              >
                <span
                  style={{
                    fontSize: "23px",
                    fontWeight: "300",
                    color: "white",
                  }}
                >
                  Account Manager:
                </span>{" "}
                <br />
                <span
                  style={{
                    fontSize: "24px",
                    width: "100%",
                    maxWidth: "400px",
                    fontWeight: "500",
                    color: "white",
                  }}
                >
                  {accountManager?.name}
                </span>
              </p>
              <p
                style={{
                  color: "white",
                  padding: "15px",
                  border: "1px solid white",
                  borderRadius: "15px",
                  textAlign: "right",
                  maxWidth: "360px",
                  width: "100%",
                }}
              >
                <span
                  style={{
                    fontSize: "23px",
                    fontWeight: "300",
                    color: "white",
                  }}
                >
                  {language === "English" ? "Contact:" : "Contatto:"}
                </span>{" "}
                <br />
                <span
                  style={{
                    fontSize: "24px",
                    fontWeight: "300",
                    color: "white",
                  }}
                >
                  info@lingoyou.com <br />
                  Telefono: (+39) 0282957101
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
