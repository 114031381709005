import { Image, Link, Page, Text, View } from "@react-pdf/renderer";
import React from "react";
import { styles } from "../globalStyles";

const content = {
  title: "A Solid Group For an All-Inclusive Language Coverage",
  desc1:
    "Global vision, local service. 20,000+ translators for complete language solutions.",
  desc2:
    "Quality professional training. Language courses, certifications, recovery, exam preparation.",
  desc3:
    "Our cutting-edge remote interpreting platform offers innovative communication solutions.",
  desc4:
    "Exceptional interpreting agency: selected experts, advanced technology, full coverage. Chosen by companies in Italy and Europe.",
};

const AboutUs2 = ({ brand, language = "English" }) => {
  return (
      
      <View
        style={{
          marginTop: "30px",
          marginLeft: "20px",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            color: "black",
            fontSize: "15px",
            marginLeft: "-25px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {language === "English" ? "A Solid Group For an All-Inclusive Language Coverage"
            : "Un gruppo solido per una copertura linguistica completa"}
        </Text>
        <View
          style={{
            display: "flex",
            width: "500px",
            marginTop: "70px",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <View>
            <Text
              style={{
                fontSize: "7.5px",
                marginLeft: "30px",
                marginTop:'20px',
                textAlign: "left",
                fontWeight: "semibold",
                color: "white",
                width: "200px",
              }}
            >
              {language === "English"
                ? "Global vision, local service. 20,000+ translators for complete language solutions."
                : "Visione globale, servizio locale. Oltre 20.000 traduttori per soluzioni linguistiche complete."}
            </Text>
            <Link
              style={{
                fontSize: "8px",
                marginLeft: "32px",
                textAlign: "left",
                fontWeight: "normal",
                textDecoration: "underline",
                color: "white",
                width: "200px",
              }}
              href="https://lingoyou.com"
            >
              {language === "English" ? "Learn more" : "Scopri di più"}
            </Link>
          </View>
<View>
    <Text
            style={{
              fontSize: "7.5px",
              marginTop:'20px',
              color: "white",
              textAlign: "right",
              fontWeight: "semibold",
              width: "200px",
              marginRight:"0px"
            }}
          >
            {language === "English"
              ? "Quality professional training. Language courses, certifications, recovery, exam preparation."
              : "Formazione professionale di qualità. Corsi di lingua, certificazioni, recupero, preparazione esami."}
          </Text>
          <Link
              style={{
                fontSize: "8px",
                textAlign: "right",
                fontWeight: "normal",
                textDecoration: "underline",
                width: "200px",
                color: "white",
              }}
              href="https://lingoyou.com/academy"
            >
              {language === "English" ? "Learn more" : "Scopri di più"}
            </Link>
</View>
        
        </View>
        <View
          style={{
            display: "flex",
            width: "510px",
            marginTop: "80px",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <View style={{
              marginLeft: "30px",
              paddingTop:'4px'

          }}>
             <Text
            style={{
              fontSize: "7.5px",
              color: "white",
              textAlign: "left",
              fontWeight: "semibold",
              width: "240px",
            }}
          >
            {language === "English"
              ? "Our cutting-edge remote interpreting platform offers innovative communication solutions."
              : "La nostra piattaforma all'avanguardia per l'interpretazione a distanza offre soluzioni di comunicazione innovative."}
          </Text>
          <Link
              style={{
                fontSize: "8px",
                textAlign: "left",
                fontWeight: "normal",
                textDecoration: "underline",
                width: "220px",
                color: "white",
              }}
              href="https://rafiky.net"
            >
              {language === "English" ? "Learn more" : "Scopri di più"}
            </Link>
          </View>
         
          <View style={{ marginTop: "-9px", paddingLeft: "30px" }}>
            <Text
              style={{
                fontSize: "7.5px",
                color: "white",
                textAlign: "right",
                fontWeight: "semibold",
                width: "200px",
                marginTop:'8px',
                marginRight:"0px"

              }}
            >
              {language === "English"
                ? "Exceptional interpreting agency: selected experts, advanced technology, full coverage. Chosen by companies in Italy and Europe."
                : "Agenzia di interpretariato d'eccezione: esperti selezionati, tecnologia avanzata, copertura completa. Scelti da aziende in Italia e in Europa."}
            </Text>
            <Link
              style={{
                fontSize: "8px",
                textAlign: "right",
                fontWeight: "normal",
                textDecoration: "underline",
                width: "200px",
                color: "white",
              }}
              href="https://congressiinternazionali.it"
            >
              {language === "English" ? "Learn more" : "Scopri di più"}
            </Link>
          </View>
        </View>
        <Image
          src={"/assets/aboutUs2-cover-pdf.png"}
          style={{
            marginTop: "-290px",
            zIndex: "-1111",
            marginLeft: "20px",
            objectFit: "contain",
            width: "500px",
            height: "350px",
          }}
        />
      </View>
  );
};

export default AboutUs2;
