import { Avatar, Box, Checkbox, Container, FormControlLabel, Radio, Typography } from '@mui/material';
import React, {useState, useEffect} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Table, TableRow, TableBody, TableHead, TableCell, TableContainer } from '@mui/material';
import Scrollbar from './Scrollbar';
import styles1 from '../styles/TablesItems.module.css';
import { fCurrency } from '../../utils/formatNumber';
import { useDispatch, useSelector } from 'react-redux';
import Table1 from './Table/Table1';
import Table2Header from './Table/Table2Header';
import Table2Items from './Table/Table2Items';
import Table3 from './Table/Table3';
import Table2ServiceItems from './Table/Table2ServiceItems';

const RowResultStyle = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));
function PackagesTablesItems() {

    const {language, packagesItemsTranslation, service1Translation, packagesItem} = useSelector((state)=>state.proposal);
    const dispatch = useDispatch();
 
   const {item: translationItem, service: additionalService, subtotal, netTotal, totalDiscount, taxes, grandTotal, notes } = packagesItem

  return (
       <div id="quote_info1">
      <Box
        sx={{
          display: 'flow-root',
          height: `auto`,
          position: 'relative',
          paddingBottom: '20px',
          backgroundColor: 'white',
      borderTop:"1px solid #2DA0F0"
        }}
      >
        <div
    style={{
      textAlign: "right",
      width: "97%",
      padding:"2px",
      marginInline:"auto",
      display:"flex",
      marginBottom: "50px",
      justifyContent: "space-between",
      borderBottom: "2px solid #AA32E1",
    }}
  >
     <p>
           <span  style={{fontWeight:"500", color: "white", fontSize: "20px"}}
           >
     {language === "English" ? "Quote Info":
            "Preventivo"}
    </span>
    </p>
    <img
      src={"/translation/logos/lingoyou.png"}
      style={{ marginRight: "20px", objectFit:"contain" }}
      alt="logo"
    />
  </div>
        <div className={styles1.tables}>
        <Table1/>

          <div
            className={styles1.table2}
            style={{
              marginBottom: '4em',
              padding: '0px',
              marginLeft: '0px',
              marginInline: 'auto',
               borderRadius: '15px',
              marginTop: '3em',
              paddingBottom: '10px',
            }}
          >
            {/* <Card sx={{ display: 'contents' }}> */}
            <Scrollbar>
              <TableContainer sx={{
                  borderTopLeftRadius: "10px !important",
                  borderTopRightRadius: "10px",
                  marginLeft: "2%",
                  width: "97%",
                }}>
                <Table sx={{ width: '100%', borderCollapse: 'inherit' }}>
                <Table2Header/>
                 <TableBody>
                    {translationItem &&
                      translationItem.map((row, index) => (
                      <Table2Items row={row} index={index} itemTranslation={packagesItemsTranslation?.length > 0 ? packagesItemsTranslation[index] : ''}/>
                      ))}
                    {additionalService &&
                      additionalService.map((row, index) => (
                        <Table2ServiceItems row={row} index={index} itemTranslation={service1Translation?.length > 0 ? service1Translation[index] : ''}/>

                      ))}

         
<RowResultStyle sx={{ borderBottom: 'none' }}>
                      <TableCell sx={{ borderBottom: 'none' }} align="left">
                        <Box sx={{ mt: 2 }} />
                        <Typography
                          sx={{
                            color: "black",
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: 'large',
                            fontWeight: '700',
                          }}
                        >
                          {language === 'English' ? 'Subtotal' : 'Subtotale'}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }} />
                      <TableCell sx={{ borderBottom: 'none' }} />

                      <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                        <Typography sx={{ color: "black", fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}>
                           &nbsp;{fCurrency(Number(subtotal))}
                        </Typography>
                      </TableCell>
                    </RowResultStyle>
                    {totalDiscount ? (
                      <RowResultStyle>
                        <TableCell sx={{ borderBottom: 'none' }} align="left">
                          <Typography
                            sx={{
                                color: "black",
                              fontFamily: "'Poppins', sans-serif",
                              fontSize: 'large',
                              fontWeight: '700',
                            }}
                          >
                            {language === 'English' ? 'Discount' : 'Sconto'} ({totalDiscount ? totalDiscount : 0}%)
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 'none' }} />
                        <TableCell sx={{ borderBottom: 'none' }} />
                        <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                          <Typography
                            sx={{
                                color: "black",
                              fontFamily: "'Poppins', sans-serif",
                              fontSize: 'large',
                              fontWeight: '700',
                            }}
                          >
                             &nbsp;{fCurrency(Number((Number(subtotal) * Number(totalDiscount)) / 100))}
                          </Typography>
                        </TableCell>
                      </RowResultStyle>
                    ) : (
                      ''
                    )}
                    <RowResultStyle>
                      <TableCell sx={{ borderBottom: 'none' }} align="left">
                        <Typography
                          sx={{
                            color: "black",
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: 'large',
                            fontWeight: '700',
                          }}
                        >
                          {language === 'English' ? 'Net Total' : 'Totale netto'}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }} />
                      <TableCell sx={{ borderBottom: 'none' }} />
                      <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                        <Typography sx={{ color: "black", fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}>
                           &nbsp;{fCurrency(netTotal)}
                        </Typography>
                      </TableCell>
                    </RowResultStyle>
                    <RowResultStyle>
                      <TableCell sx={{ borderBottom: 'none' }} align="left">
                        <Typography
                          sx={{
                            color: "black",
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: 'large',
                            fontWeight: '700',
                          }}
                        >
                          IVA ({taxes}%)
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }} />
                      <TableCell sx={{ borderBottom: 'none' }} />

                      <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                        <Typography sx={{ color: "black", fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}>
                           &nbsp;{fCurrency(Number((Number(netTotal) * Number(taxes)) / 100))}
                        </Typography>
                      </TableCell>
                    </RowResultStyle>

                    <RowResultStyle sx={{  backgroundColor: "#e6f5ff",
                        borderRadius: "10px",}}>
                      <TableCell sx={{ borderBottom: 'none' }} align="left">
                        <Typography
                          sx={{
                            color: "black",
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: 'large',
                            fontWeight: '700',
                          }}
                        >
                          {language === 'English' ? 'Total Gross' : 'Totale Lordo'}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }} />
                      <TableCell sx={{ borderBottom: 'none' }} />
                      {grandTotal ? (
                        <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                          <Box sx={{ mt: 2 }} />
                          <Typography
                            sx={{
                                color: "black",
                              fontFamily: "'Poppins', sans-serif",
                              fontSize: 'large',
                              fontWeight: '700',
                            }}
                          >
                             &nbsp;{fCurrency(Number(grandTotal))}
                          </Typography>
                        </TableCell>
                      ) : (
                        <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                          <Typography
                            sx={{
                              color: "black",
                              fontFamily: "'Poppins', sans-serif",
                              fontSize: 'large',
                              fontWeight: '700',
                            }}
                          >
                             &nbsp;{fCurrency((Number(subtotal) + (Number(subtotal) * Number(taxes)) / 100))}
                          </Typography>
                        </TableCell>
                      )}
                    </RowResultStyle>
                    <RowResultStyle sx={{ borderBottom: 'none' }}>
                      <TableCell
                        colSpan={5}
                        sx={{
                          borderBottom: 'none',
                          borderTop: '1.29922px solid #2DA0F0',
                          fontFamily: "'Poppins', sans-serif",
                          fontSize: 'large',
                        }}
                        align="left"
                      >
                        {notes && notes !== '' && notes !== '<p><br></p>' && (
                          <>
                            <Box sx={{ mt: 2 }} />
                            <Typography
                              sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                            >
                              {language === 'English' ? "Notes" : "Note"}
                            </Typography>
                            <Typography variant="body2" sx={{ padding: "10px",
                                borderRadius: "10px",
                                backgroundColor: "#F0F0F0",
                                color: "text.secondary",
                                fontSize: "15px", }}>
                            <div dangerouslySetInnerHTML={{ __html: notes }}></div>
                          </Typography>
                          </>
                        )}
                      </TableCell>
                    </RowResultStyle>
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            {/* </Card> */}
          </div>
        <Table3/>
        </div>
      </Box>
    </div>
  );
}

export default PackagesTablesItems;
