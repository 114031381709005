import { StyleSheet, Font } from "@react-pdf/renderer";
import PoppinsRegular from '../../pdf/fonts/Poppins-Medium.ttf';
import PoppinsLight from '../../pdf/fonts/Poppins-Light.ttf';
import PoppinsBold from '../../pdf/fonts/Poppins-Bold.ttf'
import DancingScriptBold from '../../pdf/fonts/DancingScript-Bold.ttf';

 


Font.register({
  family: "Poppins 'sans-serif'", fonts: [
    { src: PoppinsRegular, fontStyle: 'normal', fontWeight: "normal" },
    { src: PoppinsRegular, fontStyle: 'italic', fontWeight: "normal" },
    { src: PoppinsLight, fontStyle: 'normal', fontWeight: "light" },
    { src: PoppinsBold, fontStyle: 'normal', fontWeight: "bold" },
  ]
})
Font.register({
  family:"'Dancing Script', cursive",
  fontStyle:"bold",
  src:DancingScriptBold
})

export const styles = StyleSheet.create({
  page: {
    fontFamily: "Poppins 'sans-serif'"
  },
  fontStyles:{
    fontSize:"10px",
    fontWeight:"normal",
    color:"white"
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "30px",
    borderBottom: "0.5px solid #a158d6",
    marginBottom: "50px"
  },
  headerItem: {
    flex: 1,
  },
  headerItemText: {
    color: "#2DA0F0",
    textAlign: "left",
    fontSize: "14px",
  },
  headerRight: {
    flex: 1,
    display: "flex",
  },
  headerRightImg: {
    width: "90px",
    margin:"5px",
    objectFit:"contain"
  },
  testimonialCards:{
    display:"inline-flex",
    flexDirection:"row",
    marginRight:"10px",
    marginTop:"20px"
  },
  testimonialCard:{
    width:"200px",
    height:"130px",
    marginLeft:"10px",
    backgroundColor:"white",
    borderRadius:"12.12px",
    padding:"5px",
    border:'1px solid grey'
  },
  testimonialImage: {
    marginTop:"15px", display:"inline-flex", flexDirection:"row",
    marginRight:"10px",
    marginLeft:"40px",
    marginBottom:"20px"
  },
  tableCustomer: {
    width: "75%",
    marginHorizontal: "auto",
    marginBottom: "50px",
  },
  tableCustomerHeader: {
    backgroundColor: "#2fcbe9",
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    paddingHorizontal: "10px",
    paddingVertical: "16px",
    borderRadius:"4px"
  },
  tableCustomerItem: {
    flex: 1,
    alignItems: "flex-start",
  },
  tableCustomerText: {
    fontSize: "10px",
  },
  tableCustomerBody: {
    backgroundColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    flexDirection: "row",
    padding: "10px",
    fontSize: "11px",
  },
  bold: {
    fontWeight: "bold",
  },
  light: {
    fontWeight: "light",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  del: {
    textDecoration: "line-through"
  },
  celCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  description: {
    fontSize: "8px",
  },
  tableItems: {
    borderBottom: "1px solid #2DA0F0",
    marginBottom: '20px',
  },
  tableItemsHeader: {
    backgroundColor: '#a158d6',
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "10px",
    paddingHorizontal: "10px",
    paddingVertical: "16px",
    fontSize: "10px",
    borderRadius:"4px"
  },
  tableItemsItemLeft: {
    flex: 1,
    alignItems: 'flex-start',
  },
  tableItemsItemRight: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  tableItemsItemCol: {
    width: "60px",
    textAlign: "center",
  },
  tableItemsBody: {

  },
  tableItemsBodyItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    gap: "10px",
    fontSize: "10px",
    color: "rgba(0,0,0,.87)",
  },
  notes: {
    fontSize: "10px",
    paddingHorizontal: "10px",
  },
  notesNote: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop:"5px"
  },
  preventivo: {
    width: "75%",
    marginHorizontal: "auto",
    marginTop: "50px",
    marginBottom: "50px",
  },
  preventivoHeader: {
    backgroundColor: "#2fcbe9",
    color: "#FFFFFF",
    paddingHorizontal: "10px",
    paddingVertical: "16px",
    textAlign: "left",
    fontSize: "10px",
    borderRadius:"4px"
  },
  preventivoBody: {
    padding: "15px",
  },
  preventivoBodyText: {
    fontSize: "10px",
  },
  newTermsHeadings:{
    fontSize:"7.5px", 
    fontWeight:"bold", fontStyle:"normal",
    fontFamily: "Poppins 'sans-serif'"
  },
  termsBodyRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: "7px",
    borderBottom: "1px solid #A158D6",
  },
  termsBodyItem: {
    textAlign: "left",
    paddingHorizontal:"10px",
  },
  termsBodyItemText: {
    color: "rgba(0,0,0,.87)",
    fontSize: "8px",
  },
  termsFooter: {
   backgroundColor:"#39065F",
   padding: "10px",
   paddingBottom:"20px",
   color: "#FFFFFF",
   flex: 1
  },
  termsFooterTitle: {
    fontSize: "16px",
    marginBottom: "10px",
    marginTop:"-5px"
  },
  termsFooterBody: {
    display: "flex",
    flexDirection: "row",
    alignItems:"center",
    gap: "130px",
    fontSize: "10px",
  },
  termsFooterBodyItemText: {
    
  },
  termsFooterBodyItemBtn: {
    // backgroundColor: "#00FFFF",
    paddingVertical:"6px",
    // width: "64px",
    // textAlign:"center",
    // textTransform: "uppercase",
    // borderRadius: "4px",
    textAlign: 'center',
    fontFamily: "'Dancing Script', cursive",
    fontStyle: 'bold',
    fontWeight: '700',
    fontSize:"13px",
  },
  contactUs1: {
    width: '300px',
    height: 'auto',
    objectFit: 'contain',
  },
  contactUsImage: {
    marginTop: '-450px',
    height: 'auto',
    objectFit: 'contain',
  },
  contactUsTextSection: {
    display: 'inline-flex',
    flexDirection: 'row',
  },
  contactUsText: {
    marginTop: '-250px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  contactUsList: {
    color: 'white',
    fontSize: '10px',
    marginBottom: '10px',
  },
  whyUsIcons: {
    display: 'inline-flex',
    flexDirection: 'row',
    marginTop: '20px',
    marginBottom: '10px',
    marginLeft: '20px',
  },
  whyUsIcon: {
    marginLeft: '25px',
    width: '25px',
    height: '25px',
    marginBottom: '5px',
  },
  whyUsIconBottom: {
    marginLeft: '70px',
    width: '30px',
    height: '30px',
  },
  whyUsIconsText: {
    display: 'inline-flex',
    flexDirection: 'row',
    marginLeft: '20px',
  },
  whyUsIconText: {
    width: '90px',
    fontFamily: "Poppins 'sans-serif'",
    fontStyle: 'normal',
    fontWeight: 'normal',
    textAlign: 'center',
    fontSize: '8px',
    marginBottom: '5px',
  },
  preventivo: {
    width: '75%',
    marginHorizontal: 'auto',
    marginTop: '50px',
    marginBottom: '50px',
  },
  preventivoHeader: {
    backgroundColor: '#AA32E1',
    color: '#FFFFFF',
    paddingHorizontal: '10px',
    paddingVertical: '16px',
    textAlign: 'left',
    fontSize: '10px',
    borderRadius: '4px',
  },
  preventivoHeaderUrgency: {
    backgroundColor: '#ce4545',
    color: '#FFFFFF',
    paddingHorizontal: '10px',
    paddingVertical: '16px',
    textAlign: 'left',
    fontSize: '9px',
    borderRadius: '4px',
  },
  preventivoBody: {
    padding: '15px',
  },
  preventivoBodyText: {
    fontSize: '10px',
  },
  academyCards:{
    marginTop:"20px",
    display:"inline-flex",
    flexDirection:"row",
    marginBottom:"20px",
  },
  academyCard:{
     backgroundColor:"#2DA0F0",
     width:"250px",
     marginLeft:"10px",
     textAlign:"center",
     borderRadius:"5px",
     padding:"4px"

  },
  techBody:{
    marginLeft: "15px",
    marginTop: "20px",
    textAlign: "justify",
    color: "#161F30",
    width: "95%",
  },
  techParaBody:{
    fontFamily: "Poppins 'sans-serif'",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "8px",
    marginBottom:"5px"
  },
  techFooter:{
    backgroundColor:"#aa32e1",
    padding: "5px",
    color: "#FFFFFF",
    flex: 1,
    alignItems:"center",
    marginInline:"auto",
    placeContent:"center"
  },
  techFooterImage:{
    width:"100%",
    alignItems:"center",
    marginInline:"auto",
    placeContent:"center"
  },
  dividerText:{
    height: "41px",
    width:"100%",
    backgroundColor: "#666667",
    padding: "10px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#FFFFFF",
    fontFamily: "Poppins 'sans-serif'",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
  },
});
