import React from "react";
import { Avatar, Button, Card, Grid, Link } from "@mui/material";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import Slider from "react-slick";
import { useSelector } from "react-redux";

const content = {
  English: {
    subTitle: "At LingoYou Academy, we believe that the best way to learn a language is by practicing it. For this reason, in addition to using top learning platforms, the LingoYou Academy team has decided to integrate extra language activities, creating a motivating environment and a welcoming atmosphere for every student. Our packages include dedicated events where you will speak only in the language you are learning through various activities, from aperitifs to sports.",
    title: "EXTRA LANGUAGE ACTIVITIES",
    sections: [
        {
            title: "Summer Activities",
            para1: "We take advantage of the summer season to learn a language through nature, new sports, and group activities:",
            items: [
                "Free group SUP sessions",
                "Free SUP aperitif",
                "Free boat tour"
            ],
            img: "activity1.png"
        },
        {
          title:"Recreational Activities",
          para1:"At LingoYou Academy you can enjoy an aperitif or eat a pizza with your teacher and your classmates, using the foreign language you are learning to order and converse.",
            img: "activity2.png"
        }
    ]
},
  Italian: {
    subTitle: "Da LingoYou Academy crediamo che il miglior modo per imparare una lingua sia metterla in pratica. Per questo motivo, oltre all’impiego delle migliori piattaforme di apprendimento, il team di LingoYou Academy ha deciso di integrare delle attività linguistiche extra, creando così un ambiente motivante e un clima accogliente per ogni studente. All’interno dei pacchetti, infatti, prevediamo eventi dedicati in cui parlerai soltanto nella lingua che stai imparando attraverso attività di ogni tipo, dall’aperitivo allo sport.",
    title: "ATTIVITÀ LINGUISTICHE EXTRA",
    sections:[
        {
            title:"Attività estive",
            para1:"Approfittiamo del periodo estivo per imparare una lingua, attraverso il contatto con la natura, nuovi sport e attività di gruppo:",
            items:[
                "Sessioni di gruppo gratuite di SUP",
                "Aperitivo in SUP gratuito",
                "Tour in barca gratuito"
            ],
            img:"activity1.png"
        },
        {
            title:"Attività ricreative",
            para1:"Da LingoYou Academy potrai gustare un aperitivo o mangiare una pizza con il tuo insegnante e i tuoi compagni di corso, utilizzando la lingua straniera che stai imparando per ordinare e conversare.",
            
            img:"activity2.png"
        },
    ]
  },
};

export default function LangsActivities() {
  const { language } = useSelector((state) => state.proposal);

  return (
    <div style={{borderTop:"1px solid #2DA0F0"}}>
       
      <div
        style={{
            backgroundImage: "linear-gradient(to right top, #A158D6 20%, #43BEF3 80%) ",
            marginTop:"5px",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "auto",
          paddingBottom: "20px",
          backgroundSize: "cover",
          textAlign: "center",
          opacity: 0.9,

        }}
      >
        <div style={{
                    width: "100%",
        }} />
        
        <Grid
          container
          spacing={1}
          style={{ marginInline: "auto", paddingTop:"20px", textAlign: "center" }}
        >
          <Grid
            item
            xs={12}
            sx={{ textAlign: "-webkit-center", marginInline: "auto" }}
          >
            
            <p
              style={{
                marginTop: "10px",
                fontWeight: "600",
                fontSize: "36px",
                fontFamily: "'Poppins'",
                color:"white"
              }}
            >
              {content[language]?.title}
            </p>
            <p
              style={{
                fontSize: "17px",
                color:"white",
                width:"90%",
                letterSpacing:"1.1px"
              }}
            >
              {content[language]?.subTitle}
            </p>
          </Grid>
          <Grid item xs={12} sx={{mt:2}} >
                <Grid container sx={{width:"92%", marginInline:"auto"}} spacing={3}>
                    <Grid item xs={7} sx={{textAlign:"left", marginInline:"auto", placeContent:"center", justifyContent:"center", alignItems:"center"}}>
                    
                       <Button sx={{color:"white", backgroundColor:"#2DA0F0", fontSize:"17px", py:1, px:3}}>
                       {content[language]?.sections[0]?.title}
                        </Button> 
                        <p style={{fontSize:"19px", color:"white"}}>
                            {content[language]?.sections[0]?.para1}
                        </p>
                        <ul style={{fontSize:"19px", color:"white"}}>
                            {content[language]?.sections[0]?.items?.map((item)=><li>{item}</li>)}
                        </ul>
                    </Grid>
                    <Grid item xs={5}>
                        <img src={`/lingoAcademy/${content[language]?.sections[0]?.img}`} style={{objectFit:"contain", width:'100%'}}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{mt:2}} >
                <Grid container sx={{width:"92%", marginInline:"auto"}} spacing={3}>
                <Grid item xs={5}>
                        <img src={`/lingoAcademy/${content[language]?.sections[1]?.img}`} 
                        style={{objectFit:"contain", transform:"scaleX(-1)", width:'100%'}}/>
                    </Grid>
                    <Grid item xs={7} sx={{textAlign:"left", marginInline:"auto", placeContent:"center", justifyContent:"center", alignItems:"center"}}>
                    
                       <Button sx={{color:"white", backgroundColor:"#2DA0F0", fontSize:"17px", py:1, px:3}}>
                       {content[language]?.sections[1]?.title}
                        </Button> 
                        <p style={{fontSize:"19px", color:"white"}}>
                            {content[language]?.sections[1]?.para1}
                        </p>
                       
                    </Grid>
                    
                </Grid>
            </Grid>
        </Grid>
      </div>
    </div>
  );
}
