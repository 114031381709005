import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { SnackbarProvider } from "./components/snackbar";
import InterpretClientOutput from "./pages/InterpretClientProposal";
import BrochureClientOutput from "./pages/Brochures";
import TranslationClientOutput from "./pages/TranslationProposal";

const client = new ApolloClient({
  uri: "https://lingoserver.herokuapp.com/graphql",
  cache: new InMemoryCache({addTypename:false}),
  addTypeName:false
});
const router = createBrowserRouter([
  {
    path: "/",
    element: <div>Hello world!</div>,
  },
  {
    path: "/interpretation/:lang/:id",
    element: <InterpretClientOutput />,
  },
  {
    path: "/brochure/:lang/:id",
    element: <BrochureClientOutput />,
  },
  {
    path: "/translation/:lang/:id",
    element: <TranslationClientOutput />,
  },
]);
ReactDOM.render(
  <ApolloProvider client={client}>
  
    <SnackbarProvider>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>{" "}
    </SnackbarProvider>
  </ApolloProvider>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
