export const services = [
  {
    "price": 100,
    "img": "",
    "cardTitle": {
      "italian": "SISTEMA DI AMPLIFICAZIONE PER EVENTO IN LOCO",
      "english": "AMPLIFICATION SYSTEM FOR ON-SITE EVENT"
    },
    "service": {
      "Code": "CI-1015",
      "InvCode": "CI-RENT",
      "Description": {
        "italian": "Il nostro impianto di amplificazione è composto da:                                                                                                                                                                                                                                                                                                                                                                                                                     - Mixer Yamaha 12 canali;\n- 2 x Microfoni gelato Shure Cablati; \n- 3 x Microfoni gelato e/o Lavalier Wireless Sennheiser; \n- 2 x Altoparlanti Bose L1 Compact Speakers.",
        "english": "Our amplification system consists of: \n - Yamaha Mixer - MG12;- 1 Wired Shure PG58 Microphone; \n- 1 Sennheiser XSW 1-835 Wireless Microphone;\n- 2 Bose L1 Compact Speakers."
      },
      "Service": {
        "italian": "Impianto di amplificazione Professionale",
        "english": "Professional sound system"
      }
    }
  },
  {
    "price": 100,
    "img": "",
    "cardTitle": {
      "italian": "NOLEGGIO ATTREZZATURE PER SERVIZIO DI INTERPRETARIATO (SISTEMA BIDULE)",
      "english": "EQUIPMENT RENTAL FOR INTERPRETING SERVICE (BIDULE SYSTEM)"
    },
    "service": {
      "Code": "CI-1010",
      "InvCode": "CI-RENT",
      "Description": {
        "italian": "Ricevitori portatili Modello WT300 e/o EJ7 e/o ATS2020 + headset",
        "english": "Portable Receivers Model WT300 and/or EJ7 and/or ATS2020 + headset"
      },
      "Service": {
        "italian": "Ricevitori/Radio Sistema Bidule",
        "english": "Receivers/Radio Bidule System"
      }
    }
  },
  {
    "price": 100,
    "img": "",
    "cardTitle": {
      "italian": "REGIA AUDIO-VIDEO PER IL TUO EVENTO",
      "english": "AUDIO-VIDEO DIRECTION FOR YOUR EVENT"
    },
    "service": {
      "Code": "CI-1026",
      "InvCode": "CI-RAF",
      "Description": {
        "italian": "Realizzazione della regia live dell’evento con Blackmagic ATEM mini;\nRegia animata e interattiva dell’evento con n.2 camere;\nBlackmagic 6k;\nSoftware regia Blackmagic ATEM mini;\nSoftware live streaming OBI Studio.",
        "english": "Realization of the live direction of the event with Blackmagic ATEM mini;\nAnimated and interactive direction of the event with 2 cameras;\nBlackmagic 6k;\nBlackmagic ATEM mini direction software;\nOBI Studio live streaming software."
      },
      "Service": {
        "italian": "Regia Video Professionale ",
        "english": "Professional Video Direction"
      }
    }
  },
  {
    "price": 100,
    "img": "",
    "cardTitle": {
      "italian": "RAFIKY: PORTA IL TUO EVENTO IN SEDE A UN PUBBLICO GLOBALE",
      "english": "RAFIKY: TAKE YOUR ON-SITE EVENT TO A GLOBAL AUDIENCE"
    },
    "service": {
      "Code": "CI-1033",
      "InvCode": "CI-RAF",
      "Description": {
        "italian": "Creazione Sito dedicato ai Webinar – Dominio & Hosting (Durata 3 mesi);\nBranding completo del sito- Tema / Logo / Sponsor / Relatori  / Agenda / Pagamento Online / Graphica fino a 10 Immagini personalizzate;\nPagina di iscrizione con logo cliente;\nSocial Media sponsor nella pagina di iscrizione;Aggiunta di costi di iscrizione tramite PayPal;\n Video aziendale/dedicato nella pagina di iscrizione;\nEmail personalizzata con logo cliente – Invito ai partecipanti con login details;\n Email personalizzata con logo cliente – Reminder pre-evento (3 invii) & e-mail di ringraziamento post-evento con richiesta feedback (2 invii);\nGestione Iscrizioni su piattaforma con invio codici personalizzati;\n Gestione Iscrizioni su piattaforma con invio di comunicati stampa;\nStreaming della conferenza su Facebook e YouTube;\n Piattaforma collegata al vostro dominio web.",
        "english": "Website creation dedicated to Webinars - Domain & Hosting (Duration 3 months);\nComplete site branding - Theme / Logo / Sponsors / Speakers / Agenda / Online Payment / Graphics of up to 10 customized images;\nRegistration page with customer logo;\nSocial Media sponsor on the registration page;Adding registration costs via PayPal;\n Corporate/dedicated video on the registration page;\nCustomized email with customer logo - Invitation to participants with login details;\nCustomized email with customer logo - Reminder pre-event (3 sends) & post-event thank you e-mail with feedback request (2 sends);\nRegistration management on the platform with sending personalized codes;\nRegistration management on the platform with sending of press releases;\nStreaming of the conference on Facebook and YouTube;\n Platform connected to your web domain."
      },
      "Service": {
        "italian": "Pacchetto Marketing Rafiky ",
        "english": "Rafiky Marketing Package"
      }
    }
  },
  {
    "price": 100,
    "img": "",
    "cardTitle": {
      "italian": "SERVIZIO DI STREAMING PER EVENTI IN SEDE",
      "english": "STREAMING SERVICE FOR ON-SITE EVENT"
    },
    "service": {
      "Code": "CI-1030",
      "InvCode": "CI-ASSIST",
      "Description": {
        "italian": "Servizio di streaming su piattaforme esterne.",
        "english": "Streaming service on external platforms."
      },
      "Service": {
        "italian": "Servizio di Streaming",
        "english": "Streaming Service"
      }
    }
  },
  {
    "price": 100,
    "img": "",
    "cardTitle": {
      "italian": "ACCOGLIENZA D'ECCELLENZA: HOSTESS E STEWARD PER EVENTI INDIMENTICABILI",
      "english": "WELCOMING EXCELLENCE: HOSTESSES AND STEWARDS FOR UNFORGETTABLE EVENTS"
    },
    "service": {
      "Code": "CI-1008",
      "InvCode": "CI-INT",
      "Description": {
        "italian": "Personale multilingua qualificato (hostess interprete) per le attività di accoglienza presso mostre di settore, workshop, congressi e seminari. Nel vostro stand, oltre al sorriso e la simpatia, la presenza di figure professionali preparate permette di:\n- ricevere adeguatamente gli ospiti\n- fornire informazioni dettagliate\n- comprendere le esigenze dei partecipanti\n- gestire senza difficoltà la comunicazione nelle diverse lingue",
        "english": "Ideal service for national and international fairs, but also for support during congresses, conventions, meetings or conferences. In events for which our interpreting service is provided, hostesses and stewards will distribute the equipment (receivers/headphones) to the audience and then collect it at the end of the event."
      },
      "Service": {
        "italian": "Servizio Hostess & Steward Multilingua",
        "english": "Multilingual Hostess & Steward Service"
      }
    }
  },
  {
    "price": 100,
    "img": "",
    "cardTitle": {
      "italian": "APP CONGRESSI INTERNAZIONALI: UN'APP PER GESTIRE IL TUO EVENTO",
      "english": "CONGRESSI INTERNAZIONALI APP: AN APP TO MANAGE YOUR EVENT"
    },
    "service": {
      "Code": "CI-1020",
      "InvCode": "CI-ASSIST",
      "Description": {
        "italian": "Un nostro operatore sarà dedicato al supporto tecnico sui canali di interpretariato forniti tramite APP Live / Browser + Supporto tecnico dedicato agli interpreti.",
        "english": "One of our operators will be dedicated to technical support on the interpreting channels provided via APP Live / Browser + Technical support dedicated to interpreters."
      },
      "Service": {
        "italian": "Supporto Tecnico - App Rafiky",
        "english": "Technical Support - Rafiky App"
      }
    }
  },
  {
    "price": 100,
    "img": "",
    "cardTitle": {
      "italian": "CONDIVIDI LA TUA STORIA: CREAZIONE DI CONTENUTI PER I SOCIAL MEDIA PER IL TUO EVENTO",
      "english": "SHARE YOUR STORY: SOCIAL MEDIA CONTENT CREATION FOR YOUR EVENT"
    },
    "service": {
      "Code": "CI-1039",
      "InvCode": "CI-VIDEO",
      "Description": {
        "italian": "Servizio di gestione contenuti per eventi. Il servizio include: - Allineamento con il team marketing del cliente - Briefing creativo e script operativo - Shoting per la creazione dei contenuti durante la giornata dell'evento - Produzione di 8 short videos di contenuti dedicati - Condivisione del materiale entro 10 giorni lavorativi dalla fine dell'evento.",
        "english": "Content management service for events. The service includes: - Alignment with the client's marketing team - Creative briefing and operational script - Shots for content creation during the day of the event - Production of 8 short videos of dedicated content - Sharing of material within 10 working days of the end of the event."
      },
      "Service": {
        "italian": "User Generated Contents",
        "english": "User Generated Contents"
      }
    }
  }
]

