import { Image, Link, Page, Text, View } from "@react-pdf/renderer";
import React from "react";
import { styles } from '../globalStyles';

const usecases = [
    {
        img:'/rafiky/brochure/case1.jpg',
        it:{
            heading:'KAHOOT',
           para:'Uno strumento semplice e intuitivo. Tra le varie funzioni a disposizione, Kahoot! consente di creare dei veri e propri Quiz, domande a risposta multipla, domande “vero o falso”, ma anche di creare puzzle,domande a risposta aperta e tanto altro ancora.'
        },
        en:{
            heading:'KAHOOT',
            para:'A simple and intuitive tool. Among the various functions available, Kahoot! allows you to create real Quizzes, multiple choice questions, "true or false" questions, but also to create puzzles, open-ended questions and much more.'        }
    },
    {
        img:'/rafiky/brochure/case2.jpeg',
        it:{
            heading:'HELLOTALK',
            para:'Il modo migliore per imparare una lingua è parlarla davvero! HelloTalk vi mette in contatto con persone madrelingua; potrete chattare con singoli membri o partecipare a chat di gruppo per un’esperienza di apprendimento collaborativo.'
        },
        en:{
            heading:'HELLOTALK',
           para:'The best way to learn a language is to actually speak it! HelloTalk connects you with native speakers; you can chat with individual members or join group chats for a collaborative learning experience.'
        }
    },
    {
        img:'/rafiky/brochure/case3.jpg',
        it:{
            heading:'BRITISH COUNCIL ENGLISH SCORE',
            para:'Un test delle quattro abilità somministrato su un telefono cellulare. Utilizziamo la più recente tecnologia AI per migliorare la sicurezza e la validità del test. Il test può essere effettuato in qualsiasi momento e in qualsiasi luogo e fornisce un risultato entro 24 ore.'
        },
        en:{
            heading:'BRITISH COUNCIL ENGLISH SCORE',
            para:'A test of the four skills administered on a mobile phone. We use the latest AI technology to improve the safety and validity of the test. The test can be taken anytime, anywhere and provides a result within 24 hours.'
        }
    },
    {
        img:'/rafiky/brochure/case4.jpeg',
        it:{
            heading:'OXFORD ENGLISH ASSESSMENT',
            para:' La soluzione migliore per ottenere una valutazione della lingua inglese affidabile, flessibile e conveniente per consentire agli studenti di tutto il mondo di raggiungere i loro obiettivi di vita. Il test, effettuato prima dell’inizio del corso, è completamente gratuito.'
        },
        en:{
          heading:'OXFORD ENGLISH ASSESSMENT',
         para:' The best solution for reliable, flexible and affordable English language assessment to help students around the world achieve their life goals. The test, taken before the start of the course, is completely free.'
        }
    },
    {
      img:'/rafiky/brochure/case4.jpeg',
      it:{
          heading:'CAMBRIDGE ONE',
          para:'Soluzione per l’insegnamento e la valutazione attraversoun’ampia gamma di attività, risorse e strumenti. L’approccio di Cambridge One soddisfa le esigenze reali di chi vuole imparare l’inglese, combinando ascolto, collaborazione e partnership.'
      },
      en:{
        heading:'CAMBRIDGE ONE',
para:"Teaching and assessment solution through a wide range of activities, resources and tools. Cambridge One's approach meets the real needs of those who want to learn English, combining listening, collaboration and partnership."     
}
  }
]
const LearningApps = ({ brand, language = "English" }) => {
  return (
    <Page wrap={false} size="A4" style={{ ...styles.page }}>
      <View
        style={{
          ...styles.header,
          marginBottom: 0,
          width: "100%",
          marginHorizontal: "auto",
        }}
      >
        <View style={{ marginLeft: "460px" }}>
          <Image style={styles.headerRightImg} src="/lingoAcademy/logos/academy.png" />
 
        </View>
        <View style={styles.headerItem} />
      </View>
      <Text
          style={{
            color: "black",
            fontSize: "15px",
            marginLeft: "-25px",
            fontWeight: "bold",
            marginTop:"30px",
            textAlign: "center",
          }}
        >
          {language === "English" ? "LEARNING APPS USED BY LINGOYOU ACADEMY":
            "APP PER L’APPRENDIMENTO UTILIZZATE DA LINGOYOU ACADEMY"}
        </Text>
    <View style={{display:"flex", marginTop:"20px", marginLeft:"60px", flexDirection:"row"}}>
        <View style={{padding:"5px", paddingTop:"15px", backgroundColor:"#A158D6", width:"210px", height:"120px"}}>
        <Text style={{ fontSize: '9px', textAlign:'center', marginTop:"5px", color:"white",  fontWeight: 'bold' }}>
                {usecases[0].en?.heading}
              </Text>
        {language === 'English'
              ? <Text style={{ fontSize: '7.5px', textAlign:'center', marginTop:"3px", color:"white", fontWeight: 'normal' }}>
                {usecases[0].en?.para}
              </Text>

              : <Text style={{ fontSize: '7.5px', textAlign:'center', marginTop:"3px", color:"white", fontWeight: 'normal' }}>
                {usecases[0].it?.para}
              </Text>
        }
        </View>
        <View style={{padding:"5px", paddingTop:"15px", backgroundColor:"#2DA0F0", width:"210px", height:"120px"}}>
        <Text style={{ fontSize: '9px', textAlign:'center', marginTop:"5px", color:"white",  fontWeight: 'bold' }}>
                {usecases[1].en?.heading}
              </Text>
        {language === 'English'
              ? <Text style={{ fontSize: '7.5px', textAlign:'center', marginTop:"3px", color:"white", fontWeight: 'normal' }}>
                {usecases[1].en?.para}
              </Text>

              : <Text style={{ fontSize: '7.5px', textAlign:'center', marginTop:"3px", color:"white", fontWeight: 'normal' }}>
                {usecases[1].it?.para}
              </Text>
        }
        </View>
    </View>
    <View style={{display:"flex",  marginLeft:"160px", flexDirection:"row"}}>
        <View style={{padding:"5px", paddingTop:"15px", backgroundColor:"#2DA0F0", width:"210px", height:"120px"}}>
        <Text style={{ fontSize: '9px', textAlign:'center', marginTop:"5px", color:"white",  fontWeight: 'bold' }}>
                {usecases[2].en?.heading}
              </Text>
        {language === 'English'
              ? <Text style={{ fontSize: '7.5px', textAlign:'center', marginTop:"3px", color:"white", fontWeight: 'normal' }}>
                {usecases[2].en?.para}
              </Text>

              : <Text style={{ fontSize: '7.5px', textAlign:'center', marginTop:"3px", color:"white", fontWeight: 'normal' }}>
                {usecases[2].it?.para}
              </Text>
        }
        </View>
      
    </View>
    <Text style={{...styles.dividerText, backgroundColor:"#2DA0F0", marginTop:"20px"}}>
    {language === "English" ? "SOFTWARE" : "SOFTWARE"}

       </Text>
       <View style={{display:"flex", marginTop:"20px", marginBottom:"30px", marginLeft:"60px", flexDirection:"row"}}>
        <View style={{padding:"5px", paddingTop:"15px", backgroundColor:"#A158D6", width:"210px", height:"120px"}}>
        <Text style={{ fontSize: '9px', textAlign:'center', marginTop:"5px", color:"white",  fontWeight: 'bold' }}>
                {usecases[3].en?.heading}
              </Text>
        {language === 'English'
              ? <Text style={{ fontSize: '7.5px', textAlign:'center', marginTop:"3px", color:"white", fontWeight: 'normal' }}>
                {usecases[3].en?.para}
              </Text>

              : <Text style={{ fontSize: '7.5px', textAlign:'center', marginTop:"3px", color:"white", fontWeight: 'normal' }}>
                {usecases[3].it?.para}
              </Text>
        }
        </View>
        <View style={{padding:"5px", paddingTop:"15px", backgroundColor:"#2DA0F0", width:"210px", height:"120px"}}>
        <Text style={{ fontSize: '9px', textAlign:'center', marginTop:"5px", color:"white",  fontWeight: 'bold' }}>
                {usecases[4].en?.heading}
              </Text>
        {language === 'English'
              ? <Text style={{ fontSize: '7.5px', textAlign:'center', marginTop:"3px", color:"white", fontWeight: 'normal' }}>
                {usecases[4].en?.para}
              </Text>

              : <Text style={{ fontSize: '7.5px', textAlign:'center', marginTop:"3px", color:"white", fontWeight: 'normal' }}>
                {usecases[4].it?.para}
              </Text>
        }
        </View>
    </View>  
    </Page>
  );
};

export default LearningApps;
