export const SectorsContent = {
    'Financial':{
        en:{
            title: "Interpreting Services for the Financial Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services for the financial sector. Our highly qualified interpreters enable clear and accurate communication during conferences, meetings, and financial negotiations.",
            listItem1Title: 'Specialized Interpreters: ',
            listItem1Para: "We provide interpreters with extensive experience in the financial sector and a deep understanding of specific terminology, ensuring a detailed understanding of financial concepts.",
            listItem2Title: 'Tailored Interpretation: ',
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your financial events, including investor meetings, company presentations, and economic conferences.",
            listItem3Title: 'Technical Equipment: ',
            listItem3Para: "We supply high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your financial event.",
            listItem4Title: 'Professional Technical Assistance: ',
            listItem4Para: "Our experienced technical team takes care of the installation and management of equipment, ensuring that everything runs smoothly during your financial event.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for the financial sector at Congressi Internazionali, you can facilitate communication and collaboration among financial experts, investors, and international companies.",
            para2Pdf:""          
        },
        it:{
            title:"Servizi di interpretariato per il settore finanziario: il partner multilingue ideale",
            para1:`Congressi Internazionali offre servizi di interpretariato specializzati per il settore finanziario. I nostri interpreti altamente qualificati consentono una comunicazione chiara e accurata nel corso di conferenze, riunioni e trattative finanziarie.`,
            listItem1Title:'Interpreti specializzati: ',
            listItem1Para:`Mettiamo a disposizione interpreti con una vasta esperienza nel settore finanziario e una profonda conoscenza della terminologia specifica, garantendo una comprensione dettagliata dei concetti finanziari.`,
            listItem2Title:`Interpretariato su misura: `,
            listItem2Para:`Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi finanziari, inclusi meeting di investitori, presentazioni aziendali e conferenze economiche.`,
            listItem3Title:`Attrezzature tecniche: `,
            listItem3Para:`Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per assicurare una comunicazione ottimale durante il vostro evento nel settore finanziario.`,
            listItem4Title:`Assistenza tecnica professionale: `,
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title:"",
            listItem5Para:"",
            para2:`Con i nostri servizi di interpretariato per il settore finanziario, potete facilitare la comunicazione e la collaborazione tra esperti finanziari, investitori e aziende internazionali.`,    
            para2Pdf:""
        },
        cover: '/clients/finance/coverfinance.webp',
        coverPdf: '/clients/finance/coverfinance1.png',
        sector:'/clients/finance/finance.webp',
        sectorPdf:'/clients/finance/finance.png'
    },
    'Medical Areas':{
        en:{
            title: "Interpreting Services for the Medical Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpreting services for the medical sector. Our professional interpreters enable clear and accurate communication during medical conferences, patient consultations, and healthcare treatments.",
            listItem1Title: "Specialized Interpreters: ",
            listItem1Para: "We provide interpreters with extensive experience in the medical sector and a solid knowledge of specific terminology, ensuring a detailed understanding of medical and scientific concepts.",
            listItem2Title: "Tailored Interpretation: ",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the medical sector, including congresses, seminars, and training courses.",
            listItem3Title: "Technical Equipment: ",
            listItem3Para: "We supply high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your event in the medical sector.",
            listItem4Title: "Professional Technical Assistance: ",
            listItem4Para: "Our experienced technical team takes care of the installation and management of equipment, ensuring that everything runs smoothly during your event in the medical sector.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpreting services for the medical sector, you can facilitate communication among doctors, patients, and researchers from different parts of the world, contributing to the progress of science and global health care.",
            para2Pdf: "With our interpreting services for the medical sector, you can facilitate communication among doctors, patients, and researchers from different parts of the world, contributing to the progress of science and global health care."
            },
        it:{
            title:`Servizi di interpretariato per il settore Medico: il partner multilingue ideale`,
            para1:`Congressi Internazionali offre servizi di interpretariato specializzati per il settore medico. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di convegni medici, consultazioni pazienti e trattamenti sanitari.`,
            listItem1Title:'Interpreti specializzati: ',
            listItem1Para:`Mettiamo a disposizione interpreti con una vasta esperienza nel settore medico e una solida conoscenza della terminologia specifica, garantendo una comprensione dettagliata dei concetti medici e scientifici.`,
            listItem2Title:`Interpretariato su misura: `,
            listItem2Para:`Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore medico, inclusi congressi, seminari e corsi di formazione.`,
            listItem3Title:`Attrezzature tecniche: `,
            listItem3Para:`Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore medico.`,
            listItem4Title:`Assistenza tecnica professionale: `,
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title:``,
            listItem5Para:``,
            para2:`Con i nostri servizi di interpretariato per il settore medico, potete facilitare la comunicazione tra medici, pazienti e ricercatori provenienti da diverse parti del mondo, contribuendo al progresso della scienza e alla cura della salute globale.`,
            para2Pdf:``
        },
        cover: '/clients/medical/coverMedicalAreas.webp',
        coverPdf: '/clients/medical/coverMedicalAreas1.png',
        sector:'/clients/medical/medicalAreas.webp',
        sectorPdf:'/clients/medical/medicalAreas.png'
    },
    'Medical Materials':{
        en:{
            title: "Interpreting Services for the Medical Materials Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpreting services for the medical materials sector. Our professional interpreters enable clear and accurate communication during conferences, trade fairs, and meetings in the field of research and production of healthcare materials.",
            listItem1Title: "Specialized Interpreters: ",
            listItem1Para: "We provide interpreters with extensive experience in the medical materials sector and a solid knowledge of technical terminology, ensuring a detailed understanding of production processes and the characteristics of healthcare materials.",
            listItem2Title: "Tailored Interpretation: ",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the medical materials sector, including technical workshops, company presentations, and training sessions.",
            listItem3Title: "Technical Equipment: ",
            listItem3Para: "We supply high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your event in the medical materials sector.",
            listItem4Title: "Professional Technical Assistance: ",
            listItem4Para: "Our experienced technical team takes care of the installation and management of equipment, ensuring that everything runs smoothly during your event in the medical materials sector.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "Our expert technical team takes care of the installation and management of equipment, ensuring that everything runs smoothly during your event in the medical materials sector.",
            para2Pdf: "Our expert technical team takes care of the installation and management of equipment, ensuring that everything runs smoothly during your event in the medical materials sector."
            },
        it:{
            title:"Servizi di interpretariato per il settore dei materiali medici: il partner multilingue ideale",
            para1:`Congressi Internazionali offre servizi di interpretariato specializzati per il settore dei materiali medici. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di conferenze, fiere e incontri nel campo della ricerca e produzione di materiali sanitari.`,
            listItem1Title:'Interpreti specializzati: ',
            listItem1Para:`Mettiamo a disposizione interpreti con una vasta esperienza nel settore dei materiali medici e una solida conoscenza della terminologia tecnica, garantendo una comprensione dettagliata dei processi di produzione e delle caratteristiche dei materiali sanitari.`,
            listItem2Title:`Interpretariato su misura: `,
            listItem2Para:`Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore dei materiali medici, inclusi workshop tecnici, presentazioni aziendali e sessioni di formazione.`,
            listItem3Title:`Attrezzature tecniche: `,
            listItem3Para:`Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore dei materiali medici.`,
            listItem4Title:`Assistenza tecnica professionale: `,
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title:"",
            listItem5Para:"",
            para2:`Con i nostri servizi di interpretariato per il settore medico, potete facilitare la comunicazione tra medici, pazienti e ricercatori provenienti da diverse parti del mondo, contribuendo al progresso della scienza e alla cura della salute globale.`,
            para2Pdf:``
        },
        cover: '/clients/medical/covermedicalmaterials.webp',
        coverPdf: '/clients/medical/covermedicalmaterials1.png',
        sector:'/clients/medical/medicalMaterials.webp',
        sectorPdf:'/clients/medical/medicalMaterials.png'
    },
    'Medical Devices':{
        en:{
            title: "Interpreting Services for the Medical Devices Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpreting services for the medical devices sector. Our professional interpreters enable clear and accurate communication during trade fairs, conferences, and meetings in the field of healthcare innovation and medical technology.",
            listItem1Title: "Interpreters Specialized: ",
            listItem1Para: "We provide interpreters with extensive experience in the medical devices sector and a solid knowledge of technical terminology, ensuring a detailed understanding of medical-scientific concepts.",
            listItem2Title: "Tailored Interpretation: ",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the medical devices sector, including product launches, technical presentations, and training courses.",
            listItem3Title: "Technical Equipment: ",
            listItem3Para: "We supply high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your event in the medical devices sector.",
            listItem4Title: "Professional Technical Assistance: ",
            listItem4Para: "Our experienced technical team takes care of the installation and management of equipment, ensuring that everything runs smoothly during your event in the medical devices sector.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpreting services for the medical devices sector, you can promote innovation and improve health on an international level, facilitating communication between healthcare professionals, researchers, and manufacturing companies.",
            para2Pdf: "With our interpreting services for the medical devices sector, you can promote innovation and improve health on an international level, facilitating communication between healthcare professionals, researchers, and manufacturing companies."
            },
        it:{
            title:"Servizi di interpretariato per il settore dei dispositivi medici: il partner multilingue ideale",
            para1:"Congressi Internazionali offre servizi di interpretariato specializzati per il settore dei dispositivi medici. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di fiere, convegni e incontri nel campo dell'innovazione sanitaria e della tecnologia medica.",
            listItem1Title:'Interpreti specializzati: ',
            listItem1Para:`Mettiamo a disposizione interpreti con una vasta esperienza nel settore dei dispositivi medici e una solida conoscenza della terminologia tecnica, garantendo una comprensione dettagliata dei concetti medico-scientifici.`,
            listItem2Title:`Interpretariato su misura: `,
            listItem2Para:`Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore dei dispositivi medici, inclusi lanci di prodotti, presentazioni tecniche e corsi di formazione.`,
            listItem3Title:`Attrezzature tecniche: `,
            listItem3Para:`Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore dei dispositivi medici.`,
            listItem4Title:`Assistenza tecnica professionale: `,
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title:"",
            listItem5Para:"",
            para2:"Con i nostri servizi di interpretariato per il settore dei dispositivi medici, potete promuovere l'innovazione e migliorare la salute a livello internazionale, facilitando la comunicazione tra professionisti sanitari, ricercatori e aziende produttrici.",
            para2Pdf:"Con i nostri servizi di interpretariato per il settore dei dispositivi medici, potete promuovere l'innovazione e migliorare la salute a livello internazionale, facilitando la comunicazione tra professionisti sanitari, ricercatori e aziende produttrici."
        },
        cover: '/clients/medical/covermedicaldevices.webp',
        coverPdf: '/clients/medical/coverMedicalDevices1.png',
        sector:'/clients/medical/medicalDevices.webp',
        sectorPdf:'/clients/medical/medicalDevices.png'
    },
    'Fashion':{
            en:{
                title: "Interpreting Services for the Fashion Industry: Your Multilingual Partner",
                para1: "At Congressi Internazionali, we offer specialized interpreting services for the fashion industry. Our professional interpreters enable clear and accurate communication during fashion shows, collection presentations, and meetings in the fashion world.",
                listItem1Title: "Specialized Interpreters: ",
                listItem1Para: "We provide interpreters with extensive experience in the fashion industry and a deep knowledge of specific terminology, ensuring a detailed understanding of fashion concepts.",
                listItem2Title: "Tailored Interpretation: ",
                listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the fashion industry, including product launches, showrooms, and press conferences.",
                listItem3Title: "Technical Equipment: ",
                listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your fashion event.",
                listItem4Title: "Professional Technical Assistance: ",
                listItem4Para: "Our experienced technical team takes care of the installation and management of equipment, ensuring that everything runs smoothly during your event in the fashion industry.",
                listItem5Title: "",
                listItem5Para: "",
                para2: "With our interpreting services for fashion, you can promote your brand and collections internationally, facilitating communication with customers, partners, and media from around the world.",
                para2Pdf: "With our interpreting services for fashion, you can promote your brand and collections internationally, facilitating communication with customers, partners, and media from around the world."
                },
            it:{
                title:"Servizi di interpretariato per il settore della moda: il partner ideale",
                para1:"Congressi Internazionali offre servizi di interpretariato specializzati per il settore della moda. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di sfilate di moda, presentazioni di collezioni e incontri nel mondo della moda.",
                listItem1Title:'Interpreti specializzati: ',
                listItem1Para:`Mettiamo a disposizione interpreti con una vasta esperienza nel settore della moda e una profonda conoscenza della terminologia specifica, garantendo una comprensione dettagliata dei concetti moda.`,
                listItem2Title:"Interpretariato su misura: ",
                listItem2Para:"Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore della moda, inclusi lanci di prodotti, showroom e conferenze stampa.",
                listItem3Title:"Attrezzature tecniche: ",
                listItem3Para:"Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per assicurare una comunicazione ottimale durante il vostro evento di moda.",
                listItem4Title:"Assistenza tecnica professionale: ",
                listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
                listItem5Title:"",
                listItem5Para:"",
                para2:"Con i nostri servizi di interpretariato per la moda, potete promuovere il vostro marchio e le vostre collezioni a livello internazionale, facilitando la comunicazione con clienti, partner e media di tutto il mondo.",
                para2Pdf:"Con i nostri servizi di interpretariato per la moda, potete promuovere il vostro marchio e le vostre collezioni a livello internazionale, facilitando la comunicazione con clienti, partner e media di tutto il mondo."
            },
            cover: '/clients/fashion/coverfashion.webp',
            coverPdf: '/clients/fashion/coverfashion1.png',
            sector:'/clients/fashion/fashion.webp',
            sectorPdf:'/clients/fashion/fashion.png'        
        },
    'Transport':{
        en:{
            title: "Interpreting Services for the Transportation Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpreting services for the transportation sector. Our professional interpreters enable clear and accurate communication during conferences, forums, and meetings in the fields of logistics, transportation, and sustainable mobility.",
            listItem1Title: "Specialized Interpreters: ",
            listItem1Para: "We provide interpreters with extensive experience in the transportation sector and a solid knowledge of logistics terminology, ensuring a detailed understanding of the challenges and innovations in the industry.",
            listItem2Title: "Tailored Interpretation: ",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the transportation sector, including international conferences, roundtables on mobility policies, and meetings among industry stakeholders.",
            listItem3Title: "Technical Equipment: ",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your transportation event.",
            listItem4Title: "Professional Technical Assistance",
            listItem4Para: "Our experienced technical team takes care of the installation and management of equipment, ensuring that everything runs smoothly during your event in the transportation sector.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpreting services for the transportation sector, you can promote sustainability and efficiency in the field of mobility, facilitating communication among industry professionals, public authorities, and international stakeholders."
            },
        it:{
            title:"Servizi di interpretariato per il settore dei trasporti: il partner multilingue ideale",
            para1:"Congressi Internazionali offre servizi di interpretariato specializzati per il settore dei trasporti. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di conferenze, forum e incontri nel campo della logistica, dei trasporti e della mobilità sostenibile.",
            listItem1Title:'Interpreti specializzati: ',
            listItem1Para:"Mettiamo a disposizione interpreti con una vasta esperienza nel settore dei trasporti e una solida conoscenza della terminologia logistica, garantendo una comprensione dettagliata delle sfide e delle innovazioni nel settore.",
            listItem2Title:"Interpretariato su misura: ",
            listItem2Para:"Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore dei trasporti, inclusi convegni internazionali, tavole rotonde su politiche di mobilità e incontri tra operatori del settore.",
            listItem3Title:"Attrezzature tecniche: ",
            listItem3Para:"Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore dei trasporti.",
            listItem4Title:"Assistenza tecnica professionale",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title:"",
            listItem5Para:"",
            para2:"Con i nostri servizi di interpretariato per il settore dei trasporti, potete promuovere la sostenibilità e l'efficienza nel campo della mobilità, facilitando la comunicazione tra professionisti del settore, autorità pubbliche e stakeholder internazionali."
        },
        cover: '/clients/transport/covertransport.webp',
        coverPdf: '/clients/transport/covertransport1.png',
        sector:'/clients/transport/transport.webp',
        sectorPdf:'/clients/transport/transport.png'
    },
    'Logistics':{
        en:{
            title: "Interpreting Services for the Logistics Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpreting services for the logistics sector. Our professional interpreters enable clear and accurate communication during conferences, trade shows, and meetings in the field of supply chain management and transportation.",
            listItem1Title: "Specialized Interpreters: ",
            listItem1Para: "We provide interpreters with extensive experience in the logistics sector and a deep knowledge of specific terminology, ensuring a detailed understanding of logistics processes and transport strategies.",
            listItem2Title: "Tailored Interpretation: ",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the logistics sector, including international conferences, workshops, and corporate meetings.",
            listItem3Title: "Technical Equipment: ",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your logistics event.",
            listItem4Title: "Professional Technical Assistance: ",
            listItem4Para: "Our experienced technical team takes care of the installation and management of equipment, ensuring that everything runs smoothly during your event in the logistics sector.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpreting services for the logistics sector, you can optimize your supply chain and improve the efficiency of your transportation internationally, facilitating communication among industry professionals and logistics companies.",
            para2Pdf: "With our interpreting services for the logistics sector, you can optimize your supply chain and improve the efficiency of your transportation internationally, facilitating communication among industry professionals and logistics companies."
            },
        it:{
            title:"Servizi di Interpretariato per il settore della logistica: Il partner multilingue ideale",
            para1:"Congressi Internazionali offre servizi di interpretariato specializzati per il settore della logistica. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di conferenze, fiere e incontri nel campo della gestione della catena di approvvigionamento e dei trasporti.",
            listItem1Title:'Interpreti specializzati: ',
            listItem1Para:"Mettiamo a disposizione interpreti con una vasta esperienza nel settore della logistica e una profonda conoscenza della terminologia specifica, garantendo una comprensione dettagliata dei processi logistici e delle strategie di trasporto.",
            listItem2Title:`Interpretariato su misura: `,
            listItem2Para:"Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore della logistica, inclusi convegni internazionali, workshop e riunioni aziendali.",
            listItem3Title:"Attrezzature tecniche: ",
            listItem3Para:"Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore della logistica.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title:"",
            listItem5Para:"",
            para2:"Con i nostri servizi di interpretariato per il settore della logistica, potete ottimizzare la vostra catena di approvvigionamento e migliorare l'efficienza dei vostri trasporti a livello internazionale, facilitando la comunicazione tra professionisti del settore e aziende di logistica.",
            para2Pdf:"Con i nostri servizi di interpretariato per il settore della logistica, potete ottimizzare la vostra catena di approvvigionamento e migliorare l'efficienza dei vostri trasporti a livello internazionale, facilitando la comunicazione tra professionisti del settore e aziende di logistica."
        },
        cover: '/clients/transport/covertransport.webp',
        coverPdf: '/clients/transport/covertransport1.png',
        sector:'/clients/transport/transport.webp',
        sectorPdf:'/clients/transport/transport.png'
    },
    'Not-for-Profit':{
        en:{
            title: "Interpreting Services for the Nonprofit Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpreting services for the nonprofit sector. Our professional interpreters enable clear and accurate communication during conferences, charity events, and meetings in the field of social solidarity and the promotion of the common good.",
            listItem1Title: "Specialized Interpreters: ",
            listItem1Para: "We provide interpreters with extensive experience in the nonprofit sector and a deep sensitivity to social issues, ensuring a detailed understanding of the values and goals of nonprofit organizations.",
            listItem2Title: "Tailored Interpretation: ",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the nonprofit sector, including awareness-raising meetings, public debates, and fundraising campaigns.",
            listItem3Title: "Technical Equipment: ",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your nonprofit sector event.",
            listItem4Title: "Professional Technical Assistance: ",
            listItem4Para: "Our experienced technical team takes care of the installation and management of equipment, ensuring that everything runs smoothly during your nonprofit sector event.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpreting services for the nonprofit sector, you can promote solidarity and volunteering globally, facilitating communication between organizations, supporters, and beneficiary communities.",
            para2Pdf: "With our interpreting services for the nonprofit sector, you can promote solidarity and volunteering globally, facilitating communication between organizations, supporters, and beneficiary communities."
            },
        it:{
            title:"Servizi di interpretariato per il settore non profit: il partner multilingue ideale",
            para1:"Congressi Internazionali offre servizi di interpretariato specializzati per il settore non profit. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di conferenze, eventi di beneficenza e incontri nel campo della solidarietà sociale e della promozione del bene comune.",
            listItem1Title:'Interpreti specializzati: ',
            listItem1Para:"Mettiamo a disposizione interpreti con una vasta esperienza nel settore non profit e una profonda sensibilità verso le tematiche sociali, garantendo una comprensione dettagliata dei valori e degli obiettivi delle organizzazioni no profit.",
            listItem2Title:"Interpretariato su misura: ",
            listItem2Para:"Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore non profit, inclusi incontri di sensibilizzazione, dibattiti pubblici e campagne di raccolta fondi.",
            listItem3Title:"Attrezzature tecniche: ",
            listItem3Para:"Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore non profit.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title:"",
            listItem5Para:"",
            para2:"Con i nostri servizi di interpretariato per il settore non profit, potete promuovere la solidarietà e il volontariato a livello globale, facilitando la comunicazione tra organizzazioni, sostenitori e comunità beneficiarie.",
            para2Pdf:"Con i nostri servizi di interpretariato per il settore non profit, potete promuovere la solidarietà e il volontariato a livello globale, facilitando la comunicazione tra organizzazioni, sostenitori e comunità beneficiarie."
        },
        cover: '/clients/notForProfit/covernotforprofit.webp',
        coverPdf: '/clients/notForProfit/covernotforprofit1.png',
        sector:'/clients/notForProfit/notforprofit.webp',
        sectorPdf:'/clients/notForProfit/notforprofit.png'
    },
    'Art':{
        en:{
            title: "Artistic Interpreting Services: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer interpreting services dedicated to the world of art. Our specialized interpreters enable effective and accurate communication during exhibitions, conferences, and artistic events.",
            listItem1Title: "Specialized Interpreters: ",
            listItem1Para: "We provide interpreters with a deep understanding of artistic language and specific terminology, ensuring clear and detailed communication.",
            listItem2Title: "Tailored Interpretation: ",
            listItem2Para: "We offer simultaneous and consecutive interpreting services, adapting to the needs of your artistic events, including vernissages, artist meetings, and creative workshops.",
            listItem3Title: "Audiovisual Equipment: ",
            listItem3Para: "We provide high-quality audiovisual equipment, such as headphones and simultaneous translation systems, to ensure an optimal experience for all participants.",
            listItem4Title: "Specialized Technical Team: ",
            listItem4Para: "Our highly qualified technical team takes care of the installation and management of equipment, ensuring that everything runs smoothly during your artistic event.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our artistic interpreting services, you can communicate effectively and engagingly with a wide range of international audiences, promoting art and culture globally.",
            para2Pdf: "With our artistic interpreting services, you can communicate effectively and engagingly with a wide range of international audiences, promoting art and culture globally."
            },
        it:{
            title:"Servizi di interpretariato artistico: il partner multilingue ideale",
            para1:"Congressi Internazionali offre servizi di interpretariato dedicati al mondo dell'arte. I nostri interpreti specializzati consentono una comunicazione efficace e accurata durante mostre, conferenze e eventi artistici.",
            listItem1Title:"Interpreti specializzati: ",
            listItem1Para:"Mettiamo a disposizione interpreti con una profonda comprensione del linguaggio artistico e della terminologia specifica, garantendo una comunicazione chiara e dettagliata.",
            listItem2Title:"Interpretariato su misura: ",
            listItem2Para:"Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze dei vostri eventi artistici, inclusi vernissage, incontri con gli artisti e workshop creativi.",
            listItem3Title:"Attrezzature audiovisive: ",
            listItem3Para:"Forniamo attrezzature audiovisive di alta qualità, come auricolari e sistemi di traduzione simultanea, per assicurare un'esperienza ottimale per tutti i partecipanti.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title:"",
            listItem5Para:"",
            para2:"Con i nostri servizi di interpretariato artistico, potete comunicare in modo efficace e coinvolgente con una vasta gamma di pubblici internazionali, promuovendo l'arte e la cultura a livello globale.",
            para2Pdf:"Con i nostri servizi di interpretariato artistico, potete comunicare in modo efficace e coinvolgente con una vasta gamma di pubblici internazionali, promuovendo l'arte e la cultura a livello globale."
        },
        cover: '/clients/Art/coverart.webp',
        coverPdf: '/clients/Art/coverart1.png',
        sector:'/clients/Art/Art.webp',
        sectorPdf:'/clients/Art/Art.png'
    },
    'Agronomy':{
        en:{
            title: "Interpreting Services for the Agronomy Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpreting services in the agricultural sector. Our highly qualified interpreters enable smooth and accurate communication during conferences, meetings, and agricultural workshops.",
            listItem1Title: "Specialized Interpreters: ",
            listItem1Para: "We provide professional interpreters with extensive knowledge of agricultural technical language, ensuring precise and detailed understanding during events.",
            listItem2Title: "Tailored Interpretation: ",
            listItem2Para: "We offer simultaneous and consecutive interpreting services, adapting to the specific needs of your agricultural events, including conferences, seminars, and field visits.",
            listItem3Title: "Technical Equipment: ",
            listItem3Para: "We provide the best interpretation equipment, including simultaneous translation systems and audio equipment, ensuring an optimal experience for all participants.",
            listItem4Title: "Reliable Technical Team: ",
            listItem4Para: "Our experienced technical team takes care of the installation and management of equipment, ensuring that everything works perfectly during your agricultural event.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpreting services for agronomy, you can rely on high-quality language support to promote knowledge exchange and facilitate international collaboration in the agricultural sector.",
            para2Pdf: "With our interpreting services for agronomy, you can rely on high-quality language support to promote knowledge exchange and facilitate international collaboration in the agricultural sector."
            },
        it:{
            title:"Servizi di interpretariato per il settore dell'agronomia: il partner multilingue ideale",
            para1:"Congressi Internazionali offre servizi di interpretariato specializzati nel settore agricolo. I nostri interpreti altamente qualificati consentono una comunicazione fluida e accurata durante conferenze, riunioni e workshop agricoli.",
            listItem1Title:"Interpreti specializzati: ",
            listItem1Para:"Mettiamo a disposizione interpreti professionisti con una vasta conoscenza del linguaggio tecnico agricolo, garantendo una comprensione precisa e dettagliata durante gli eventi.",
            listItem2Title:"Interpretariato su Misura: ",
            listItem2Para:"Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi agricoli, inclusi convegni, seminari e visite sul campo.",
            listItem3Title:"Attrezzature tecniche: ",
            listItem3Para:"Forniamo le migliori attrezzature per l'interpretazione, inclusi sistemi di traduzione simultanea e apparecchiature audio, garantendo un'esperienza ottimale per tutti i partecipanti.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title:"",
            listItem5Para:"",
            para2:"Con i nostri servizi di interpretariato per l'agronomia, potete contare su un supporto linguistico di alta qualità per promuovere lo scambio di conoscenze e facilitare la collaborazione internazionale nel settore agricolo.",
            para2Pdf:"Con i nostri servizi di interpretariato per l'agronomia, potete contare su un supporto linguistico di alta qualità per promuovere lo scambio di conoscenze e facilitare la collaborazione internazionale nel settore agricolo."
        },
        cover: '/clients/Agronomy/coveragronomy.webp',
        coverPdf: '/clients/Agronomy/coveragronomy1.png',
        sector:'/clients/Agronomy/Agronomy.webp',
        sectorPdf:'/clients/Agronomy/Agronomy.png'
    },
    'Communication':{
        en:{
            title: "Interpreting Services for the Communication Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer a comprehensive range of interpretation services tailored to meet the specific needs of the technology sector. Our services include:",
            listItem1Title: "Highly Qualified Interpreters: ",
            listItem1Para: "We provide highly qualified and specialized professional interpreters in the technology sector, ensuring accurate understanding and effective communication during events and situations of all kinds.",
            listItem2Title: "Simultaneous and Consecutive Interpretation: ",
            listItem2Para: "We are able to provide simultaneous and consecutive interpretation for a wide range of events, including conferences, corporate meetings, presentations, and webinars, ensuring smooth and uninterrupted communication.",
            listItem3Title: "Quality Technical Equipment: ",
            listItem3Para: "We provide the necessary equipment to support interpretation events, including soundproof booths, simultaneous translation equipment, microphones, and headphones for listeners, ensuring an optimal experience for all participants.",
            listItem4Title: "Expert Technical Team: ",
            listItem4Para: "Our highly qualified technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your event and that communication is clear and uninterrupted.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With Congressi Internazionali's interpretation services, you can rely on professional and reliable support to facilitate your multilingual communication and promote the success of your projects in the technology sector.",
            para2Pdf: "With Congressi Internazionali's interpretation services, you can rely on professional and reliable support to facilitate your multilingual communication and promote the success of your projects in the technology sector."
            },
        it:{
            title:`Servizi di interpretariato per il settore della comunicazione: il partner multilingue ideale`,
            para1:"Congressi Internazionali offre una gamma completa di servizi di interpretariato mirati a soddisfare le esigenze specifiche del settore tecnologico. I nostri servizi includono:",
            listItem1Title:`Interpreti altamente qualificati: `,
            listItem1Para:`Mettiamo a disposizione interpreti professionisti altamente qualificati e specializzati nel settore tecnologico, garantendo una comprensione accurata e una comunicazione efficace durante eventi e situazioni di ogni genere.`,
            listItem2Title:`Interpretariato simultaneo e consecutivo: `,
            listItem2Para:`Siamo in grado di fornire interpretariato simultaneo e consecutivo per una vasta gamma di eventi, inclusi convegni, riunioni aziendali, presentazioni e webinar, assicurando una comunicazione fluida e senza interruzioni.`,
            listItem3Title:`Attrezzature tecniche di qualità: `,
            listItem3Para:`Forniamo l'attrezzatura necessaria per supportare gli eventi di interpretariato, tra cui cabine insonorizzate, apparecchiature di traduzione simultanea, microfoni e cuffie per gli ascoltatori, garantendo un'esperienza ottimale per tutti i partecipanti.`,
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title:``,
            listItem5Para:``,
            para2:`Con i servizi di interpretariato di Congressi Internazionali, potete contare su un supporto professionale e affidabile per facilitare la vostra comunicazione multilingue e promuovere il successo dei vostri progetti nel settore tecnologico.`,
            para2Pdf:"Con i servizi di interpretariato di Congressi Internazionali, potete contare su un supporto professionale e affidabile per facilitare la vostra comunicazione multilingue e promuovere il successo dei vostri progetti nel settore tecnologico."
        },
        cover: '/clients/Communication/covercommunication.webp',
        coverPdf: '/clients/Communication/covercommunication1.png',
        sector:'/clients/Communication/Communication.webp',
        sectorPdf:'/clients/Communication/Communication.png'
    },
    'Construction':{
        en:{
            title: "Interpreting Services for the Construction Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services in the construction sector. Our highly qualified interpreters enable smooth and accurate communication during conferences, trade fairs, and technical events in the construction industry.",
            listItem1Title: "Specialized Interpreters: ",
            listItem1Para: "We provide professional interpreters with extensive experience in the construction sector and a deep understanding of technical terminology, ensuring clear and precise communication.",
            listItem2Title: "Customized Interpretation: ",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your construction events, including conferences, workshops, and site visits.",
            listItem3Title: "Technical Equipment: ",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure an optimal experience for all participants.",
            listItem4Title: "Professional Technical Support: ",
            listItem4Para: "Our highly qualified technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your construction event.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for the construction sector, you can communicate effectively and seamlessly with a wide range of international partners and professionals, promoting collaboration and success in your construction projects.",
            para2Pdf: "With our interpretation services for the construction sector, you can communicate effectively and seamlessly with a wide range of international partners and professionals, promoting collaboration and success in your construction projects."
            },
        it:{
            title:`Servizi di interpretariato per il settore edile: il partner multilingue ideale`,
            para1:`Congressi Internazionali offre servizi di interpretariato specializzati nel settore delle costruzioni. I nostri interpreti altamente qualificati consentono una comunicazione fluida e accurata durante conferenze, fiere ed eventi tecnici nel settore edile.:`,
            listItem1Title:`Interpreti specializzati: `,
            listItem1Para:`Mettiamo a disposizione interpreti professionisti con una vasta esperienza nel settore edile e una profonda conoscenza della terminologia tecnica, garantendo una comunicazione chiara e precisa.`,
            listItem2Title:`Interpretariato su misura: `,
            listItem2Para:`Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi di costruzione, inclusi convegni, workshop e visite in cantiere.`,
            listItem3Title:`Attrezzature tecniche: `,
            listItem3Para:`Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per assicurare un'esperienza ottimale per tutti i partecipanti.`,
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title:``,
            listItem5Para:``,
            para2:`Con i nostri servizi di interpretariato per il settore edile, potete comunicare in modo efficace e agevole con una vasta gamma di partner e professionisti internazionali, promuovendo la collaborazione e il successo nei vostri progetti di costruzione.`,
            para2Pdf:`Con i nostri servizi di interpretariato per il settore edile, potete comunicare in modo efficace e agevole con una vasta gamma di partner e professionisti internazionali, promuovendo la collaborazione e il successo nei vostri progetti di costruzione.`
        },
        cover: '/clients/Construction/coverconstruction.webp',
        coverPdf: '/clients/Construction/coverconstruction1.png',
        sector:'/clients/Construction/Construction.webp',
        sectorPdf:'/clients/Construction/Construction.png'
    },
    'E-Learning':{
        en:{
            title: "Interpreting Services for the E-Learning Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services in the e-learning sector. Our highly qualified interpreters enable clear and accurate communication during webinars, online courses, and conferences on digital education.",
            listItem1Title: "Specialized Interpreters: ",
            listItem1Para: "We provide interpreters with extensive experience in the field of online education and a deep understanding of technical terminology, ensuring precise comprehension of instructional content.",
            listItem2Title: "Customized Interpretation: ",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your e-learning events, including seminars, corporate training, and educational conferences.",
            listItem3Title: "Technical Equipment: ",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation devices and microphones, to ensure an optimal experience for all participants.",
            listItem4Title: "Professional Technical Support: ",
            listItem4Para: "Our highly qualified technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your e-learning event.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for e-learning, you can provide effective and engaging training to an international audience, promoting access to online education worldwide.",
            para2Pdf: "With our interpretation services for e-learning, you can provide effective and engaging training to an international audience, promoting access to online education worldwide."
            },
        it:{
            title:"Servizi di interpretariato per il settore dell'e-Learning: il partner multilingue ideale",
            para1:"Congressi Internazionali offre servizi di interpretariato specializzati nel settore dell'e-learning. I nostri interpreti altamente qualificati consentono una comunicazione chiara e accurata nel corso di webinar, corsi online e conferenze sull'istruzione digitale.",
            listItem1Title:"Interpreti specializzati: ",
            listItem1Para:"Mettiamo a disposizione interpreti con una vasta esperienza nel campo dell'istruzione online e una profonda conoscenza della terminologia tecnica, garantendo una comprensione precisa dei contenuti didattici.",
            listItem2Title:"Interpretariato su misura: ",
            listItem2Para:"Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi di e-learning, inclusi seminari, formazioni aziendali e conferenze educative.",
            listItem3Title:"Attrezzature tecniche: ",
            listItem3Para:"Forniamo attrezzature audiovisive di alta qualità, come apparecchiature di traduzione simultanea e microfoni, per assicurare un'esperienza ottimale per tutti i partecipanti.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title:"",
            listItem5Para:"",
            para2:"Con i nostri servizi di interpretariato per l'e-learning, potete offrire una formazione efficace e coinvolgente a un pubblico internazionale, promuovendo l'accesso all'istruzione online in tutto il mondo.",
            para2Pdf:"Con i nostri servizi di interpretariato per l'e-learning, potete offrire una formazione efficace e coinvolgente a un pubblico internazionale, promuovendo l'accesso all'istruzione online in tutto il mondo."
        },
        cover: '/clients/E-Learning/coverelearning.webp',
        coverPdf: '/clients/E-Learning/coverelearning1.png',
        sector:'/clients/E-Learning/eLearning.webp',
        sectorPdf:'/clients/E-Learning/eLearning.png'
    },
    'Education':{
        en:{
            title: "Interpreting Services for the Education Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services in the education sector. Our professional interpreters enable effective and accurate communication during conferences, seminars, and educational meetings.",
            listItem1Title: "Specialized Interpreters: ",
            listItem1Para: "We provide interpreters with extensive experience in the field of education and a deep understanding of educational terminology, ensuring detailed comprehension during events.",
            listItem2Title: "Customized Interpretation: ",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your educational events, including conferences, training courses, and educational workshops.",
            listItem3Title: "Technical Equipment: ",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your educational event.",
            listItem4Title: "Professional Technical Support: ",
            listItem4Para: "Our experienced technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your event in the education sector.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for education, you can promote the exchange of knowledge and facilitate international collaboration in the field of education, contributing to the growth and development of education globally.",
            para2Pdf: "With our interpretation services for education, you can promote the exchange of knowledge and facilitate international collaboration in the field of education, contributing to the growth and development of education globally."
            },
        it:{
            title:"Servizi di interpretariato per il settore dell'istruzione: il partner multilingue ideale",
            para1:"Congressi Internazionali offre servizi di interpretariato specializzati nel settore dell'istruzione. I nostri interpreti professionisti consentono una comunicazione efficace e accurata durante conferenze, seminari e incontri educativi.",
            listItem1Title:"Interpreti specializzati: ",
            listItem1Para:"Mettiamo a disposizione interpreti con una vasta esperienza nel campo dell'istruzione e una profonda conoscenza della terminologia educativa, garantendo una comprensione dettagliata durante gli eventi.",
            listItem2Title:"Interpretariato su misura: ",
            listItem2Para:"Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi educativi, inclusi convegni, corsi di formazione e workshop didattici.",
            listItem3Title:"Attrezzature tecniche: ",
            listItem3Para:"Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per assicurare una comunicazione ottimale durante il vostro evento educativo.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title:"",
            listItem5Para:"",
            para2:"Con i nostri servizi di interpretariato per l'istruzione, potete promuovere lo scambio di conoscenze e facilitare la collaborazione internazionale nel campo dell'educazione, contribuendo alla crescita e allo sviluppo dell'istruzione a livello globale.",
            para2Pdf:"Con i nostri servizi di interpretariato per l'istruzione, potete promuovere lo scambio di conoscenze e facilitare la collaborazione internazionale nel campo dell'educazione, contribuendo alla crescita e allo sviluppo dell'istruzione a livello globale."

        },
        cover: '/clients/Education/covereducation.webp',
        coverPdf: '/clients/Education/covereducation1.png',
        sector:'/clients/Education/education.webp',
        sectorPdf:'/clients/Education/education.png'
    },
    'Consumer Products':{
        en:{
            title: "Interpreting Services for the Consumer Products Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services in the consumer goods sector. Our experienced interpreters enable clear and accurate communication during presentations, trade shows, and meetings in the field of consumer products.",
            listItem1Title: "Specialized Interpreters: ",
            listItem1Para: "We provide professional interpreters with extensive knowledge of consumer products and market trends, ensuring detailed understanding during events.",
            listItem2Title: "Customized Interpretation: ",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the consumer goods sector, including product launches, training sessions, and customer meetings.",
            listItem3Title: "Audiovisual Equipment: ",
            listItem3Para: "We provide advanced audiovisual equipment, such as simultaneous translation devices and microphone systems, to ensure optimal communication during your event.",
            listItem4Title: "Specialized Technical Team: ",
            listItem4Para: "Our professional technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your event in the consumer goods sector.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for consumer products, you can effectively promote your products globally, ensuring clear and professional communication with your international clients and partners."
            },
        it:{
            title:`Servizi di interpretariato per il settore dei prodotti di consumo: il partner multilingue ideale`,
            para1:`Congressi Internazionali offre servizi di interpretariato specializzati nel settore dei beni di consumo. I nostri interpreti esperti consentono una comunicazione chiara e accurata nel corso di presentazioni, fiere e incontri nel campo dei prodotti di consumo.: `,
            listItem1Title:`Interpreti specializzati: `,
            listItem1Para:`Mettiamo a disposizione interpreti professionisti con una vasta conoscenza dei prodotti di consumo e delle tendenze di mercato, garantendo una comprensione dettagliata durante gli eventi.`,
            listItem2Title:`Interpretariato su misura: `,
            listItem2Para:`Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore dei beni di consumo, inclusi lanci di prodotti, sessioni di formazione e incontri con i clienti.`,
            listItem3Title:`Attrezzature audiovisive: `,
            listItem3Para:`Forniamo attrezzature audiovisive avanzate, come apparecchiature di traduzione simultanea e sistemi di microfonia, per garantire una comunicazione ottimale durante il vostro evento.`,
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,            listItem5Title:``,
            listItem5Para:``,
            para2:`Con i nostri servizi di interpretariato per i prodotti di consumo, potete promuovere i vostri prodotti in modo efficace e coinvolgente a livello globale, garantendo una comunicazione chiara e professionale con i vostri clienti e partner internazionali.`,
            para2Pdf:`Con i nostri servizi di interpretariato per i prodotti di consumo, potete promuovere i vostri prodotti in modo efficace e coinvolgente a livello globale, garantendo una comunicazione chiara e professionale con i vostri clienti e partner internazionali.`

        },
        cover: '/clients/ConsumerProducts/coverconsumerproducts.webp',
        coverPdf: '/clients/ConsumerProducts/coverconsumerproducts1.png',
        sector:'/clients/ConsumerProducts/consumerProducts.webp',
        sectorPdf:'/clients/ConsumerProducts/consumerProducts.png'
    },
    'Energy':{
        en:{
            title: "Interpreting Services for the Energy Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services in the energy sector. Our highly qualified interpreters enable clear and accurate communication during conferences, meetings, and events in the energy industry.",
            listItem1Title: "Specialized Interpreters: ",
            listItem1Para: "We provide interpreters with extensive experience in the energy sector and a deep understanding of technical terminology, ensuring precise comprehension of energy concepts.",
            listItem2Title: "Customized Interpretation: ",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the energy sector, including conferences, workshops, and technical meetings.",
            listItem3Title: "Technical Equipment: ",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your energy-related event.",
            listItem4Title: "Professional Technical Support: ",
            listItem4Para: "Our highly qualified technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your event in the energy sector.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for the energy sector, you can promote knowledge exchange and facilitate international collaboration in the energy industry, contributing to the transition to more sustainable and efficient energy sources.",
            para2Pdf: "With our interpretation services for the energy sector, you can promote knowledge exchange and facilitate international collaboration in the energy industry, contributing to the transition to more sustainable and efficient energy sources."
        },
        it:{
            title:"Servizi di interpretariato per il settore dell'energia: il partner multilingue ideale",
            para1:"Congressi Internazionali offre servizi di interpretariato specializzati nel settore dell'energia. I nostri interpreti altamente qualificati consentono una comunicazione chiara e accurata nel corso di conferenze, riunioni e eventi nel settore energetico.",
            listItem1Title:"Interpreti specializzati: ",
            listItem1Para:"Mettiamo a disposizione interpreti con una vasta esperienza nel settore dell'energia e una profonda conoscenza della terminologia tecnica, garantendo una comprensione precisa dei concetti energetici.",
            listItem2Title:"Interpretariato su misura: ",
            listItem2Para:"Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore energetico, inclusi convegni, workshop e incontri tecnici.",
            listItem3Title:"Attrezzature tecniche: ",
            listItem3Para:"Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per assicurare una comunicazione ottimale durante il vostro evento energetico.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title:"",
            listItem5Para:"",
            para2:"Con i nostri servizi di interpretariato per l'energia, potete promuovere lo scambio di conoscenze e facilitare la collaborazione internazionale nel settore energetico, contribuendo alla transizione verso fonti di energia più sostenibili e efficienti.",
            para2Pdf:"Con i nostri servizi di interpretariato per l'energia, potete promuovere lo scambio di conoscenze e facilitare la collaborazione internazionale nel settore energetico, contribuendo alla transizione verso fonti di energia più sostenibili e efficienti."
        },
        cover: '/clients/Energy/coverenergy.webp',
        coverPdf: '/clients/Energy/coverenergy1.png',
        sector:'/clients/Energy/energy.webp',
        sectorPdf:'/clients/Energy/energy.png'
        
    },
    'Environmental':{
        en:{
            title: "Interpreting Services for the Environmental Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services in the environmental sector. Our professional interpreters enable effective and accurate communication during conferences, meetings, and events in the field of environmental sustainability.",
            listItem1Title: "Specialized Interpreters: ",
            listItem1Para: "We provide interpreters with extensive experience in the environmental sector and a deep understanding of specific terminology, ensuring a detailed comprehension of environmental topics.",
            listItem2Title: "Customized Interpretation: ",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the environmental sector, including international summits, conferences, and sustainability workshops.",
            listItem3Title: "Technical Equipment: ",
            listItem3Para: "We supply high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your environmental event.",
            listItem4Title: "Professional Technical Support: ",
            listItem4Para: "Our highly skilled technical team handles the installation and management of equipment, ensuring everything runs smoothly during your environmental event.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our environmental interpretation services, you can promote awareness and action on sustainability globally, contributing to the protection and preservation of our planet.",
            para2Pdf: "With our environmental interpretation services, you can promote awareness and action on sustainability globally, contributing to the protection and preservation of our planet."
        },
        it:{
            title:"Servizi di interpretariato per il settore dell'ambiente: il partner multilingue ideale",
            para1:"Congressi Internazionali offre servizi di interpretariato specializzati nel settore ambientale. I nostri interpreti professionisti consentono una comunicazione efficace e accurata durante conferenze, riunioni e eventi nel campo della sostenibilità ambientale.",
            listItem1Title:"Interpreti specializzati: ",
            listItem1Para:"Mettiamo a disposizione interpreti con una vasta esperienza nel settore ambientale e una profonda conoscenza della terminologia specifica, garantendo una comprensione dettagliata dei temi ambientali.",
            listItem2Title:"Interpretariato su misura: ",
            listItem2Para:"Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore ambientale, inclusi summit internazionali, conferenze e workshop sulla sostenibilità.",
            listItem3Title:"Attrezzature tecniche: ",
            listItem3Para:"Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per assicurare una comunicazione ottimale durante il vostro evento ambientale.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title:"",
            listItem5Para:"",
            para2:"Con i nostri servizi di interpretariato per l'ambiente, potete promuovere la consapevolezza e l'azione in materia di sostenibilità a livello globale, contribuendo alla protezione e alla salvaguardia del nostro pianeta.",
            para2Pdf:"Con i nostri servizi di interpretariato per l'ambiente, potete promuovere la consapevolezza e l'azione in materia di sostenibilità a livello globale, contribuendo alla protezione e alla salvaguardia del nostro pianeta."
        },
        cover: '/clients/Environmental/coverenvironmental.webp',
        coverPdf: '/clients/Environmental/coverenvironmental1.png',
        sector:'/clients/Environmental/environmental.webp',
        sectorPdf:'/clients/Environmental/environmental.png'
    },
    'Engineering':{
        en:{
            title: "Interpreting Services for the Engineering Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services in the field of engineering. Our professional interpreters enable effective and accurate communication during conferences, meetings, and workshops in the engineering field.",
            listItem1Title: "Specialized Interpreters: ",
            listItem1Para: "We provide interpreters with extensive experience in the field of engineering and a solid knowledge of technical terminology, ensuring a detailed understanding of engineering concepts.",
            listItem2Title: "Customized Interpretation: ",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your engineering events, including conferences, presentations, and technical meetings.",
            listItem3Title: "Technical Equipment: ",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your engineering event.",
            listItem4Title: "Professional Technical Support: ",
            listItem4Para: "Our experienced technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your engineering event.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for engineering, you can promote innovation and international collaboration in the engineering sector, contributing to the realization of advanced and sustainable technological projects.",
            para2Pdf: "With our interpretation services for engineering, you can promote innovation and international collaboration in the engineering sector, contributing to the realization of advanced and sustainable technological projects."
        },
        it:{
            title:"Servizi di interpretariato per il settore dell'ingegneria: il partner multilingue ideale",
            para1:"Congressi Internazionali offre servizi di interpretariato specializzati nel settore dell'ingegneria. I nostri interpreti professionisti consentono una comunicazione efficace e accurata durante conferenze, riunioni e workshop nel campo dell'ingegneria.",
            listItem1Title:"Interpreti specializzati: ",
            listItem1Para:"Mettiamo a disposizione interpreti con una vasta esperienza nel settore dell'ingegneria e una solida conoscenza della terminologia tecnica, garantendo una comprensione dettagliata dei concetti ingegneristici.",
            listItem2Title:"Interpretariato su misura: ",
            listItem2Para:"Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi ingegneristici, inclusi convegni, presentazioni e incontri tecnici.",
            listItem3Title:"Attrezzature tecniche: ",
            listItem3Para:"Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per assicurare una comunicazione ottimale durante il vostro evento di ingegneria.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title:"",
            listItem5Para:"",
            para2:"Con i nostri servizi di interpretariato per l'ingegneria, potete promuovere l'innovazione e la collaborazione internazionale nel settore ingegneristico, contribuendo alla realizzazione di progetti tecnologici avanzati e sostenibili.",
            para2Pdf:"Con i nostri servizi di interpretariato per l'ingegneria, potete promuovere l'innovazione e la collaborazione internazionale nel settore ingegneristico, contribuendo alla realizzazione di progetti tecnologici avanzati e sostenibili."
        },
        cover: '/clients/Engineering/coverengineering.webp',
        coverPdf: '/clients/Engineering/coverengineering1.png',
        sector:'/clients/Engineering/Engineering.webp',
        sectorPdf:'/clients/Engineering/Engineering.png'
    
    },
    'Event Organiser':{
        en:{
            title: "Interpreting Services for the Event Organiser Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services for event organizers. Our highly qualified interpreters enable clear and accurate communication during trade shows, conferences, and organizational meetings.",
            listItem1Title: "Specialized Interpreters: ",
            listItem1Para: "We provide professional interpreters with experience in the event organizing sector, ensuring precise and detailed understanding of organizational needs.",
            listItem2Title: "Customized Interpretation: ",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific requirements of your events, such as corporate meetings, product launches, and press conferences.",
            listItem3Title: "Technical Equipment: ",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your event.",
            listItem4Title: "Professional Technical Support: ",
            listItem4Para: "Our experienced technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your event.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for event organizers, you can facilitate communication and planning for successful events internationally.",
            para2Pdf: "With our interpretation services for event organizers, you can facilitate communication and planning for successful events internationally."
        },
        it:{
            title:"Servizi di interpretariato per il settore degli organizzatori di eventi: il partner multilingue ideale",
            para1:"Congressi Internazionali offre servizi di interpretariato specializzati per gli organizzatori di eventi. I nostri interpreti altamente qualificati consentono una comunicazione chiara e accurata nel corso di fiere, conferenze, e incontri organizzativi.",
            listItem1Title:"Interpreti specializzati: ",
            listItem1Para:"Mettiamo a disposizione interpreti professionisti con esperienza nel settore dell'organizzazione eventi, garantendo una comprensione precisa e dettagliata delle necessità organizzative.",
            listItem2Title:"Interpretariato su misura: ",
            listItem2Para:"Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi, come meeting aziendali, lanci di prodotti, e conferenze stampa.",
            listItem3Title:"Attrezzature tecniche: ",
            listItem3Para:"Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title:"",
            listItem5Para:"",
            para2:"Con i nostri servizi di interpretariato per gli organizzatori di eventi, potete facilitare la comunicazione e la pianificazione di eventi di successo a livello internazionale.",
            para2Pdf:"Con i nostri servizi di interpretariato per gli organizzatori di eventi, potete facilitare la comunicazione e la pianificazione di eventi di successo a livello internazionale."
        },
        cover: '/clients/EventOrganiser/covereventorganiser.webp',
        coverPdf: '/clients/EventOrganiser/covereventorganiser1.png',
        sector:'/clients/EventOrganiser/eventOrganiser.webp',
        sectorPdf:'/clients/EventOrganiser/eventOrganiser.png'
    },
    'Food and Beverage':{
        en:{
            title: "Interpreting Services for the Food and Beverage Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services in the food and beverage sector. Our professional interpreters enable clear and accurate communication during fairs, conferences, and meetings in the food & beverage world.",
            listItem1Title: "Specialized Interpreters: ",
            listItem1Para: "We provide interpreters with extensive experience in the food and beverage sector and a solid understanding of specific terminology, ensuring a detailed understanding of products and processes.",
            listItem2Title: "Customized Interpretation: ",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the food & beverage sector, including gastronomic festivals, product presentations, and sectoral conferences.",
            listItem3Title: "Technical Equipment: ",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your event.",
            listItem4Title: "Professional Technical Support: ",
            listItem4Para: "Our experienced technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your event in the food & beverage sector.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for the food and beverage sector, you can promote your products and culinary culture internationally, facilitating communication with customers, suppliers, and industry operators.",
            para2Pdf: "With our interpretation services for the food and beverage sector, you can promote your products and culinary culture internationally, facilitating communication with customers, suppliers, and industry operators."
        },
        it:{
            title:"Servizi di interpretariato per il settore alimentare e delle bevande: il partner multilingue ideale",
            para1:"Congressi Internazionali offre servizi di interpretariato specializzati nel settore alimentare e delle bevande. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di fiere, conferenze e incontri nel mondo del food & beverage.",
            listItem1Title:"Interpreti specializzati: ",
            listItem1Para:"Mettiamo a disposizione interpreti con una vasta esperienza nel settore alimentare e delle bevande e una solida conoscenza della terminologia specifica, garantendo una comprensione dettagliata dei prodotti e dei processi.",
            listItem2Title:"Interpretariato su misura: ",
            listItem2Para:"Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore food & beverage, inclusi festival gastronomici, presentazioni di prodotti e conferenze settoriali.",
            listItem3Title:"Attrezzature tecniche: ",
            listItem3Para:"Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per assicurare una comunicazione ottimale durante il vostro evento.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title:"",
            listItem5Para:"",
            para2:"Con i nostri servizi di interpretariato per il settore alimentare e delle bevande, potete promuovere i vostri prodotti e la vostra cultura culinaria a livello internazionale, facilitando la comunicazione con clienti, fornitori e operatori del settore.",
            para2Pdf:"Con i nostri servizi di interpretariato per il settore alimentare e delle bevande, potete promuovere i vostri prodotti e la vostra cultura culinaria a livello internazionale, facilitando la comunicazione con clienti, fornitori e operatori del settore."
        },
        cover: '/clients/Food/coverfood.webp',
        coverPdf: '/clients/Food/coverfood1.png',
        sector:'/clients/Food/food.webp',
        sectorPdf:'/clients/Food/food.png'     
    },
    'Games/Apps':{
        en:{
            title: "Interpreting Services for the Gaming/Applications Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services for the gaming and applications sector. Our highly qualified interpreters enable clear and accurate communication during conferences, trade shows, and presentations in the world of digital gaming and applications.",
            listItem1Title: "Specialized Interpreters: ",
            listItem1Para: "We provide interpreters with extensive experience in the gaming and applications sector, ensuring precise and detailed understanding of technical terminology and industry trends.",
            listItem2Title: "Customized Interpretation: ",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the gaming and applications sector, including product launches, press conferences, and promotional events.",
            listItem3Title: "Technical Equipment: ",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your event in the gaming and applications sector.",
            listItem4Title: "Professional Technical Support: ",
            listItem4Para: "Our experienced technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your event in the gaming and applications sector.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for the gaming and applications sector, you can promote your creations and establish international connections, facilitating communication with developers, publishers, and enthusiasts worldwide.",
            para2Pdf: "With our interpretation services for the gaming and applications sector, you can promote your creations and establish international connections, facilitating communication with developers, publishers, and enthusiasts worldwide."
        },
        it:{
            title:"Servizi di interpretariato per il settore dei giochi/applicazioni: il partner multilingue ideale",
            para1:"Congressi Internazionali offre servizi di interpretariato specializzati per il settore dei giochi e delle applicazioni. I nostri interpreti altamente qualificati consentono una comunicazione chiara e accurata nel corso di conferenze, fiere e presentazioni nel mondo dei giochi digitali e delle applicazioni.",
            listItem1Title:"Interpreti specializzati: ",
            listItem1Para:"Mettiamo a disposizione interpreti con una vasta esperienza nel settore dei giochi e delle applicazioni, garantendo una comprensione precisa e dettagliata della terminologia tecnica e delle tendenze del settore.",
            listItem2Title:"Interpretariato su misura: ",
            listItem2Para:"Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore dei giochi e delle applicazioni, inclusi lanci di prodotti, conferenze stampa e eventi promozionali.",
            listItem3Title:"Attrezzature tecniche: ",
            listItem3Para:"Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore dei giochi e delle applicazioni.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title:"",
            listItem5Para:"",
            para2:"Con i nostri servizi di interpretariato per il settore dei giochi e delle applicazioni, potete promuovere le vostre creazioni e stabilire connessioni internazionali, facilitando la comunicazione con sviluppatori, editori e appassionati di tutto il mondo.",
            para2Pdf:"Con i nostri servizi di interpretariato per il settore dei giochi e delle applicazioni, potete promuovere le vostre creazioni e stabilire connessioni internazionali, facilitando la comunicazione con sviluppatori, editori e appassionati di tutto il mondo."
        },
        cover: '/clients/Games/covergamesapps.webp',
        coverPdf: '/clients/Games/covergamesapps1.png',
        sector:'/clients/Games/gamesApps.webp',
        sectorPdf:'/clients/Games/gamesApps.png'
    },
    'Legal':{
        en:{
            title: "Interpreting Services for the Legal Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services for the legal sector. Our professional interpreters enable clear and accurate communication during hearings, legal negotiations, and meetings in the field of law.",
            listItem1Title: "Specialized Interpreters: ",
            listItem1Para: "We provide interpreters with extensive experience in the legal sector and a deep understanding of legal terminology, ensuring detailed comprehension of legal issues.",
            listItem2Title: "Customized Interpretation: ",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the legal sector, including trials, arbitrations, and legal conferences.",
            listItem3Title: "Technical Equipment: ",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your legal event.",
            listItem4Title: "Professional Technical Support: ",
            listItem4Para: "Our experienced technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your legal event.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for the legal sector, you can facilitate communication between legal professionals, clients, and involved parties, contributing to the success of your legal activities globally.",
            para2Pdf: "With our interpretation services for the legal sector, you can facilitate communication between legal professionals, clients, and involved parties, contributing to the success of your legal activities globally."
        },
        it:{
            title:"Servizi di interpretariato per il settore legale: il partner multilingue ideale",
            para1:"Congressi Internazionali offre servizi di interpretariato specializzati per il settore legale. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di udienze, negoziati legali e incontri nel campo del diritto.",
            listItem1Title:"Interpreti specializzati: ",
            listItem1Para:"Mettiamo a disposizione interpreti con una vasta esperienza nel settore legale e una profonda conoscenza della terminologia giuridica, garantendo una comprensione dettagliata delle questioni legali.",
            listItem2Title:"Interpretariato su misura: ",
            listItem2Para:"Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore legale, inclusi processi, arbitrati e conferenze giuridiche.",
            listItem3Title:"Attrezzature tecniche: ",
            listItem3Para:"Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore legale.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title:"",
            listItem5Para:"",
            para2:"Con i nostri servizi di interpretariato per il settore legale, potete facilitare la comunicazione tra professionisti del diritto, clienti e parti coinvolte, contribuendo al successo delle vostre attività legali a livello globale.",
            para2Pdf:"Con i nostri servizi di interpretariato per il settore legale, potete facilitare la comunicazione tra professionisti del diritto, clienti e parti coinvolte, contribuendo al successo delle vostre attività legali a livello globale."
        },
        cover: '/clients/Legal/coverlegal.webp',
        coverPdf: '/clients/Legal/coverlegal1.png',
        sector:'/clients/Legal/legal.webp',
        sectorPdf:'/clients/Legal/legal.png'
    },
    'Manufacturing':{
        en:{
            title: "Interpreting Services for the Manufacturing Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services for the manufacturing sector. Our professional interpreters enable clear and accurate communication during meetings, presentations, and exhibitions in the field of industrial production and manufacturing.",
            listItem1Title: "Specialized Interpreters: ",
            listItem1Para: "We provide interpreters with extensive experience in the manufacturing sector and a solid understanding of technical terminology, ensuring detailed comprehension of production processes and industrial technologies.",
            listItem2Title: "Customized Interpretation: ",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the manufacturing sector, including company meetings, product presentations, and factory visits.",
            listItem3Title: "Technical Equipment: ",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your event in the manufacturing sector.",
            listItem4Title: "Professional Technical Support: ",
            listItem4Para: "Our experienced technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your event in the manufacturing sector.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for the manufacturing sector, you can promote innovation and excellence in industrial production, facilitating communication among industry experts, suppliers, and customers from around the world."
        },
        it:{
            title:"Servizi di interpretariato per il settore manifatturiero: il partner multilingue ideale",
            para1:"Congressi Internazionali offre servizi di interpretariato specializzati per il settore manifatturiero. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di riunioni, presentazioni e fiere nel campo della produzione industriale e della manifattura.",
            listItem1Title:"Interpreti specializzati: ",
            listItem1Para:"Mettiamo a disposizione interpreti con una vasta esperienza nel settore manifatturiero e una solida conoscenza della terminologia tecnica, garantendo una comprensione dettagliata dei processi di produzione e delle tecnologie industriali.",
            listItem2Title:"Interpretariato su misura: ",
            listItem2Para:"Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore manifatturiero, inclusi incontri aziendali, presentazioni di prodotti e visite alle fabbriche.",
            listItem3Title:"Attrezzature tecniche: ",
            listItem3Para:"Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore manifatturiero.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title:"",
            listItem5Para:"",
            para2:"Con i nostri servizi di interpretariato per il settore manifatturiero, potete promuovere l'innovazione e l'eccellenza nella produzione industriale, facilitando la comunicazione tra esperti del settore, fornitori e clienti provenienti da tutto il mondo."
        },
        cover: '/clients/Manufacturing/covermanufacturing.webp',
        coverPdf: '/clients/Manufacturing/covermanufacturing1.png',
        sector:'/clients/Manufacturing/Manufacturing.webp',
        sectorPdf:'/clients/Manufacturing/Manufacturing.png'
    },
    'Entertainment Media':{
        en:{
            title: "Interpreting Services for the Entertainment Media Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services in the media entertainment sector. Our highly qualified interpreters enable clear and accurate communication during conferences, events, and meetings within the entertainment industry.",
            listItem1Title: "Specialized Interpreters:",
            listItem1Para: "We provide interpreters with extensive experience in the media entertainment field and a solid understanding of specific terminology, ensuring precise comprehension of content.",
            listItem2Title: "Customized Interpretation:",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the media entertainment sector, including festivals, presentations, and conferences.",
            listItem3Title: "Technical Equipment:",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your event.",
            listItem4Title: "Professional Technical Support:",
            listItem4Para: "Our experienced technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your event in the media entertainment sector.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for media entertainment, you can promote the sharing of ideas and trends internationally, contributing to the growth and success of the entertainment industry.",
            para2Pdf: "With our interpretation services for media entertainment, you can promote the sharing of ideas and trends internationally, contributing to the growth and success of the entertainment industry."
        },
        it:{
            title: "Servizi di interpretariato per il settore dell'intrattenimento mediatico: il partner multilingue ideale",
            para1: "Congressi Internazionali offre servizi di interpretariato specializzati nel settore dell'intrattenimento mediatico. I nostri interpreti altamente qualificati consentono una comunicazione chiara e accurata nel corso di conferenze, eventi e incontri nell'ambito dell'industria dell'intrattenimento.",
            listItem1Title: "Interpreti specializzati:",
            listItem1Para: "Mettiamo a disposizione interpreti con una vasta esperienza nel campo dell'intrattenimento mediatico e una solida conoscenza della terminologia specifica, garantendo una comprensione precisa dei contenuti.",
            listItem2Title: "Interpretariato su misura:",
            listItem2Para: "Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore dell'intrattenimento mediatico, inclusi festival, presentazioni e conferenze.",
            listItem3Title: "Attrezzature tecniche:",
            listItem3Para: "Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per assicurare una comunicazione ottimale durante il vostro evento.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title: "",
            listItem5Para: "",
            para2: "Con i nostri servizi di interpretariato per l'intrattenimento mediatico, potete promuovere la condivisione di idee e tendenze a livello internazionale, contribuendo alla crescita e al successo dell'industria dell'intrattenimento.",
            para2Pdf:"Con i nostri servizi di interpretariato per l'intrattenimento mediatico, potete promuovere la condivisione di idee e tendenze a livello internazionale, contribuendo alla crescita e al successo dell'industria dell'intrattenimento."
            },
        cover: '/clients/Entertainment/coverentertainment.webp',
        coverPdf: '/clients/Entertainment/coverentertainment1.png',
        sector:'/clients/Entertainment/Entertainment.webp',
        sectorPdf:'/clients/Entertainment/Entertainment.png'
    
    },
    'Human Resources':{
        en:{
            title: "Interpreting Services for HR Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpreting services for the human resources sector. Our professional interpreters enable effective and accurate communication during conferences, workshops, and meetings in the field of human resources and personnel management.",
            listItem1Title: "Specialized Interpreters:",
            listItem1Para: "We provide interpreters with extensive experience in the human resources sector and a deep understanding of specific terminology, ensuring a detailed comprehension of personnel management processes.",
            listItem2Title: "Customized Interpretation:",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the human resources sector, including training courses, seminars, and company meetings.",
            listItem3Title: "Technical Equipment:",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your event in the human resources sector.",
            listItem4Title: "Professional Technical Support:",
            listItem4Para: "Our experienced technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your event in the human resources sector.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpreting services for human resources, you can promote collaboration and personnel development internationally, facilitating communication with human resources professionals, managers, and employees from diverse cultural and business backgrounds.",
            para2Pdf: "With our interpreting services for human resources, you can promote collaboration and personnel development internationally, facilitating communication with human resources professionals, managers, and employees from diverse cultural and business backgrounds."
        },
        it:{
            title: "servizi di interpretariato per il settore delle risorse umane: il partner multilingue ideale",
            para1: "Congressi Internazionali offre servizi di interpretariato specializzati per il settore delle risorse umane. I nostri interpreti professionisti consentono una comunicazione efficace e accurata durante conferenze, workshop e riunioni nel campo delle risorse umane e della gestione del personale.",
            listItem1Title: "Interpreti specializzati:",
            listItem1Para: "Mettiamo a disposizione interpreti con una vasta esperienza nel settore delle risorse umane e una profonda conoscenza della terminologia specifica, garantendo una comprensione dettagliata dei processi di gestione del personale.",
            listItem2Title: "Interpretariato su misura:",
            listItem2Para: "Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore delle risorse umane, inclusi corsi di formazione, seminari e incontri aziendali.",
            listItem3Title: "Attrezzature tecniche:",
            listItem3Para: "Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore delle risorse umane.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
          listItem5Title: "",
            listItem5Para: "",
            para2: "Con i nostri servizi di interpretariato per le risorse umane, potete promuovere la collaborazione e lo sviluppo del personale a livello internazionale, facilitando la comunicazione con professionisti delle risorse umane, manager e dipendenti provenienti da diverse culture e contesti aziendali.",
            para2Pdf:"Con i nostri servizi di interpretariato per le risorse umane, potete promuovere la collaborazione e lo sviluppo del personale a livello internazionale, facilitando la comunicazione con professionisti delle risorse umane, manager e dipendenti provenienti da diverse culture e contesti aziendali."
        },
        cover: '/clients/HR/coverHR.webp',
        coverPdf: '/clients/HR/coverHR1.png',
        sector:'/clients/HR/HR.webp',
        sectorPdf:'/clients/HR/HR.png' 
    },
    'Patents':{
        en:{
            title: "Interpreting Services for Intellectual Property: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services for the patent sector. Our professional interpreters enable clear and accurate communication during conferences, workshops, and meetings in the field of intellectual property and patent protection.",
            listItem1Title: "Specialized Interpreters:",
            listItem1Para: "We provide interpreters with extensive experience in the patent sector and a solid understanding of legal terminology, ensuring a detailed understanding of registration procedures and intellectual property regulations.",
            listItem2Title: "Customized Interpretation:",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the patent sector, including debates on regulations, technical presentations, and joint meetings with legal experts.",
            listItem3Title: "Technical Equipment:",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your event in the patent sector.",
            listItem4Title: "Professional Technical Support:",
            listItem4Para: "Our experienced technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your event in the patent sector.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for the patent sector, you can protect and enhance your intellectual property internationally, facilitating communication between inventors, lawyers, and competent authorities.",
            para2Pdf: "With our interpretation services for the patent sector, you can protect and enhance your intellectual property internationally, facilitating communication between inventors, lawyers, and competent authorities."
        },
        it:{
            title: "Servizi di interpretariato per brevetti: il partner multilingue ideale",
            para1: "Congressi Internazionali offre servizi di interpretariato specializzati per il settore dei brevetti. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di conferenze, workshop e incontri nel campo della proprietà intellettuale e della tutela dei brevetti.",
            listItem1Title: "Interpreti specializzati:",
            listItem1Para: "Mettiamo a disposizione interpreti con una vasta esperienza nel settore dei brevetti e una solida conoscenza della terminologia legale, garantendo una comprensione dettagliata delle procedure di registrazione e delle normative sulla proprietà intellettuale.",
            listItem2Title: "Interpretariato su misura:",
            listItem2Para: "Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore dei brevetti, inclusi dibattiti su normative, presentazioni tecniche e incontri congiunti con esperti legali.",
            listItem3Title: "Attrezzature tecniche:",
            listItem3Para: "Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore dei brevetti.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title: "",
            listItem5Para: "",
            para2: "Con i nostri servizi di interpretariato per il settore dei brevetti, potete proteggere e valorizzare la vostra proprietà intellettuale a livello internazionale, facilitando la comunicazione tra inventori, avvocati e autorità competenti.",
            para2Pdf:"Con i nostri servizi di interpretariato per il settore dei brevetti, potete proteggere e valorizzare la vostra proprietà intellettuale a livello internazionale, facilitando la comunicazione tra inventori, avvocati e autorità competenti."
        },
        cover: '/clients/Patents/coverpatent.webp',
        coverPdf: '/clients/Patents/coverpatent1.png',
        sector:'/clients/Patents/patent.webp',
        sectorPdf:'/clients/Patents/patent.png'
    },
    'Marketing':{
        en:{
            title: "Interpreting Services for Marketing Sector: Your Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services for the marketing sector. Our professional interpreters enable clear and accurate communication during conferences, presentations, and fairs in the field of corporate promotion and marketing.",
            listItem1Title: "Specialized Interpreters:",
            listItem1Para: "We provide interpreters with extensive experience in the marketing sector and a deep understanding of specific terminology, ensuring a detailed comprehension of communication strategies and marketing techniques.",
            listItem2Title: "Customized Interpretation:",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your marketing events, including product launches, company presentations, and training workshops.",
            listItem3Title: "Technical Equipment:",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your marketing event.",
            listItem4Title: "Professional Technical Support:",
            listItem4Para: "Our experienced technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your marketing event.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for the marketing sector, you can promote your brand and reach new markets internationally, facilitating communication between marketing experts, clients, and business partners.",
            para2Pdf:"With our interpretation services for the marketing sector, you can promote your brand and reach new markets internationally, facilitating communication between marketing experts, clients, and business partners."
        },
        it:{
            title: "Servizi di interpretariato per marketing: il partner ideale",
            para1: "Congressi Internazionali offre servizi di interpretariato specializzati per il settore del marketing. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di conferenze, presentazioni e fiere nel campo della promozione aziendale e del marketing.",
            listItem1Title: "Interpreti specializzati:",
            listItem1Para: "Mettiamo a disposizione interpreti con una vasta esperienza nel settore del marketing e una profonda conoscenza della terminologia specifica, garantendo una comprensione dettagliata delle strategie di comunicazione e delle tecniche di marketing.",
            listItem2Title: "Interpretariato su misura:",
            listItem2Para: "Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore del marketing, inclusi lanci di prodotti, presentazioni aziendali e workshop di formazione.",
            listItem3Title: "Attrezzature tecniche:",
            listItem3Para: "Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore del marketing.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title: "",
            listItem5Para: "",
            para2: "Con i nostri servizi di interpretariato per il settore del marketing, potete promuovere la vostra marca e raggiungere nuovi mercati a livello internazionale, facilitando la comunicazione tra esperti di marketing, clienti e partner commerciali.",
            para2Pdf:"Con i nostri servizi di interpretariato per il settore del marketing, potete promuovere la vostra marca e raggiungere nuovi mercati a livello internazionale, facilitando la comunicazione tra esperti di marketing, clienti e partner commerciali."
       },
        cover: '/clients/Marketing/covermarketing.webp',
        coverPdf: '/clients/Marketing/covermarketing1.png',
        sector:'/clients/Marketing/Marketing.webp',
        sectorPdf:'/clients/Marketing/Marketing.png'
    },
    'Literature':{
        en:{
            title: "Interpreting Services for Literature: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services for the literature sector. Our professional interpreters enable clear and accurate communication during book presentations, literary festivals, and meetings with authors and literary critics.",
            listItem1Title: "Specialized Interpreters:",
            listItem1Para: "We provide interpreters with extensive experience in the literature sector and a deep knowledge of literary terminology, ensuring a detailed understanding of literary themes and styles.",
            listItem2Title: "Customized Interpretation:",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the literature sector, including debates, readings, and presentations of literary works.",
            listItem3Title: "Technical Equipment:",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your event in the literature sector.",
            listItem4Title: "Professional Technical Support:",
            listItem4Para: "Our experienced technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your event in the literature sector.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for the literature sector, you can promote literary culture and facilitate communication among authors, publishers, and book enthusiasts from diverse cultures and languages.",
            para2Pdf: "With our interpretation services for the literature sector, you can promote literary culture and facilitate communication among authors, publishers, and book enthusiasts from diverse cultures and languages."
        },
        it:{
            title: "Servizi di Interpretariato per letteratura: il partner multilingue ideale",
            para1: "Congressi Internazionali offre servizi di interpretariato specializzati per il settore della letteratura. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di presentazioni di libri, festival letterari e incontri con autori e critici letterari.",
            listItem1Title: "Interpreti specializzati:",
            listItem1Para: "Mettiamo a disposizione interpreti con una vasta esperienza nel settore della letteratura e una profonda conoscenza della terminologia letteraria, garantendo una comprensione dettagliata dei temi e degli stili letterari.",
            listItem2Title: "Interpretariato su misura:",
            listItem2Para: "Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore della letteratura, inclusi dibattiti, letture e presentazioni di opere letterarie.",
            listItem3Title: "Attrezzature tecniche:",
            listItem3Para: "Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore della letteratura.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title: "",
            listItem5Para: "",
            para2: "Con i nostri servizi di interpretariato per il settore della letteratura, potete promuovere la cultura letteraria e facilitare la comunicazione tra autori, editori e appassionati di libri provenienti da diverse culture e lingue.",
            para2Pdf:"Con i nostri servizi di interpretariato per il settore della letteratura, potete promuovere la cultura letteraria e facilitare la comunicazione tra autori, editori e appassionati di libri provenienti da diverse culture e lingue."
         },
        cover: '/clients/Literature/coverliterature.webp',
        coverPdf: '/clients/Literature/coverliterature1.png',
        sector:'/clients/Literature/literature.webp',
        sectorPdf:'/clients/Literature/literature.png' 
    },
    'Internet':{
        en:{
            title: "Interpreting Services for Internet Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services for the Internet sector. Our highly qualified interpreters enable clear and accurate communication during conferences, online forums, and meetings in the digital world.",
            listItem1Title: "Specialized Interpreters:",
            listItem1Para: "We provide interpreters with extensive experience in the Internet sector and a solid understanding of technical terminology, ensuring precise comprehension of digital concepts.",
            listItem2Title: "Customized Interpretation:",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the Internet sector, including webinars, conferences, and online presentations.",
            listItem3Title: "Technical Equipment:",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your Internet sector event.",
            listItem4Title: "Professional Technical Support:",
            listItem4Para: "Our expert technical team handles the installation and management of equipment, ensuring that everything functions smoothly during your digital event.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for the Internet sector, you can promote innovation and collaboration in the digital world, facilitating communication between technology experts, developers, and end users.",
            para2Pdf: "With our interpretation services for the Internet sector, you can promote innovation and collaboration in the digital world, facilitating communication between technology experts, developers, and end users."
        },
        it:{
            title: "Servizi di interpretariato per internet: il partner multilingue ideale",
            para1: "Congressi Internazionali offre servizi di interpretariato specializzati per il settore Internet. I nostri interpreti altamente qualificati consentono una comunicazione chiara e accurata nel corso di conferenze, forum online e incontri nel mondo digitale.",
            listItem1Title: "Interpreti specializzati:",
            listItem1Para: "Mettiamo a disposizione interpreti con una vasta esperienza nel settore Internet e una solida conoscenza della terminologia tecnica, garantendo una comprensione precisa dei concetti digitali.",
            listItem2Title: "Interpretariato su misura:",
            listItem2Para: "Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore Internet, inclusi webinar, convegni e presentazioni online.",
            listItem3Title: "Attrezzature tecniche:",
            listItem3Para: "Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore Internet.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title: "",
            listItem5Para: "",
            para2: "Con i nostri servizi di interpretariato per il settore Internet, potete promuovere l'innovazione e la collaborazione nel mondo digitale, facilitando la comunicazione tra esperti di tecnologia, sviluppatori e utenti finali.",
            para2Pdf:"Con i nostri servizi di interpretariato per il settore Internet, potete promuovere l'innovazione e la collaborazione nel mondo digitale, facilitando la comunicazione tra esperti di tecnologia, sviluppatori e utenti finali."
        },
        cover: '/clients/Internet/coverinternet.webp',
        coverPdf: '/clients/Internet/coverinternet1.png',
        sector:'/clients/Internet/Internet.webp',
        sectorPdf:'/clients/Internet/Internet.png'
    },
    'IT':{
        en:{
            title: "Interpreting Services for IT Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services for the IT sector. Our professional interpreters enable clear and accurate communication during conferences, seminars, and meetings in the field of computer science and information technology.",
            listItem1Title: "Specialized Interpreters:",
            listItem1Para: "We provide interpreters with extensive experience in the IT sector and a deep understanding of technical terminology, ensuring a detailed understanding of computer concepts.",
            listItem2Title: "Customized Interpretation:",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your IT events, including technical workshops, company presentations, and training courses.",
            listItem3Title: "Technical Equipment:",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your IT event.",
            listItem4Title: "Professional Technical Support:",
            listItem4Para: "Our experienced technical team handles the installation and management of equipment, ensuring that everything functions smoothly during your IT event.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for the IT sector, you can promote innovation and technological development globally, facilitating communication between computer experts, developers, and end users.",
            para2Pdf: "With our interpretation services for the IT sector, you can promote innovation and technological development globally, facilitating communication between computer experts, developers, and end users."
        },
        it:{
            title: "Servizi di interpretariato per il settore IT: il partner multilingue ideale",
            para1: "Congressi Internazionali offre servizi di interpretariato specializzati per il settore IT. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di conferenze, seminari e riunioni nel campo dell'informatica e delle tecnologie dell'informazione.",
            listItem1Title: "Interpreti specializzati:",
            listItem1Para: "Mettiamo a disposizione interpreti con una vasta esperienza nel settore IT e una profonda conoscenza della terminologia tecnica, garantendo una comprensione dettagliata dei concetti informatici.",
            listItem2Title: "Interpretariato su misura:",
            listItem2Para: "Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore IT, inclusi workshop tecnici, presentazioni aziendali e corsi di formazione.",
            listItem3Title: "Attrezzature tecniche:",
            listItem3Para: "Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore IT.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title: "",
            listItem5Para: "",
            para2: "Con i nostri servizi di interpretariato per il settore IT, potete promuovere l'innovazione e lo sviluppo tecnologico a livello globale, facilitando la comunicazione tra esperti di informatica, sviluppatori e utenti finali.",
            para2Pdf:"Con i nostri servizi di interpretariato per il settore IT, potete promuovere l'innovazione e lo sviluppo tecnologico a livello globale, facilitando la comunicazione tra esperti di informatica, sviluppatori e utenti finali."
            },
        cover: '/clients/IT/coverIT.webp',
        coverPdf: '/clients/IT/coverIT1.png',
        sector:'/clients/IT/IT.webp',
        sectorPdf:'/clients/IT/IT.png'
    },
    'Leisure':{
        en:{
            title: "Interpreting Services for the Leisure Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services for the leisure sector. Our professional interpreters enable clear and accurate communication during events, festivals, and meetings in the field of entertainment and recreational activities.",
            listItem1Title: "Specialized Interpreters:",
            listItem1Para: "We provide interpreters with extensive experience in the leisure sector and a deep understanding of specific terminology, ensuring a detailed understanding of entertainment-related themes.",
            listItem2Title: "Customized Interpretation:",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the leisure sector, including concerts, theatrical performances, and amusement fairs.",
            listItem3Title: "Technical Equipment:",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your event in the leisure sector.",
            listItem4Title: "Professional Technical Support:",
            listItem4Para: "Our experienced technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your event in the leisure sector.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for the leisure sector, you can promote entertainment and culture internationally, facilitating communication with artists, organizers, and enthusiasts worldwide.",
            para2Pdf: "With our interpretation services for the leisure sector, you can promote entertainment and culture internationally, facilitating communication with artists, organizers, and enthusiasts worldwide."
        },
        it:{
            title: "Servizi di interpretariato per il settore turistico: il partner multilingue ideale",
            para1: "Congressi Internazionali offre servizi di interpretariato specializzati per il settore del tempo libero. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di eventi, festival e incontri nel campo dell'intrattenimento e delle attività ricreative.",
            listItem1Title: "Interpreti specializzati:",
            listItem1Para: "Mettiamo a disposizione interpreti con una vasta esperienza nel settore del tempo libero e una profonda conoscenza della terminologia specifica, garantendo una comprensione dettagliata dei temi legati all'intrattenimento.",
            listItem2Title: "Interpretariato su misura:",
            listItem2Para: "Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore del tempo libero, inclusi concerti, spettacoli teatrali e fiere del divertimento.",
            listItem3Title: "Attrezzature tecniche:",
            listItem3Para: "Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore del tempo libero.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title: "",
            listItem5Para: "",
            para2: "Con i nostri servizi di interpretariato per il settore del tempo libero, potete promuovere l'intrattenimento e la cultura a livello internazionale, facilitando la comunicazione con artisti, organizzatori e appassionati di tutto il mondo.",
            para2Pdf:"Con i nostri servizi di interpretariato per il settore del tempo libero, potete promuovere l'intrattenimento e la cultura a livello internazionale, facilitando la comunicazione con artisti, organizzatori e appassionati di tutto il mondo."
        },
        cover: '/clients/Leisure/coverleisure.webp',
        coverPdf: '/clients/Leisure/coverleisure1.png',
        sector:'/clients/Leisure/Leisure.webp',
        sectorPdf:'/clients/Leisure/Leisure.png'
    },
    'Sports':{
        en:{
            title: "Interpreting Services for Sports Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services for the sports sector. Our professional interpreters enable clear and accurate communication during sports events, conferences, and meetings in the field of physical activity and wellness.",
            listItem1Title: "Specialized Interpreters:",
            listItem1Para: "We provide interpreters with extensive experience in the sports sector and a deep knowledge of athletic terminology, ensuring a detailed understanding of rules and gameplay strategies.",
            listItem2Title: "Customized Interpretation:",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the sports sector, including international tournaments, training seminars, and conferences on health and sports nutrition.",
            listItem3Title: "Technical Equipment:",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your sports event.",
            listItem4Title: "Professional Technical Support:",
            listItem4Para: "Our experienced technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your sports event.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for the sports sector, you can promote physical activity and wellness globally, facilitating communication among athletes, coaches, and enthusiasts from diverse cultures and traditions.",
            para2Pdf: "With our interpretation services for the sports sector, you can promote physical activity and wellness globally, facilitating communication among athletes, coaches, and enthusiasts from diverse cultures and traditions."
        },
        it:{
            title: "Servizi di interpretariato per il settore sport: il partner multilingue ideale",
            para1: "Congressi Internazionali offre servizi di interpretariato specializzati per il settore dello sport. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di eventi sportivi, conferenze e incontri nel campo dell'attività fisica e del benessere.",
            listItem1Title: "Interpreti specializzati:",
            listItem1Para: "Mettiamo a disposizione interpreti con una vasta esperienza nel settore dello sport e una profonda conoscenza della terminologia atletica, garantendo una comprensione dettagliata delle regole e delle strategie di gioco.",
            listItem2Title: "Interpretariato su misura:",
            listItem2Para: "Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore dello sport, inclusi tornei internazionali, seminari di formazione e conferenze sulla salute e la nutrizione sportiva.",
            listItem3Title: "Attrezzature tecniche:",
            listItem3Para: "Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore dello sport.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title: "",
            listItem5Para: "",
            para2: "Con i nostri servizi di interpretariato per il settore dello sport, potete promuovere l'attività fisica e il benessere a livello globale, facilitando la comunicazione tra atleti, allenatori e appassionati provenienti da diverse culture e tradizioni.",
            para2Pdf:"Con i nostri servizi di interpretariato per il settore dello sport, potete promuovere l'attività fisica e il benessere a livello globale, facilitando la comunicazione tra atleti, allenatori e appassionati provenienti da diverse culture e tradizioni."
        },
        cover: '/clients/Sports/coversports.webp',
        coverPdf: '/clients/Sports/coversports1.png',
        sector:'/clients/Sports/sports.webp',
        sectorPdf:'/clients/Sports/sports.png'
    },
    'Life Sciences':{
        en:{
            title: "Interpreting Services for Life Sciences Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services for the life sciences sector. Our professional interpreters enable clear and accurate communication during conferences, congresses, and meetings in the pharmaceutical, biomedical, and life sciences fields.",
            listItem1Title: "Specialized Interpreters:",
            listItem1Para: "We provide interpreters with extensive experience in the life sciences sector and a deep understanding of technical terminology, ensuring detailed comprehension of biomedical and pharmaceutical concepts.",
            listItem2Title: "Customized Interpretation:",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the life sciences sector, including medical congresses, research presentations, and stakeholder meetings.",
            listItem3Title: "Technical Equipment:",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your event in the life sciences sector.",
            listItem4Title: "Professional Technical Support:",
            listItem4Para: "Our experienced technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your event in the life sciences sector.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for the life sciences sector, you can promote innovation and collaboration in the field of medical and biomedical research, facilitating communication between researchers, physicians, and pharmaceutical companies worldwide.",
            para2Pdf: "With our interpretation services for the life sciences sector, you can promote innovation and collaboration in the field of medical and biomedical research, facilitating communication between researchers, physicians, and pharmaceutical companies worldwide."
        },
        it:{
            title: "Servizi di interpretariato per il settore scienze della vita: il partner multilingue ideale",
            para1: "Congressi Internazionali offre servizi di interpretariato specializzati per il settore delle scienze della vita. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di conferenze, convegni e riunioni nel campo farmaceutico, biomedico e delle scienze della vita.",
            listItem1Title: "Interpreti specializzati:",
            listItem1Para: "Mettiamo a disposizione interpreti con una vasta esperienza nel settore delle scienze della vita e una profonda conoscenza della terminologia tecnica, garantendo una comprensione dettagliata dei concetti biomedici e farmaceutici.",
            listItem2Title: "Interpretariato su misura:",
            listItem2Para: "Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore delle scienze della vita, inclusi congressi medici, presentazioni di ricerca e incontri con stakeholder.",
            listItem3Title: "Attrezzature tecniche:",
            listItem3Para: "Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore delle scienze della vita.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title: "",
            listItem5Para: "",
            para2: "Con i nostri servizi di interpretariato per il settore delle scienze della vita, potete promuovere l'innovazione e la collaborazione nel campo della ricerca medica e biomedica, facilitando la comunicazione tra ricercatori, medici e aziende farmaceutiche a livello globale.",
            para2Pdf:"Con i nostri servizi di interpretariato per il settore delle scienze della vita, potete promuovere l'innovazione e la collaborazione nel campo della ricerca medica e biomedica, facilitando la comunicazione tra ricercatori, medici e aziende farmaceutiche a livello globale."
        },
        cover: '/clients/LifeSciences/coverlifesciences.webp',
        coverPdf: '/clients/LifeSciences/coverlifesciences1.png',
        sector:'/clients/LifeSciences/LifeSciences.webp',
        sectorPdf:'/clients/LifeSciences/LifeSciences.png'
    
    },
    'Religious':{
        en:{
            title: "Interpreting Services for the Religious Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services for the religious sector. Our professional interpreters enable clear and accurate communication during ecumenical conferences, interreligious meetings, and spiritual events.",
            listItem1Title: "Specialized Interpreters:",
            listItem1Para: "We provide interpreters with extensive experience in the religious sector and a deep understanding of spiritual traditions and doctrines, ensuring respectful and inclusive communication among different religious denominations.",
            listItem2Title: "Customized Interpretation:",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the religious sector, including interreligious symposiums, spiritual pilgrimages, and liturgical ceremonies.",
            listItem3Title: "Technical Equipment:",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your event in the religious sector.",
            listItem4Title: "Professional Technical Support:",
            listItem4Para: "Our experienced technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your event in the religious sector.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for the religious sector, you can promote mutual respect, foster interreligious dialogue, and spread spiritual values in a global context, facilitating communication among religious leaders, scholars, and believers from diverse cultures and traditions."
            },
        it:{
            title: "Servizi di interpretariato per il settore religioso: il partner multilingue ideale",
            para1: "Congressi Internazionali offre servizi di interpretariato specializzati per il settore religioso. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di conferenze ecumeniche, incontri interreligiosi e eventi spirituali.",
            listItem1Title: "Interpreti Specializzati:",
            listItem1Para: "Mettiamo a disposizione interpreti con una vasta esperienza nel settore religioso e una profonda comprensione delle tradizioni e delle dottrine spirituali, garantendo una comunicazione rispettosa e inclusiva tra diverse confessioni religiose.",
            listItem2Title: "Interpretariato su misura:",
            listItem2Para: "Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore religioso, inclusi simposi interreligiosi, pellegrinaggi spirituali e cerimonie liturgiche.",
            listItem3Title: "Attrezzature tecniche:",
            listItem3Para: "Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore religioso.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title: "",
            listItem5Para: "",
            para2: "Con i nostri servizi di interpretariato per il settore religioso, potete promuovere il rispetto reciproco, favorire il dialogo interreligioso e diffondere i valori spirituali in un contesto globale, facilitando la comunicazione tra leader religiosi, studiosi e fedeli provenienti da diverse culture e tradizioni."
        },
        cover: '/clients/Religious/coverreligious.webp',
        coverPdf: '/clients/Religious/coverreligious1.png',
        sector:'/clients/Religious/religious.webp',
        sectorPdf:'/clients/Religious/religious.png'
    },
    'Public Sector':{
        en:{
            title: "Interpreting Services for the Public Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services for the public sector. Our professional interpreters enable clear and accurate communication during conferences, political meetings, and international negotiations in the field of governance and public administration.",
            listItem1Title: "Specialized Interpreters:",
            listItem1Para: "We provide interpreters with extensive experience in the public sector and a solid understanding of legislative and administrative procedures, ensuring detailed comprehension of political and legal issues.",
            listItem2Title: "Customized Interpretation:",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the public sector, including international summits, diplomatic meetings, and training sessions for government officials.",
            listItem3Title: "Technical Equipment:",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your event in the public sector.",
            listItem4Title: "Professional Technical Support:",
            listItem4Para: "Our experienced technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your event in the public sector.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for the public sector, you can facilitate international cooperation, promote transparency, and improve governance globally by facilitating communication among government representatives, international organizations, and citizens.",
            para2Pdf: "With our interpretation services for the public sector, you can facilitate international cooperation, promote transparency, and improve governance globally by facilitating communication among government representatives, international organizations, and citizens."
        },
        it:{
            title: "Servizi di Interpretariato per il settore pubblico: il partner multilingue ideale",
            para1: "Congressi Internazionali offre servizi di interpretariato specializzati per il settore pubblico. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di conferenze, incontri politici e negoziati internazionali nel campo della governance e dell'amministrazione pubblica.",
            listItem1Title: "Interpreti specializzati:",
            listItem1Para: "Mettiamo a disposizione interpreti con una vasta esperienza nel settore pubblico e una solida conoscenza delle procedure legislative e amministrative, garantendo una comprensione dettagliata delle questioni politiche e giuridiche.",
            listItem2Title: "Interpretariato su misura:",
            listItem2Para: "Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore pubblico, inclusi vertici internazionali, incontri diplomatici e sessioni di formazione per funzionari governativi.",
            listItem3Title: "Attrezzature tecniche:",
            listItem3Para: "Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore pubblico.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title: "",
            listItem5Para: "",
            para2: "Con i nostri servizi di interpretariato per il settore pubblico, potete facilitare la cooperazione internazionale, promuovere la trasparenza e migliorare la governance a livello globale, facilitando la comunicazione tra rappresentanti governativi, organizzazioni internazionali e cittadini.",
            para2Pdf:"Con i nostri servizi di interpretariato per il settore pubblico, potete facilitare la cooperazione internazionale, promuovere la trasparenza e migliorare la governance a livello globale, facilitando la comunicazione tra rappresentanti governativi, organizzazioni internazionali e cittadini."
        },
        cover: '/clients/PublicSector/coverpublicsector.webp',
        coverPdf: '/clients/PublicSector/coverpublicsector1.png',
        sector:'/clients/PublicSector/publicSector.webp',
        sectorPdf:'/clients/PublicSector/publicSector.png' 
    },
    'Retail':{
        en:{
            title: "Interpreting Services for the Retail Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services for the retail sector. Our professional interpreters enable clear and accurate communication during trade shows, product presentations, and meetings in the field of retail.",
            listItem1Title: "Specialized Interpreters:",
            listItem1Para: "We provide interpreters with extensive experience in the retail sector and a solid understanding of commercial terminology, ensuring a detailed understanding of sales strategies and market dynamics.",
            listItem2Title: "Customized Interpretation:",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the retail sector, including product launches, training workshops, and meetings with international suppliers and clients.",
            listItem3Title: "Technical Equipment:",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your event in the retail sector.",
            listItem4Title: "Professional Technical Support:",
            listItem4Para: "Our experienced technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your event in the retail sector.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for the retail sector, you can enhance your presence in the global market, facilitating communication between sellers, buyers, and business partners from different parts of the world.",
            para2Pdf: "With our interpretation services for the retail sector, you can enhance your presence in the global market, facilitating communication between sellers, buyers, and business partners from different parts of the world."
        },
        it:{
            title: "Servizi di interpretariato per il settore retail: il partner multilingue ideale",
            para1: "Congressi Internazionali offre servizi di interpretariato specializzati per il settore del retail. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di fiere, presentazioni di prodotti e incontri nel campo del commercio al dettaglio.",
            listItem1Title: "Interpreti specializzati:",
            listItem1Para: "Mettiamo a disposizione interpreti con una vasta esperienza nel settore del retail e una solida conoscenza della terminologia commerciale, garantendo una comprensione dettagliata delle strategie di vendita e delle dinamiche di mercato.",
            listItem2Title: "Interpretariato su misura:",
            listItem2Para: "Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore del retail, inclusi lanci di prodotti, workshop di formazione e incontri con fornitori e clienti internazionali.",
            listItem3Title: "Attrezzature tecniche:",
            listItem3Para: "Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore del retail.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title: "",
            listItem5Para: "",
            para2: "Con i nostri servizi di interpretariato per il settore del retail, potete migliorare la vostra presenza sul mercato globale, facilitando la comunicazione tra venditori, acquirenti e partner commerciali provenienti da diverse parti del mondo.",
            para2Pdf:"Con i nostri servizi di interpretariato per il settore del retail, potete migliorare la vostra presenza sul mercato globale, facilitando la comunicazione tra venditori, acquirenti e partner commerciali provenienti da diverse parti del mondo."
        },
        cover: '/clients/Retail/coverretail.webp',
        coverPdf: '/clients/Retail/coverretail1.png',
        sector:'/clients/Retail/retail.webp',
        sectorPdf:'/clients/Retail/retail.png'
    },
    'Support Services':{
        en:{
            title: "Interpreting Services for Essential Services: Your Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services for the support services sector. Our professional interpreters enable clear and accurate communication during conferences, workshops, and meetings in the field of consultancy and business assistance.",
            listItem1Title: "Specialized Interpreters:",
            listItem1Para: "We provide interpreters with extensive experience in the support services sector and a solid understanding of business terminology, ensuring a detailed understanding of management strategies and business practices.",
            listItem2Title: "Customized Interpretation:",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the support services sector, including training seminars, coaching sessions, and meetings with international clients.",
            listItem3Title: "Technical Equipment:",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your event in the support services sector.",
            listItem4Title: "Professional Technical Support:",
            listItem4Para: "Our experienced technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your event in the support services sector.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for the support services sector, you can optimize business efficiency and improve the quality of services offered internationally, facilitating communication between consultants, managers, and clients from different parts of the world.",
            para2Pdf: "With our interpretation services for the support services sector, you can optimize business efficiency and improve the quality of services offered internationally, facilitating communication between consultants, managers, and clients from different parts of the world."
        },
        it:{
            title: "Servizi di interpretariato per i servizi di supporto: il partner ideale",
            para1: "Congressi Internazionali offre servizi di interpretariato specializzati per il settore dei servizi di supporto. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di conferenze, workshop e incontri nel campo della consulenza e dell'assistenza aziendale.",
            listItem1Title: "Interpreti specializzati:",
            listItem1Para: "Mettiamo a disposizione interpreti con una vasta esperienza nel settore dei servizi di supporto e una solida conoscenza della terminologia aziendale, garantendo una comprensione dettagliata delle strategie di gestione e delle pratiche commerciali.",
            listItem2Title: "Interpretariato su misura:",
            listItem2Para: "Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore dei servizi di supporto, inclusi seminari di formazione, sessioni di coaching e incontri con clienti internazionali.",
            listItem3Title: "Attrezzature tecniche:",
            listItem3Para: "Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore dei servizi di supporto.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title: "",
            listItem5Para: "",
            para2: "Con i nostri servizi di interpretariato per il settore dei servizi di supporto, potete ottimizzare l'efficienza aziendale e migliorare la qualità dei servizi offerti a livello internazionale, facilitando la comunicazione tra consulenti, manager e clienti provenienti da diverse parti del mondo.",
            para2Pdf:"Con i nostri servizi di interpretariato per il settore dei servizi di supporto, potete ottimizzare l'efficienza aziendale e migliorare la qualità dei servizi offerti a livello internazionale, facilitando la comunicazione tra consulenti, manager e clienti provenienti da diverse parti del mondo."
        },
        cover: '/clients/SupportServices/coversupportservices.webp',
        coverPdf: '/clients/SupportServices/coversupportservices1.png',
        sector:'/clients/SupportServices/supportservices.webp',
        sectorPdf:'/clients/SupportServices/supportservices.png'
    },
    'Technical':{
        en:{
            title: "Interpreting Services for Technical Communication: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services for the technical sector. Our professional interpreters enable clear and accurate communication during conferences, seminars, and workshops in the fields of engineering, science, and technology.",
            listItem1Title: "Specialized Interpreters:",
            listItem1Para: "We provide interpreters with extensive experience in the technical sector and a solid understanding of scientific and technical terminology, ensuring detailed comprehension of concepts and innovations in the field.",
            listItem2Title: "Customized Interpretation:",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the technical sector, including international conferences, research presentations, and advanced training courses.",
            listItem3Title: "Technical Equipment:",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your technical event.",
            listItem4Title: "Professional Technical Support:",
            listItem4Para: "Our experienced technical team handles the installation and management of equipment, ensuring that everything functions smoothly during your technical event.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for the technical sector, you can promote innovation and scientific collaboration globally, facilitating communication among researchers, engineers, and technicians from diverse cultures and traditions.",
            para2Pdf: "With our interpretation services for the technical sector, you can promote innovation and scientific collaboration globally, facilitating communication among researchers, engineers, and technicians from diverse cultures and traditions."
         },
        it:{
            title: "Servizi di interpretariato per il settore tecnico: il partner multilingue ideale",
            para1: "Congressi Internazionali offre servizi di interpretariato specializzati per il settore tecnico. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di conferenze, seminari e workshop nel campo dell'ingegneria, della scienza e della tecnologia.",
            listItem1Title: "Interpreti specializzati:",
            listItem1Para: "Mettiamo a disposizione interpreti con una vasta esperienza nel settore tecnico e una solida conoscenza della terminologia scientifica e tecnica, garantendo una comprensione dettagliata dei concetti e delle innovazioni nel settore.",
            listItem2Title: "Interpretariato su misura:",
            listItem2Para: "Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore tecnico, inclusi convegni internazionali, presentazioni di ricerca e corsi di formazione avanzata.",
            listItem3Title: "Attrezzature tecniche:",
            listItem3Para: "Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore tecnico.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title: "",
            listItem5Para: "",
            para2: "Con i nostri servizi di interpretariato per il settore tecnico, potete promuovere l'innovazione e la collaborazione scientifica a livello globale, facilitando la comunicazione tra ricercatori, ingegneri e tecnici provenienti da diverse culture e tradizioni.",
            para2Pdf:"Con i nostri servizi di interpretariato per il settore tecnico, potete promuovere l'innovazione e la collaborazione scientifica a livello globale, facilitando la comunicazione tra ricercatori, ingegneri e tecnici provenienti da diverse culture e tradizioni."
        },
        cover: '/clients/Technical/covertechnical.webp',
        coverPdf: '/clients/Technical/covertechnical1.png',
        sector:'/clients/Technical/technical.webp',
        sectorPdf:'/clients/Technical/technical.png'
    },
    'Technology':{
        en:{
            title: "Interpreting Services for Technological Sector: Your Multilingual Partner",
            para1: "At Congressi Internazionali, we offer specialized interpretation services for the technology sector. Our professional interpreters enable clear and accurate communication during conferences, trade fairs, and meetings in the fields of IT, telecommunications, and technological innovation.",
            listItem1Title: "Specialized Interpreters:",
            listItem1Para: "We provide interpreters with extensive experience in the technology sector and a solid understanding of computer terminology, ensuring a detailed comprehension of concepts and trends in the industry.",
            listItem2Title: "Customized Interpretation:",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, adapting to the specific needs of your events in the technology sector, including expert keynotes, product presentations, and roundtable discussions on emerging topics.",
            listItem3Title: "Technical Equipment:",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your event in the technology sector.",
            listItem4Title: "Professional Technical Support:",
            listItem4Para: "Our experienced technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your event in the technology sector.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for the technology sector, you can promote innovation and technological collaboration internationally, facilitating communication among experts, entrepreneurs, and investors from diverse parts of the world.",
            para2Pdf: "With our interpretation services for the technology sector, you can promote innovation and technological collaboration internationally, facilitating communication among experts, entrepreneurs, and investors from diverse parts of the world."
        },
        it:{
            title: "Servizi di interpretariato per il settore tecnologico: il partner multilingue ideale",
            para1: "Congressi Internazionali offre servizi di interpretariato specializzati per il settore tecnologico. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di conferenze, fiere commerciali e incontri nel campo dell'informatica, delle telecomunicazioni e dell'innovazione tecnologica.",
            listItem1Title: "Interpreti specializzati:",
            listItem1Para: "Mettiamo a disposizione interpreti con una vasta esperienza nel settore tecnologico e una solida conoscenza della terminologia informatica, garantendo una comprensione dettagliata dei concetti e delle tendenze nel settore.",
            listItem2Title: "Interpretariato su misura:",
            listItem2Para: "Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore tecnologico, inclusi keynote di esperti, presentazioni di prodotti e tavole rotonde su temi emergenti.",
            listItem3Title: "Attrezzature tecniche:",
            listItem3Para: "Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore tecnologico.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title: "",
            listItem5Para: "",
            para2: "Con i nostri servizi di interpretariato per il settore tecnologico, potete promuovere l'innovazione e la collaborazione tecnologica a livello internazionale, facilitando la comunicazione tra esperti, imprenditori e investitori provenienti da diverse parti del mondo.",
            para2Pdf:"Con i nostri servizi di interpretariato per il settore tecnologico, potete promuovere l'innovazione e la collaborazione tecnologica a livello internazionale, facilitando la comunicazione tra esperti, imprenditori e investitori provenienti da diverse parti del mondo."
        },
        cover: '/clients/Technology/covertechnology.webp',
        coverPdf: '/clients/Technology/covertechnology1.png',
        sector:'/clients/Technology/Technology.webp',
        sectorPdf:'/clients/Technology/Technology.png'
    },
    'Automotive':{
        en:{
            title: "Interpreting Services for the Automotive Sector: Your Multilingual Partner in Vehicle Innovation and Development",
            para1: "At international conferences and trade fairs in the automotive sector, we offer specialized interpreting services. Our professional interpreters enable clear and accurate communication during presentations, technical sessions, and meetings in the field of automotive engineering, vehicle production, and transportation innovation.",
            listItem1Title: "Specialized Interpreters:",
            listItem1Para: "We provide interpreters with extensive experience in the automotive sector and a solid understanding of technical terminology, ensuring a detailed comprehension of concepts and trends in the industry.",
            listItem2Title: "Customized Interpreting:",
            listItem2Para: "We offer simultaneous and consecutive interpreting services, tailoring them to the specific needs of your events in the automotive sector, including new model launches, presentations of innovative technologies, and roundtable discussions on mobility issues.",
            listItem3Title: "Technical Equipment:",
            listItem3Para: "We supply high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your automotive sector event.",
            listItem4Title: "Professional Technical Support:",
            listItem4Para: "Our experienced technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your automotive sector event.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpreting services for the automotive sector, you can promote innovation and collaboration in transportation on an international scale, facilitating communication among engineers, designers, and industry executives from various parts of the world.",
            para2Pdf: "With our interpreting services for the automotive sector, you can promote innovation and collaboration in transportation on an international scale, facilitating communication among engineers, designers, and industry executives from various parts of the world."
        },
        it:{
            title: "Servizi di interpretariato per il settore automobilistico: il partner multilingue nello sviluppo di veicoli",
            para1: "Presso Convegni Internazionali e fiere del settore automobilistico, offriamo servizi di interpretariato specializzati. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di presentazioni, sessioni tecniche e incontri nel campo dell'ingegneria automobilistica, della produzione di veicoli e dell'innovazione nel settore dei trasporti.",
            listItem1Title: "Interpreti specializzati:",
            listItem1Para: "Mettiamo a disposizione interpreti con una vasta esperienza nel settore automobilistico e una solida conoscenza della terminologia tecnica, garantendo una comprensione dettagliata dei concetti e delle tendenze nel settore.",
            listItem2Title: "Interpretariato su misura:",
            listItem2Para: "Offriamo servizi di interpretariato simultaneo e consecutivo, adattandoci alle esigenze specifiche dei vostri eventi nel settore automobilistico, inclusi lanci di nuovi modelli, presentazioni di tecnologie innovative e tavole rotonde su questioni legate alla mobilità.",
            listItem3Title: "Attrezzature tecniche:",
            listItem3Para: "Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore automobilistico.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title: "",
            listItem5Para: "",
            para2: "Con i nostri servizi di interpretariato per il settore automobilistico, potete promuovere l'innovazione e la collaborazione nel campo dei trasporti su scala internazionale, facilitando la comunicazione tra ingegneri, designer e dirigenti del settore provenienti da diverse parti del mondo.",
            para2Pdf: "Con i nostri servizi di interpretariato per il settore automobilistico, potete promuovere l'innovazione e la collaborazione nel campo dei trasporti su scala internazionale, facilitando la comunicazione tra ingegneri, designer e dirigenti del settore provenienti da diverse parti del mondo."
        },
        cover: '/clients/Automotive/coverAutomotive.webp',
        coverPdf: '/clients/Automotive/coverAutomotive1.png',
        sector:'/clients/Automotive/Automotive.webp',
        sectorPdf:'/clients/Automotive/Automotive.png'
    },
    'Cosmetics':{
        en:{
            title: "Interpretation Services for the Cosmetic Sector: Your Multilingual Partner in Beauty and the Cosmetics Industry",
            para1: "At beauty fairs and international events in the cosmetic sector, we offer specialized interpretation services. Our professional interpreters enable clear and accurate communication during product presentations, seminars, and meetings on the latest trends in cosmetics and beauty.",
            listItem1Title: "Specialized Interpreters:",
            listItem1Para: "We provide interpreters with extensive experience in the cosmetic sector and a solid understanding of specific terminology, ensuring a detailed comprehension of products, ingredients, and beauty practices.",
            listItem2Title: "Customized Interpretation:",
            listItem2Para: "We offer simultaneous and consecutive interpretation services, customized to meet the specific needs of your events in the cosmetic sector, including product launches, workshops, and meetings with sector influencers.",
            listItem3Title: "Technical Equipment:",
            listItem3Para: "We provide high-quality audiovisual equipment, such as simultaneous translation systems and microphones, to ensure optimal communication during your event in the cosmetic sector.",
            listItem4Title: "Professional Technical Support:",
            listItem4Para: "Our experienced technical team handles the installation and management of equipment, ensuring that everything runs smoothly during your event in the cosmetic sector.",
            listItem5Title: "",
            listItem5Para: "",
            para2: "With our interpretation services for the cosmetic sector, you can promote knowledge sharing and the development of international partnerships in the beauty world, facilitating communication among brands, beauty experts, and consumers from different parts of the world.",
            para2Pdf: "With our interpretation services for the cosmetic sector, you can promote knowledge sharing and the development of international partnerships in the beauty world, facilitating communication among brands, beauty experts, and consumers from different parts of the world."
        },
        it:{
            title: "Servizi di Interpretariato per il settore cosmetico: il partner multilingue nell'industria dei cosmetici",
            para1: "Presso fiere di bellezza e eventi internazionali del settore cosmetico, offriamo servizi di interpretariato specializzati. I nostri interpreti professionisti consentono una comunicazione chiara e accurata nel corso di presentazioni di prodotti, seminari e incontri sulle ultime tendenze nel mondo della cosmesi e della bellezza.",
            listItem1Title: "Interpreti specializzati:",
            listItem1Para: "Metteniamo a disposizione interpreti con una vasta esperienza nel settore cosmetico e una solida conoscenza della terminologia specifica, garantendo una comprensione dettagliata dei prodotti, degli ingredienti e delle pratiche di bellezza.",
            listItem2Title: "Interpretariato su misura:",
            listItem2Para: "Offriamo servizi di interpretariato simultaneo e consecutivo, personalizzati per adattarsi alle esigenze specifiche dei vostri eventi nel settore cosmetico, inclusi lanci di prodotti, workshop e incontri con influencer del settore.",
            listItem3Title: "Attrezzature tecniche:",
            listItem3Para: "Forniamo attrezzature audiovisive di alta qualità, come sistemi di traduzione simultanea e microfoni, per garantire una comunicazione ottimale durante il vostro evento nel settore cosmetico.",
            listItem4Title:"Assistenza tecnica professionale: ",
            listItem4Para:`Il nostro team di tecnici esperti si occupa dell'installazione e della gestione delle attrezzature, garantendo che il vostro evento proceda senza problemi.`,
            listItem5Title: "",
            listItem5Para: "",
            para2: "Con i nostri servizi di interpretariato per il settore cosmetico, potete favorire la condivisione di conoscenze e lo sviluppo di partnership internazionali nel mondo della bellezza, facilitando la comunicazione tra marchi, esperti di bellezza e consumatori provenienti da diverse parti del mondo.",
            para2Pdf: "Con i nostri servizi di interpretariato per il settore cosmetico, potete favorire la condivisione di conoscenze e lo sviluppo di partnership internazionali nel mondo della bellezza, facilitando la comunicazione tra marchi, esperti di bellezza e consumatori provenienti da diverse parti del mondo."
        },
        cover: '/clients/Cosmetics/coverCosmetics.webp',
        coverPdf: '/clients/Cosmetics/coverCosmetics1.png',
        sector:'/clients/Cosmetics/Cosmetics.webp',
        sectorPdf:'/clients/Cosmetics/Cosmetics.png'
    },
    }