import { Image, Link, Page, Text, View } from "@react-pdf/renderer";
import React from "react";
import { styles } from "../globalStyles";

const clients = [
    {img:'/assets/clients/image12.png'},
    {img:'/assets/clients/image11.png'},
    {img:'/assets/clients/image10.png'},
    {img:'/assets/clients/image9.png'},
    {img:'/assets/clients/image8.png'},
    {img:'/assets/clients/image7.png'},
    {img:'/assets/clients/image1.png'},
    {img:'/assets/clients/image4.png'},
    {img:'/assets/clients/image3.png'},
    {img:'/assets/clients/image2.png'},
    {img:'/assets/clients/image6.png'},
    {img:'/assets/clients/image5.png'},
]

const OurClients = ({ brand, language = "English" }) => {
  return (
    <Page wrap={false} size="A4" style={{ ...styles.page }}>
       
      <View
        style={{
          marginTop: "30px",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
    
        <Text
          style={{
            fontSize: "17px",
            marginTop: "5px",
            marginLeft: "-10px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
                           {language === "English" ? "Our Clients" : "I nostri clienti"}

        </Text>
      </View>
      <View style={{display:"flex", flexDirection:"row", marginTop:"25px"}}>
        {clients.slice(0, 4).map((client)=><Image src={client.img} style={{width:"120px", marginLeft:"20px", 
            height:"70px", objectFit:"contain"}}/>)}
      </View>
      <View style={{display:"flex", flexDirection:"row",  marginTop:"25px"}}>
        {clients.slice(4, 8).map((client)=><Image src={client.img} style={{width:"120px", marginLeft:"20px", 
            height:"70px", objectFit:"contain"}}/>)}
      </View>
      <View style={{display:"flex", flexDirection:"row",  marginTop:"25px"}}>
        {clients.slice(8).map((client)=><Image src={client.img} style={{width:"120px", marginLeft:"20px", 
            height:"70px", objectFit:"contain"}}/>)}
      </View>
      <Image
        src={"/assets/testimonial-bg-pdf.png"}
        style={{ objectFit: "cover",transform:"rotate(180deg)", width: "100%", zIndex:"-1111", marginTop:"-1300px", height: "1320px" }}
      />
    </Page>
  );
};

export default OurClients;
