import { Image, Link, Page, Text, View } from "@react-pdf/renderer";
import React from "react";
import { styles } from "../globalStyles";
import { fCurrency } from "../../../utils/formatNumber";
import _ from "lodash";
const content = {
    "English" :{
     title: "Packages",
     economy: {
       items: ["Automatic translation", "Light revision"],
     },
     professional:{
       items:['Specialized Native Translator', 'Quality Control Expert']
     },
     premium:{
       items:['Specialized Native Translator', 'Specialized Reviser', 'Quality Control Expert']
     }
   },
   "Italian" :{
         title: "Pacchetti",
         economy: {
           items: ["Traduzione automatica", "Revisione leggera"],
         },
         professional:{
           items:['Traduttore madrelingua specializzato', 'Esperto in controllo qualità']
         },
         premium:{
           items:['Traduttore madrelingua specializzato', 'Revisore specializzato', 'Esperto in controllo qualità']
         }
       }
   };

   function formatWithTrailingZero(value, precision = 3) {
    // Convert the string value to a number
    // const numericValue = Number(value);
  
    // Floor the value to the desired precision
    const flooredValue = _.floor(Number(value), 3);
    const flooredString = flooredValue.toFixed(2); // Ensures consistent decimal places
    const lastValue = String(value)?.split('.')[1]
    console.log("last", lastValue)
    // Check if the last digit after decimal is 0
    if (value[value.length - 1] === '0' ) {
        return flooredString; // Add a trailing 0
    }else if(lastValue?.length === 1){
      return flooredString
    }
  
  
    return String(flooredValue); // Return as string without unnecessary formatting
  }
   function formatDate(dat) {
    const date = new Date(dat)
    console.log(date)
    // Get day, month, year, hours, and minutes
    var day = date.getDate();
    var month = date.getMonth() + 1; // Month is zero-based
    var year = date.getFullYear();
    var hours = date.getHours();
    var minutes = date.getMinutes();
  
    // Add leading zero if necessary
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
  
    // Return formatted date
    return day + '/' + month + '/' + year + ' ' + hours + ':' + minutes;
  }
  function subtractDays (pkg, dateStr, days, orgPkgsOption) {
    var date = new Date(dateStr);
    if(pkg === "Premium"){
      date.setDate(date.getDate() + days);
      if(new Date(date).getDay() === 0){
        if(orgPkgsOption === 0 && (new Date(dateStr).getDay() !== 4)){
          date.setDate(date.getDate() + 2);
        }else{
          date.setDate(date.getDate() + 1);
        }
      }else if( new Date(date).getDay() === 6 ){
        if(orgPkgsOption === 0 && (new Date(dateStr).getDay() !== 4)){
          date.setDate(date.getDate() + 3);
        }else{
          date.setDate(date.getDate() + 2);
        }
      }
    }else if(pkg === "Professional"){
      if(orgPkgsOption === 0){
        date.setDate(date.getDate() + 1);
        if(new Date(date).getDay() === 0){
          date.setDate(date.getDate() + 1);
        }else if( new Date(date).getDay() === 6 ){
          date.setDate(date.getDate() + 2);
        }
      }else if(orgPkgsOption === 2){
        date.setDate(date.getDate() - 1);
        if(new Date(date).getDay() === 0){
          date.setDate(date.getDate() - 2);
        }else if( new Date(date).getDay() === 6 ){
          date.setDate(date.getDate() - 1);
        }
      }else{
        date.setDate(date.getDate());
      }
     
    }else if(pkg === "Economy"){
      date.setDate(date.getDate() - days);
      if(new Date(date).getDay() === 0){
        if(orgPkgsOption === 2 && (new Date(dateStr).getDay() === 1)){
          date.setDate(date.getDate() - 3);
        }else{
          date.setDate(date.getDate() - 2);
        }
      }else if( new Date(date).getDay() === 6 ){
        if(orgPkgsOption === 2 && (new Date(dateStr).getDay() === 1)){
          date.setDate(date.getDate() - 2);
        }else{
          date.setDate(date.getDate() - 1);
        }
      }
    }
    return formatDate(date);
  }

const Packages = ({ packagesNetTotals, deliveryDateTime, packagesPrices, selectedPackageOption, status="", orgPkgsOption, language = "English" }) => {
   console.log(packagesNetTotals, deliveryDateTime, packagesPrices, selectedPackageOption, status="", orgPkgsOption)
    return (
    <Page wrap={false} size="A4" style={{ ...styles.page }}>
      <View
        style={{
          ...styles.header,
          marginBottom: 0,
          width: "100%",
          marginHorizontal: "auto",
        }}
      >
        <View style={{ marginLeft: "460px" }}>
          
            <Image
              style={styles.headerRightImg}
              src="/translation/logos/lingoyou.png"
            />
        </View>
        <View style={styles.headerItem} />
      </View>
      <View
        style={{
          marginTop: "30px",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        
        <Text
          style={{
            fontSize: "17px",
            marginLeft: "-10px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {content[language]?.title}
        </Text>
      </View>
      {(status !== "Quote-Won" && status !== "Sent To PM") ? <>
        <View style={{marginTop:"15px", marginLeft:"30px", width:"500px"}}>
         <View style={{display:"flex", flexDirection:"row"}}>
            <View style={{margin:"20px", width:"200px"}}>
                <Text style={{color:'#39065F', fontSize:"11px", textAlign:"left", fontWeight:"bold"}}>
                    Economy
                </Text>
                <Text style={{fontWeight:"light", marginTop:"4px", width:"170px", textAlign:'left', fontSize:"7px"}}>
                {language === "English" ? `Automatic translation through one of our software. Quick review of the document by an expert native translator. Translations suitable for understanding a text or for private use,  not suitable for publication.`: 
                   `Traduzione automatica tramite uno dei nostri software. Revisione rapida del documento da parte di un traduttore madrelingua esperto. Traduzioni adatte alla comprensione di un testo o per uso privato, non adatte alla pubblicazione.`}
                                </Text>
            </View>
            <View style={{ marginTop:"30px"}}>
                {content[language]?.economy.items.map((item)=><View style={{display:"flex", flexDirection:"row", marginTop:"1px"}}>
                    <Image src={"/assets/icons/check.png"} style={{objectFit:"contain", width:"10px", height:"10px"}}/>
                    <Text style={{fontSize:"7px", marginLeft:"2px", fontWeight:"normal"}}>
                        {item}
                    </Text>
                </View>)}
                <Text style={{fontSize:"7px", color:"#637381", fontWeight:"normal", marginTop:"20px"}}>
                {language === "English" ? "Delivery guaranteed by" : "Consegna garantita"}
                </Text>
                <Text style={{fontSize:"7px", fontWeight:"bold"}}>
                {subtractDays("Economy", deliveryDateTime,  orgPkgsOption === 0 ? 0 : orgPkgsOption === 2 ? 2 : 1, orgPkgsOption)}

                </Text>
            </View>
            <View style={{ marginLeft:"70px", marginTop:"30px", textAlign:"right"}}>
                <Text style={{fontSize:"13px", textAlign:"right", marginLeft:"20px", fontWeight:"bold"}}>
                {packagesNetTotals?.length > 0 && fCurrency(Number(packagesNetTotals[0]))}

                </Text>
                {packagesPrices?.economy?.length === 1 &&<Text style={{fontSize:"7px", fontWeight:"normal",marginLeft:"10px",  textAlign:"right"}}>
                {language === "English" ? `about €${formatWithTrailingZero(packagesPrices?.economy[0])}/word` : 
                `€${formatWithTrailingZero(packagesPrices?.economy[0])}/parola`}

                </Text>}
                <Text style={{backgroundColor:selectedPackageOption === 0 ?"lightgrey" :"#103996",
                    marginTop:"3px", padding:"3px", width:"70px", textAlign:"center", fontSize:"8px",
                    color:selectedPackageOption === 0 ? "grey":"white", borderRadius:"30px"}}>
                {selectedPackageOption === 0 ? `${language === "English" ? "SELECTED" : "SELEZIONATO"}` :
                 `${language === "English" ? "SELECT":"SELEZIONA"}`}

                </Text>
            </View>
         </View>
         <Image src={"/translation/economy-pkg.png"} style={{objectFit:"contain", zIndex:"-1111", 
            marginTop:"-105px"}}/>
     </View>
     <View style={{marginTop:"15px", marginLeft:"30px", width:"500px"}}>
         <View style={{display:"flex", flexDirection:"row"}}>
            <View style={{margin:"20px", width:"200px"}}>
                <Text style={{color:'#A158D6', fontSize:"11px", textAlign:"left", fontWeight:"bold"}}>
                    Professional
                </Text>
                <Text style={{fontWeight:"light", marginTop:"4px", width:"180px", textAlign:'left', fontSize:"7px"}}>
                {language === "English" ? `Translations carried out by a native speaker expert. Review by one of our specialized Project Managers. Use of the Translation Memory upon request included in the price. High quality translations for standard documents.`:
            'Traduzioni eseguite da un esperto madrelingua. Revisione da parte di uno dei nostri Project Manager specializzati. Utilizzo della memoria di traduzione su richiesta incluso nel prezzo. Traduzioni di alta qualità per documenti standard.'}
          </Text>
            </View>
            <View style={{ marginTop:"30px"}}>
                {content[language]?.professional?.items.map((item)=><View style={{display:"flex", marginTop:"1px", flexDirection:"row"}}>
                    <Image src={"/assets/icons/check.png"} style={{objectFit:"contain", width:"10px", height:"10px"}}/>
                    <Text style={{fontSize:"7px", marginLeft:"2px", fontWeight:"normal"}}>
                        {item}
                    </Text>
                </View>)}
                <Text style={{fontSize:"7px", color:"#637381", fontWeight:"normal", marginTop:"20px"}}>
                {language === "English" ? "Delivery guaranteed by" : "Consegna garantita"}
                </Text>
                <Text style={{fontSize:"7px", fontWeight:"bold"}}>
                {subtractDays("Professional", deliveryDateTime, 0, orgPkgsOption )}
                </Text>
            </View>
            <View style={{ marginLeft:"25px", marginTop:"30px", textAlign:"right"}}>
                <Text style={{fontSize:"13px", textAlign:"right", marginLeft:"20px", fontWeight:"bold"}}>
                {packagesNetTotals?.length > 0 && fCurrency(Number(packagesNetTotals[1]))}
                </Text>
                {packagesPrices?.professional?.length === 1 &&  
                <Text style={{fontSize:"7px", fontWeight:"normal", marginLeft:"10px", textAlign:"right"}}>
                                     {language === "English" ? `about €${formatWithTrailingZero(packagesPrices?.professional[0])}/word` : 
                                     `€${formatWithTrailingZero(packagesPrices?.professional[0])}/parola`}

                </Text>
               }
                <Text style={{backgroundColor:selectedPackageOption === 1 ?"lightgrey" :"#103996",marginTop:"3px", padding:"3px", width:"70px",
                     textAlign:"center", fontSize:"8px",color:selectedPackageOption === 1 ? "grey":"white", borderRadius:"30px"}}>
                {selectedPackageOption === 1 ? `${language === "English" ? "SELECTED" : "SELEZIONATO"}` : `${language === "English" ? "SELECT":"SELEZIONA"}`}

                </Text>
            </View>
         </View>
         <Image src={"/translation/professional-pkg.png"} style={{objectFit:"contain", zIndex:"-1111", 
            marginTop:"-110px"}}/>
     </View>
     <View style={{marginTop:"15px", marginBottom:"20px", marginLeft:"30px", width:"500px"}}>
         <View style={{display:"flex", flexDirection:"row"}}>
            <View style={{margin:"20px", width:"200px"}}>
                <Text style={{color:'#43BEF3', fontSize:"11px", textAlign:"left", fontWeight:"bold"}}>
                    Premium
                </Text>
                <Text style={{fontWeight:"light", marginTop:"4px", width:"180px", textAlign:'left', fontSize:"7px"}}>
                {language === "English" ? `Translations carried out by an expert native translator. Quality control by a second native translator. Review by one of our specialized Project Managers. Translations of high-quality content intended for rapid publication.`
                     :'Traduzioni eseguite da un traduttore madrelingua esperto. Controllo di qualità da parte di un secondo traduttore madrelingua. Revisione da parte di uno dei nostri Project Manager specializzati. Traduzioni di contenuti di alta qualità destinati a una rapida pubblicazione.'}
                 </Text>
            </View>
            <View style={{ marginTop:"30px"}}>
                {content[language]?.premium.items.map((item)=><View style={{display:"flex", marginTop:"1px", flexDirection:"row"}}>
                    <Image src={"/assets/icons/check.png"} style={{objectFit:"contain", width:"10px", height:"10px"}}/>
                    <Text style={{fontSize:"7px", marginLeft:"2px", fontWeight:"normal"}}>
                        {item}
                    </Text>
                </View>)}
                <Text style={{fontSize:"7px", color:"#637381", fontWeight:"normal", marginTop:"12px"}}>
                {language === "English" ? "Delivery guaranteed by" : "Consegna garantita"}

                </Text>
                <Text style={{fontSize:"7px", fontWeight:"bold"}}>
                {subtractDays("Premium", deliveryDateTime, orgPkgsOption === 2 ? 0 : orgPkgsOption === 1 ? 1 : 2, orgPkgsOption)}

                </Text>
            </View>
            <View style={{ marginLeft:"20px", marginTop:"30px", textAlign:"right"}}>
                <Text style={{fontSize:"13px", textAlign:"right", marginLeft:"20px", fontWeight:"bold"}}>
                {packagesNetTotals?.length > 0 && fCurrency(Number(packagesNetTotals[2]))}
                </Text>
               {packagesPrices?.premium?.length === 1 && <Text style={{fontSize:"7px", marginLeft:"15px", fontWeight:"normal",  textAlign:"right"}}>
               {language === "English" ? `about €${formatWithTrailingZero(packagesPrices?.premium[0])}/word` : 
        `€${formatWithTrailingZero(packagesPrices?.premium[0])}/parola`}
                </Text>}
                <Text style={{backgroundColor:selectedPackageOption === 2 ?"lightgrey" :"#103996",marginTop:"3px", padding:"3px",
                     width:"70px", textAlign:"center", fontSize:"8px",  color:selectedPackageOption === 2 ? "grey":"white",borderRadius:"30px"}}>
                    Select
                </Text>
            </View>
         </View>
         <Image src={"/translation/premium-pkg.png"} style={{objectFit:"contain", zIndex:"-1111", 
            marginTop:"-115px"}}/>
     </View>
     </>: <>
     {selectedPackageOption === 0 &&  <View style={{marginTop:"20px", marginLeft:"30px", width:"500px"}}>
         <View style={{display:"flex", flexDirection:"row"}}>
            <View style={{margin:"20px", width:"200px"}}>
                <Text style={{color:'#39065F', fontSize:"11px", textAlign:"left", fontWeight:"bold"}}>
                    Economy
                </Text>
                <Text style={{fontWeight:"light", marginTop:"4px", width:"170px", textAlign:'left', fontSize:"7px"}}>
                {language === "English" ? `Automatic translation through one of our software. Quick review of the document by an expert native translator. Translations suitable for understanding a text or for private use,  not suitable for publication.`: 
                   `Traduzione automatica tramite uno dei nostri software. Revisione rapida del documento da parte di un traduttore madrelingua esperto. Traduzioni adatte alla comprensione di un testo o per uso privato, non adatte alla pubblicazione.`}
                                </Text>
            </View>
            <View style={{ marginTop:"30px"}}>
                {content[language]?.economy.items.map((item)=><View style={{display:"flex", flexDirection:"row", marginTop:"1px"}}>
                    <Image src={"/assets/icons/check.png"} style={{objectFit:"contain", width:"10px", height:"10px"}}/>
                    <Text style={{fontSize:"7px", marginLeft:"2px", fontWeight:"normal"}}>
                        {item}
                    </Text>
                </View>)}
                <Text style={{fontSize:"7px", color:"#637381", fontWeight:"normal", marginTop:"20px"}}>
                {language === "English" ? "Delivery guaranteed by" : "Consegna garantita"}
                </Text>
                <Text style={{fontSize:"7px", fontWeight:"bold"}}>
                {subtractDays("Economy", deliveryDateTime,  orgPkgsOption === 0 ? 0 : orgPkgsOption === 2 ? 2 : 1, orgPkgsOption)}

                </Text>
            </View>
            <View style={{ marginLeft:"70px", marginTop:"30px", textAlign:"right"}}>
                <Text style={{fontSize:"13px", textAlign:"right", marginLeft:"20px", fontWeight:"bold"}}>
                {packagesNetTotals?.length > 0 && fCurrency(Number(packagesNetTotals[0]))}

                </Text>
                {packagesPrices?.economy?.length === 1 &&<Text style={{fontSize:"7px", fontWeight:"normal",marginLeft:"10px",  textAlign:"right"}}>
                {language === "English" ? `about €${formatWithTrailingZero(packagesPrices?.economy[0])}/word` : 
                `€${formatWithTrailingZero(packagesPrices?.economy[0])}/parola`}

                </Text>}
                <Text style={{backgroundColor:selectedPackageOption === 0 ?"lightgrey" :"#103996",
                    marginTop:"3px", padding:"3px", width:"70px", textAlign:"center", fontSize:"8px",
                    color:selectedPackageOption === 0 ? "grey":"white", borderRadius:"30px"}}>
                {selectedPackageOption === 0 ? `${language === "English" ? "SELECTED" : "SELEZIONATO"}` :
                 `${language === "English" ? "SELECT":"SELEZIONA"}`}

                </Text>
            </View>
         </View>
         <Image src={"/translation/economy-pkg.png"} style={{objectFit:"contain", zIndex:"-1111", 
            marginTop:"-105px"}}/>
     </View>}
     {selectedPackageOption === 1 &&   <View style={{marginTop:"20px", marginLeft:"30px", width:"500px"}}>
         <View style={{display:"flex", flexDirection:"row"}}>
            <View style={{margin:"20px", width:"200px"}}>
                <Text style={{color:'#A158D6', fontSize:"11px", textAlign:"left", fontWeight:"bold"}}>
                    Professional
                </Text>
                <Text style={{fontWeight:"light", marginTop:"4px", width:"180px", textAlign:'left', fontSize:"7px"}}>
                {language === "English" ? `Translations carried out by a native speaker expert. Review by one of our specialized Project Managers. Use of the Translation Memory upon request included in the price. High quality translations for standard documents.`:
            'Traduzioni eseguite da un esperto madrelingua. Revisione da parte di uno dei nostri Project Manager specializzati. Utilizzo della memoria di traduzione su richiesta incluso nel prezzo. Traduzioni di alta qualità per documenti standard.'}
          </Text>
            </View>
            <View style={{ marginTop:"30px"}}>
                {content[language]?.professional?.items.map((item)=><View style={{display:"flex", marginTop:"1px", flexDirection:"row"}}>
                    <Image src={"/assets/icons/check.png"} style={{objectFit:"contain", width:"10px", height:"10px"}}/>
                    <Text style={{fontSize:"7px", marginLeft:"2px", fontWeight:"normal"}}>
                        {item}
                    </Text>
                </View>)}
                <Text style={{fontSize:"7px", color:"#637381", fontWeight:"normal", marginTop:"20px"}}>
                {language === "English" ? "Delivery guaranteed by" : "Consegna garantita"}
                </Text>
                <Text style={{fontSize:"7px", fontWeight:"bold"}}>
                {subtractDays("Professional", deliveryDateTime, 0, orgPkgsOption  )}
                </Text>
            </View>
            <View style={{ marginLeft:"25px", marginTop:"30px", textAlign:"right"}}>
                <Text style={{fontSize:"13px", textAlign:"right", marginLeft:"20px", fontWeight:"bold"}}>
                {packagesNetTotals?.length > 0 && fCurrency(Number(packagesNetTotals[1]))}
                </Text>
                {packagesPrices?.professional?.length === 1 &&  
                <Text style={{fontSize:"7px", fontWeight:"normal", marginLeft:"10px", textAlign:"right"}}>
                                     {language === "English" ? `about €${formatWithTrailingZero(packagesPrices?.professional[0])}/word` : 
                                     `€${formatWithTrailingZero(packagesPrices?.professional[0])}/parola`}

                </Text>
               }
                <Text style={{backgroundColor:selectedPackageOption === 1 ?"lightgrey" :"#103996",marginTop:"3px", padding:"3px", width:"70px",
                     textAlign:"center", fontSize:"8px",color:selectedPackageOption === 1 ? "grey":"white", borderRadius:"30px"}}>
                {selectedPackageOption === 1 ? `${language === "English" ? "SELECTED" : "SELEZIONATO"}` : `${language === "English" ? "SELECT":"SELEZIONA"}`}

                </Text>
            </View>
         </View>
         <Image src={"/translation/professional-pkg.png"} style={{objectFit:"contain", zIndex:"-1111", 
            marginTop:"-110px"}}/>
     </View>}
     {selectedPackageOption === 2 &&  <View style={{marginTop:"20px", marginBottom:"20px", marginLeft:"30px", width:"500px"}}>
         <View style={{display:"flex", flexDirection:"row"}}>
            <View style={{margin:"20px", width:"200px"}}>
                <Text style={{color:'#43BEF3', fontSize:"11px", textAlign:"left", fontWeight:"bold"}}>
                    Premium
                </Text>
                <Text style={{fontWeight:"light", marginTop:"4px", width:"180px", textAlign:'left', fontSize:"7px"}}>
                {language === "English" ? `Translations carried out by an expert native translator. Quality control by a second native translator. Review by one of our specialized Project Managers. Translations of high-quality content intended for rapid publication.`
                     :'Traduzioni eseguite da un traduttore madrelingua esperto. Controllo di qualità da parte di un secondo traduttore madrelingua. Revisione da parte di uno dei nostri Project Manager specializzati. Traduzioni di contenuti di alta qualità destinati a una rapida pubblicazione.'}
                 </Text>
            </View>
            <View style={{ marginTop:"30px"}}>
                {content[language]?.premium.items.map((item)=><View style={{display:"flex", marginTop:"1px", flexDirection:"row"}}>
                    <Image src={"/assets/icons/check.png"} style={{objectFit:"contain", width:"10px", height:"10px"}}/>
                    <Text style={{fontSize:"7px", marginLeft:"2px", fontWeight:"normal"}}>
                        {item}
                    </Text>
                </View>)}
                <Text style={{fontSize:"7px", color:"#637381", fontWeight:"normal", marginTop:"12px"}}>
                {language === "English" ? "Delivery guaranteed by" : "Consegna garantita"}

                </Text>
                <Text style={{fontSize:"7px", fontWeight:"bold"}}>
                {subtractDays("Premium", deliveryDateTime, orgPkgsOption === 2 ? 0 : orgPkgsOption === 1 ? 1 : 2, orgPkgsOption)}

                </Text>
            </View>
            <View style={{ marginLeft:"20px", marginTop:"30px", textAlign:"right"}}>
                <Text style={{fontSize:"13px", textAlign:"right", marginLeft:"20px", fontWeight:"bold"}}>
                {packagesNetTotals?.length > 0 && fCurrency(Number(packagesNetTotals[2]))}
                </Text>
               {packagesPrices?.premium?.length === 1 && <Text style={{fontSize:"7px", marginLeft:"15px", fontWeight:"normal",  textAlign:"right"}}>
               {language === "English" ? `about €${formatWithTrailingZero(packagesPrices?.premium[0])}/word` : 
        `€${formatWithTrailingZero(packagesPrices?.premium[0])}/parola`}
                </Text>}
                <Text style={{backgroundColor:selectedPackageOption === 2 ?"lightgrey" :"#103996",marginTop:"3px", padding:"3px",
                     width:"70px", textAlign:"center", fontSize:"8px",  color:selectedPackageOption === 2 ? "grey":"white",borderRadius:"30px"}}>
                    Select
                </Text>
            </View>
         </View>
         <Image src={"/translation/premium-pkg.png"} style={{objectFit:"contain", zIndex:"-1111", 
            marginTop:"-120px"}}/>
     </View>}
     </>}

     
    </Page>
  );
};

export default Packages;
