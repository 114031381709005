import { Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import styles from "../styles/AboutUs2.module.css";

export default function AboutUs2() {
  const { details, language} = useSelector(state => state.proposal)


const handleClickLink = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };
  return (
    <div style={{ paddingTop:"20px"}}>
      
      <Typography variant="h4" sx={{textAlign:"center", fontFamily:"'Poppins', sans-serif",
       marginBottom:"7px",pt:1,
         fontWeight:"bold"}}>
            {language === "English" ? "A Solid Group For an All-Inclusive Language Coverage":
            "Un gruppo solido per una copertura linguistica completa"}
            </Typography>
            <div style={{width:"100%", marginInline:"auto", paddingBottom:"50px", marginTop:"50px"}}>
            <Grid container spacing={0} sx={{marginInline:"auto", width:"80%"}}>
    {/* Card 1 */}
    <Grid item xs={6} sx={{marginInline:"auto"}}>
        <div style={{
            position: "relative", 
            overflow: "hidden", 
            width: "100%", 
            height: "280px",
            cursor:"pointer" 
        }} 
        className={styles.card_container}
        >
            <div style={{
                backgroundColor: "#A158D6",
                width: "100%",
                height: "280px",
                backgroundImage: "url('/assets/aboutUs2-lingoyou-bg.webp')",
                backgroundPosition: "top",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                textAlign: "left",
                minBlockSize: "100%",
                backgroundBlendMode: "overlay",
                opacity: 0.8,
                transition: "opacity 0.5s ease",
            }} 
            className={`${styles.card_background} ${styles.card1_background}`} 
            onClick={()=>handleClickLink('https://lingoyou.com')}>
                <div style={{paddingTop:"17%",textAlign:"left", width:"85%", paddingLeft:"20px"}}>
                <img alt="logo" style={{ objectFit: "contain", marginLeft:"-10px", height: "auto", width: "200px" }} src="/assets/logos/whiteLogos/lingoyou.png" />
                <p style={{ color: "white", marginBottom: "5px", marginTop: "-5px" }}>
                        {language === "English" ? "Global vision, local service. 20,000+ translators for complete language solutions."
                        : "Visione globale, servizio locale. Oltre 20.000 traduttori per soluzioni linguistiche complete."}
                    </p>
                    <p style={{ color: "white", textDecorationLine: "underline" }}>
                        {language === "English" ? "Learn more" : "Scopri di più"}
                    </p>
                </div>
            </div>

            {/* Popup overlay */}
            <div  className={styles.popup_overlay}
             style={{backgroundImage: "url('/assets/aboutUs2-lingoyou-bg.webp')"}}>
            </div>
        </div>
    </Grid>

    {/* Card 2 */}
    <Grid item xs={6} sx={{marginInline:"auto"}}>
        <div style={{
            position: "relative", 
            overflow: "hidden", 
            width: "100%", 
            height: "280px",
            cursor:"pointer"

        }} 
        className={styles.card_container}
        >
            <div style={{
                backgroundColor:"#2DA0F0",
                backgroundBlendMode:"overlay",
                width: "100%",
                height: "280px",
                backgroundImage: "url('/assets/aboutUs2-lgacademy-bg.webp')",
                backgroundPosition: "top",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                textAlign: "left",
                minBlockSize: "100%",
                opacity:0.8,
                justifyContent:"right",
                float:"right",
                transition: "opacity 0.5s ease",
            }} onClick={()=>handleClickLink('https://lingoyou.com/academy')}>
              <div style={{paddingTop:"17%",textAlign:"right",float:"right", width:"85%",
                     paddingRight:"20px"}}>
              <img alt="logo" style={{objectFit:"contain", width:"200px"}} 
                    src="/assets/logos/whiteLogos/lingoyouAcademy.png"/>
                    <p style={{color:"white",marginBottom:"5px", marginTop:"-3px"}}>
                    {language === "English" ? "Quality professional training. Language courses, certifications, recovery, exam preparation.":
                    "Formazione professionale di qualità. Corsi di lingua, certificazioni, recupero, preparazione esami."}
                    </p>
                    <p style={{ color: "white", textDecorationLine: "underline" }}>
                        {language === "English" ? "Learn more" : "Scopri di più"}
                    </p>
                </div>
            </div>

            {/* Popup overlay */}
            <div  className={styles.popup_overlay} style={{backgroundImage: "url('/assets/aboutUs2-lgacademy-bg.webp')"}}>
            </div>
        </div>
    </Grid>

    {/* Card 3 */}
    <Grid item xs={6} sx={{marginInline:"auto"}}>
        <div style={{
            position: "relative", 
            overflow: "hidden", 
            width: "100%", 
            height: "280px",
            cursor:"pointer"

        }} 
        className={styles.card_container}
        >
            <div style={{
                backgroundColor:"#2DA0F0",
                backgroundBlendMode:"overlay",
                width: "100%",
                height: "280px",
                backgroundImage: "url('/assets/aboutUs2-rafiky-bg.webp')",
                backgroundPosition: "top",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                textAlign: "left",
                minBlockSize: "100%",
                opacity:0.8,
                transition: "opacity 0.5s ease",
            }} onClick={()=>handleClickLink('https://rafiky.net')}>
                <div style={{paddingTop:"17%",textAlign:"left",width:"85%", paddingLeft:"20px"}}>
                <img alt="logo" style={{objectFit:"contain", width:"200px"}} 
                    src="/assets/logos/whiteLogos/Rafiky.png"/>
                    <p style={{color:"white",marginBottom:"5px", marginTop:"-3px"}}>
                    {language === "English" ? "Our cutting-edge remote interpreting platform offers innovative communication solutions.":
                    "La nostra piattaforma all'avanguardia per l'interpretazione a distanza offre soluzioni di comunicazione innovative."
                    }
                    </p>
                    <p style={{ color: "white", textDecorationLine: "underline" }}>
                        {language === "English" ? "Learn more" : "Scopri di più"}
                    </p>
                </div>
            </div>

            {/* Popup overlay */}
            <div  className={styles.popup_overlay} 
            style={{backgroundImage: "url('/assets/aboutUs2-rafiky-bg.webp')"}}>
            </div>
        </div>
    </Grid>

    {/* Card 4 */}
    <Grid item xs={6} sx={{marginInline:"auto"}}>
        <div style={{
            position: "relative", 
            overflow: "hidden", 
            width: "100%", 
            height: "280px",
            cursor:"pointer"

        }} 
        className={styles.card_container}
        >
            <div style={{
                backgroundColor:"#A158D6",
                width: "100%",
                height: "280px",
                backgroundImage: "url('/assets/aboutUs2-congressi-bg.webp')",
                backgroundPosition: "top",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                textAlign: "left",
                minBlockSize: "100%",
                backgroundBlendMode:"overlay",
                opacity:0.8,
                transition: "opacity 0.5s ease",
            }}
            onClick={()=>handleClickLink('https://congressiinternazionali.it')}>
                <div style={{paddingTop:"17%",textAlign:"right",float:"right", width:"85%",
                     paddingRight:"20px"}}>
                    <img alt="logo" style={{objectFit:"contain", width:"200px"}} 
                    src="/assets/logos/whiteLogos/CI.png"/>
                    <p style={{color:"white", marginBottom:"0px", marginTop:"-27px"}}>
                    {language === "English" ? "Exceptional interpreting agency: selected experts, advanced technology, full coverage. Chosen by companies in Italy and Europe." : 
                    "Agenzia di interpretariato d'eccezione: esperti selezionati, tecnologia avanzata, copertura completa. Scelti da aziende in Italia e in Europa."}                    
                    </p>
                    <p style={{color:"white", textDecorationLine:"underline"}}>
                        {language === "English" ? "Learn more" : "Scopri di più"}
                    </p>
                </div>
            </div>

            {/* Popup overlay */}
            <div  className={styles.popup_overlay} 
            style={{backgroundImage: "url('/assets/aboutUs2-congressi-bg.webp')"}}>
            </div>
        </div>
    </Grid>
</Grid>

            </div>
    </div>
  );
}
