import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';



const content = {
    "Italian":{
        items:[
            { desc: "Ossessione per il cliente: concentrarsi sul cliente e tutto il resto seguirà." },
{ desc: "Innovazione e curiosità sono la nostra benzina. Abbracciamo e guidiamo il cambiamento." },
{ desc: "Integrità, onestà e trasparenza. Costruiamo relazioni aperte e oneste con la comunicazione." },
{ desc: "Assumiamo e sviluppiamo i migliori." },
{ desc: "Esigiamo i più alti standard. Essere grandi non è abbastanza." },
{ desc: "Valutiamo le idee più della gerarchia. Crediamo che la democrazia in azienda funzioni." },
{ desc: "Celebriamo le differenze. Un team che è responsabilizzato, diversificato e inclusivo." },
{ desc: "Agiamo come proprietari e facciamo la cosa giusta." },
{ desc: "Creare un'azienda forte e costruire un mondo migliore non sono obiettivi in conflitto." },
{ desc: "Siamo più motivati dall'impatto che dal denaro." }

        ]
    },
    "English":{
        items:[
            { desc:'Customer Obsession:Focus on the client and all else will follow.'},
            {desc:'Innovation & Curiosity are our Gasoline. We Embrace and Drive Change'},
            { desc:'Integrity, Honesty and Transparency. We Build Open and Honest relationships With Communication'},
            { desc:'We Hire and Develop the Best.'},
            { desc:'We Insist on the Highest Standards. Great just isn’t good enough.'},
            { desc:'We value ideas over hierarchy. We believe that democracy in the company works.'},
            { desc:'We celebrate differences. A team that’s empowered, diverse and inclusive.'},
            { desc:'We act like owners & we do the right thing'},
            { desc:'Creating a strong business and building a better world are not conflicting Goals.'},
            { desc:'We are more motivated by impact than money.'}

        ]
    }
}
 
const contentWhyUs = {
    "English":{ 
     title: "We Provide Effortless Translation Solutions",
     desc: "LingoYou provides accurate and convenient translations worldwide, committed to breaking down language barriers with over ten years of experience and a dedication to quality.",
     icons: [
       {
           img: "book.png",
           en: "Flexible and customizable courses to meet all needs",
       },
       {
           img: "online-inperson.png",
           en: "Immediate availability for online and in-person courses",
       },
       {
           img: "discount.png",
           en: "First lesson free and competitive rates with a 10% discount for new students",
       },
       { 
           img: "certificate.png",
           en: "ISO-9001: 2015 and ISO 17100:2017 quality certifications" 
       },
       { 
           img: "translation.png",
           en: "Courses in 50+ languages for all ages and for business" 
       },
       {
           img: "people.png",
           en: "Group, semi-individual, and individual lessons",
       },
       {
           img: "teach.png",
           en: "Preparation courses for IELTS, TOEFL, PET, FCE, CAE exams",
       },
       {
           img: "building.png",
           en: "Solutions for businesses, individuals, and schools",
       },
       {
           img: "teach.png",
           en: "Innovative teaching methods and cutting-edge online platforms",
       },
       {
           img: "environment.png",
           en: "Motivating environment and welcoming atmosphere",
       },
       {
           img: "learning-app.png",
           en: "Learning app and interactive lessons",
       },
       {
           img: "classroom.png",
           en: "Spacious and well-equipped classrooms"
       },
       {
           img: "english.png",
           en: "Oxford test to assess English language proficiency"
       },
       {
           img: "events.png",
           en: "Recreational activities and dedicated events"
       }
   ],
   },
   "Italian":{
     title: "Forniamo soluzioni linguistiche per ogni esigenza",
     desc: "LingoYou fornisce traduzioni accurate a prezzi competitivi in tutto il mondo, impegnandosi ad abbattere le barriere linguistiche.",
     icons: [
       {
           img: "book.png",
           en: "Corsi flessibili e personalizzabili in base a tutte le esigenze",
       },
       {
           img: "online-inperson.png",
           en: "Disponibilità immediata per corsi online e in presenza",
       },
       {
           img: "discount.png",
           en: "Prima lezione in omaggio e tariffe competitive con un 10% di sconto per i nuovi studenti",
       },
       { 
           img: "certificate.png",
           en: "Certificazioni di qualità ISO-9001: 2015 e ISO 17100:2017" },
       { 
           img: "translation.png",
           en: "Corsi in più di 10 lingue per tutte le età e le necessità" 
       },
       
       {
           img: "people.png",
           en: "Lezioni di gruppo, semi-individuali e individuali",
       },{
           img: "teach.png",
           en: "Corsi di preparazione ad esami IELTS, TOEFL, PET, FCE, CAE",
       },{
           img: "building.png",
           en: "Soluzioni per aziende, privati e scuole",
       },{
           img: "teach.png",
           en: "Metodologie didattiche innovative e piattaforme online all’avanguardia",
       },
       {
           img: "environment.png",
           en: "Ambiente motivante e clima accogliente",
       },
       {
           img: "learning-app.png",
           en: "Learning app e lezioni interattive",
       },
       {
           img:'classroom.png',
           en:'Aule ampie ed attrezzate'
       },
       {
           img:'english.png',
           en:'Test Oxford per verificare il livello di conoscenza della lingua inglese'
       },
       {
           img:'events.png',
           en:'Attività ricreative ed eventi dedicati'
       }
     ],
   }
   };
const Values = ({ language }) => {
 
  console.log(language)
  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
        <View style={{ ...styles.header, marginBottom: 0, width: "100%", display:"flex", flexDirection:"row", marginHorizontal: "auto" }}>
      <Text style={{fontSize:"8px",marginTop:"2px", marginLeft:"5px", color:"#A158D6"}}>
      {language === 'English' ? `COMPANY VALUES` : `VALORI DELL'AZIENDA`}

        </Text>
         <View style={{marginLeft:"430px"}}>
         <Image style={styles.headerRightImg} src="/lingoAcademy/logos/academy.png" />
         </View>
        <View style={styles.headerItem} />
      </View>
      <View style={{display:"flex", marginTop:"40px", marginLeft:"5px", marginBottom:"15px", flexDirection:"row"}}>
        {content[language]?.items?.slice(0,4)?.map((item, index)=>{
            return (
                <View style={{textAlign:"center",justifyContent:"center", width:"130px", flexDirection:"column", padding:"3px",
                    borderRadius:"5px", margin:"5px",
                    border:"1px solid #AA32E1"}}>
                           
                            <Text style={{padding:'5px', color:"#AA32E1", zIndex:"111111", marginLeft:"40px", backgroundColor:"white", width:"40px", marginTop:"-20px",
                                borderRadius:"50%",border:"1px solid #AA32E1"}}>
                               {index + 1}
                            </Text>
                            <Text style={{ fontSize:"7px", textAlign:"center", fontWeight:"normal", margin:"3px"}}>
                            {item?.desc}
                            </Text>
                            </View>
            )
        })}     
      </View> 
      <View style={{display:"flex", marginTop:"20px", marginLeft:"5px", marginBottom:"15px", flexDirection:"row"}}>
        {content[language]?.items?.slice(4, 8)?.map((item, index)=>{
            return (
                <View style={{textAlign:"center",justifyContent:"center", width:"130px", flexDirection:"column", padding:"3px",
                    borderRadius:"5px", margin:"5px",
                    border:"1px solid #AA32E1"}}>
                           
                            <Text style={{padding:'5px', color:"#AA32E1", backgroundColor:"white", marginLeft:"40px",zIndex:"111111", width:"40px", marginTop:"-20px",
                                borderRadius:"50%",border:"1px solid #AA32E1"}}>
                               {index+5}
                            </Text>
                            <Text style={{ fontSize:"7px", textAlign:"center", fontWeight:"normal", margin:"3px"}}>
                            {item?.desc}
                            </Text>
                            </View>
            )
        })}     
      </View> 
      <View style={{display:"flex", marginTop:"20px", marginLeft:"140px", marginBottom:"15px", flexDirection:"row"}}>
        {content[language]?.items?.slice(8)?.map((item, index)=>{
            return (
                <View style={{textAlign:"center",justifyContent:"center", width:"130px", flexDirection:"column", padding:"3px",
                    borderRadius:"5px", margin:"5px",
                    border:"1px solid #AA32E1"}}>
                           
                            <Text style={{padding:'5px', color:"#AA32E1", backgroundColor:"white", marginLeft:"40px",zIndex:"111111", width:"40px", marginTop:"-20px",
                                borderRadius:"50%",border:"1px solid #AA32E1"}}>
                               {index+9}
                            </Text>
                            <Text style={{ fontSize:"7px", textAlign:"center", fontWeight:"normal", margin:"3px"}}>
                            {item?.desc}
                            </Text>
                            </View>
            )
        })}     
      </View> 
      <View style={{ ...styles.header, marginBottom: 0, width: "94%", marginHorizontal: "auto" }}>
        <View style={{ ...styles.headerItem }}>
        <Text style={{...styles.headerItemText, color:"white"}}>
          {language === 'English' ? `WHY US` : `PERCHÉ NOI`}

          </Text>
        </View>
        
      </View>
      <View style={{marginTop:"15px", textAlign:"center", justifyContent:"center"}}>
          
           <Text style={{color:"white", fontSize:"17px", marginLeft:"-10px", 
            fontWeight:"bold", textAlign:"center" }}>
            {contentWhyUs[language]?.title}
           </Text>
           <Text style={{color:"white", fontSize:"9px", width:"400px", 
            fontWeight:"normal", textAlign:"center", marginLeft:"90px" }}>
            {contentWhyUs[language]?.desc}
           </Text>
      </View>
     
       <View style={{...styles.whyUsIcons, marginLeft:"10px"}}>
        {contentWhyUs[language]?.icons?.slice(0,5).map((icon)=>
         <View style={{width:"100px", textAlign:"center", marginLeft:"7px"}}>
          <Image style={{...styles.whyUsIconBottom, marginLeft:"30px"}} src={`/lingoAcademy/icons/${icon?.img}`}/>
          <Text style={{...styles.whyUsIconText,width:"100%",fontSize:"7px", color:"white", textAlign:"center"}}>
           {icon?.en}
        </Text>  
         </View>
        )}
        
      </View>
      <View style={{...styles.whyUsIcons, marginLeft:"10px"}}>
        {contentWhyUs[language]?.icons?.slice(5, 10).map((icon)=>
         <View style={{width:"100px", textAlign:"center", marginLeft:"7px"}}>
          <Image style={{...styles.whyUsIconBottom, marginLeft:"30px"}} src={`/lingoAcademy/icons/${icon?.img}`}/>
          <Text style={{...styles.whyUsIconText,width:"100%",fontSize:"7px", color:"white", textAlign:"center"}}>
           {icon?.en}
        </Text>  
         </View>
        )}
        
      </View>
      <View style={{...styles.whyUsIcons, marginLeft:"50px"}}>
        {contentWhyUs[language]?.icons?.slice(10).map((icon)=>
         <View style={{width:"100px", textAlign:"center", marginLeft:"7px"}}>
          <Image style={{...styles.whyUsIconBottom, marginLeft:"30px"}} src={`/lingoAcademy/icons/${icon?.img}`}/>
          <Text style={{...styles.whyUsIconText,width:"100%",fontSize:"7px", color:"white", textAlign:"center"}}>
           {icon?.en}
        </Text>  
         </View>
        )}
        
      </View>
      <Image src={"/translation/aboutUs-footer-bg-pdf.png"} style={{objectFit:"cover", zIndex:"-1111",
         width:"100%", marginTop:"-400px", height:"440px"}}/>
    </Page>
  );
};

export default Values;