import React from 'react';
import styles from '../styles/T&CsWeb.module.css';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { FaRegHandPointLeft } from 'react-icons/fa';
import { BsVectorPen } from 'react-icons/bs';
import { addName } from '../../../redux/proposalSlice';

function formatDate(param) {
  // Extracting date components
  const date = new Date(param);
  var day = date.getDate();
  var month = date.getMonth() + 1; // Months are zero indexed
  var year = date.getFullYear() % 100; // Getting last two digits of the year
  var hours = date.getHours();
  var minutes = date.getMinutes();

  // Adding leading zeros if necessary
  day = (day < 10 ? '0' : '') + day;
  month = (month < 10 ? '0' : '') + month;
  year = (year < 10 ? '0' : '') + year;
  hours = (hours < 10 ? '0' : '') + hours;
  minutes = (minutes < 10 ? '0' : '') + minutes;

  // Concatenating date components
  var formattedDate = day + '/' + month + '/' + year + ' ' + hours + ':' + minutes;

  return formattedDate;
}
function subtractDays (dateStr, days) {
  console.log("Option", days)
  var date = new Date(dateStr);
  if(days === 2){
    date.setDate(date.getDate() + 1);
  }else if(days === 1){
    date.setDate(date.getDate());
  }else if(days === 0){
    date.setDate(date.getDate() - 1 );

  }else{
    date.setDate(date.getDate());
  }
  return date;
}
export default function TermsAndCondition({
  handleAcceptance
}) {
  const {language, name, details:data, isBtn, selectedPackageOption, packagesNetTotals } = useSelector((state)=>state.proposal);
 const dispatch = useDispatch()
 const {workingDaysToDeadline, companyNameAndId, additionalService, brand, additionalService2, status} = data;

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState('');
  const [openTerms, setOpenTerms] = React.useState(false);
  const endDate = (packagesNetTotals?.length > 0 && (status !== "Quote-Won" && status !== "Sent To PM")) ? new Date(subtractDays(data?.deliveryDateTime, selectedPackageOption)) : new Date(data?.deliveryDateTime)
  const endDateDay = String(endDate.getDate()).padStart(2, '0');
  const endDateMonth = String(endDate.getMonth() + 1).padStart(2, '0'); //January is 0!
  const endDateYear = String(endDate.getFullYear()).slice(-2);
  const endingDate = data?.deliveryDateTime && `${endDateDay +  '/' +  endDateMonth +  '/' +  endDateYear}`
  
  // Format the hours and minutes as needed
  const endDateHours = endDate.getHours() < 10 ? '0' + endDate.getHours() : endDate.getHours();
  const endDateMins = endDate.getMinutes() < 10 ? '0' + endDate.getMinutes() : endDate.getMinutes();
  const swornDeliveryDateServices = additionalService.length > 0 && additionalService?.filter(((service)=>service?.swornDeliveryDateTime))
  const swornDeliveryDateServicesOptions = additionalService2
  ?.map(subArray =>
    subArray.filter(item =>
      item.swornDeliveryDateTime !== "" && item.swornDeliveryDateTime !== null
    )
  )
  // .filter(subArray => subArray.length > 0);
  const swornDeliveryDateServicesOptionsLength = swornDeliveryDateServicesOptions?.length > 0 && swornDeliveryDateServicesOptions?.filter(subArray => subArray.length > 0);
 
  const handleClickOpenTerms = () => {
    setError('');
    setOpenTerms(true);
  };
  const handleClickCloseTerms = () => {
    setOpenTerms(false);
    handleAcceptance();
  };
  const handleCloseTerms = () => {
    setOpenTerms(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
    handleAcceptance();
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div style={{borderTop:"1px solid #2DA0F0"}}
 className={styles.desktop7}>
      <div
    style={{
      textAlign: "right",
      width: "97%",
      padding:"2px",
      marginInline:"auto",
      display:"flex",
      justifyContent: "space-between",
      borderBottom: "2px solid #AA32E1",

    }}
  >
     <p>
           <span  style={{fontWeight:"500", color: "#AA32E1", fontSize: "20px"}}
           >
{language === "English" ? 'Terms and conditions' : "Termini e Condizioni"}  
    </span>
    </p>
    <img
      src={brand && brand?.academy ? "/lingoAcademy/logos/academy.png":"/translation/logos/lingoyou.png"}
      style={{ marginRight: "20px", objectFit:"contain" }}
      alt="logo"
    />
  </div>
      <Grid container spacing={3} sx={{ marginTop: '10px' }}  className={styles.sections}>
      <Grid item xs={12}>
          <p style={{fontSize:"28px", fontWeight:"600"}}>
          {language === "English" ? 'Terms and conditions' : "Termini e Condizioni"}
          </p>
        </Grid>
        <Grid item xs={4}>
        <p className={styles.titles}><b>
          {language === "English" ? `Quote expiry period` : `Scadenza`}: 
          </b></p>
          <p>
          {language === 'English'
                ? `-	It is necessary to send confirmation of the quote by `
                : `- È necessario inviare conferma del preventivo entro il `}
            <b style={{fontSize:"15px"}}>
            {formatDate(data?.quoteDeadline)}.
            </b>
             
          </p>
          <p>
          {language === "English" ? `-	After this date, the quote may be subject to change.` : 
          `-	Oltre tale data il preventivo potrebbe subire delle variazioni.`}

          </p>
        
         <p className={styles.titles}><b>
          {language === "English" ? `Services` : `Servizi`}: 
          </b></p>
          <p>
        
        {language === "English" ? `- The LingoYou Group Srl entity indicated in the above Estimate ("LingoYou Group Srl ") undertakes to provide the Client with language and other courses by means of its own project management system ` : 
        `- Il soggetto LingoYou Group Srl indicato nel suddetto Preventivo ("LingoYou Group Srl ") si impegna a fornire al Cliente corsi di lingua e di altro tipo per mezzo del proprio sistema di gestione dei progetti`}
         </p>
         <p>
        
        {language === "English" ? `- LingoYou Group Srl will make every commercially reasonable effort to provide language or other courses within the timescales estimated in writing by LingoYou Group Srl or, if no written estimate has been provided by LingoYou Group Srl, the latter will comply to its obligations in accordance with its usual "standard delivery" parameters, subject to circumstances existing from time to time` : 
        `- LingoYou Group Srl compirà ogni sforzo commercialmente ragionevole al fine di fornire corsi di lingua o di altro tipo rispettando le tempistiche stimate per iscritto da LingoYou Group Srl o, qualora non sia stata fornita alcuna stima scritta da parte di LingoYou Group Srl , quest'ultimo adempierà ai propri obblighi in conformità con i propri consueti parametri di "consegna standard", soggetti alle circostanze esistenti di volta in volta`}
         </p>
         <p>
        
        {language === "English" ? `- "Urgent" projects requiring completion within shorter timeframes than the standard delivery period will be accepted on the understanding that, in certain cases, the usual level of quality and project management of LingoYou Group Srl may not be met. Additionally, LingoYou Group Srl reserves the right to apply additional charges due to the complexity and costs involved in fulfilling such requests.` : 
        `- I progetti "urgenti" che richiedono il completamento entro tempistiche più brevi rispetto alla consegna standard verranno accettati sull'assunto che in determinati casi il livello consueto di qualità e di gestione dei progetti di LingoYou Group Srl  potrebbe non essere rispettato e che LingoYou Group Srl  possa applicare tariffe aggiuntive a causa della complessità e dei costi necessari a soddisfare tali richieste.`}
         </p>
         <p className={styles.titles}><b>
          {language === "English" ? `Quote` : `Preventivo`}: 
          </b></p>
         <p>
        
        {language === "English" ? `- This Quote is strictly bound by the Terms & Conditions set forth herein and is based on the information and files made available to LingoYou Group Srl on the date of the Quote creation.` : 
        `-	Il presente Preventivo è strettamente vincolato ai Termini & Condizioni qui indicati e si basa sulle informazioni e sui files resi disponibili a LingoYou Group Srl alla data del Preventivo.  `}
         </p>
         <p>
        
        {language === "English" ? `- In order to ensure that the line of work is respected, LingoYou Group Srl requires the timely receipt of all materials for the execution of the desired services and clarification of the requirements.` : 
        `-	Al fine di garantire il rispetto della linea di lavoro, LingoYou Group Srl  necessita della ricezione tempestiva di tutti i materiali per l'esecuzione dei servizi desiderati e di un chiarimento dei requisiti.`}
         </p>
         
         <p>
         {language === "English" ? `- Any delay due to failure to receive the above shall relieve LingoYou Group Srl of the failure to deliver in proportion to the time taken by the customer to clarify the requirements and for the sections of the work affected by the requirements.` : 
        `-	Eventuali ritardi dovuti alla mancata ricezione di quanto sopra esposto esonerano LingoYou Group Srl  dalla mancata consegna in proporzione al tempo impiegato dal cliente per chiarire i requisiti e per le sezioni del lavoro interessate dai requisiti.`}
         </p>

        <p>
        
        {language === "English" ? `- The specific terms in the above quote will supersede any conflicting provisions in these Terms & Conditions.` : 
        `-	I termini specifici nel Preventivo di cui sopra sostituiranno qualsiasi disposizione contrastante nei presenti Termini & Condizioni.`}
         </p> 
       
       
          <p className={styles.titles}><b>
          {language === "English" ? `Fees and payments` : `Commissioni e pagamenti`}: 
          </b></p>
          <p>
          {language === 'English' ? `- Payment terms`: `- Termini di pagamento`}: {' '}
         <b style={{fontSize:"15px"}}>
         {companyNameAndId?.paymentTerms === 'Immediate'
                        ? `${language === "English" ? "Prepayment" : "Pagamento Anticipato" }`
                        : `${language === "English" ? companyNameAndId?.paymentTerms : `${String(companyNameAndId?.paymentTerms).includes("days") ? 
                        String(companyNameAndId?.paymentTerms).replace("days", "giorni") : 
                        String(companyNameAndId?.paymentTerms)}`}`
                        }.
         </b>
          
          </p>
          <p>
            {language === "English" ? "- Payments by Bank Transfer and Cash" : "- Pagamenti tramite Bonifico Bancario o contanti."}
          </p>
          <p>
          {language === "English" ? `- LingoYou Group Srl agrees to charge, and the Client agrees to pay, the fees for services provided by LingoYou Group Srl or its affiliates in accordance with LingoYou Group Srl's current rates or, where applicable, at rates separately negotiated and agreed upon in writing by LingoYou Group Srl and the Client prior to the commencement of the services.` : 
          `-	LingoYou Group Srl accetta di addebitare, e il Cliente accetta di pagare, le commissioni per i servizi forniti da LingoYou Group Srl o dalle sue consociate secondo le tariffe correnti di LingoYou Group Srl o, qualora applicabile, secondo tariffe negoziate separatamente e concordate per iscritto da LingoYou Group Srl e dal Cliente prima dell'inizio della prestazione dei servizi.`}
 
          </p>
          
          <p >
          {language === "English" ? `- All payments must be made within thirty (30) days of the invoice date (or as stipulated in the contract).` : 
          `-	Tutti i pagamenti devono essere effettuati entro le tempistiche concordate (o in base a quanto previsto dal contratto).`}
         
          </p>
          <p>
          {language === "English" ? `In the event of a good-faith dispute regarding an invoice item, the Client agrees to promptly inform LingoYou Group Srl in writing (including a description of the reason for the dispute) within the aforementioned thirty (30) days and commits to paying the undisputed portion as outlined in this document.` : 
          `In caso di eventuale controversia in buona fede riguardante una voce relativa alla fattura, il Cliente si impegna a informare tempestivamente LingoYou Group Srl circa la natura della controversia per iscritto (includendo una descrizione della motivazione della controversia) entro il suddetto periodo di trenta (30) giorni e si impegna a pagare la porzione non contestata come stabilito nel presente documento.`}
          </p>
          <p>
          {language === "English" ? `- Any undisputed amount not paid within thirty (30) days of the date of invoice issued by LingoYou Group Srl will accrue interest at the rate of ` : 
          `-	Qualsiasi importo non contestato non pagato entro trenta (30) giorni dalla data di emissione della fattura di LingoYou Group Srl maturerà interessi al tasso di `}
           <b>
           1,5% 
           </b>
           {language === "English" ? ` until full payment, or at the highest legal interest rate, whichever is lower (interest will be charged daily).` :
          ` fino al completo pagamento, ovvero al massimo tasso di interesse legale, qualora inferiore (gli interessi verranno addebitati quotidianamente).`}

          </p>
          <p>
          {language === "English" ? `- All amounts due by the Client under this Agreement shall be paid to LingoYou Group Srl without compensation or counterclaim and without deduction or withholding.  ` : 
          `-	Tutti gli importi dovuti dal Committente in virtù del presente Accordo saranno corrisposti a LingoYou Group Srl senza compensazione o domanda riconvenzionale e senza detrazione o ritenuta. `}
           
          </p>
          <p>
          {language === "English" ? `- The acceptance by LingoYou Group Srl of the partial payment or any payment less than the total amount due at any time does not constitute a release or waiving of LingoYou Group Srl's rights to unpaid amounts.` : 
          `-	L'accettazione da parte di LingoYou Group Srl del pagamento parziale o di qualsiasi pagamento inferiore all'importo totale dovuto in qualsiasi momento non costituisce una liberatoria o una rinuncia ai diritti di LingoYou Group Srl agli importi non corrisposti.`}
           
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Confidentiality` : `Riservatezza`}: 
          </b></p>
          <p>
          {language === "English" ? `- Both Parties agree that any information or documents provided by one Party to the other (including the Terms & Conditions of this agreement and the details of the applicable fee structure) shall remain strictly confidential, except to the extent that a Party is required by law or court order to disclose them.` : 
          `-	Entrambe le Parti concordano che qualsiasi informazione o documento fornito da una Parte all'altra (inclusi Termini & Condizioni del presente accordo e i dettagli della struttura tariffaria applicabile) rimarranno strettamente riservati, salvo nella misura in cui una Parte è obbligata dalla legge o da un'ordinanza del tribunale a rivelarli.`}

          </p>
        </Grid>
        <Grid item xs={4} sx={{marginTop:"3px"}}>
        
         
          
          <p className={styles.titles}><b>
          {language === "English" ? `Non-solicitation` : `Non sollecitazione`}: 
          </b></p>
          <p style={{lineHeight: `${language === "English"? "24px": "24px"}`}}>
          {language === "English" ? `- At any time when LingoYou Group Srl is providing the Service to the Client under this document and for two (2) years thereafter, the Client will not solicit or induce any employee, translator, interpreter or consultant of LingoYou Group Srl engaged in the provision of services to the client to resign or to end the engagement with LingoYou Group Srl.` : 
          `-	In qualsiasi momento in cui LingoYou Group Srl stia fornendo il servizio al Committente ai sensi del presente documento e per due (2) anni a seguire, il Committente non solleciterà né indurrà alcun dipendente, traduttore, interprete o consulente di LingoYou Group Srl impegnato nella fornitura dei servizi al Committente a licenziarsi o a terminare l'impegno con LingoYou Group Srl . `}
          </p>
          <p>
          {language === "English" ? `- The Client acknowledges to LingoYou Group Srl the right of execution in a specific form or of injunctive or other equitable relief in order to enforce this provision.` : 
          `-	Il Committente riconosce a LingoYou Group Srl il diritto di esecuzione in forma specifica o di un provvedimento ingiuntivo o altro provvedimento equo al fine di applicare la presente disposizione. `}
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Warranties` : `Garanzie`}: 
          </b></p>
          <p style={{lineHeight: `${language === "English"? "23px": "23px"}`}}>
          {language === "English" ? `- LingoYou Group Srl guarantees to the Client that all services provided will comply in all relevant respects with the specifications set out in the applicable Quote. In the event of failure to promptly contest the claim, nothing can be claimed by way of compensation. The Supplier shall also not be liable for defects attributable to the user or to a third party unrelated to the provision of the services.` : 
          `-	LingoYou Group Srl garantisce al Committente che tutti i servizi effettuati saranno conformi in tutti gli aspetti rilevanti alle specifiche stabilite nel Preventivo applicabile. In caso di mancata tempestiva contestazione nulla potrà essere richiesto a titolo risarcitorio. Il Fornitore non potrà altresì essere ritenuto responsabile per difetti imputabili all’utente o ad un terzo estraneo alla fornitura dei servizi.`}

          </p>
          <p>
          {language === "English" ? `- Except as provided in this section, LingoYou Group Srl makes no warranties or representations, whether express, implied by law or otherwise, with respect to the services or any final products provided hereunder and specifically denies any implied warranties of merchantability, title, non-infringement and fitness for a particular purpose.` : 
          `-	Salvo quanto stabilito nella presente sezione, Lingoyou Group Srl non fornisce alcuna garanzia o dichiarazione, sia essa esplicita, implicitamente prevista dalla legge o altrimenti, in relazione ai servizi o a eventuali prodotti finali forniti ai sensi del presente documento e, nello specifico, esclude qualsiasi garanzia implicita di commerciabilità, titolo, non-violazione e idoneità a un particolare scopo.`}

          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Remedies` : `Rimedi`}: 
          </b></p>
          <p>
          {language === "English" ? `- The Supplier may remedy the identified defect upon prompt written objection (within five (5) working days from the provision of the Service) of any lack of conformity during the performance of the Service provided by LingoYou Group Srl.` : 
          `-	Il Fornitore può porre rimedio al difetto rilevato previa tempestiva contestazione scritta (entro cinque (5) giorni lavorativi dalla fornitura del servizio) di eventuali difetti di conformità durante l’esecuzione del servizio fornito da LingoYou Group Srl.`}
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Limitation of liability` : `Limitazione di responsabilità `}: 
          </b></p>
          <p style={{lineHeight:`${language === "English" ? "22px" : "22px"}`}}>
          {language === "English" ? `- The liability of each party (whether defined by contract, whether arising out of wrongful act, negligence, direct liability, regulatory provision or otherwise) to the other Party and any third party in respect of or concerning this Agreement shall be cumulatively limited to direct and actual damages equal to or less than the amount payable to LingoYou Group Srl under this Agreement for the portion of the services it has given the source of that complaint.` : 
          `-	La responsabilità di ciascuna Parte (sia definita da un contratto, che derivante da atto illecito, negligenza, diretta responsabilità, disposizione normativa o altro) nei confronti dell'altra Parte e di eventuali terze parti riguardante o relativa al presente Accordo sarà limitata cumulativamente ai danni diretti ed effettivi pari a un ammontare non superiore a quello da corrispondere a LingoYou Group Srl ai sensi del presente Accordo per la porzione dei servizi che ha dato origine a tale reclamo.`}
          </p>
          <p style={{lineHeight:`${language === "English" ? "22px" : "22px"}`}}>
          {language === "English" ? `- Without prejudice to gross negligence or misconduct, in no event shall either Party be liable for the following types of damage and each Party hereby waives its right in respect of the following types of damage: consequential; incidental; punitive; special; exemplary; indirect; loss of profits; loss of refunds; loss of data; or loss of savings. Each Party waives its right to such damage, even if that party has been informed of the possibility of such damage occurring.` : 
          `-	Escludendo colpa grave o condotta scorretta, in nessun caso alcuna delle Parti sarà responsabile per i seguenti tipi di danno e ciascuna Parte rinuncia con la presente al proprio diritto in relazione ai seguenti tipi di danno: conseguente; accidentale; punitivo; speciale; esemplare; indiretto; perdita di profitti; perdita di rimborsi; perdita di dati; o perdita di risparmi. Ciascuna Parte rinuncia al diritto relativo ai suddetti danni, anche se tale Parte è stata informata della possibilità di avvenimento di tali danni.`}
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Applicable Law and Competent Court` : `Legge applicabile e Foro Competente`}: 
          </b></p>
          <p style={{lineHeight:`${language === "English" ? "22px" : "22px"}`}}>
          {language === "English" ? `- Except as otherwise provided in the Statement of work, this Agreement shall be governed by Italian law without application of its principles of conflict of laws.` : 
          `-	Salvo quanto diversamente previsto nella Dichiarazione di lavoro, il presente Accordo sarà regolato dalla Legge Italiana senza applicazione dei suoi principi di conflitto di leggi.`} 
          </p>
          <p>
          {language === "English" ? `- Any action in connection with this Agreement shall be brought before the State or Federal Court of Milan and the Parties consent exclusively to the seat and personal authority provided for therein.` : 
          `-	Qualsiasi causa connessa al presente Accordo dovrà essere intentata presso il Tribunale Statale o Federale di Milano e le Parti acconsentono esclusivamente alla sede e alla giurisdizione personale ivi previste.`} 
         
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Agreement` : `Accordo`}: 
          </b></p>
          <p>
          {language === "English" ? `- Unless expressly agreed otherwise by both Parties in writing, as in a Framework Agreement (FA), if any, this Quote together with these Terms & Conditions constitutes the Parties’ entire Agreement and understanding with respect to the subject matter hereof and supersedes all prior agreements (oral or written) and intended in this regard.` : 
          `-	Salvo quanto diversamente concordato espressamente da entrambe le Parti per iscritto, come in un Accordo Quadro (AQ), se presente, questo preventivo insieme a questi Termini & Condizioni, costituisce l'intero Accordo e comprensione delle Parti in relazione all'oggetto del presente documento e sostituisce tutti i precedenti accordi (orali o scritti) e intese al riguardo. `}
          </p>
        </Grid>
        <Grid item xs={4}>
      
          <p style={{lineHeight: `${language === "English"? "22px": "22px"}`}}>
          {language === "English" ? `- No representations, promises, inducements or intentions have been made by any Party not incorporated herein, and no Party shall be bound by or liable for any alleged representations, promises or inducements not so established herein, including, but not limited to, the Terms & Conditions of the Customer’s pre-printed documents (e.g. purchase orders, order confirmations, etc.).` : 
          `-	Nessuna dichiarazione, promessa, incentivo, dichiarazione o intenzione è stata fatta da alcuna Parte che non sia incorporata nel presente documento, e nessuna Parte sarà vincolata o responsabile per qualsiasi presunta dichiarazione, promessa, incentivo o dichiarazione non così stabilita nel presente documento, inclusi - senza limitazioni - i Termini & Condizioni dei documenti prestampati del Cliente (ad esempio, ordini di acquisto, conferme d'ordine e simili). `}
          </p>
          <p style={{lineHeight: `${language === "English"? "22px": "22px"}`}}>
          {language === "English" ? `- This Agreement may be modified, amended, superseded or canceled only by a written instrument signed by each Party, and all terms, understandings, representations, warranties or conditions herein may be waived only by a written instrument executed by the Party to be bound by such waiver.` : 
          `-	Il presente Contratto può essere modificato, emendato, sostituito o annullato solo da uno strumento scritto firmato da ciascuna delle Parti e tutti i termini, patti, dichiarazioni, garanzie o condizioni del presente documento possono essere derogati solo da uno strumento scritto eseguito dalla Parte per essere vincolato da tale rinuncia.`}
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Failure to participate or withdrawal` : `Mancata partecipazione o rinuncia`}: 
          </b></p>
          <p style={{lineHeight: `${language === "English"? "22px": "22px"}`}}>
          {language === "English" ? `- If for personal reasons beyond the control of LingoYou Group Srl, it becomes necessary to interrupt the attendance of the lessons, it will not be possible to request a refund of the amount paid.` : 
          `- Qualora per motivi personali indipendenti dalla LingoYou Group Srl, si rendesse necessario interrompere la frequenza delle lezioni non sarà possibile richiedere la restituzione della somma versata.`}
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Changes to the class schedule/course closure` : `Modifiche al calendario delle lezioni/chiusura del corso`}: 
          </b></p>
          <p style={{lineHeight: `${language === "English"? "22px": "22px"}`}}>
          {language === "English" ? `- LingoYou Group Srl reserves the right, for reasons beyond its control, to modify the calendar and/or cancel the course by giving timely notice and refunding the fees paid, in proportion and net of the lessons already regularly held.` : 
          `- LingoYou Group Srl si riserva la facoltà, per cause non dipendenti dalla propria volontà, di modificare il calendario e/o annullare il corso dandone tempestiva comunicazione e restituendo le quote versate, in proporzione ed al netto delle lezioni già regolarmente svolte`}
          </p>
          <p style={{lineHeight: `${language === "English"? "22px": "22px"}`}}>
          {language === "English" ? `- Lessons can be cancelled or postponed by the Client up to 48 hours before the scheduled lesson date. Lessons cancelled after the above time will be charged to the Client and considered as effective.` : 
          `- Le lezioni possono essere cancellate o posticipate dal Cliente massimo 48 ore prima della data stabilita per la lezione. Le lezioni annullate dopo l’orario suddetto verranno addebitate al Cliente e considerate come effettive.`}
          </p>
          <p style={{lineHeight: `${language === "English"? "22px": "22px"}`}}>
          {language === "English" ? `- In case of impossibility for the Client to attend the course (due to illness or other) the lessons will still be charged.` : 
          `- In caso di impossibilità da parte del Cliente a frequentare il corso (causa malattia o altro) le lezioni verranno comunque addebitate.`}
          </p>
          <p style={{lineHeight: `${language === "English"? "22px": "22px"}`}}>
          {language === "English" ? `- Should the Authority take measures that limit, in whole or in part, for public health and safety reasons, in-person participation, LingoYou Group Srl reserves the right to convert the hours of in-person lessons into the same number of hours delivered in distance learning, without this entailing any changes in the amount paid.` : 
          `- Qualora intervengano provvedimenti dell’Autorità che limitino in tutto o in parte per esigenze di salute e sicurezza pubbliche la partecipazione in presenza, LingoYou Group Srl si riserva la facoltà di convertire le ore di lezione in presenza in altrettante ore erogate in didattica a distanza, senza che ciò comporti variazioni del corrispettivo versato.`}
          </p>
          <div style={{marginTop:`${language === "English" ? "20px": "15px"}`}} className={styles.sign}>
          <div style={{display:'flex', textAlign:"left", margin:"20px"}}>
                <p style={{fontSize:"14px", fontWeight:"600"}}>
                  {
                language === 'English' ? `Signature for acceptance of quote CI/${data?.orderNumber}:` : 
                `Firma per accettazione Preventivo CI/${data?.orderNumber}:`
              }                
                </p>
               {(data?.status !== 'Quote-Won' && data?.status !== "Sent To PM") && isBtn ? (
                    <Button sx={{ width: "150px",height:"37px", fontSize: "16.09",borderRadius:"35px", 
                      color:"white", backgroundColor:"#2da0f0" }} onClick={handleClickOpen}>
                       <img alt='pen' style={{marginTop:"-3px"}} src="/assets/icons/mdi_pen.png"/>  FIRMA</Button>
                ) : <span style={{ fontFamily: "Allura", fontSize: "26px" }}>{name || data?.quoteSignature}</span>
                }
               </div>
          </div>
          <div style={{marginTop:"10px"}} className={styles.sign}>
          <div style={{display:"flex"}}>
                <p style={{fontSize:"14px", fontWeight:"600"}}>
                  {
                language === 'English' ? 'Signature for acceptance of the Terms & Conditions:' : 'Firma per accettazione Termini & Condizioni:'
              }                  
                </p>
               {(data?.status !== 'Quote-Won' && data?.status !== "Sent To PM") && isBtn ? (
                    <Button sx={{ width: "150px",height:"37px", fontSize: "16.09",borderRadius:"35px", 
                      color:"white", backgroundColor:"#2da0f0" }} onClick={handleClickOpen}>
                       <img alt='pen' style={{marginTop:"-3px"}} src="/assets/icons/mdi_pen.png"/>  FIRMA</Button>
                ) : <span style={{ fontFamily: "Allura", fontSize: "26px" }}>{name || data?.quoteSignature}</span>
                }
               </div>
          </div>
          <div style={{marginTop:"10px"}} className={styles.sign}>
          <div style={{display:"flex"}}>
                <p style={{fontSize:"14px", fontWeight:"600"}}>
                  {
                language === 'English' ? 'Signature for acceptance Terms and conditions of the site' : 'Firma per accettazione Termini & condizioni del sito '
              }<br/> <a style={{color:"#4183C4",fontStyle:"italic", fontSize:"10px"}} href='http://www.lingoyou.com/terminicondizioni'>(http://www.lingoyou.com/terminicondizioni)</a>                 
                </p>
               {(data?.status !== 'Quote-Won' && data?.status !== "Sent To PM") && isBtn ? (
                    <Button sx={{ width: "150px",height:"37px", fontSize: "16.09",borderRadius:"35px", 
                      color:"white", backgroundColor:"#2da0f0" }} onClick={handleClickOpen}>
                       <img alt='pen' style={{marginTop:"-3px"}} src="/assets/icons/mdi_pen.png"/> FIRMA
                    </Button>
                ) : <span style={{ fontFamily: "Allura", fontSize: "26px" }}>{name || data?.quoteSignature}</span>
                }
               </div>
          </div>          
        </Grid>
       
        <Grid item xs={4}>
          
          </Grid>
          <Grid item xs={4}>
          
          </Grid>
        <Grid item xs={4} >
             
        </Grid>
      </Grid>
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          sx={{
            '& .css-y4brsv-MuiPaper-root-MuiDialog-paper.MuiPaper-rounded': { borderRadius: '5px' },
            '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '500px', textAlign: 'center', borderRadius: '5px' },
            borderRadius: '5px',
          }}
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '-webkit-fill-available', textAlign: 'center' },
              color: 'rgba(0,0,0,.65)',
              fontWeight: '600',
              fontSize: '25px',
            }}
          >

            {
              language === 'English' ? 'Accept the quote' : 'Accetta il preventivo'
            }
          </DialogTitle>
          <DialogContent
            id="dialog_content"
            sx={{
              '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '-webkit-fill-available', textAlign: 'center' },
            }}
          >
            <span style={{ display: "inline-flex", marginTop: "30px" }}>
              <BsVectorPen style={{ fontSize: "25px", marginTop: "5px" }} />  <input placeholder={`${language === "English" ? "Sign here":"Firma qui"}`} value={name} onChange={(e) => dispatch(addName(e.target.value))} />{' '}
              <FaRegHandPointLeft style={{ fontSize: "25px", marginTop: "5px" }} />

            </span>
            {(error && !name && isBtn && data?.status !== 'Quote-Won') && <span className={styles.error}>{error} <br /></span>}
            <DialogContentText id="alert-dialog-description">{name}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button

              sx={{
                background: '#7cd1f9',
                color: 'white',
                border: 'none',
                boxShadow: 'none',
                fontWeight: '600',
                fontSize: '14px',
                padding: '10px 10px',
                cursor: 'pointer',
                '&:disabled': {
                  cursor: 'not-allowed',
                  pointerEvents: 'none',
                  color: 'white',
                  background: 'grey',
                },
              }}
              disabled={!name && true}
              onClick={handleClickClose}
              autoFocus
            >
              Conferma preventivo
              {
                language === 'English' ? 'Quote confirmation                ' : 'Accetta il preventivo'
              }
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openTerms}
          onClose={handleCloseTerms}
          aria-labelledby="alert-dialog-title"
          sx={{ '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '500px', textAlign: 'center' } }}
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '-webkit-fill-available', textAlign: 'center' },
            }}
          >
            {
              language === 'English' ? 'Accept the Terms & Conditions' : 'Accetta i Termini e Condizioni'
            }
          </DialogTitle>
          <DialogContent
            id="dialog_content"
            sx={{
              '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '-webkit-fill-available', textAlign: 'center' },
            }}
          >
            <span style={{ display: "inline-flex", marginTop: "30px" }}>
              <BsVectorPen style={{ fontSize: "25px", marginTop: "5px" }} />  <input placeholder={`${language === "English" ? "Sign here":"Firma qui"}`} value={name} onChange={(e) => dispatch(addName(e.target.value))} />{' '}
              <FaRegHandPointLeft style={{ fontSize: "25px", marginTop: "5px" }} />
            </span>
            <DialogContentText id="alert-dialog-description">{name}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                background: '#7cd1f9',
                color: 'white',
                border: 'none',
                boxShadow: 'none',
                fontWeight: '600',
                fontSize: '14px',
                padding: '10px 10px',
                cursor: 'pointer',
                '&:disabled': {
                  cursor: 'not-allowed',
                  pointerEvents: 'none',
                  color: 'white',
                  background: 'grey',
                },
              }}
              disabled={!name && true}
              onClick={handleClickCloseTerms}
              autoFocus
            >


              {
                language === 'English' ? 'Confirm Quote                ' : 'Conferma preventivo'
              }            </Button>
          </DialogActions>
        </Dialog>
    </div>
  );
}
