import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'

const clients = [
    { img: '/assets/clients/image12.png' },
    { img: '/assets/clients/image11.png' },
    { img: '/assets/clients/image10.png' },
    { img: '/assets/clients/image9.png' },
    { img: '/assets/clients/image8.png' },
    { img: '/assets/clients/image7.png' },
    { img: '/assets/clients/image1.png' },
    { img: '/assets/clients/image4.png' },
    { img: '/assets/clients/image3.png' },
    { img: '/assets/clients/image2.png' },
    { img: '/assets/clients/image6.png' },
    { img: '/assets/clients/image5.png' },
]

export default function OurClients() {
    const {language} = useSelector((state)=>state.proposal)
    return (
        <div style={{borderTop:"1px solid #2DA0F0"}}>
         
            <div style={{
                backgroundImage: "linear-gradient(to right,#FFFFFF, rgba(161, 88, 214, 0.4)) ",
                width: "100%",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                height: "auto",
                paddingBottom: "40px",
                backgroundSize: "cover",
                textAlign: 'center',
            }}>
                <Grid container spacing={3} style={{ marginInline: "auto",marginTop:0, paddingTop: "30px", textAlign: "center" }}>
                    <Grid item xs={12} sx={{ textAlign: "-webkit-center", marginInline: "auto" }}>
                        <Typography variant='h4' sx={{
                             fontWeight: "bold",
                            fontFamily: "'Poppins', sans-serif"
                        }}>
                                                       {language === "English" ? "Our Clients" : "I nostri clienti"}

                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    {clients.map((client, index) => (
                        <Grid key={index} item xs={3} sm={4} lg={2} sx={{ height: "200px", mt: 2, display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img src={client.img} style={{ maxWidth: "140px", objectFit: "contain", height: "auto" }} alt='logo' />
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    )
}
