import { createSlice, current } from '@reduxjs/toolkit'
import { services } from '../mock/CI_services';
import { round } from 'lodash';


const calculateNetTotal = (items, service, totalDiscount) => {

  const itemTotal = items?.map((item)=> Number(item?.quantity) * Number(item?.price) -
  (Number(item?.quantity) * Number(item?.price) * Number(item?.itemDiscount)) / 100).reduce((a, b) => Number(a) + Number(b), 0) 
  const serviceTotal = service?.length > 0 ? service
  .map((item) => {
    if (item.serviceTotal === '' || item.serviceTotal === "NaN" || item.discount === null) {
      const totalItem = Number(item.quantity) * Number(item.price);
      return Number(totalItem).toFixed(2);
    } else {
      return Number(item.serviceTotal).toFixed(2);
    }
  })
  .reduce((a, b) => Number(a) + Number(b), 0) 
  : 0
  const subtotal = itemTotal + Number(serviceTotal)
  const netTotal = subtotal - ((subtotal * Number(totalDiscount))/100);
  console.log("Totals",subtotal, itemTotal, service)
  return netTotal
}
const handleNetTotals = (items, service, totalDiscount, option) => {
  const netTotals = []
  if(option === 0){
    let priceOption1 = items?.map((item)=>{
      return {price:Number(item?.pricePerWord) + (Number(item?.pricePerWord) * (0.50 * option)), quantity:item?.totalWords, itemDiscount:item?.discount }
    })
   let netTotalOption1 = calculateNetTotal(priceOption1, service, totalDiscount)   
   let priceOption2 = items?.map((item)=>{
    return {price:Number(item?.pricePerWord) + (Number(item?.pricePerWord) * (0.50 * 1)), quantity:item?.totalWords, itemDiscount:item?.discount }
  })
  //  Number(price) + (Number(price) * (0.20 * 1)); 
   let netTotalOption2 = calculateNetTotal(priceOption2, service, totalDiscount)   
   let priceOption3 = items?.map((item)=>{
    return {price:Number(item?.pricePerWord) + (Number(item?.pricePerWord) * (0.40 * 2)), quantity:item?.totalWords, itemDiscount:item?.discount }
  })
  //  Number(price) + (Number(price) * (0.20 * 2)); 
   let netTotalOption3 = calculateNetTotal(priceOption3, service, totalDiscount)   
   netTotals.push(netTotalOption1, netTotalOption2, netTotalOption3)
  }else if(option === 1){
    let priceOption1 = items?.map((item)=>{
      return {price:Number(item?.pricePerWord) - (Number(item?.pricePerWord) * (0.50 * 1)), quantity:item?.totalWords, itemDiscount:item?.discount }
    })
    // Number(price) - (Number(price) * (0.20 * 1)); 
    let netTotalOption1 = calculateNetTotal(priceOption1, service, totalDiscount)   
    let priceOption2 = items?.map((item)=>{
      return {price:Number(item?.pricePerWord) + (Number(item?.pricePerWord) * (0.20 * 0)), quantity:item?.totalWords, itemDiscount:item?.discount }
    })
    // Number(price) + (Number(price) * (0.20 * 0)); 
    let netTotalOption2 = calculateNetTotal(priceOption2, service, totalDiscount)   
    let priceOption3 = items?.map((item)=>{
      return {price:Number(item?.pricePerWord) + (Number(item?.pricePerWord) * (0.20 * 1)), quantity:item?.totalWords, itemDiscount:item?.discount }
    })
    // Number(price) + (Number(price) * (0.20 * 1)); 
    let netTotalOption3 = calculateNetTotal(priceOption3, service, totalDiscount)   
    netTotals.push(netTotalOption1, netTotalOption2, netTotalOption3)
   }else if(option === 2){
    let priceOption1 = items?.map((item)=>{
      return {price:Number(item?.pricePerWord) - (Number(item?.pricePerWord) * (0.6)), quantity:item?.totalWords, itemDiscount:item?.discount }
    })
    // Number(price) - (Number(price) * (0.20 * 2)); 
    let netTotalOption1 = calculateNetTotal(priceOption1, service, totalDiscount)   
    let priceOption2 = items?.map((item)=>{
      return {price:Number(item?.pricePerWord) - (Number(item?.pricePerWord) * (0.20 * 1)), quantity:item?.totalWords, itemDiscount:item?.discount }
    })
    // Number(price) - (Number(price) * (0.20 * 1)); 
    let netTotalOption2 = calculateNetTotal(priceOption2, service, totalDiscount)   
    let priceOption3 = items?.map((item)=>{
      return {price:Number(item?.pricePerWord) + (Number(item?.pricePerWord) * (0.20 * 0)), quantity:item?.totalWords, itemDiscount:item?.discount }
    })
    // Number(price) + (Number(price) * (0.20 * 0)); 
    let netTotalOption3 = calculateNetTotal(priceOption3, service, totalDiscount)   
    netTotals.push(netTotalOption1, netTotalOption2, netTotalOption3)
   }
return netTotals
}
function calculatePrice(selectedOption, previousOption,economyPrice, professionalPrice, premiumPrice) {
  let price;
  // Check if the selected option is 0 (Economy Package)
  if (selectedOption === 0) {
     price = economyPrice
  }
  // Check if the selected option is 1 (Professional Package)
  else if (selectedOption === 1) {
     price = professionalPrice;
  }
  // Check if the selected option is 2 (Premium Package)
  else if (selectedOption === 2) {
     price = premiumPrice
  }
  return price;
}
const handlePackages = (items, service, totalDiscount, taxes, option, prevOption, itemService, prices, notes) => {
   const selPrices = calculatePrice(option, prevOption, prices.economy, prices.professional, prices.premium)
   console.log("Prices", option, prices, selPrices)

  const itemTotal = items?.map((item, index)=> {
    const price = selPrices[index]
  return Number(item?.totalWords) * Number(price) -
  (Number(item?.totalWords) * Number(price) * Number(item?.discount)) / 100
})
const itemsTotal  = itemTotal ? itemTotal.reduce((a, b) => Number(a) + Number(b), 0) : 0
// const itemTotal = Number(quantity) * Number(price) -
  // (Number(quantity) * Number(price) * Number(itemDiscount)) / 100;


  const serviceTotal = service?.length > 0 ? service
  .map((item) => {
    if (item.serviceTotal === '' || item.serviceTotal === "NaN" || item.discount === null) {
      const totalItem = Number(item.quantity) * Number(item.price);
      return Number(totalItem).toFixed(2);
    } else {
      return Number(item.serviceTotal).toFixed(2);
    }
  })
  .reduce((a, b) => Number(a) + Number(b), 0) 
  : 0
  const subtotal = itemsTotal + Number(serviceTotal)
  const netTotal = subtotal - ((subtotal * Number(totalDiscount))/100);
  const grandTotal = String(netTotal + ((netTotal * Number(taxes))/100));
  if(items[0]?.pricePerWord){
    const updatedItems = items?.map((item, ind)=>{
      return {...item, service:itemService[ind], pricePerWord:String(selPrices[ind]), total:String(itemTotal[ind])}
    })
    return {item: updatedItems, service, subtotal: String(subtotal), netTotal, totalDiscount, taxes, grandTotal, notes }
  }else{
    const updatedItems = items?.map((item, ind)=>{
      return {...item, service:itemService[ind], pricePerWord:String(selPrices[ind]), total:String(itemTotal[ind])}
    })
    return {item: updatedItems, service, subtotal: String(subtotal), netTotal:String(netTotal), totalDiscount, taxes, grandTotal, notes }

  }
}
function calculateDaysInRange(startDate, endDate) {
  // Convert input to Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Check if the dates are valid
  if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      throw new Error("Invalid date provided.");
  }

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = end - start;

  // Convert milliseconds to days
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  // Add 1 to include the start date
  return round(differenceInDays);
}
export function calculateTotalServiceValue(values) {
  const totalServiceValue = values?.additionalService?.length > 0
    ? values.additionalService
        .map((item) => {
          if (item.serviceTotal === '' || item.serviceTotal === "NaN" || item.discount === null) {
            const totalItem = Number(item.quantity) * Number(item.price);
            return totalItem.toFixed(2);
          } else {
            return Number(item.serviceTotal).toFixed(2);
          }
        })
        .reduce((a, b) => Number(a) + Number(b), 0)
    : 0;

  return totalServiceValue;
}
export function calculateTotalValue(values) {
  const totalValue = values?.interpretationItem
    .map((item) => Number(item.total))
    .reduce((a, b) => Number(a) + Number(b), 0);

  return totalValue;
}
export function calculateDiscountedSubTotal(values) {
  const totalValue = calculateTotalValue(values);
  const totalServiceValue = calculateTotalServiceValue(values);

  const discountedSubTotal = (totalValue || totalServiceValue)
    ? Number(
        Number(totalValue + totalServiceValue) -
          (Number(totalValue + totalServiceValue) * Number(values.totalDiscount)) / 100
      ).toFixed(2)
    : 0;

  return discountedSubTotal;
}

export function calculateGrandTotal(values) {
  const discountedSubTotal = calculateDiscountedSubTotal(values);

  const grandTotal = discountedSubTotal
    ? Number(Number(discountedSubTotal) + (Number(discountedSubTotal) * Number(values.taxes)) / 100).toFixed(2)
    : 0;

  return grandTotal;
}
export const proposal = createSlice({
  name: 'proposal',
  initialState: {
    value: "Muzammil Irshad",
    details:{},
    language: '',
    ip:'',
    name:'',
    option:0,
    addOns:{},
    isBtn:true,
    quoteId:'',
    items1Translation:[],
    service1Translation:[],
    items2Translation:[],
    service2Translation:[],
    selectedPackageOption:0,
    type:"",
    packagesItem: {},
    packagesNetTotals:[],
    packagesPrices:{},
    packagesItemsTranslation:[],
    orgPkgsOption:0,
    selectedPkgDeliveryDate:'',
 
  },
  reducers: {
    addDetails: (state, action) => {
      const details = action.payload?.details;
      console.log(details)
      state.details = details
      state.addOns = details?.addOns;
      const type = action.payload?.type;
      state.type = type;
      if(type === "LY" && details?.addOns?.packages){
        const {translationItem, additionalService, totalDiscount, subtotal, netTotal, taxes, grandTotal, notes, deliveryDateTime} = details;
        state.selectedPkgDeliveryDate = deliveryDateTime
        state.packagesItem = {item: translationItem, service: additionalService, subtotal, netTotal, totalDiscount, taxes, grandTotal, notes }
       if(translationItem[0]?.service?.Item?.italian === "Servizio di Traduzione Economy"){
        const economyPrice = translationItem?.map((item)=> Number(item?.pricePerWord));
        const professionalPrice = translationItem?.map((item)=> Number(item?.pricePerWord) * 1.5) 
        const premiumPrice = translationItem?.map((item)=> Number(item?.pricePerWord) * 1.8) 
        state.packagesPrices = {economy: economyPrice,professional: professionalPrice, premium: premiumPrice}
        state.selectedPackageOption = 0
        state.orgPkgsOption = 0;
         state.packagesNetTotals = handleNetTotals(translationItem, additionalService, totalDiscount, 0)
       }else if(translationItem[0]?.service?.Item?.italian === "Servizio di Traduzione Professionale"){
        const economyPrice = translationItem?.map((item)=> Number(item?.pricePerWord) * 0.5)
        const professionalPrice = translationItem?.map((item)=> Number(item?.pricePerWord)) 
        const premiumPrice = translationItem?.map((item)=> Number(item?.pricePerWord) * 1.2)
        state.packagesPrices = {economy: economyPrice,professional: professionalPrice, premium: premiumPrice}
        state.selectedPackageOption = 1;
        state.orgPkgsOption = 1;
        state.packagesNetTotals = handleNetTotals(translationItem, additionalService, totalDiscount, 1)
      }else if(translationItem[0]?.service?.Item?.italian === "Servizio di Traduzione Premium"){
        const economyPrice = translationItem?.map((item)=> Number(item?.pricePerWord) * 0.4)
        const professionalPrice = translationItem?.map((item)=> Number(item?.pricePerWord) * 0.8)
        const premiumPrice = translationItem?.map((item)=> Number(item?.pricePerWord))
        state.packagesPrices = {economy: economyPrice,professional: professionalPrice, premium: premiumPrice}
        state.selectedPackageOption = 2
        state.orgPkgsOption = 2;
        state.packagesNetTotals = handleNetTotals(translationItem, additionalService, totalDiscount, 2)
      }
      }else if(type === "CI" && details?.addOns?.packages){
        const economy = Number(details?.netTotal) - (Number(details?.netTotal) * 0.5); 
        const professional = Number(details?.netTotal); 
        const premium = Number(details?.netTotal) + (Number(details?.netTotal) * 0.25); 
        state.packagesNetTotals = [economy, professional, premium]
      }
     
    },
    addName: (state, action) => {
      state.name = action.payload
    },
    addLanguageAndId: (state, action) => {
      state.language = action.payload.language;
      state.quoteId = action.payload.id
    },
    addOption: (state, action) => {
      state.option = action.payload
    },
     addBtnDisplay: (state, action) => {
      state.isBtn = action.payload
    },
    addDefaultItemsTranslation:(state, action)=>{
      state.items1Translation = action.payload.items;
      state.service1Translation = action.payload.services;
      if(state?.addOns?.packages){
        state.packagesItemsTranslation = action.payload.items;
      }
    },
    addItems2Translation:(state, action)=>{
      state.items2Translation = action.payload
    },
    addServices2Translation:(state, action)=>{
      state.service2Translation = action.payload
    },
    addIp:(state, action)=>{
      state.ip = action.payload
    },
    selectPackageOptions: (state, action)=>{
        const option = action.payload;
        const currentState = current(state)
        const prevOption = currentState.selectedPackageOption;
        const details = currentState.details;
        const packagesPerWordPrices =  currentState.packagesPrices
        let itemsTranslation = currentState.packagesItemsTranslation
       if(currentState.type === "LY"){
        const {translationItem:items,additionalService:service, totalDiscount, taxes, notes } =  currentState.details
        if(option === 0){
          let translation; 
          if(itemsTranslation?.length > 0){
            translation = items?.map((item, ind)=>item?.service?.Code === "LY-1001" ? currentState.items1Translation[ind] : 
            'The translation process is carried out thanks to Machine Translation, i.e. an automated translation that makes use of artificial intelligence. The post-editing service is included in the applied rate.')
            itemsTranslation = translation;
          }
          state.packagesItemsTranslation = itemsTranslation 
           console.log("translation", translation, itemsTranslation)

           const itemServicesDesc = items?.map((item, index)=> {
            return{
            Code: 'LY-1001',
            InvCode:'LY/TRAD',
            Item: {
              italian: 'Servizio di Traduzione Economy',
              english: 'Economy Translation Service',
            },
            Description: {
              italian:
              item?.service?.Code === "LY-1001" ? item?.service?.Description?.italian : "Il processo di traduzione viene effettuato grazie alla Machine Translation, ovvero una traduzione automatizzata che si avvale dell'intelligenza artificiale. È incluso il servizio di post-editing nella tariffa applicata.",
              english: translation ? translation[index] : item?.service?.Description?.english,
            },
          }})
            const selectedPkg = handlePackages(items, service, totalDiscount, taxes, option, prevOption, itemServicesDesc, packagesPerWordPrices, notes)
            console.log("Prices", selectedPkg)
            state.packagesItem = selectedPkg
        }else if(option === 1){
          let translation;
          if(itemsTranslation?.length > 0){
            translation =items?.map((item, ind)=> item?.service?.Code === "LY-1002" ? currentState.items1Translation[ind] : 
            'The translation process is carried out by native-speaking linguists with at least 5 years of experience and with a background specialized in the type of documentation received. The quality control service is included in the applied rate.')
            itemsTranslation = translation;
          }
          state.packagesItemsTranslation = itemsTranslation 
          const itemServicesDesc = items?.map((item, index)=> {
            return{
              InvCode:'LY/TRAD',
              Code: 'LY-1002',
              Item: {
                italian: 'Servizio di Traduzione Professionale',
                english: 'Professional Translation Service',
              },
              Description: {
                italian:
                item?.service?.Code === "LY-1002" ? item?.service?.Description?.italian :  "Il processo di traduzione viene effettuato da linguisti madrelingua con minimo 5 anni di esperienza e con un background specializzato nella tipologia di documentazione ricevuta. E' incluso il servizio di controllo qualità nella tariffa applicata.",
                english: translation ? translation[index] : item?.service?.Description?.english,
              },
        }})
            state.packagesItem = handlePackages(items, service, totalDiscount, taxes, option, prevOption, itemServicesDesc, packagesPerWordPrices, notes)
          }else if(option === 2){
            let translation;
          if(itemsTranslation?.length > 0){
            translation =items?.map((item, ind)=> item?.service?.Code === "LY-1003" ? currentState.items1Translation[ind] : 'The translation process is carried out by native-speaking linguists with at least 5 years of experience and with a background specialized in the type of documentation received. The premium translation service includes proofreading by a second linguist and an additional final quality check.')
            itemsTranslation = translation;
          }
          state.packagesItemsTranslation = itemsTranslation 

          const itemServicesDesc = items?.map((item, index)=> {
            return{          
              InvCode:'LY/TRAD',
              Code: 'LY-1003',
              Item: {
                italian: 'Servizio di Traduzione Premium',
                english: 'Premium Translation Service',
              },
              Description: {
                italian:
                item?.service?.Code === "LY-1003" ? item?.service?.Description?.italian : 'Il processo di traduzione viene effettuato da linguisti madrelingua con minimo 5 anni di esperienza e con un background specializzato nella tipologia di documentazione ricevuta. Il servizio di traduzione premium include il proofreading effettuato da un secondo linguista e un ulteriore controllo qualità finale.',
                english: translation ? translation[index] : item?.service?.Description?.english,
              },
            }})
            state.packagesItem = handlePackages(items, service, totalDiscount, taxes, option, prevOption, itemServicesDesc, packagesPerWordPrices, notes)
          }
           state.selectedPackageOption = option
        }
    },
    selectServiceItems:(state, action)=>{
              const currentState = current(state)
              const filter = services.filter((item)=> item.service["Code"] === action.payload);
              const days = calculateDaysInRange(currentState.details.interpretationItem[0]?.startDateTime, 
                currentState.details.interpretationItem[currentState.details.interpretationItem.length-1].endDateTime)
                console.log(days);
                const addServices = currentState.details.additionalService; 

             const additionalServices = [...addServices,{
              title: "",
              price: `${filter[0].price}`,
              quantity: `${days}`,
              startDateTime: currentState.details.interpretationItem[0]?.startDateTime,
              endDateTime: currentState.details.interpretationItem[currentState.details.interpretationItem.length-1].endDateTime,
              discount: 0,
              service: filter[0].service,
              serviceTotal: ""
          }];
          
        const values = {...currentState.details, additionalService:[...additionalServices]}
          const subtotal = String(calculateTotalValue(values) + calculateTotalServiceValue(values))
          const netTotal = String(calculateDiscountedSubTotal(values))
         const grandTotal = String(calculateGrandTotal(values))
        state.details = {...values, subtotal, netTotal, grandTotal, additionalService:additionalServices }
        console.log(values, subtotal, netTotal, grandTotal)

    },
    unSelectServiceItems:(state, action)=>{
      const currentState = current(state)
      const filterServices = currentState.details.additionalService.filter((item)=> item.service["Code"] != action.payload);
      console.log(filterServices)

      const values = {...currentState.details, additionalService:[...filterServices]}
      const subtotal = String(calculateTotalValue(values) + calculateTotalServiceValue(values))
      const netTotal = String(calculateDiscountedSubTotal(values))
     const grandTotal = String(calculateGrandTotal(values))
    state.details = {...values, subtotal, netTotal, grandTotal, additionalService:filterServices }
    },
    selectedDeliveryDate: (state, action)=>{
      console.log("Date", action)
      state.selectedPkgDeliveryDate = action?.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { addDetails,selectedDeliveryDate,selectServiceItems,unSelectServiceItems, addBtnDisplay, addOption, addLanguageAndId, addName, addIp, addDefaultItemsTranslation, addItems2Translation, addServices2Translation, selectPackageOptions } = proposal.actions

export default proposal.reducer