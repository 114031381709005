import { Grid, Typography } from "@mui/material";
import React from "react";
import { FaLock } from "react-icons/fa";
import { useSelector } from "react-redux";
import styles from '../styles/Quality.module.css';
import LiteYouTubeEmbed from "react-lite-youtube-embed";


const content = {
    "Italian" :[
        "Cambridge English: Starters (YLE Starters)",
        "Cambridge English: Movers (YLE Movers)",
        "Cambridge English: Flyers (YLE Flyers)",
        "Cambridge English: Key for Schools (KET for Schools)",
        "Cambridge English: Preliminary for Schools (PET for Schools)",
        "Cambridge English: First for Schools (FCE for Schools)",
        "Cambridge English: Advanced (CAE)",
        "Cambridge English: Proficiency (CPE)",
        "ETS – Educational Testing Service (TOEFL)",
        "International English Language Testing System (IELTS)",
        "Trinity College London (TCL – Trinity)",
        "DELE",
        "DELF",
        "CELI"
     ],
     "English":[
       "Cambridge English: Starters (YLE Starters)",
        "Cambridge English: Movers (YLE Movers)",
        "Cambridge English: Flyers (YLE Flyers)",
        "Cambridge English: Key for Schools (KET for Schools)",
        "Cambridge English: Preliminary for Schools (PET for Schools)",
        "Cambridge English: First for Schools (FCE for Schools)",
        "Cambridge English: Advanced (CAE)",
        "Cambridge English: Proficiency (CPE)",
        "ETS – Educational Testing Service (TOEFL)",
        "International English Language Testing System (IELTS)",
        "Trinity College London (TCL – Trinity)",
        "DELE",
        "DELF",
        "CELI" 
      ]
}

export default function Certification() {
  const { language } = useSelector((state) => state.proposal);
  return (
    <div style={{borderTop:"1px solid #2DA0F0"}}>
      <div
        style={{
          textAlign: "right",
          width: "97%",
          padding: "2px",
          marginInline: "auto",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "2px solid #AA32E1",
        }}
      >
        <p>
          <span
            style={{ fontWeight: "500", textTransform:"uppercase", color: "#AA32E1", fontSize: "20px" }}
          >
        {language === "English" ? "CERTIFICATIONS" : "CERTIFICAZIONI"}
        </span>
        </p>
        <img
          src={"/lingoAcademy/logos/academy.png"}
          style={{ marginRight: "20px", objectFit: "contain" }}
          alt="logo"
        />
      </div>
      <div
        style={{
            backgroundImage: `linear-gradient(to right top, rgba(45, 160, 240, 0.15), white 40%, rgba(45, 160, 240, 0.15))`,

          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "auto",
          paddingBottom: "40px",
          backgroundSize: "cover",
          textAlign: "center",
        }}
      >
        <Grid
          container
          spacing={3}
          style={{
            marginInline: "auto",
            marginTop: 0,
            paddingTop: "30px",
            maxWidth:"1400px",
          }}
        >
          <Grid item xs={12} md={11} sx={{ textAlign: "left", marginBottom:"10px" }}>
          <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              {language === "English" ? "Certification preparation courses:": 
              "Corsi di preparazione per certificazioni:"
              }
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize:"18px",
                fontFamily: "'Poppins', sans-serif",
                marginTop:"20px",
                marginLeft:"20px"
              }}
            >
              {language === "English"
                ? `At LingoYou Academy you will find everything you need for your certification. Our Institute, as well as
Cambridge Assessment English Preparation Centre, will follow your path from start to finish,
from the Oxford English Test to the exam for the required certification.`
                : `Da LingoYou Academy troverai tutto ciò di cui necessiti per la tua certificazione. Il nostro Istituto, nonché
Cambridge Assessment English Preparation Centre, seguirà il tuo percorso dall’inizio fino alla fine,
dall’Oxford English Test all’esame per la certificazione richiesta.`}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize:"18px",
                fontFamily: "'Poppins', sans-serif",
                marginTop:"20px",
                marginLeft:"20px"
              }}
            >
              {language === "English"
                ? `Our team will, in fact, manage the entire logistical process of registering for the exam to obtain the
language certification, completely free of charge. All you have to do is tell us the type of exam, the
date and LingoYou Academy will take care of everything else!`
                : `Il nostro Team, infatti, gestirà l’intero processo logistico di iscrizione all’esame di conseguimento della
certificazione in lingua, in maniera del tutto gratuita. Ti basterà comunicarci la tipologia di esame, la
data e LingoYou Academy penserà a tutto il resto!
`}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                justifyContent:"space-between",
                display:"flex",
                marginTop:"10px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
            <ul style={{fontSize:"18px"}}>
               {content[language]?.slice(0, 7)?.map((item)=>(
                <li style={{marginTop:"10px"}}>
                    {item}
                </li>
               ))}               
            </ul>
            <ul style={{fontSize:"18px"}}>
               {content[language]?.slice(7)?.map((item)=>(
                <li style={{marginTop:"10px"}}>
                    {item}
                </li>
               ))}               
            </ul>
            </Typography>
           
          </Grid>
          <Grid item xs={12} md={1}></Grid>
        </Grid>
    <div style={{width:"100%", height:"70px", fontSize:"17px", placeContent:"center", alignItems:"center", marginTop:"30px", marginBottom:"30px", color:"white", backgroundColor:"#2DA0F0"}}>
        {language === "English" ? "CERTIFICATIONS" : "CERTIFICAZIONI"}
    </div>   
    <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sx={{ marginInline: "auto", my: 8, textAlign: "-webkit-center" }}
          >
            <div>
              <LiteYouTubeEmbed
                id="DS2L__o_1k8"
                title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
              />
            </div>
          </Grid>
        </Grid>
        
      </div>
    </div>
  );
}
