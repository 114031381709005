import { Avatar, Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";


const content = {
    "Italian":{
        title:'Il nostro team',
        team:[
            {
                name:'Manuel Costa M’Skani:',
                jobTitle:'CEO & Founder dell’azienda',
                avatar:'/lingoAcademy/Manuel.png',
                skills:[
                    'Laurea magistrale in Economia, Business Management, Marketing & Management',
                    'Anni di esperienza: 17',
                    'Inglese: C2',
                    'Francese: C1',
                    'Arabo: C1'
                ]
            },
            {
                name:'Christine Dowse:',
                jobTitle:'Insegnante d’inglese',
                avatar:'/lingoAcademy/Christine.png',
                skills:[
                    'Madrelingua inglese',
                    'Traduttrice e scrittrice',
                    'Insegnante d’inglese per pubblica amministrazione, privati e aziende',
                    'Francese: C1',
                    'Anni di esperienza: +40'
                ]
            },
            {
                name:'Miriam Assenzo:',
                jobTitle:'Insegnante d’inglese',
                avatar:'/lingoAcademy/Miriam.png',
                skills:[
                    'Madrelingua inglese',
                    'Insegnante d’inglese per pubblica amministrazione, privati e aziende',
                    'Anni di esperienza: +20',
                ]
            },
            {
                name:'Giovanna Quartararo:',
                jobTitle:'Insegnante di lettere',
                avatar:'/lingoAcademy/Giovanna.png',
                skills:[
                    'Laurea in Lettere',
                    ' Insegnante di italiano e latino per pubblica amministrazione, privati e aziende',
                    'Anni di esperienza: +40',
                ]
            },
            {
                name:'Chiara Moschitta:',
                jobTitle:'Senior Account Manager',
                avatar:'/lingoAcademy/Chiara.png',
                skills:[
                    'Laurea in lingue per la comunicazione interculturale e la cooperazione internazionale',
                    'Anni di esperienza: 2',
                    'Inglese: C1',                   
                     'Spagnolo: C1',
                ]
            }
        ]
    },
    "English":{
      title: "Our Team",
      team: [
          {
              name: "Manuel Costa M’Skani:",
              jobTitle: "CEO & Founder of the company",
              avatar: "/lingoAcademy/Manuel.png",
              skills: [
                  "Master's degree in Economics, Business Management, Marketing & Management",
                  "Years of experience: 17",
                  "English: C2",
                  "French: C1",
                  "Arabic: C1"
              ]
          },
          {
              name: "Christine Dowse:",
              jobTitle: "English Teacher",
              avatar: "/lingoAcademy/Christine.png",
              skills: [
                  "Native English speaker",
                  "Translator and writer",
                  "English teacher for public administration, individuals, and companies",
                  "French: C1",
                  "Years of experience: +40"
              ]
          },
          {
              name: "Miriam Assenzo:",
              jobTitle: "English Teacher",
              avatar: "/lingoAcademy/Miriam.png",
              skills: [
                  "Native English speaker",
                  "English teacher for public administration, individuals, and companies",
                  "Years of experience: +20"
              ]
          },
          {
              name: "Giovanna Quartararo:",
              jobTitle: "Literature Teacher",
              avatar: "/lingoAcademy/Giovanna.png",
              skills: [
                  "Degree in Literature",
                  "Italian and Latin teacher for public administration, individuals, and companies",
                  "Years of experience: +40"
              ]
          },
          {
              name: "Chiara Moschitta:",
              jobTitle: "Senior Account Manager",
              avatar: "/lingoAcademy/Chiara.png",
              skills: [
                  "Degree in languages for intercultural communication and international cooperation",
                  "Years of experience: 2",
                  "English: C1",
                  "Spanish: C"
              ]
          }
      ]
  }
  
  }

export default function OurTeam() {
  const { details, language } = useSelector((state) => state.proposal);
  const {clients, industry} = details
  
  const settings = {
    dots: false,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 4,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
  };
  return (
    <div>
     <div
        style={{
          backgroundImage: "linear-gradient(to right top, #A158D6 20%, #43BEF3 80%) ",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "auto",
          paddingBottom: "30px",
          backgroundSize: "cover",
          textAlign: "center",
          opacity: 0.9,
        }}
      >
             <div
    style={{
      textAlign: "right",
      width: "97%",
      marginInline:"auto",
      padding:"2px",
      display:"flex",
      marginBottom: "30px",
      justifyContent: "space-between",
      borderBottom: "2px solid white",
    }}
  >
     <p>
           <span  style={{fontWeight:"500", color: "white", fontSize: "20px"}}
           >
                        {content[language]?.title}

          </span>
    </p>
    <img
      src={"/lingoAcademy/logos/academy-wh.png"}
      style={{ marginRight: "20px", objectFit:"contain" }}
      alt="logo"
    />
  </div>
        <Grid
          container
          spacing={2}
          style={{
            marginInline: "auto",
            paddingTop: "20px",
            textAlign: "center",
          }}
        >
         
         <Grid item xs={12} sx={{mt:9, mb:1, textAlign:"center", marginInline:"auto"}}>
         <div
                style={{
                  background: "white",
                  width: "100%",
                  height: "auto",
                  maxWidth: "500px",
                  borderRadius:"20px",
                  marginInline:"auto",
                  justifyContent:"center",
                  placeContent:"center",
                  alignItems:"center",
                }}
              >
                <Avatar sx={{objectFit:"contain", marginInline:"auto", marginTop:"-70px", width:"150px", height:"150px"}} 
                src= {content[language]?.team[0]?.avatar}/>
              <div style={{ marginInline:"auto"}}>
              <p style={{color:"black", letterSpacing:"1.2px",}}>
                    <span style={{ fontSize:"18px", fontWeight:"bold"}}>
                    {content[language]?.team[0]?.name}
                    </span><br/>
                    <span style={{marginLeft:"10px",  fontSize:"17px",}}>
                    {content[language]?.team[0]?.jobTitle}     
                    </span>
                </p>
                <ul style={{maxWidth:"90%", marginInline:"auto", textAlign:"left"}}>
                {content[language]?.team[0]?.skills?.map((skill)=>
                <li style={{color:"black",  fontSize:"17px",}}>
                    {skill}
                </li>
                )}
                </ul>
                
              </div>
                
               
              </div>
         </Grid>
         {content[language]?.team?.slice(1).map((item)=><Grid item xs={12} md={6} sx={{mt:9, mb:1, textAlign:"center", marginInline:"auto"}}>
         <div
                style={{
                  background: "white",
                  width: "70%",
                  maxWidth:"80%",
                  height: "350px",
                  maxWidth: "fit-content",
                  borderRadius:"20px",
                  marginInline:"auto",
                  justifyContent:"center",
                  placeContent:"center",
                  alignItems:"center",
                }}
              >
                <Avatar sx={{objectFit:"contain", marginInline:"auto", marginTop:"-90px", width:"150px", height:"150px"}} 
                src= {item?.avatar}/>
              <div style={{ marginInline:"auto"}}>
              <p style={{color:"black", letterSpacing:"1.2px",}}>
                    <span style={{ fontSize:"18px", fontWeight:"bold"}}>
                    {item?.name}
                    </span><br/>
                    <span style={{marginLeft:"10px",  fontSize:"17px",}}>
                    {item?.jobTitle}     
                    </span>
                </p>
                <ul style={{maxWidth:"90%", marginInline:"auto", textAlign:"left"}}>
                {item?.skills?.map((skill)=>
                <li style={{color:"black",  fontSize:"17px",}}>
                    {skill}
                </li>
                )}
                </ul>
                
              </div>
                
               
              </div>
         </Grid>)}
        </Grid>
        
  </div>
    </div>
  );
}
