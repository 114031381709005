import { Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export default function Innovation() {
  const { details, language } = useSelector((state) => state.proposal);

  return (
    <div style={{borderTop:"1px solid #2DA0F0"}}>
     <div
    style={{
      textAlign: "right",
      width: "97%",
      padding:"2px",
      marginInline:"auto",
      display:"flex",
      marginBottom: "50px",
      justifyContent: "space-between",
      borderBottom: "2px solid #AA32E1",
    }}
  >
     <p>
           <span  style={{fontWeight:"500", textTransform:"uppercase", color: "#AA32E1", fontSize: "20px"}}
           >
      {language === 'English' ? 
      `TECHNOLOGIES AND INNOVATION` :
      `TECNOLOGIE E INNOVAZIONE`}
    </span>
    </p>
    <img
      src={"/lingoAcademy/logos/academy.png"}
      style={{ marginRight: "20px", objectFit:"contain" }}
      alt="logo"
    />
  </div>
       <Grid container spacing={1}>
        <Grid item xs={2.5} sx={{marginInline:"auto"}}>
            <div style={{textAlign:"center", paddingLeft:"15px", marginInline:"auto"}}>
                <div style={{marginInline:"auto", width:"fit-content", padding:"25px",
                     backgroundImage:"url('/lingoAcademy/icons/cloud.svg')", 
                     backgroundSize:"contain", backgroundRepeat:"no-repeat"}}>
                  <div style={{marginTop:"-50px"}}>
                  <p style={{width:'25px', textAlign:'center', paddingTop:"2px", marginLeft:"-15px",
                    fontWeight:"600", marginTop:"30px", backgroundColor:"#AC34E3", 
                    color:"white", borderRadius:"50%"}}>
                       1
                   </p>
                   <img src="/lingoAcademy/icons/offers.png" style={{marginTop:"-20px", objectFit:"contain", width:"120px"}}/>
           
                  </div>
                       </div>
             <p style={{borderRadius:"10px", marginTop:"-5px",fontWeight:"500", fontSize:"14px", color:"#2DA0F0", marginInline:"auto",
                 padding:"10px", border:"2px solid #2DA0F0"}}>
               {language === "English" ? "Receive convenient and immediate offers":
               "Ricevere offerte convenienti e immediate"}
             </p>
            </div>
            <div style={{textAlign:"center", paddingLeft:"15px", marginTop:"60px", marginInline:"auto"}}>
            <div style={{marginInline:"auto", width:"fit-content", padding:"25px",
                     backgroundImage:"url('/lingoAcademy/icons/cloud.svg')", 
                     backgroundSize:"contain", backgroundRepeat:"no-repeat"}}>
                  <div style={{marginTop:"-50px"}}>
                  <p style={{width:'25px',paddingTop:"2px", marginLeft:"-15px", textAlign:'center', 
                    fontWeight:"600", marginTop:"30px", backgroundColor:"#AC34E3", 
                    color:"white", borderRadius:"50%"}}>
                       2
                   </p>
                   <img src="/lingoAcademy/icons/devices.png" style={{marginTop:"-20px", objectFit:"contain", width:"120px"}}/>
           
                  </div>
                       </div>
             <p style={{borderRadius:"10px", marginTop:"-5px",fontWeight:"500", fontSize:"14px", color:"#2DA0F0", marginInline:"auto",
                 padding:"10px", border:"2px solid #2DA0F0"}}>     
                {language === "English" ? "Easily access all your teaching and learning materials in one place and across multiple devices" : 
                "Accedere facilmente a tutti i materiali didattici e di apprendimento in un unica piattaforma e su più dispositivi"}
             </p>
            </div>
            <div style={{textAlign:"center", paddingLeft:"15px", marginTop:"150px", marginInline:"auto"}}>
            <div style={{marginInline:"auto", width:"fit-content", padding:"25px",
                     backgroundImage:"url('/lingoAcademy/icons/cloud.svg')", 
                     backgroundSize:"contain", backgroundRepeat:"no-repeat"}}>
                  <div style={{marginTop:"-50px"}}>
                  <p style={{width:'25px',paddingTop:"2px", marginLeft:"-15px", textAlign:'center', 
                    fontWeight:"600", marginTop:"30px", backgroundColor:"#AC34E3", 
                    color:"white", borderRadius:"50%"}}>
                       3
                   </p>
                   <img src="/lingoAcademy/icons/upload-download.png" style={{marginTop:"-20px", objectFit:"contain", width:"120px"}}/>
           
                  </div>
                       </div>
             <p style={{borderRadius:"10px", marginTop:"-5px",fontWeight:"500", fontSize:"14px", color:"#2DA0F0", marginInline:"auto",
                 padding:"10px", border:"2px solid #2DA0F0"}}>   
                {language === "English" ? "Upload and download files of any type" :
                 "Caricare e scaricare file di ogni tipo"}
             </p>
            </div>
        </Grid>
        <Grid item xs={7} sx={{marginTop:"30px"}}>
        <div style={{
        backgroundImage:"url('/translation/privacy/bg.png')",
        backgroundSize:"contain",
        backgroundRepeat:"no-repeat",
        width:"100%",
        height:"auto",
        marginBottom:"100px",
        marginInline:"auto",
        
       }}>
          <div style={{paddingTop:"62%", marginInline:"auto"}}>
         <p style={{
          marginInline:"auto",
        display:"flex", backgroundColor:"#2DA0F0", flexDirection:"row",
            borderRadius: '7px', fontSize:"10px", maxWidth:"240px", padding:"20px", 
            marginBottom:"30px",
            textAlign: 'center', 
            color: '#FFFFFF',
            fontFamily: "Poppins 'sans-serif'",
            fontSize:"16px", marginTop:"-5px"}}>
              <img
                style={{ width: '20px', height: '20px', objectFit:"contain", paddingRight:"1px" }}
                src="/lingoAcademy/icons/learning-app.png"
              />
         {language === 'English' ? 
      `TECHNOLOGIES AND INNOVATION` :
      `TECNOLOGIE E INNOVAZIONE`}
         </p>
         </div>
        
         <div style={{textAlign:"center", paddingTop:"20%", marginInline:"auto"}}>
         <div style={{marginInline:"auto", width:"fit-content", padding:"30px",
                     backgroundImage:"url('/lingoAcademy/icons/cloud.svg')", 
                     backgroundSize:"contain", backgroundRepeat:"no-repeat"}}>
                  <div style={{marginTop:"-50px"}}>
                  <p style={{width:'25px',paddingTop:"2px", marginLeft:"-15px", textAlign:'center', 
                    fontWeight:"600", marginTop:"30px", backgroundColor:"#AC34E3", 
                    color:"white", borderRadius:"50%"}}>
                       7
                   </p>
                   <img src="/lingoAcademy/icons/team.png" style={{marginTop:"-20px", 
                    objectFit:"contain", width:"100px"}}/>
           
                  </div>
                       </div>
             <p style={{borderRadius:"10px", maxWidth:"300px", marginTop:"-5px",fontWeight:"500", fontSize:"14px", color:"#2DA0F0", marginInline:"auto",
                 padding:"10px", border:"2px solid #2DA0F0"}}>
               {language === "English" ? "Collaborate with selected virtual team members":
               "Collaborare con i membri del team virtuale selezionato"}
             </p>
            </div>
        </div> 
      
        </Grid>
        <Grid item xs={2.5}>
        <div style={{textAlign:"center", paddingRight:"15px", marginInline:"auto"}}>
        <div style={{marginInline:"auto", width:"fit-content", padding:"25px",
                     backgroundImage:"url('/lingoAcademy/icons/cloud.svg')", 
                     backgroundSize:"contain", backgroundRepeat:"no-repeat"}}>
                  <div style={{marginTop:"-50px"}}>
                  <p style={{width:'25px',paddingTop:"2px", marginLeft:"-15px", textAlign:'center', 
                    fontWeight:"600", marginTop:"30px", backgroundColor:"#AC34E3", 
                    color:"white", borderRadius:"50%"}}>
                       4
                   </p>
                   <img src="/lingoAcademy/icons/reports.png" style={{marginTop:"-20px", objectFit:"contain", width:"120px"}}/>
           
                  </div>
                       </div>
             <p style={{borderRadius:"10px", marginTop:"-5px",fontWeight:"500", fontSize:"14px", color:"#2DA0F0", marginInline:"auto",
                 padding:"10px", border:"2px solid #2DA0F0"}}>
               {language === "English" ? "Check complete course status reports with activity lists and calendars":
               "Controllare i report completi sullo status del corso con gli elenchi di attività e calendari"}
             </p>
            </div>
            <div style={{textAlign:"center", paddingRight:"15px", marginTop:"60px", marginInline:"auto"}}>
            <div style={{marginInline:"auto", width:"fit-content", padding:"30px",
                     backgroundImage:"url('/lingoAcademy/icons/cloud.svg')", 
                     backgroundSize:"contain", backgroundRepeat:"no-repeat"}}>
                  <div style={{marginTop:"-50px"}}>
                  <p style={{width:'25px',paddingTop:"2px", marginLeft:"-15px", textAlign:'center', 
                    fontWeight:"600", marginTop:"30px", backgroundColor:"#AC34E3", 
                    color:"white", borderRadius:"50%"}}>
                       5
                   </p>
                   <img src="/lingoAcademy/icons/support.png" style={{marginTop:"-20px", 
                    objectFit:"contain", width:"100px"}}/>
           
                  </div>
                       </div>
             <p style={{borderRadius:"10px", marginTop:"-5px",fontWeight:"500", fontSize:"14px", color:"#2DA0F0", marginInline:"auto",
                 padding:"10px", border:"2px solid #2DA0F0"}}>     
                {language === "English" ? "Receive support and materials, anytime, anywhere" : 
                "Ricevere supporto e materiali, in qualsiasi momento e ovunque"}
             </p>
            </div>
            <div style={{textAlign:"center", paddingRight:"15px", marginTop:"150px", marginInline:"auto"}}>
            <div style={{marginInline:"auto", width:"fit-content", padding:"30px",
                     backgroundImage:"url('/lingoAcademy/icons/cloud.svg')", 
                     backgroundSize:"contain", backgroundRepeat:"no-repeat"}}>
                  <div style={{marginTop:"-50px"}}>
                  <p style={{width:'25px',paddingTop:"2px", marginLeft:"-15px", textAlign:'center', 
                    fontWeight:"600", marginTop:"30px", backgroundColor:"#AC34E3", 
                    color:"white", borderRadius:"50%"}}>
                       6
                   </p>
                   <img src="/lingoAcademy/icons/email.png" style={{marginTop:"-20px", 
                    objectFit:"contain", width:"100px"}}/>
           
                  </div>
                       </div>
             <p style={{borderRadius:"10px", marginTop:"-5px",fontWeight:"500", fontSize:"12px", color:"#2DA0F0", marginInline:"auto",
                 padding:"10px", border:"2px solid #2DA0F0"}}>   
                {language === "English" ? "Receive automatic email information and updates" : 
                "Ricevere informazioni e aggiornamenti e-mail automatici"}
             </p>
            </div>
</Grid>
       </Grid>
       <Grid container spacing={1} sx={{
        marginInline:"auto",
        background:"linear-gradient(to right, #A158D6, #CCF0FF)",
        padding:"80px"
       }}>
        <Grid item xs={4} sm={6} md={4} sx={{marginInline:"auto", justifyContent:"space-around", textAlign:"center"}}>
          <div>
          <img alt="services" style={{objectFit:"contain", height:"200px", width:"100%"}} 
          src="/lingoAcademy/innovation1.jpg" />
          <p style={{fontSize:"20px", fontWeight:"600", maxWidth:"300px", marginInline:"auto", color:"white", marginTop:"10px"}}>
               {language === "English" ? "Flexible and customizable courses based on students' objectives" : 
               "Corsi flessibili e personalizzabili in base agli obiettivi degli studenti"}
          </p>
          </div>
          </Grid>
          <Grid item xs={4} sm={6} md={4} sx={{marginInline:"auto", justifyContent:"space-around", textAlign:"center"}}>
          <div>
          <img alt="services" style={{objectFit:"contain", height:"200px", width:"100%"}} 
          src="/lingoAcademy/innovation2.jpg" />
          <p style={{fontSize:"20px", fontWeight:"600", maxWidth:"300px", marginInline:"auto", color:"white", marginTop:"10px"}}>
               {language === "English" ? "365/365 Subscriptions and Customer Care available 24/7" : 
               "Iscrizioni 365/365 e Customer Care disponibile 24/7"}
          </p>
          </div>  
          </Grid>
          <Grid item xs={4} sm={6} md={4} sx={{marginInline:"auto", justifyContent:"space-around", textAlign:"center"}}>
          <div>
          <img alt="services" style={{objectFit:"contain",height:"200px", width:"100%"}} 
          src="/lingoAcademy/innovation3.jpg" />
          <p style={{fontSize:"20px", fontWeight:"600", maxWidth:"300px", marginInline:"auto", color:"white", marginTop:"10px"}}>
               {language === "English" ? "Using the best learning platforms" : 
               "Impiego delle migliori piattaforme di apprendimento"}
          </p>
          </div>
        </Grid>
       </Grid>
    </div>
  );
}
