import { Avatar, Box, Checkbox, Container, FormControlLabel, Radio, Typography } from '@mui/material';
import React, {useState, useEffect} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Table, TableRow, TableBody, TableHead, TableCell, TableContainer } from '@mui/material';
import Scrollbar from './Scrollbar';
import styles1 from '../styles/TablesItems.module.css';
import { fCurrency } from '../../utils/formatNumber';
import { useDispatch, useSelector } from 'react-redux';
import { addOption } from '../../redux/proposalSlice';
import Table1 from './Table/Table1';
import Table2Header from './Table/Table2Header';
import Table2Items from './Table/Table2Items';
import Table3 from './Table/Table3';
import Table2ServiceItems from './Table/Table2ServiceItems';

const RowResultStyle = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));
function TablesItems() {
    const [hoveredBox, setHoveredBox] = useState(null);

    const {language, details, option, items1Translation, items2Translation, service1Translation, service2Translation} = useSelector((state)=>state.proposal);
    const dispatch = useDispatch();
  const {
    taxes,
    netTotal,
    subtotal,
    grandTotal,
    totalDiscount,
    taxesOptions,
    grandTotalOptions,
    netTotalOptions,
    totalDiscountOptions,
    subtotalOptions,
    notes,
    notesOptions,
    additionalService,
    translationItem,
    additionalService2,
    translationItem2,
    brand
  } = details;
 
  const totalLength = translationItem.length + additionalService.length;
  console.log(details);
  const totalLength2 = translationItem2?.length + additionalService2?.length;
  console.log(option);
  const handleMouseEnter = (index) => {
    setHoveredBox(index);
  };

  const handleMouseLeave = () => {
    setHoveredBox(null);
  };
  return (
    <div id="quote_info1">
      <Box
        sx={{
          display: 'flow-root',
          height: `${totalLength > 1 ? 'auto' : 'auto'}`,
          position: 'relative',
          paddingBottom: '20px',
          backgroundColor: 'white',
      borderTop:"1px solid #2DA0F0"
        }}
      >
         <div
    style={{
      textAlign: "right",
      width: "97%",
      padding:"2px",
      marginInline:"auto",
      display:"flex",
      marginBottom: "50px",
      justifyContent: "space-between",
      borderBottom: "2px solid #AA32E1",
    }}
  >
 <p>
           <span  style={{fontWeight:"500", color: "white", fontSize: "20px"}}
           >
     {language === "English" ? "Quote Info":
            "Preventivo"}
    </span>
    </p>
    <img
      src={brand && brand?.academy ? "/lingoAcademy/logos/academy.png":"/translation/logos/lingoyou.png"}
      style={{ marginRight: "20px", objectFit:"contain" }}
      alt="logo"
    />
  </div>
        <div className={styles1.tables}>
        <Table1/>

          <div
            className={styles1.table2}
            style={{
                marginBottom: "4em",
                padding: "0px",
                marginLeft: "0px",
                paddingBottom:"10px",
                marginInline: "auto",
                marginTop: "3em",
                cursor:`${totalLength2 > 0 && 'pointer'}`,
                // boxShadow: `${
                //   totalLength2 > 0
                //     ? "0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)"
                //     : "none"
                // }`,
                
                border: `${option === 1 ? `2px solid #4FD5ED` : "none"}`,
                borderRadius: "15px",
                transition: 'transform 0.4s, box-shadow 0.4s', // smooth transition for hover effects
      transform: (hoveredBox === 1 && totalLength2 > 0) ? 'scale(1.02)' : 'scale(1)', // slight zoom when hovered
      boxShadow: (hoveredBox === 1 && totalLength2 > 0)
        ? "0 0 22px 10px rgba(145, 158, 171, 0.4), 0 12px 24px -4px rgba(145, 158, 171, 0.12)" // deeper shadow on hover
        : hoveredBox !== 1 && totalLength2>0 ? "rgba(145, 158, 171, 0.2) 0px 0px 12px 5px, rgba(145, 158, 171, 0.01) 0px 2px 5px -4px" :
         "none", // normal shadow
        
            }}
            
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={handleMouseLeave}
          >
            {translationItem2?.length > 0 && (
              <FormControlLabel
                sx={{
                  marginBottom: '20px',
                  marginLeft: '10px',
                  '&.span': { fontSize: 'large' },
                  borderRadius: '10px',
                  padding: '10px',
                  marginRight: '60px',
                  width: '-webkit-fill-available',
                  justifyContent: 'space-between',
                }}
                value={`${language === 'English' ? 'Select Option' : 'Seleziona Opzione'} 01`}
                control={<Radio  sx={{
                    '&.Mui-checked': {
      color: '#4FD5ED', // Custom color when checked
    },
                    '& .MuiSvgIcon-root': {
                      fontSize: 35, // Adjust the fontSize to enlarge the icon
                    },

                  }} checked={option === 1 ? true : false} onClick={() => dispatch(addOption(1))} />}
                label={
                  <span style={{ fontSize: 'large', color: `${option === 1 ? `#2da0f0` : `black`}` }}>
                    {`${language === 'English' ? 'Select Option' : 'Seleziona Opzione'}`} 01
                  </span>
                }
                labelPlacement="start"
              />
            )}
            {/* <Card sx={{ display: 'contents' }}> */}
            <Scrollbar>
              <TableContainer sx={{
                  borderTopLeftRadius: "10px !important",
                  borderTopRightRadius: "10px",
                  marginLeft: "2%",
                  width: "97%",
                }}>
                <Table sx={{ width: '100%', borderCollapse: 'inherit' }}>
                <Table2Header/>
                 <TableBody>
                    {translationItem &&
                      translationItem.map((row, index) => (
                      <Table2Items row={row} index={index} itemTranslation={items1Translation?.length > 0 ? items1Translation[index] : ''}/>
                      ))}
                    {additionalService &&
                      additionalService.map((row, index) => (
                        <Table2ServiceItems row={row} index={index} itemTranslation={service1Translation?.length > 0 ? service1Translation[index] : ''}/>

                      ))}

                
                    <RowResultStyle sx={{ borderBottom: 'none' }}>
                      <TableCell sx={{ borderBottom: 'none' }} align="left">
                        <Box sx={{ mt: 2 }} />
                        <Typography
                          sx={{
                            color: `${option === 1 ? `#2da0f0` : `black`}`,
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: 'large',
                            fontWeight: '700',
                          }}
                        >
                          {language === 'English' ? 'Subtotal' : 'Subtotale'}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }} />
                      <TableCell sx={{ borderBottom: 'none' }} />

                      <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                        <Typography sx={{color:`${option === 1 ?`#2da0f0`:`black`}`, fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}>
                           &nbsp;{fCurrency(Number(subtotal))}
                        </Typography>
                      </TableCell>
                    </RowResultStyle>
                    {totalDiscount ? (
                      <RowResultStyle>
                        <TableCell sx={{ borderBottom: 'none' }} align="left">
                          <Typography
                            sx={{
                              color: `${option === 1 ? `#2da0f0` : `black`}`,
                              fontFamily: "'Poppins', sans-serif",
                              fontSize: 'large',
                              fontWeight: '700',
                            }}
                          >
                            {language === 'English' ? 'Discount' : 'Sconto'} ({totalDiscount ? totalDiscount : 0}%)
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 'none' }} />
                        <TableCell sx={{ borderBottom: 'none' }} />
                        <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                          <Typography
                            sx={{
                              color: `${option === 1 ? `#2da0f0` : `black`}`,
                              fontFamily: "'Poppins', sans-serif",
                              fontSize: 'large',
                              fontWeight: '700',
                            }}
                          >
                             &nbsp;{fCurrency(Number((Number(subtotal) * Number(totalDiscount)) / 100))}
                          </Typography>
                        </TableCell>
                      </RowResultStyle>
                    ) : (
                      ''
                    )}
                    <RowResultStyle>
                      <TableCell sx={{ borderBottom: 'none' }} align="left">
                        <Typography
                          sx={{
                            color: `${option === 1 ? `#2da0f0` : `black`}`,
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: 'large',
                            fontWeight: '700',
                          }}
                        >
                          {language === 'English' ? 'Net Total' : 'Totale netto'}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }} />
                      <TableCell sx={{ borderBottom: 'none' }} />
                      <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                        <Typography sx={{color:`${option === 1 ?`#2da0f0`:`black`}`, fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}>
                           &nbsp;{fCurrency(netTotal)}
                        </Typography>
                      </TableCell>
                    </RowResultStyle>
                    <RowResultStyle>
                      <TableCell sx={{ borderBottom: 'none' }} align="left">
                        <Typography
                          sx={{
                            color: `${option === 1 ? `#2da0f0` : `black`}`,
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: 'large',
                            fontWeight: '700',
                          }}
                        >
                          IVA ({taxes}%)
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }} />
                      <TableCell sx={{ borderBottom: 'none' }} />

                      <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                        <Typography sx={{color:`${option === 1 ?`#2da0f0`:`black`}`, fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}>
                           &nbsp;{fCurrency(Number((Number(netTotal) * Number(taxes)) / 100))}
                        </Typography>
                      </TableCell>
                    </RowResultStyle>

                    <RowResultStyle sx={{  backgroundColor: "#e6f5ff",
                        borderRadius: "10px",}}>
                      <TableCell sx={{ borderBottom: 'none' }} align="left">
                        <Typography
                          sx={{
                            color: `${option === 1 ? `#2da0f0` : `black`}`,
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: 'large',
                            fontWeight: '700',
                          }}
                        >
                          {language === 'English' ? 'Total Gross' : 'Totale Lordo'}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }} />
                      <TableCell sx={{ borderBottom: 'none' }} />
                      {grandTotal ? (
                        <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                          <Box sx={{ mt: 2 }} />
                          <Typography
                            sx={{
                              color: `${option === 1 ? `#2da0f0` : `black`}`,
                              fontFamily: "'Poppins', sans-serif",
                              fontSize: 'large',
                              fontWeight: '700',
                            }}
                          >
                             &nbsp;{fCurrency(Number(grandTotal))}
                          </Typography>
                        </TableCell>
                      ) : (
                        <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                          <Typography
                            sx={{
                              color: `${option === 1 ? `#2da0f0` : `black`}`,
                              fontFamily: "'Poppins', sans-serif",
                              fontSize: 'large',
                              fontWeight: '700',
                            }}
                          >
                             &nbsp;{fCurrency((Number(subtotal) + (Number(subtotal) * Number(taxes)) / 100))}
                          </Typography>
                        </TableCell>
                      )}
                    </RowResultStyle>
                    <RowResultStyle sx={{ borderBottom: 'none' }}>
                      <TableCell
                        colSpan={5}
                        sx={{
                          borderBottom: 'none',
                          borderTop: '1.29922px solid #2DA0F0',
                          fontFamily: "'Poppins', sans-serif",
                          fontSize: 'large',
                        }}
                        align="left"
                      >
                        {notes && notes !== '' && notes !== '<p><br></p>' && (
                          <>
                            <Box sx={{ mt: 2 }} />
                            <Typography
                              sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                            >
                              {language === 'English' ? "Notes" : "Note"}
                            </Typography>
                            <Typography variant="body2" sx={{ padding: "10px",
                                borderRadius: "10px",
                                backgroundColor: "#F0F0F0",
                                color: "text.secondary",
                                fontSize: "15px", }}>
                            <div dangerouslySetInnerHTML={{ __html: notes }}></div>
                          </Typography>
                          </>
                        )}
                      </TableCell>
                    </RowResultStyle>
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            {/* </Card> */}
          </div>
          {translationItem2?.length + additionalService2?.length > 0 &&
            translationItem2?.length > 0 &&
            translationItem2?.map((item, index) => (
              <div
                className={styles1.table2}
                style={{
                    marginBottom: "4em",
                    padding: "0px",
                    paddingTop:"10px",
                    paddingBottom:"10px",
                    marginLeft: "0px",
                    marginInline: "auto",
                    cursor:"pointer",
                    marginTop: "3em",
                    border: `${
                      option === index + 2 ? `2px solid #4FD5ED` : `none`
                    }`,
                    borderRadius: "15px",
                    transition: 'transform 0.4s, box-shadow 0.4s', // smooth transition for hover effects
                    transform: hoveredBox === index+2 ? 'scale(1.02)' : 'scale(1)', // slight zoom when hovered
      boxShadow: hoveredBox === index+2
        ? "0 0 22px 10px rgba(145, 158, 171, 0.5), 0 12px 24px -4px rgba(145, 158, 171, 0.12)" // deeper shadow on hover
        : "rgba(145, 158, 171, 0.2) 0px 0px 12px 5px, rgba(145, 158, 171, 0.01) 0px 2px 5px -4px", // normal shadow
        
                  }}
                  onMouseEnter={() => handleMouseEnter(index+2)}
            onMouseLeave={handleMouseLeave}
              >
                <FormControlLabel
                  sx={{
                    marginBottom: '20px',
                    marginLeft: '10px',
                    '&.span': { fontSize: 'large' },
                    borderRadius: '10px',
                    padding: '10px',
                    marginRight: '60px',
                    width: '-webkit-fill-available',
                    justifyContent: 'space-between',
                  }}
                  value={`${language === 'English' ? 'Select Option' : 'Seleziona Opzione'} 0${index + 2}`}
                  control={<Radio sx={{
                    '&.Mui-checked': {
    color: '#4FD5ED', // Custom color when checked
  },
                    '& .MuiSvgIcon-root': {
                      fontSize: 35, // Adjust the fontSize to enlarge the icon
                    },
                  }} checked={option === index + 2 ? true : false} onClick={() => dispatch(addOption(index + 2))} />}
                  label={
                    <span style={{ fontSize: 'large', color: `${option === index + 2 ? `#2da0f0` : `black`}` }}>
                      {`${language === 'English' ? 'Select Option' : 'Seleziona Opzione'}`} 0{index + 2}
                    </span>
                  }
                  labelPlacement="start"
                />

                {/* <Card sx={{ display: 'contents' }}> */}
                <Scrollbar>
                  <TableContainer sx={{ borderTopLeftRadius: "10px !important",
                      borderTopRightRadius: "10px",
                      marginLeft: "2%",
                      width: "97%", }}>
                    <Table sx={{ width: '100%', borderCollapse: 'inherit' }}>
                      <Table2Header/>
                      <TableBody>
                        {translationItem2[index]?.length > 0 &&
                          translationItem2[index]?.map((row, ind) => (
                            <Table2Items row={row} index={index} itemTranslation={items2Translation?.length > 0 ? items2Translation[index][ind] : ''}/>

                          ))}
                        {additionalService2[index]?.length > 0 &&
                          additionalService2[index]?.map((row, ind) => (
                            <Table2ServiceItems row={row} index={index} itemTranslation={service2Translation?.length > 0 ? service2Translation[index][ind] : ''}/>

                          ))}

                      
                        <RowResultStyle sx={{ borderBottom: 'none' }}>
                          <TableCell sx={{ borderBottom: 'none' }} align="left">
                            <Box sx={{ mt: 2 }} />
                            <Typography
                              sx={{
                                color: `${option === index + 2 ? `#2da0f0` : `black`}`,
                                fontFamily: "'Poppins', sans-serif",
                                fontSize: 'large',
                                fontWeight: '700',
                              }}
                            >
                              {language === 'English' ? 'Subtotal' : 'Subtotale'}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ borderBottom: 'none' }} />
                          <TableCell sx={{ borderBottom: 'none' }} />

                          <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                            <Typography
                              sx={{
                                color: `${option === index + 2 ? `#2da0f0` : `black`}`,
                                fontFamily: "'Poppins', sans-serif",
                                fontSize: 'large',
                                fontWeight: '700',
                              }}
                            >
                            &nbsp;{fCurrency(Number(subtotalOptions[index]).toFixed(2))}
                            </Typography>
                          </TableCell>
                        </RowResultStyle>
                        {totalDiscountOptions[index] ? (
                          <RowResultStyle>
                            <TableCell sx={{ borderBottom: 'none' }} align="left">
                              <Typography
                                sx={{
                                  color: `${option === index + 2 ? `#2da0f0` : `black`}`,
                                  fontFamily: "'Poppins', sans-serif",
                                  fontSize: 'large',
                                  fontWeight: '700',
                                }}
                              >
                                {language === 'English' ? 'Discount' : 'Sconto'} (
                                {totalDiscountOptions[index] ? totalDiscountOptions[index] : 0}%)
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ borderBottom: 'none' }} />
                            <TableCell sx={{ borderBottom: 'none' }} />
                            <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                              <Typography
                                sx={{
                                  color: `${option === index + 2 ? `#2da0f0` : `black`}`,
                                  fontFamily: "'Poppins', sans-serif",
                                  fontSize: 'large',
                                  fontWeight: '700',
                                }}
                              >
                                &nbsp;
                                {fCurrency(
                                  Number(
                                    (Number(subtotalOptions[index]) * Number(totalDiscountOptions[index])) / 100
                                  ).toFixed(2)
                                )}
                              </Typography>
                            </TableCell>
                          </RowResultStyle>
                        ) : (
                          ''
                        )}
                        <RowResultStyle>
                          <TableCell sx={{ borderBottom: 'none' }} align="left">
                            <Typography
                              sx={{
                                color: `${option === index + 2 ? `#2da0f0` : `black`}`,
                                fontFamily: "'Poppins', sans-serif",
                                fontSize: 'large',
                                fontWeight: '700',
                              }}
                            >
                              {language === 'English' ? 'Net Total' : 'Totale netto'}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ borderBottom: 'none' }} />
                          <TableCell sx={{ borderBottom: 'none' }} />
                          <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                            <Typography
                              sx={{
                                color: `${option === index + 2 ? `#2da0f0` : `black`}`,
                                fontFamily: "'Poppins', sans-serif",
                                fontSize: 'large',
                                fontWeight: '700',
                              }}
                            >
                              &nbsp;{fCurrency(netTotalOptions[index])}
                            </Typography>
                          </TableCell>
                        </RowResultStyle>
                        <RowResultStyle>
                          <TableCell sx={{ borderBottom: 'none' }} align="left">
                            <Typography
                              sx={{
                                color: `${option === index + 2 ? `#2da0f0` : `black`}`,
                                fontFamily: "'Poppins', sans-serif",
                                fontSize: 'large',
                                fontWeight: '700',
                              }}
                            >
                              IVA ({taxesOptions[index]}%)
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ borderBottom: 'none' }} />
                          <TableCell sx={{ borderBottom: 'none' }} />

                          <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                            <Typography
                              sx={{
                                color: `${option === index + 2 ? `#2da0f0` : `black`}`,
                                fontFamily: "'Poppins', sans-serif",
                                fontSize: 'large',
                                fontWeight: '700',
                              }}
                            >
                              &nbsp;
                              {fCurrency(
                                Number((Number(netTotalOptions[index]) * Number(taxesOptions[index])) / 100).toFixed(2)
                              )}
                            </Typography>
                          </TableCell>
                        </RowResultStyle>

                        <RowResultStyle  sx={{
                            backgroundColor: "#e6f5ff",
                            borderRadius: "10px",
                          }}>
                          <TableCell sx={{ borderBottom: 'none' }} align="left">
                            <Typography
                              sx={{
                                color: `${option === index + 2 ? `#2da0f0` : `black`}`,
                                fontFamily: "'Poppins', sans-serif",
                                fontSize: 'large',
                                fontWeight: '700',
                              }}
                            >
                              {language === 'English' ? 'Total Gross' : 'Totale Lordo'}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ borderBottom: 'none' }} />
                          <TableCell sx={{ borderBottom: 'none' }} />
                          {grandTotalOptions[index] ? (
                            <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                              <Box sx={{ mt: 2 }} />
                              <Typography
                                sx={{
                                  color: `${option === index + 2 ? `#2da0f0` : `black`}`,
                                  fontFamily: "'Poppins', sans-serif",
                                  fontSize: 'large',
                                  fontWeight: '700',
                                }}
                              >
                                &nbsp;{fCurrency(Number(grandTotalOptions[index]).toFixed(2))}
                              </Typography>
                            </TableCell>
                          ) : (
                            <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                              <Typography
                                sx={{
                                  color: `${option === index + 2 ? `#2da0f0` : `black`}`,
                                  fontFamily: "'Poppins', sans-serif",
                                  fontSize: 'large',
                                  fontWeight: '700',
                                }}
                              >
                                &nbsp;
                                {fCurrency(
                                  (
                                    Number(subtotalOptions[index]) +
                                    (Number(subtotalOptions[index]) * Number(taxesOptions[index])) / 100
                                  ).toFixed(2)
                                )}
                              </Typography>
                            </TableCell>
                          )}
                        </RowResultStyle>
                        <RowResultStyle sx={{ borderBottom: 'none' }}>
                          <TableCell
                            colSpan={5}
                            sx={{
                              borderBottom: 'none',
                              borderTop: '1.29922px solid #2DA0F0',
                              fontFamily: "'Poppins', sans-serif",
                              fontSize: 'large',
                            }}
                            align="left"
                          >
                            {notesOptions && notesOptions[index] !== '' && notesOptions[index] !== '<p><br></p>' && (
                              <>
                                <Box sx={{ mt: 2 }} />
                                <Typography
                                  sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                                >
                                  {language === 'English' ? "Notes" : "Note"}
                                </Typography>
                                <Typography variant="body2" sx={{   padding: "10px",
                                      borderRadius: "10px",
                                      backgroundColor: "#F0F0F0",
                                      color: "text.secondary",
                                      fontSize: "15px", }}>
                                <div dangerouslySetInnerHTML={{ __html: notesOptions[index] }}></div>
                              </Typography>
                              </>
                            )}
                          </TableCell>
                        </RowResultStyle>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                {/* </Card> */}
              </div>
            ))}
        <Table3/>
        </div>
      </Box>
    </div>
  );
}

export default TablesItems;
