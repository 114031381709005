import React from "react";
import { Grid, Link } from "@mui/material";
import { useSelector } from "react-redux";

const content = {
items: [
    {
      city: {
        english: "Ribera",
        italian: "Ribera",
      },
      address: {
        english: "Viale Garibaldi 79, 92016, Ribera (AG)",
        italian: "Viale Garibaldi 79, 92016, Ribera (AG)",
      },
      email:"academy@lingoyou.com",
      width: "391px",
    },
    
  ],
};


export default function ContactUs() {
  const {details, language} = useSelector(state => state.proposal)
console.log({language})
 const {brand} = details
  return (
    <div>
      <div
        style={{
          backgroundImage:`linear-gradient(to right top, #A158D6 20%, #43BEF3 80%) `,
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "auto",
          paddingBottom: "20px",
          backgroundSize: "cover",
          textAlign: "center",
        }}
      >
        <Grid
          container
          spacing={3}
          style={{
            marginInline: "auto",
            paddingTop: "80px",
            textAlign: "left",
            maxWidth: "1400px",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              textAlign: "left",
              fontFamily: "'Poppins'",
              width: "85%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <img
              alt="logo"
              style={{ objectFit: "contain" }}
              src={"/lingoAcademy/logos/academy-wh.png"}
              />
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                gap: "20px",
                marginRight: "5%",
              }}
            >
              <Link
              href={"https://m.facebook.com/lingoyougroup/"}> 
               <img
                alt="social"
                style={{ objectFit: "contain" }}
                src="/assets/icons/facebook.png"
              />
              </Link>
            <Link href='https://x.com/lingoyou3'>  
             <img
                alt="social"
                style={{ objectFit: "contain" }}
                src="/assets/icons/twitter.png"
              />
            </Link>
           
              <Link href='https://linkedin.com/company/lingoyou'>  
               <img
                alt="social"
                style={{ objectFit: "contain" }}
                src="/assets/icons/linkedIn.png"
              />
              </Link>
           
              <Link href={'https://www.instagram.com/lingo_you/'}>  <img
                alt="social"
                style={{ objectFit: "contain" }}
                src="/assets/icons/insta.png"
              />
              </Link>
            
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            marginInline: "auto",
            textAlign: "-webkit-center",
            marginTop: "50px",
          }}
        >
          <Grid
            container
            spacing={3}
            sx={{ width: "90%", paddingBottom: "40px" }}
          >
            {content.items.map((item) => (
              <Grid item xs={12} md={12} lg={12} sx={{ textAlign: "left" }}>
                <p
                  style={{
                    fontFamily: "'Poppins'",
                    maxWidth: `${item.width}`,
                    textAlign: "left",
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    <img
                      alt="map"
                      style={{ objectFit: "contain", marginBottom: "-5px" }}
                      src="/assets/icons/location.png"
                    />
                    <span style={{ marginTop: "-15px", marginLeft: "5px" }}>
                      {item?.city[String(language).toLowerCase()]}
                    </span>
                  </span>
                  <br />
                  <span
                    style={{
                      color: "white",
                      fontSize: "18px",
                      fontWeight: "400",
                    }}
                  >
                    {item?.address[String(language).toLowerCase()]}
                  </span><br/>
                 
                </p>
              </Grid>
            ))}
            <Grid item xs={12} md={12} lg={12} sx={{ textAlign: "left" }}>
                <p
                  style={{
                    fontFamily: "'Poppins'",
                    textAlign: "left",
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      fontSize: "18px",
                      fontWeight: "400",
                    }}
                  >
                    <img style={{objectFit:"contain", marginBottom:"-10px", width:"30px"}} 
                    src="/lingoAcademy/icons/globe.png"/> {content.items[0]?.email}
                  </span>
                </p>
                <p
                  style={{
                    fontFamily: "'Poppins'",
                    textAlign: "left",
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      fontSize: "18px",
                      fontWeight: "400",
                    }}
                  >
                    <img style={{objectFit:"contain", marginBottom:"-10px", width:"30px"}} 
                    src="/lingoAcademy/icons/phone.png"/> 
                  {" "}  +39 375 580 6530
                  </span>
                </p>
              </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            sx={{
              width: "88%",
              color: "white",
              borderTop: "1px solid white",
              paddingBottom: "40px",
            }}
          >
            {/* <Grid item xs={4} sx={{ textAlign: "left" }}>
              <p style={{ fontSize: "18px", fontWeight: "400" }}>
                Contact details
              </p>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: "left" }}>
              <p style={{ fontSize: "18px", fontWeight: "400" }}>
                Email: info@lingoyou.com
              </p>
            </Grid>
            <Grid item xs={4}>
              <p style={{ fontSize: "18px", fontWeight: "400" }}>
                Telefono: +39 0282957101
              </p>
            </Grid> */}
            <Grid item xs={12} sx={{ textAlign: "center" }}></Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
