import React from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const content = {
 "English":{ 
  title: "We Provide Effortless Translation Solutions",
  desc: "LingoYou provides accurate and convenient translations worldwide, committed to breaking down language barriers with over ten years of experience and a dedication to quality.",
  icons: [
    {
        img: "book.png",
        en: "Flexible and customizable courses to meet all needs",
    },
    {
        img: "online-inperson.png",
        en: "Immediate availability for online and in-person courses",
    },
    {
        img: "discount.png",
        en: "First lesson free and competitive rates with a 10% discount for new students",
    },
    { 
        img: "certificate.png",
        en: "ISO-9001: 2015 and ISO 17100:2017 quality certifications" 
    },
    { 
        img: "translation.png",
        en: "Courses in 50+ languages for all ages and for business" 
    },
    {
        img: "people.png",
        en: "Group, semi-individual, and individual lessons",
    },
    {
        img: "teach.png",
        en: "Preparation courses for IELTS, TOEFL, PET, FCE, CAE exams",
    },
    {
        img: "building.png",
        en: "Solutions for businesses, individuals, and schools",
    },
    {
        img: "teach.png",
        en: "Innovative teaching methods and cutting-edge online platforms",
    },
    {
        img: "environment.png",
        en: "Motivating environment and welcoming atmosphere",
    },
    {
        img: "learning-app.png",
        en: "Learning app and interactive lessons",
    },
    {
        img: "classroom.png",
        en: "Spacious and well-equipped classrooms"
    },
    {
        img: "english.png",
        en: "Oxford test to assess English language proficiency"
    },
    {
        img: "events.png",
        en: "Recreational activities and dedicated events"
    }
],
},
"Italian":{
  title: "Forniamo soluzioni linguistiche per ogni esigenza",
  desc: "LingoYou fornisce traduzioni accurate a prezzi competitivi in tutto il mondo, impegnandosi ad abbattere le barriere linguistiche.",
  icons: [
    {
        img: "book.png",
        en: "Corsi flessibili e personalizzabili in base a tutte le esigenze",
    },
    {
        img: "online-inperson.png",
        en: "Disponibilità immediata per corsi online e in presenza",
    },
    {
        img: "discount.png",
        en: "Prima lezione in omaggio e tariffe competitive con un 10% di sconto per i nuovi studenti",
    },
    { 
        img: "certificate.png",
        en: "Certificazioni di qualità ISO-9001: 2015 e ISO 17100:2017" },
    { 
        img: "translation.png",
        en: "Corsi in più di 50 lingue per tutte le età e le necessità" 
    },
    
    {
        img: "people.png",
        en: "Lezioni di gruppo, semi-individuali e individuali",
    },{
        img: "teach.png",
        en: "Corsi di preparazione ad esami IELTS, TOEFL, PET, FCE, CAE",
    },{
        img: "building.png",
        en: "Soluzioni per aziende, privati e scuole",
    },{
        img: "teach.png",
        en: "Metodologie didattiche innovative e piattaforme online all’avanguardia",
    },
    {
        img: "environment.png",
        en: "Ambiente motivante e clima accogliente",
    },
    {
        img: "learning-app.png",
        en: "Learning app e lezioni interattive",
    },
    {
        img:'classroom.png',
        en:'Aule ampie ed attrezzate'
    },
    {
        img:'english.png',
        en:'Test Oxford per verificare il livello di conoscenza della lingua inglese'
    },
    {
        img:'events.png',
        en:'Attività ricreative ed eventi dedicati'
    }
  ],
}
};

export default function WhyUs() {

  const { language} = useSelector(state => state.proposal)

  return (
    <div
    >
      <div
        style={{
          backgroundImage: "linear-gradient(to right top, #A158D6 20%, #43BEF3 80%) ",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "auto",
          paddingBottom: "30px",
          backgroundSize: "cover",
          textAlign: "center",
          opacity: 0.9,
        }}
      >
        <div
    style={{
      textAlign: "right",
      width: "97%",
      marginInline:"auto",
      padding:"2px",
      display:"flex",
      marginBottom: "30px",
      justifyContent: "space-between",
      borderBottom: "2px solid white",
    }}
  >
     <p>
           <span  style={{fontWeight:"500", color: "white", fontSize: "20px"}}
           >
          {language === 'English' ? `WHY US` : `PERCHÉ NOI`}
          </span>
    </p>
    <img
      src={"/lingoAcademy/logos/academy-wh.png"}
      style={{ marginRight: "20px", objectFit:"contain" }}
      alt="logo"
    />
  </div>
      
        <div  style={{
            marginInline: "auto",
            textAlign: "-webkit-center",
            marginTop:"50px",
            marginBottom:"30px"
          }}>
 <Grid container spacing={1}>
            {content[language]?.icons.map((icon) => (
              <Grid item xs={2.4} md={6} lg={2.4}>
                <p style={{fontFamily:"'Poppins'", maxWidth:"260px", textAlign:"center"}}>
                <div
                      style={{
                        width: "50px",
                        height: "50px",
                        textAlign: "center",
                        marginInline: "auto",
                      }}
                    >
                      <img
                        src={`/lingoAcademy/icons/${icon.img}`}
                        style={{
                          width: "40px",
                          height: "40px",
                          paddingTop: "10%",
                          objectFit: "contain",
                        }}
                        alt="logo"
                      />
                    </div>
                    <span style={{color:"white", fontSize:"14px", fontWeight:"400"}}>

                    {icon?.en}
                    </span>
                </p>
               
              </Grid>
            ))}
          </Grid>
        </div>
       
      </div>
    </div>
  );
}
