import { Image, Link, Page, Text, View } from "@react-pdf/renderer";
import React from "react";
import { styles } from "../globalStyles";

const content = {
  Italian: [
    "Cambridge English: Starters (YLE Starters)",
    "Cambridge English: Movers (YLE Movers)",
    "Cambridge English: Flyers (YLE Flyers)",
    "Cambridge English: Key for Schools (KET for Schools)",
    "Cambridge English: Preliminary for Schools (PET for Schools)",
    "Cambridge English: First for Schools (FCE for Schools)",
    "Cambridge English: Advanced (CAE)",
    "Cambridge English: Proficiency (CPE)",
    "ETS – Educational Testing Service (TOEFL)",
    "International English Language Testing System (IELTS)",
    "Trinity College London (TCL – Trinity)",
    "DELE",
    "DELF",
    "CELI",
  ],
  English: [
    "Cambridge English: Starters (YLE Starters)",
    "Cambridge English: Movers (YLE Movers)",
    "Cambridge English: Flyers (YLE Flyers)",
    "Cambridge English: Key for Schools (KET for Schools)",
    "Cambridge English: Preliminary for Schools (PET for Schools)",
    "Cambridge English: First for Schools (FCE for Schools)",
    "Cambridge English: Advanced (CAE)",
    "Cambridge English: Proficiency (CPE)",
    "ETS – Educational Testing Service (TOEFL)",
    "International English Language Testing System (IELTS)",
    "Trinity College London (TCL – Trinity)",
    "DELE",
    "DELF",
    "CELI",
  ],
};
const Certification = ({ language, quoteId }) => {
  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
      <View
        style={{
          ...styles.header,
          marginBottom: 0,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          marginHorizontal: "auto",
        }}
      >
        <Text
          style={{
            fontSize: "8px",
            marginTop: "2px",
            marginLeft: "5px",
            color: "#A158D6",
          }}
        >
          {language === "English" ? "CERTIFICATIONS" : "CERTIFICAZIONI"}
        </Text>
        <View style={{ marginLeft: "430px" }}>
          <Image
            style={styles.headerRightImg}
            src="/lingoAcademy/logos/academy.png"
          />
        </View>
        <View style={styles.headerItem} />
      </View>
      <View style={{ marginLeft: "30px", marginRight: "30px" }}>
        <Text
          style={{ fontSize: "13px", marginTop: "25px", fontWeight: "bold" }}
        >
          {language === "English"
            ? "Certification preparation courses:"
            : "Corsi di preparazione per certificazioni:"}
        </Text>
        <Text
          style={{ fontSize: "9px", marginTop: "15px", fontWeight: "light" }}
        >
          {language === "English"
            ? `At LingoYou Academy you will find everything you need for your certification. Our Institute, as well as
Cambridge Assessment English Preparation Centre, will follow your path from start to finish,
from the Oxford English Test to the exam for the required certification.`
            : `Da LingoYou Academy troverai tutto ciò di cui necessiti per la tua certificazione. Il nostro Istituto, nonché
Cambridge Assessment English Preparation Centre, seguirà il tuo percorso dall’inizio fino alla fine,
dall’Oxford English Test all’esame per la certificazione richiesta.`}
        </Text>
        <Text
          style={{ fontSize: "9px", marginTop: "15px", fontWeight: "light" }}
        >
          {language === "English"
            ? `Our team will, in fact, manage the entire logistical process of registering for the exam to obtain the
language certification, completely free of charge. All you have to do is tell us the type of exam, the
date and LingoYou Academy will take care of everything else!`
            : `Il nostro Team, infatti, gestirà l’intero processo logistico di iscrizione all’esame di conseguimento della
certificazione in lingua, in maniera del tutto gratuita. Ti basterà comunicarci la tipologia di esame, la
data e LingoYou Academy penserà a tutto il resto!
`}
        </Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View style={{ marginTop: "10px" }}>
            {content[language]?.slice(0, 7).map((item) => (
              <Text
                style={{
                  fontSize: "8px",
                  marginTop: "5px",
                  fontWeight: "light",
                }}
              >
                •{item}
              </Text>
            ))}
          </View>
          <View style={{ marginTop: "10px" }}>
            {content[language]?.slice(7).map((item) => (
              <Text
                style={{
                  fontSize: "8px",
                  marginTop: "5px",
                  fontWeight: "light",
                }}
              >
                •{item}
              </Text>
            ))}
          </View>
        </View>
      </View>
      <Text style={{...styles.dividerText, backgroundColor:"#2DA0F0", marginTop:"30px"}}>
          {language === "English" ? "CERTIFICATIONS" : "CERTIFICAZIONI"}

       </Text>
       <View style={{marginTop:"30px", marginBottom:"30px", justifyContent:"center", alignItems:"center", textAlign:"center"}}>
       <Link  href="https://www.youtube.com/embed/DS2L__o_1k8">
           <Image src="/lingoAcademy/certification.png"
           style={{objectFit:'contain', width:"250px", height:"150px"}}
           />
        </Link>
       </View>
      <Image
        src={"/translation/pdf-bg.png"}
        style={{
          objectFit: "cover",
          width: "100%",
          zIndex: "-1111",
          marginTop: "-1000px",
          height: "1020px",
        }}
      />
    </Page>
  );
};

export default Certification;
