import React from "react";
import { styles } from "../../globalStyles";
import { Image, Page, Text, View } from "@react-pdf/renderer";
import { fCurrency } from "../../../../utils/formatNumber";

const Header = ({
  language = "Italian",
  grandTotal,
  accountManager,
  companyNameAndId,
  bookedBy,
  brand,
}) => {
  return (
    <View>
       <View style={{ ...styles.header, marginBottom: 0, width: "100%", display:"flex", flexDirection:"row", marginHorizontal: "auto" }}>
      <Text style={{fontSize:"8px",marginTop:"2px", marginLeft:"5px", color:"#A158D6"}}>
      {language === 'English' ? `Quote` : `Preventivo`}        

        </Text>
         <View style={{marginLeft:"430px"}}>
         <Image style={styles.headerRightImg} src={brand?.academy ? "/lingoAcademy/logos/academy.png":"/translation/logos/lingoyou.png"} />
         </View>
        <View style={styles.headerItem} />
      </View>
      <View
        style={{
          marginTop: "60px",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            color: "white",
            fontSize: "17px",
            marginTop: "5px",
            marginLeft: "-10px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {language === "English"
            ? `Customer Billing Data`
            : `Dati fatturazione cliente`}
        </Text>
        <Text
          style={{
            color: "white",
            fontSize: "9px",
            width: "400px",
            fontWeight: "normal",
            textAlign: "center",
            marginLeft: "90px",
          }}
        >
          {language === "English"
            ? "Reciept of Customer Billing Data"
            : "Ricezione dei dati di fatturazione del cliente"}
        </Text>
      </View>
      <View
        style={{
          marginTop: "30px",
          marginLeft: "70px",
          width: "430px",
          paddingBottom: "5px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <Text style={{ color: "white", fontSize: "9px", fontWeight: "light" }}>
          {language === "English" ? "Total Payment" : "Pagamento totale"}
        </Text>
        <Text style={{ color: "white", fontSize: "11px", fontWeight: "bold" }}>
          {fCurrency(grandTotal)}
        </Text>
      </View>
      <View
        style={{
          marginTop: "10px",
          marginLeft: "70px",
          width: "450px",
          paddingBottom: "5px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View>
          <View
            style={{
              padding: "10px",
              border: "1px solid white",
              borderRadius: "5px",
              width: "260px",
            }}
          >
            <Text
              style={{ color: "white", fontSize: "9px", fontWeight: "light" }}
            >
              {language === "English" ? "Billed To" : "Fatturato a"}
            </Text>
            <View
              style={{
                color: "white",
                marginTop: "5px",
                marginBottom: "5px",
                fontSize: "10px",
                fontWeight: "bold",
              }}
            >
              <Text style={{ textTransform: "uppercase" }}>
                {companyNameAndId?.name} 
              </Text>
              <Text style={{color:"white", fontSize:"9px"}}>
              {companyNameAndId?.address1 ? companyNameAndId?.address1 : ''}
                {companyNameAndId?.address2 ? companyNameAndId?.address2 : ''}
                {companyNameAndId?.postcode} {companyNameAndId?.city}
              </Text>
            </View>
          </View>
           <View
            style={{
              padding: "10px",
              marginTop: "10px",
              border: "1px solid white",
              borderRadius: "5px",
              width: "260px",
            }}
          >
            <Text
              style={{
                color: "white",
                // marginTop: "5px",
                fontSize: "9px",
                fontWeight: "light",
              }}
            >
              {language === "English" ? "Contact:" : "Contatto:"}
            </Text>
            <View
              style={{
                color: "white",
                fontSize: "9px",
                marginTop: "5px",
                marginBottom: "5px",
                fontWeight: "light",
              }}
            >
              <Text>
              {bookedBy?.firstName}{" "}
              {bookedBy?.lastName}
                
              </Text>
            </View>
          </View> 
        </View>

        <View>
          <View
            style={{
              padding: "10px",
              border: "1px solid white",
              borderRadius: "5px",
              width: "180px",
            }}
          >
            <Text
              style={{ color: "white", fontSize: "9px", fontWeight: "light" }}
            >
              Account Manager:
            </Text>
            <Text
              style={{
                color: "white",
                marginTop: "5px",
                marginBottom: "5px",
                fontSize: "11px",
                fontWeight: "bold",
              }}
            >
              {accountManager?.name}
            </Text>
          </View>
          <View
            style={{
              padding: "10px",
              marginTop: "10px",
              // marginBottom: "5px",
              border: "1px solid white",
              borderRadius: "5px",
              
            }}
          >
            <Text
              style={{
                color: "white",
                fontSize: "9px",
                // marginTop: "5px",
                fontWeight: "light",
              }}
            >
              {language === "English" ? "Contact:" : "Contatto:"}
            </Text>
            <Text
              style={{
                color: "white",
                marginTop: "5px",
                fontSize: "9px",
                fontWeight: "light",
              }}
            >
              info@lingoyou.com
            </Text>
            <Text
              style={{
                color: "white",
                fontSize: "9px",
                fontWeight: "light",
                marginTop: "5px",
              }}
            >
              Telefono: (+39) 0282957101
            </Text>
          </View>
        </View>
      </View>
      <Image
        src={"/translation/quote-header-bg.png"}
        style={{
          objectFit: "contain",
          zIndex: "-1111",
          width: "100%",
          marginTop: "-300px",
          height: "100%",
        }}
      />
    </View>
  );
};

export default Header;
