import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';


const Home = ({ data, language }) => {
     const {companyNameAndId, bookedBy, brand, accountManager,subject, addOns, orderNumber, industry} = data
    
  return (
    <Page wrap={false} size="A4" style={{ ...styles.page }}>
     
      <View style={{ marginTop:"60px", marginLeft:"150px"}}>
         <Image style={{objectFit:"contain", width:"250px", height:"100px"}} src={"/lingoAcademy/logos/lingoyou.png"}/>
         <Text style={{fontSize:"8px", marginLeft:"15px", marginTop:"35px", letterSpacing:"1.5px",textTransform:"uppercase", fontWeight:"semibold", color:"white"}}>
         {language === "English" ? "A new Era of Global Communication":"Una nuova era di comunicazione globale"}
         </Text>
         <Text style={{fontSize:"18px", textAlign:"center", marginTop:"15px",width:"280px", 
          fontWeight:"bold", color:"white"}}>
         {language === "English" ? "Proposal Dedicated To": "Proposal per"} {companyNameAndId ? companyNameAndId.name : '___'}
         </Text>
         <Text style={{fontSize:"10px", textAlign:"center", justifyContent:"center", padding:"7px", border:"1px solid white", borderRadius:"6px",  
          marginTop:"20px",width:"170px", marginLeft:"40px", fontWeight:"semibold",  color:"white"}}>
               {language === "English"?`Quote: LY/${orderNumber} Project`:`Preventivo: LY/${orderNumber}`} 
          </Text>
         {/* <Text style={{fontSize:"7px", marginLeft:"80px", marginTop:"30px", fontWeight:"semibold", color:"white"}}>
         {language === "English" ? "Project" : "Progetto"}: {subject}
         </Text> */}
         <Text style={{fontSize:"10px", marginLeft:"40px", marginTop:"10px", fontWeight:"normal", 
          color:"white"}}>
         {language === "English" ? "Kindly Addressed To" : "All'attenzione di"} {bookedBy ? `${bookedBy?.firstName} ${bookedBy?.lastName}` : '___'}.
         </Text>
         
      </View>
      <Text style={{marginTop:"80px", marginLeft:"20px", marginRight:"20px", fontSize:"9px", 
          fontWeight:"medium", color:"white", textAlign:"right", paddingTop:"10px", borderTop:"1px solid lightgrey"}}>
               {language === "English" ? "Document Created By":"Documento creato da"} {accountManager?.name}
               </Text>
    <Image 
      style={{width:"101%", objectFit:"cover", zIndex:"-99", marginTop:"-520px", height:"550px"}}  
      src={`/clients/finance/coverfinance1.png`}/>
    </Page>
  );
};

export default Home;



