import React from 'react';
import { styles } from '../../globalStyles';
import { Image, Text, View } from '@react-pdf/renderer';
import { fCurrency } from "../../../../utils/formatNumber";


function isBlockLevel(tagName) {
  const blockLevelTags = [
      "address", "article", "aside", "blockquote", "details", "dialog", "dd",
      "div", "dl", "dt", "fieldset", "figcaption", "figure", "footer", "form",
      "h1", "h2", "h3", "h4", "h5", "h6", "header", "hgroup", "hr", "li", 
      "main", "nav", "ol", "p", "pre", "section", "table", "tfoot", "ul", "video"
  ];
  return blockLevelTags.includes(tagName.toLowerCase());
}
function splitByBlockLevelTags(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const body = doc.body;
  let substrings = [];
  let currentString = '';

  for (let node of body.childNodes) {
      if (node.nodeType === Node.ELEMENT_NODE) {
          const tagName = node.tagName;
          if (isBlockLevel(tagName)) {
              if (currentString.trim() !== '') {
                  substrings.push(currentString.trim());
                  currentString = '';
              }
              substrings.push(node.outerHTML);
          } else {
              currentString += node.outerHTML;
          }
      } else if (node.nodeType === Node.TEXT_NODE) {
          currentString += node.textContent;
      }
  }

  if (currentString.trim() !== '') {
      substrings.push(currentString.trim());
  }

  return substrings;
}


const TableTotals = ({ language = "Italian", details  }) => {

    const { notes, totalDiscount, subtotal, netTotal, taxes, grandTotal} = details
    const substrings = notes && splitByBlockLevelTags(notes);

    return (
   <>
    <View style={styles.notes}>
        
        <View style={styles.notesNote}>
          <View><Text style={styles.bold}>{language === "English" ? "Subtotal" : "Subtotale"}</Text></View>
          <View><Text>  &nbsp;{fCurrency(subtotal)}</Text></View>
        </View>
        {totalDiscount && (
          <View style={styles.notesNote}>
            <View><Text style={styles.bold}>{language === "English" ? "Discount" : "Sconto"} ({totalDiscount ? totalDiscount : 0}%)</Text></View>
            <View><Text>  &nbsp;{fCurrency((Number(subtotal) * Number(totalDiscount)) / 100)}</Text></View>
          </View>
        )}
        <View style={styles.notesNote}>
          <View><Text style={styles.bold}>{language === "English" ? "Net Total" : "Totale netto"}</Text></View>
          <View><Text>  &nbsp;{fCurrency(netTotal)}</Text></View>
        </View>
        <View style={styles.notesNote}>
          <View><Text style={styles.bold}>IVA ({taxes}%)</Text></View>
          <View><Text>  &nbsp;{fCurrency((Number(netTotal) * Number(taxes)) / 100)}</Text></View>
        </View>
        <View style={{...styles.notesNote, backgroundColor:"#e6f5ff", borderRadius:"10px", padding:"5px"}}>
          <View><Text style={styles.bold}>{language === "English" ? "Total Gross" : "Totale Lordo"}</Text></View>
          <View>
            <Text>
              {
                grandTotal ?
                  <> &nbsp;{fCurrency(grandTotal)}</>
                  : <> &nbsp;{fCurrency(Number(subtotal) + (Number(subtotal) * Number(taxes)) / 100)}</>
              }
            </Text>
          </View>
        </View>
        {notes && notes !== '' && notes !== '<p><br></p>' && (
          <View style={{ ...styles.notesNote, marginBottom: "20px" }}>
            <View>
              <Text style={styles.bold}>{language === 'English' ? "Notes" : "Note"}</Text>
              <View style={{...styles.light, backgroundColor:"#F0F0F0", borderRadius:"10px", padding:"5px"}}>{substrings?.length > 0 ? substrings?.map((note)=>(
                <Text style={styles.light}>
                {note.replace(/<[^>]*>/g, '')}
                </Text>
              )) : ''
            }</View>
            </View>
          </View>
        )}
      </View>
   </>
  );
};

export default TableTotals;